import { Button, CircularProgress, Divider, FormControl, Paper } from '@material-ui/core'
import classNames from 'classnames'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import _ from 'lodash'
import React from 'react'
import styles from './SignUpForm.module.scss'
import schema from './SignUpForm.schema'
import useAuth from '../../hooks/auth'
import { SignUpPayloadType } from '../../services/auth/auth.types'
import { AxiosError, AxiosResponse } from '../../utils/axios'
import DateUtil from '../../utils/date'
import handleError from '../../utils/handleError'
import FormCheckboxField from '../Form/FormCheckboxField'
import FormDateField from '../Form/FormDateField'
import FormTextField from '../Form/FormTextField'
import notify from '../Notification/notify'
import SignSocial from '../SignSocial'
import Trans from '../Trans'

type Props = {}

const SignUpForm = (props: Props) => {
  const { signUp } = useAuth()

  const onSubmit = (values: SignUpPayloadType, formikBag: FormikHelpers<SignUpPayloadType>) => {
    const newValues = _.cloneDeep(values)
    newValues.birthday = DateUtil.dateToUnixTimestamp(values.birthday as string)
    signUp(
      newValues,
      (response: AxiosResponse) => {
        notify.success('You have been successfully logged in')
        formikBag.setSubmitting(false)
      },
      (error: AxiosError) => {
        formikBag.setSubmitting(false)
        handleError(error, formikBag)
      },
    )
  }

  return (
    <Formik
      initialValues={
        {
          email: '',
          password: '',
          repeatedPassword: '',
          birthday: '',
          tos: false,
          newsletter: false,
        } as SignUpPayloadType
      }
      validationSchema={schema}
      onSubmit={(values, formikBag) => {
        onSubmit(values, formikBag)
      }}
      render={formikBag => (
        <Paper elevation={0} classes={{ root: styles['paper'] }}>
          <Form className={styles['sign-up']}>
            <Field
              name="email"
              label={<Trans ns="SignUpForm" id="SignUpForm.Email" msg="E-mail" />}
              component={FormTextField}
              fullWidth
              classes={{
                root: styles['sign-up__email'],
              }}
            />
            <Field
              type="password"
              name="password"
              label={<Trans ns="SignUpForm" id="SignUpForm.Password" msg="Password" />}
              component={FormTextField}
              fullWidth
              classes={{
                root: styles['sign-up__password'],
              }}
            />
            <Field
              type="password"
              name="repeatedPassword"
              label={<Trans ns="SignUpForm" id="SignUpForm.PasswordRepeat" msg="Repeat password" />}
              component={FormTextField}
              fullWidth
              classes={{
                root: styles['sign-up__password-repeated'],
              }}
            />
            <Field
              type="text"
              name="birthday"
              label={<Trans ns="SignUpForm" id="SignUpForm.Birthday" msg="Date of birth" />}
              component={FormDateField}
              fullWidth
              classes={{
                root: styles['sign-up__birthday'],
              }}
              pickerProps={{
                autoOk: true,
                openTo: 'year',
              }}
            />
            <Field
              type="checkbox"
              name="newsletter"
              label="Sign up to our Newsletter"
              component={FormCheckboxField}
              fullWidth
              classes={{
                root: styles['sign-up__newsletter'],
              }}
              emptyHelperText={false}
            />
            <Field
              type="checkbox"
              name="tos"
              label="I accept the Terms Of Service"
              component={FormCheckboxField}
              fullWidth
              classes={{
                root: styles['sign-up__tos'],
              }}
            />
            <FormControl fullWidth className={classNames(styles['sign-up__button'])}>
              <Button variant="contained" color="primary" onClick={formikBag.submitForm} disabled={formikBag.isSubmitting}>
                {formikBag.isSubmitting ? (
                  <CircularProgress size="1.6rem" />
                ) : (
                  <Trans ns="SignUpForm" id="SignUpForm.ButtonSignUp" msg="Sign Up" />
                )}
              </Button>
            </FormControl>
          </Form>

          <FormControl fullWidth classes={{ root: styles['sign-up__socials'] }}>
            <Divider classes={{ root: styles['sign-up__divider'] }} />
            <SignSocial
              classes={{
                facebook: styles['sign-up__facebook'],
                google: styles['sign-up__google'],
                weChat: styles['sign-ip__we-chat'],
              }}
            />
          </FormControl>
        </Paper>
      )}
    />
  )
}

export default SignUpForm
