import { Typography } from '@material-ui/core'
import React from 'react'
import Link, { routes } from '../../../components/Link'
import styles from '../FaqPage.module.scss'

const FaqPageEN = () => {
  const bodyClass = { root: styles['item__body'] }
  return (
    <>
      <ul className={styles['faq__list']}>
        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            What <span>is WhiskyMarket.com?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            WhiskyMarket.com is a global marketplace for whisky enthusiasts from all over the world. It is easy to use and safes your time.
            It takes just 15 seconds to{' '}
            <Link to={routes.signUp} classes="primary">
              become a member
            </Link>{' '}
            and fully benefit from our marketplace.
          </Typography>
        </li>

        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            Why <span>is WhiskyMarket.com a great place to be?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            Regardless who you are - a whisky trader, a private collector, or a shop owner – WhiskyMarket.com is the place to be! Imagine a
            platform where all the bottles from Instagram, facebook, twitter (you name it) come in one place. Next time you’ll be searching
            for your beloved bottle it will be easier than ever before!
          </Typography>
        </li>

        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            Who <span>can become a member?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            Everyone who is of legal drinking age can become a member and fully benefit from the platform. Just{' '}
            <Link to={routes.signUp} classes="primary">
              sign up
            </Link>{' '}
            and become one of us.
          </Typography>
        </li>

        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            Do <span>I have to register to search for bottles?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            No, you don’t have to register to search for your favorite booze. Just go to our homepage and scroll down the page, or use our
            powerful search engine to find the bottle you need.
          </Typography>
        </li>

        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            How <span>to sell a bottle?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            <Link to={routes.sell} classes="primary">
              Fill in the contact form
            </Link>{' '}
            and let one of our team members to contact you. We would be happy to help you out to sell your bottle(s).
          </Typography>
        </li>

        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            How <span>to buy?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            Nothing simpler than that! Just go to our main page and search for your favorite bottle. Once you find it, contact the seller
            directly, or ask our staff to help you out with the logistics. Our concierge service is tailor made to meet your highest
            expectations.
          </Typography>
        </li>
        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            Can <span>I post bottles by myself?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            Yes, you can. Please contact our team, so we walk you through a registration process. No matter who you are – a whisky trader,
            collector, or a shop owner – you can become a featured member and post bottles by yourself.
          </Typography>
        </li>
        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            What <span>is the concierge service?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            Our staff is here to help you out with all the concerns, that may occur. For a small fee we can handle the purchase for you, or
            sell the bottle you would like to get rid of. If needed, feel free to contact us, so we can walk you through our kingdom and
            assist you with your first steps.
          </Typography>
        </li>
        <li className={styles['list__item']}>
          <Typography variant="h3" className={styles['item__question']}>
            Can <span>I sell bottles on the platform by myself?</span>
          </Typography>
          <Typography variant="body1" classes={bodyClass}>
            Sure, you can. Please contact us and let one of our team members to create a featured account for you. With this account you can
            sell as many bottles as you want.
          </Typography>
        </li>
      </ul>
    </>
  )
}

FaqPageEN.getInitialProps = function () {
  return {}
}

export default FaqPageEN
