import { Typography } from '@material-ui/core'
import { FormikProps } from 'formik'
import React from 'react'
import styles from './MyDetails.module.scss'
import { UserType } from '../../../../interfaces/User.type'
import FormTextField from '../../../Form/FormTextField'
import Img from '../../../Img'
import Trans from '../../../Trans'
import { SettingsFormType } from '../UserSettings.types'

type Props = {
  formik: FormikProps<SettingsFormType>
  user: UserType
}

const MyDetails = (props: Props) => {
  const { formik, user } = props
  const usernameHelper = user.data?.usernameChangedAt ? 'Username already edited.' : ''
  return (
    <div className={styles['details']}>
      <Typography variant="h5" classes={{ root: styles['details__headline'] }}>
        <Trans ns="UserSettingsMyDetails" id="DetailsHeadline" msg="Details" />
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormTextField
          form={formik}
          field={{
            name: 'email',
            value: formik.values.email,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="Email address"
          InputProps={{ disabled: true }}
          fullWidth
          helperText="Email address provided in sign up form"
        />
        <FormTextField
          form={formik}
          field={{
            name: 'username',
            value: formik.values.username,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label={user.data?.isBusiness ? 'Username/Shop name' : 'Username'}
          InputProps={{ disabled: !!user.data?.usernameChangedAt }}
          fullWidth
          helperText={usernameHelper}
        />

        <div className={styles['details__username-help']}>
          <Img src="/icons/info.svg" />
          <span className={styles['username-help__text']}>
            This username has been generated automatically. Feel free to change it at any time. Please mind you can change it just once, so
            choose wisely.
            <br />
            <br />
            <a className="primary text">Remember to save your changes by clicking Save under the form</a>
          </span>
        </div>
      </form>
    </div>
  )
}

export default MyDetails
