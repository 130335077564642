import classNames from 'classnames'
import React, { useContext } from 'react'
import NewsletterSend from '../../../components/Newsletter/NewsletterSend/NewsletterSend'
import PageLayout from '../../../components/PageLayout/PageLayout'
import pageStyles from '../../../components/PageLayout/PageLayout.module.scss'
import UserContext from '../../../contexts/UserContext'

type Props = {}

const NewsletterSendPage = (props: Props) => {
  const user = useContext(UserContext)

  return (
    <PageLayout classes={classNames(pageStyles['content--sides-padding'])}>
      <NewsletterSend userData={user} />
    </PageLayout>
  )
}

export default NewsletterSendPage
