export const ORDER_CREATED_AT = 'createdAt'
export const ORDER_UPDATED_AT = 'updatedAt'
export const ORDER_EXPIRES_AT = 'expiresAt'
export const ORDER_PRICE_NET = 'priceNet'
export const ORDER_PRICE_GROSS = 'priceGross'
export const ORDER_TITLE = 'title.keyword'

export type SortByOrderType =
  | typeof ORDER_CREATED_AT
  | typeof ORDER_UPDATED_AT
  | typeof ORDER_EXPIRES_AT
  | typeof ORDER_PRICE_NET
  | typeof ORDER_PRICE_GROSS
  | typeof ORDER_TITLE

export const DIRECTION_ASC = 'asc'
export const DIRECTION_DESC = 'desc'

export type SortByDirectionType = typeof DIRECTION_ASC | typeof DIRECTION_DESC

export const ACTIVITY_ACTIVE = 'only active'
export const ACTIVITY_ALL = 'all offers'

export type ActivityType = typeof ACTIVITY_ACTIVE | typeof ACTIVITY_ALL

export const DEFAULT_ORDER = ORDER_CREATED_AT
export const DEFAULT_DIRECTION = DIRECTION_DESC
export const DEFAULT_ACTIVITY = ACTIVITY_ACTIVE

export type BasicFiltersType = {
  bottleType: string
  distillery: string
  age: string
  ageFrom: number | ''
  ageTo: number | ''
  price: string
  priceFrom: string
  priceTo: string
  activity: ActivityType
  active: boolean
  query: string
}

export type FiltersType = BasicFiltersType & {
  category: string
  bottler: string
  distilledFrom: number | ''
  distilledTo: number | ''
  bottledFrom: number | ''
  bottledTo: number | ''
  abvFrom: number | ''
  abvTo: number | ''
  sizeFrom: number | ''
  sizeTo: number | ''
  caskNumber: string
  caskType: string
  condition: string
  fillLevel: string
  availability: string
  package: string
  quantity: number | ''
  bottleLocation: string
  order: SortByOrderType
  direction: SortByDirectionType
}
