import React, { useState } from 'react'
import UserOffersView from './UserOffersView'
import OfferType from '../../../interfaces/Offer.type'
import { UserDataType } from '../../../interfaces/User.type'
import * as api from '../../../services/offer/offer.api'

export enum USER_OFFERS_TYPE {
  MY = 'my',
  OTHER = 'other',
}

type Props = {
  user: UserDataType
  ownerType: USER_OFFERS_TYPE
  items: OfferType[] | false
  onLoadMore: (clear?: boolean, all?: boolean) => Promise<void>
  onSearchClick: (value: string) => void
  onOfferUpdate: (offer: OfferType) => void
  onOffersRemove: (ids: string[]) => void
  onOffersArchive: (ids: string[]) => void
  loadMoreUrl?: string
}

const UserOffers = (props: Props) => {
  const { user, ownerType, items, loadMoreUrl } = props
  const [loading, setLoading] = useState<boolean>(false)

  const onLoadMore = async (all?: boolean): Promise<void> => {
    if (!loading) {
      setLoading(true)
      await props.onLoadMore(all, all)
      setLoading(false)
    }
  }

  const onOfferRemove = async () => {
    await props.onLoadMore(true, !loadMoreUrl)
  }

  const onOffersRemove = async (ids: string[]) => {
    await Promise.all(ids.map(id => api.deleteOffer(id)))
    await props.onLoadMore(true, !loadMoreUrl)
    props.onOffersRemove(ids)
  }

  const onOffersArchive = async (ids: string[]) => {
    await Promise.all(ids.map(id => api.archiveOffer(id)))
    props.onOffersArchive(ids)
  }

  return (
    <UserOffersView
      ownerType={ownerType}
      user={user}
      items={items}
      itemsCounter={user?.offers || 0}
      loadMoreUrl={loadMoreUrl}
      loadingItems={loading}
      onLoadMore={onLoadMore}
      onSearchClick={props.onSearchClick}
      onOfferUpdate={props.onOfferUpdate}
      onOfferRemove={onOfferRemove}
      onOffersRemove={onOffersRemove}
      onOffersArchive={onOffersArchive}
    />
  )
}

export default UserOffers
