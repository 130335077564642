import { createContext } from 'react'
import InitDataType from '../interfaces/InitData.type'

type InitContextType = InitDataType

export const defaultInitData: InitContextType = {
  offers: 0,
  loaded: false,
  homePage: {
    mainBanner: undefined,
    slides: undefined,
    topSearches: undefined,
    mostWanted: undefined,
  },
  catalog: {
    slides: undefined,
  },
}

const InitContext = createContext<InitContextType>(defaultInitData)

export default InitContext
