import Yup from '../../utils/yup'

const schema = Yup.object().shape({
  name: Yup.string().ensure().required('Requried').trim(),
  email: Yup.string().ensure().required('Requried').email('It must be a valid email address').trim(),
  phone: Yup.string().ensure().trim(),
  topic: Yup.string().ensure().required('Requried').trim(),
  question: Yup.string().ensure().max(5000, 'Must be lower or equal 5000').trim(),
  tos: Yup.boolean().required('Required').oneOf([true], 'Terms not accepted'),
})

export default schema
