import { CheckboxProps } from '@material-ui/core/Checkbox'
import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React from 'react'
import CheckboxField from '../../UI/CheckboxField'

type Props = {
  field: {
    name: string
    value: any
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
  form: FormikProps<FormikValues>
  label: React.ReactNode | string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  emptyHelperText?: boolean
  InputProps?: CheckboxProps
}

const FormCheckboxField: React.FC<Props> = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
  return (
    <CheckboxField
      {..._.pick(props, ['type', 'label', 'fullWidth', 'classes', 'children', 'emptyHelperText', 'InputProps'])}
      {...fields}
      error={Boolean(_.has(touched, fields.name) && _.has(errors, fields.name))}
      helperText={_.has(touched, fields.name) && _.has(errors, fields.name) ? (_.get(errors, fields.name) as string) : ''}
    />
  )
}

export default FormCheckboxField
