import { AttributeType, BottleAddType, BottleType } from './Bottle.type'
import { UserDataType } from './User.type'

export const MASS_ACTION_REMOVE = 'remove'
export const MASS_ACTION_ARCHIVE = 'archive'
export const MASS_ACTION_CLEAR_SELECTION = 'Clear selection'
export type MassActionType = typeof MASS_ACTION_REMOVE | typeof MASS_ACTION_ARCHIVE | typeof MASS_ACTION_CLEAR_SELECTION

export const VISIBILITY_PUBLIC = 'public'
export const VISIBILITY_PROTECTED = 'protected'
export const VISIBILITY_PRIVATE = 'private'
export type OfferVisibilityType = typeof VISIBILITY_PUBLIC | typeof VISIBILITY_PROTECTED | typeof VISIBILITY_PRIVATE

export const STATUS_SOLD = 'sold'
export const STATUS_ACTIVE = 'active'
export const STATUS_ARCHIVED = 'archived'
export const STATUS_NOT_SOLD = 'not-sold'
export type OfferStatusType = typeof STATUS_SOLD | typeof STATUS_ACTIVE | typeof STATUS_ARCHIVED | typeof STATUS_NOT_SOLD

export const EXPIRES_IN_VALUES = [14, 30, 45, 60, 90]
export type OfferExpiresInType = '' | 14 | 30 | 45 | 60 | 90

export interface OfferFormType extends BottleAddType {
  id?: string
  subtitle?: string
  condition: string
  fillLevel: string
  availability: string
  package?: string
  quantity: string
  bottleLocation: string
  price: string
  priceGross?: string
  gst: boolean
  gstRate: string | number
  negotiable?: boolean
  swap?: boolean
  pricePerLiter: string
  description?: string
  images?: ImageUploadListType | OfferImageUploadType[]
  imagesToDelete?: string[]
  bottleType: string
  phoneCode: string
  phone: string
  email: string
  buyNowAvailable: boolean
  buyNowLink: string
  expiresIn?: OfferExpiresInType
  visibility?: OfferVisibilityType
}

export type OfferImageUploadType = {
  id?: string | undefined
  data: string
  main: boolean
}

export type ImageUploadListType = {
  [name: string]: OfferImageUploadType
}

export type OfferImageType = {
  id?: string
  parentId?: string
  src: string
  main: boolean
  thumb: string
}

export type OfferPhotosType = {
  original: OfferImageType[]
  gallery: OfferImageType[]
  catalog: OfferImageType[]
  catalogList: OfferImageType[]
  product: OfferImageType[]
  icon: OfferImageType[]
  large: OfferImageType[]
}

export interface OfferType {
  type: 'offer'
  id: string
  sku: string
  subtitle?: string
  condition: AttributeType
  fillLevel: AttributeType
  availability: AttributeType
  package: AttributeType
  quantity: number
  bottleLocation: string
  priceNet: number
  priceGross: number
  gst: boolean
  gstRate: number
  negotiable: boolean
  swap: boolean
  pricePerLiterNet: number
  pricePerLiterGross: number
  description: string
  photos: OfferPhotosType
  bottle: BottleType
  age: number
  title: string
  slug: string
  phoneCode: string
  phone: string
  user: UserDataType
  buyNowAvailable: boolean
  buyNowLink: string
  expiresIn: number
  expiresAt: string
  isActive: boolean
  isArchived: boolean
  isPublic: boolean
  isSold: boolean
  isLastCall: boolean
}

export interface OfferAdType {
  type: 'ad'
  image: string
  url: string
}

export type OfferSelectFieldValueType = {
  id: string
  type: string
  name: string
  value?: string
  bottleType: string
  createdAt: string
  updatedAt: string | null
  verifiedAt: string | null
  slug: string
}

export type OfferSelectFieldType = {
  type: string
  name: string
  values: OfferSelectFieldValueType[]
}

export type OfferFieldsValuesType = {
  bottleType: OfferSelectFieldType
  category: OfferSelectFieldType
  caskType: OfferSelectFieldType
  condition: OfferSelectFieldType
  fillLevel: OfferSelectFieldType
  availability: OfferSelectFieldType
  package: OfferSelectFieldType
}

export const SELECT_FIELD_NAME_BOTTLE_TYPE = 'type'
export const SELECT_FIELD_NAME_CATEGORY = 'category'
export const SELECT_FIELD_NAME_CASK_TYPE = 'caskType'
export const SELECT_FIELD_NAME_CONDITION = 'condition'
export const SELECT_FIELD_NAME_FILL_LEVEL = 'fillLevel'
export const SELECT_FIELD_NAME_PACKAGE = 'package'
export const SELECT_FIELD_NAME_AVAILABILITY = 'availability'

export default OfferType
