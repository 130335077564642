import _ from 'lodash'
import { all, put, PutEffect, takeEvery } from 'redux-saga/effects'
import { ActionTypes, SignInSuccessActionInterface, SignUpSuccessActionInterface } from './auth.types'
import UserType from '../../interfaces/User.type'
import * as userActions from '../user/user.actions'

export function* signSuccessEvent(action: SignUpSuccessActionInterface | SignInSuccessActionInterface) {
  const user: UserType = action.payload
  const readProfile: PutEffect = yield put(userActions.signSuccess(user))
  yield all([readProfile])
}

function* signInListener() {
  yield takeEvery(ActionTypes.SIGN_IN_SUCCESS, signSuccessEvent)
}

function* signUpListener() {
  yield takeEvery(ActionTypes.SIGN_UP_SUCCESS, signSuccessEvent)
}

export default function* authSaga() {
  yield all([signInListener(), signUpListener()])
}
