import { moment } from '../../../utils/date'
import Yup from '../../../utils/yup'

const YEAR_MAX = moment().add(1, 'years')
const YEAR_MIN = moment('01/01/1800', 'dd/mm/YYYY')

const schema = Yup.object().shape({
  subtitle: Yup.string().nullable().max(35, 'Can contain 35 signs').trim(),
  bottleType: Yup.string().required('Required'),
  category: Yup.string().ensure().required('Requried').trim(),
  distillery: Yup.string().ensure().required('Requried').min(3, 'Must contain 3 signs at least').max(70, 'Can contain 70 signs').trim(),
  bottler: Yup.string().min(3, 'Must contain 3 signs at least').max(50, 'Can contain 50 signs').trim(),
  distilled: Yup.string()
    .nullable()
    // @ts-ignore
    .isValidDateRange(YEAR_MIN, YEAR_MAX, 'Wrong fromat of date', 'It must be at least 1800', 'It seems like to be from future', true),
  bottled: Yup.string()
    .nullable()
    // @ts-ignore
    .isValidDateRange(YEAR_MIN, YEAR_MAX, 'Wrong fromat of date', 'It must be at least 1800', 'It seems like to be from future', true),
  age: Yup.number().nullable().min(0, 'Must be greater or equal 0').max(999, 'Must be lower or equal 999'),
  abv: Yup.number().nullable().min(1, 'Must be greater or equal 1').max(100, 'Must be lower or equal 100'),
  size: Yup.number().required('Required').min(1, 'Must be greater or equal 1').max(4999, 'Must be lower or equal 4999'),
  caskNumber: Yup.string().min(1, 'Must contain 1 sign at least').max(10, 'Can contain 10 signs').trim(),
  caskType: Yup.string(),
  outturn: Yup.number().max(10000, 'Must be lower or equal 10 000'),
  condition: Yup.string().trim(),
  fillLevel: Yup.string().ensure().trim(),
  availability: Yup.string().required('Required').ensure().trim(),
  package: Yup.string().trim().ensure(),
  quantity: Yup.number().min(1, 'Must be greater or equal 1').max(1000, 'Must be lower or equal 1000'),
  bottleLocation: Yup.string()
    .ensure()
    //@ts-ignore
    .isValidCountryIsoCode('Must be valid country name')
    .required('Required'),
  price: Yup.string()
    //@ts-ignore
    .isValidPriceRange(1, false, 'Wrong value', 'Must be greater or equal 1')
    .required('Required'),

  priceGross: Yup.number().min(1, 'Must be greater or equal 1').required('Required'),
  gstRate: Yup.number(),
  negotiable: Yup.boolean(),
  swap: Yup.boolean(),
  description: Yup.string().max(1000, 'Can contain 1000 signs'),
  buyNowAvailable: Yup.boolean(),
  buyNowLink: Yup.string().url('Must be a valid URL'),
  expiresIn: Yup.number().required('Required'),
  hidden: Yup.boolean(),
})

export default schema
