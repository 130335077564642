import { Button } from '@material-ui/core'
import classNames from 'classnames'
import { FormikHelpers, useFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router'
import styles from './Filters.module.scss'
import schema from './Filters.schema'
import { ACTIVITY_ACTIVE, BasicFiltersType } from '../../../interfaces/Filters.type'
import { OfferFieldsValuesType } from '../../../interfaces/Offer.type'
import { getDefault as getDefaultFilters } from '../../../utils/filters'
import parsePrice from '../../../utils/parsePrice'
import AutocompleteField from '../../Catalog/Filters/Field/AutocompleteField'
import SelectField from '../../Catalog/Filters/Field/SelectField'
import { ageValues, priceValues } from '../../Catalog/Filters/Filters'
import { getDistillerySuggestions } from '../../Catalog/Filters/util/suggestions'
import { routes } from '../../Link'
import SearchInput from '../../Search/SearchInput'

type Props = {
  onSubmit: (onFilterSubmit: BasicFiltersType) => void
  onChanged: (filters: BasicFiltersType) => void
  fieldsValues?: OfferFieldsValuesType
}

const Filters = (props: Props) => {
  const history = useHistory()
  const filters = getDefaultFilters()
  const formik = useFormik({
    initialValues: {
      bottleType: filters.bottleType,
      distillery: filters.distillery,
      age: filters.age,
      ageFrom: filters.ageFrom,
      ageTo: filters.ageTo,
      price: filters.price,
      priceFrom: filters.priceFrom,
      priceTo: filters.priceTo,
      activity: filters.activity,
      active: filters.active,
      query: filters.query,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values: BasicFiltersType, formikBag: FormikHelpers<BasicFiltersType>) => {
      const newValues = _.cloneDeep(values)

      if (newValues.age) {
        const ageValue = ageValues.find(item => item.value === newValues.age)

        if (ageValue) {
          newValues.ageFrom = ageValue.min || ''
          newValues.ageTo = ageValue.max || ''
        }
      } else {
        newValues.ageFrom = ''
        newValues.ageTo = ''
      }

      if (newValues.price) {
        const priceValue = priceValues.find(item => item.value === newValues.price)

        if (priceValue) {
          newValues.priceFrom = priceValue.min ? parsePrice(priceValue.min * 100).normalized : ''
          newValues.priceTo = priceValue.max ? parsePrice(priceValue.max * 100).normalized : ''
        }
      } else {
        newValues.priceFrom = ''
        newValues.priceTo = ''
      }

      newValues.active = newValues.activity === ACTIVITY_ACTIVE

      props.onSubmit(newValues)
    },
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    formik.submitForm()
  }

  React.useEffect(() => {
    props.onChanged(formik.values)
  }, [formik.values])

  const handleSearchClick = (value: string) => {
    history.push({ pathname: routes.catalog, search: `query=${encodeURIComponent(value)}` })
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    // if ((event.charCode || event?.keyCode) === 13 || event?.key?.toLowerCase() === 'Enter') {
    //   event.preventDefault();
    // }
  }

  return (
    <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <div className={classNames(styles['form__field'], styles['form__field--search'])}>
        <SearchInput
          form={formik}
          name="query"
          onSearchClick={handleSearchClick}
          onChange={query => {
            formik.setValues({
              ...formik.values,
              query,
            })
          }}
        />
      </div>
      <div className={styles['filters']}>
        <div className={styles['form__field']}>
          <SelectField
            form={formik}
            name="bottleType"
            label="Type"
            classes={{ root: styles['field'] }}
            values={props.fieldsValues?.bottleType.values || []}
          />
        </div>
        <div className={styles['form__field']}>
          <AutocompleteField
            form={formik}
            name="distillery"
            label="Distillery"
            placeholder="Write"
            classes={{ root: styles['field'] }}
            onLoadSuggestions={getDistillerySuggestions}
            submitOnEnter={false}
          />
        </div>
        <div className={styles['form__field']}>
          <SelectField form={formik} name="age" label="Age" classes={{ root: styles['field'] }} values={ageValues} />
        </div>
        <div className={styles['form__field']}>
          <SelectField form={formik} name="price" label="Price" classes={{ root: styles['field'] }} values={priceValues} />
        </div>
      </div>
      <div className={styles['buttons']}>
        <div className={styles['buttons__container']}>
          <Button
            disabled={formik.isSubmitting}
            variant="contained"
            type="submit"
            color="primary"
            classes={{
              root: classNames(styles['buttons__button'], styles['buttons__button--primary'], styles['buttons__button--submit']),
            }}
          >
            Show results
          </Button>
        </div>
      </div>
    </form>
  )
}

export default Filters
