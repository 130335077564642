import { Button, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import styles from './CookiesConsent.module.scss'
import usePrerender from '../../hooks/prerender'
import * as actions from '../../services/app/app.actions'
import { ActionTypes } from '../../services/app/app.types'
import { StateType } from '../../services/reducers'
import Link, { routes } from '../Link'

export const LS_COOKIES_CONSENT = 'cookies_consent'

type Props = {
  show?: boolean
  onShown: (height: number) => void
  onHide: () => void
}

const removeElementByIds = () => {
  for (const elementId of ['googleScript', 'facebookScript', 'webbotScript']) {
    const element = document.getElementById(elementId)
    if (element && element.parentNode) {
      element.parentNode.removeChild(element)
    }
  }
}

const removeElementsByTagName = (tagName: string) => {
  const elements = document.getElementsByTagName(tagName)
  const elementList = Array.prototype.slice.call(elements)
  for (const element of elementList) {
    for (const src of ['webbot', 'google', 'facebook']) {
      if (element.src.indexOf(src) > 0 && element.parentNode) {
        element.parentNode.removeChild(element)
      }
    }
  }
}

const CookieConsent = (props: Props) => {
  const divRef = React.useRef<HTMLDivElement | null>(null)
  const dispatch: Dispatch<ActionTypes> = useDispatch()
  const acceptedCookies = useSelector((state: StateType) => state.app.acceptedCookies)
  const [showConsent, setShowConsent] = useState<boolean>(!(window.localStorage && window.localStorage.getItem(LS_COOKIES_CONSENT)))
  const isPrerender = usePrerender().isPrerender

  React.useEffect(() => {
    if (showConsent) {
      props.onShown(divRef.current?.clientHeight || 0)
    } else {
      props.onHide()
    }
  }, [showConsent])

  const removeScripts = () => {
    if (document) {
      // Lista skryptów z _document.tsx
      removeElementByIds()
      removeElementsByTagName('script')
      removeElementsByTagName('iframe')
    }
  }

  const close = () => {
    window.localStorage.setItem(LS_COOKIES_CONSENT, '1')
    setShowConsent(false)
  }

  const handleAccept = () => {
    dispatch(actions.cookiesAccept())
    close()
  }

  const handleRefuse = () => {
    dispatch(actions.cookiesRefuse())
    removeScripts()
    close()
  }

  if (!acceptedCookies) {
    removeScripts()
  }

  return showConsent && !isPrerender ? (
    <div className={styles['cookies']} ref={divRef}>
      <Typography variant="body1">
        This site uses cookies to offer you a better browsing experience. Find out more on how we use cookies and how you can change your
        settings (
        <Link to={routes.gdpr} classes="inverted">
          privacy policy
        </Link>
        ).
      </Typography>
      <Button color="secondary" variant="contained" onClick={handleAccept} classes={{ root: styles['cookies__button'] }}>
        I accept cookies
      </Button>
      <Button color="secondary" variant="text" onClick={handleRefuse} classes={{ root: styles['cookies__button'] }}>
        I refuse cookies
      </Button>
    </div>
  ) : null
}

export default CookieConsent
