import { SelectProps } from '@material-ui/core/Select'
import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React from 'react'
import ChildrenType from '../../../interfaces/Children.type'
import SelectField from '../../UI/SelectField'

type Props = {
  field: {
    name: string
    value: any
    onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  }
  form: FormikProps<FormikValues | any>
  label?: (JSX.Element & string) | string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  InputProps?: SelectProps
  children: ChildrenType
  classes?: object
  withMargins?: boolean
}

const FormTextField: React.FC<Props> = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
  return (
    <SelectField
      {..._.pick(props, ['type', 'label', 'fullWidth', 'classes', 'children', 'withMargins'])}
      {...fields}
      disabled={props.InputProps?.disabled}
      error={Boolean(_.has(touched, fields.name) && _.has(errors, fields.name))}
      helperText={_.has(touched, fields.name) && _.has(errors, fields.name) ? _.get(errors, fields.name) : ''}
    >
      {props.children}
    </SelectField>
  )
}

export default FormTextField
