import {
  Button,
  Checkbox,
  Divider,
  Popover,
  Typography
  } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import Dinero from 'dinero.js'
import React, { useRef } from 'react'
import { ACTION_STEP } from './OfferListItem'
import styles from './OfferListItem.module.scss'
import useMatchMedia from '../../hooks/matchMedia'
import OfferType from '../../interfaces/Offer.type'
import countries from '../../utils/countries'
import { priceToInt } from '../../utils/tax'
import Dialog, { DISPLAY_MODE } from '../Dialog'
import Img from '../Img'
import Link, { routes } from '../Link'
import FavoriteButton from '../UI/FavoriteButton'
import ShareButton from '../UI/ShareButton'
import { USER_OFFERS_TYPE } from '../User/UserOffers/UserOffers'

type Props = {
  offer: OfferType
  mine: boolean
  isFavorite: boolean
  onShareBoxClose: () => void
  archiveStep: ACTION_STEP
  deleteStep: ACTION_STEP
  onRemove: () => void
  onArchive: () => void
  onActivate: () => void
  openShareBox: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  typeLabel: string
  sharingBoxOpen: boolean
  shareUrl: string
  selected: boolean
  ownerType: USER_OFFERS_TYPE
  onShareClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onAddToFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onRemoveFromFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onDeleteConfirmed: () => void
  onDeleteCanceled: () => void
  onArchiveConfirmed: () => void
  onArchiveCanceled: () => void
  onSelectedChange?: (offerId: string, isChecked: boolean) => void
}

const OfferListItemView = (props: Props) => {
  const {
    offer,
    mine,
    onShareBoxClose,
    archiveStep,
    deleteStep,
    sharingBoxOpen,
    typeLabel,
    onRemove,
    onArchive,
    onActivate,
    onArchiveConfirmed,
    onArchiveCanceled,
    openShareBox,
    shareUrl,
    onDeleteConfirmed,
    onDeleteCanceled,
  } = props

  const rootClasses = classNames(styles['item'], {
    [styles['item--deleted']]: deleteStep === ACTION_STEP.DONE,
  })

  const matchMedia = useMatchMedia()
  const shareLinkRef = useRef<HTMLAnchorElement>(null)
  const priceGross = new BigNumber(offer.priceGross).multipliedBy(100).toString()
  const priceGrossFormatted = Dinero({ amount: priceToInt(priceGross) }).toFormat('0,0.00')
  const priceNet = new BigNumber(offer.priceNet).multipliedBy(100).toString()
  const priceNetFormatted = Dinero({ amount: priceToInt(priceNet) }).toFormat('0,0.00')
  const pricePerLiter = new BigNumber(offer.pricePerLiterGross).multipliedBy(100).toString()
  const pricePerLiterFormatted = Dinero({ amount: priceToInt(pricePerLiter) }).toFormat('0,0.00')

  if (deleteStep === ACTION_STEP.DONE) {
    return null
  }

  return (
    <>
      <Popover
        PaperProps={{
          onMouseLeave: onShareBoxClose,
        }}
        onBackdropClick={onShareBoxClose}
        elevation={4}
        id={`item-share-${offer.id}`}
        open={sharingBoxOpen}
        anchorEl={shareLinkRef.current!}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: styles['share-popover'],
        }}
      >
        <Typography variant="body1" classes={{ root: styles['share-popover__headline'] }}>
          Share this offer
        </Typography>
        <Divider />
        <ul className={styles['share-popover__share-icons']}>
          <li className={styles['share-icons__item']}>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}>
              <Img src="/icons/social_face.svg" />
            </a>
          </li>
          <li className={styles['share-icons__item']}>
            <a href={`mailto:?subject=${offer.title}&body=${shareUrl}`}>
              <Img src="/icons/chat_mail.svg" classes={styles['share__mail']} />
            </a>
          </li>
        </ul>
      </Popover>
      <div className={styles['item__row']}>
        {mine && matchMedia.sm && props.onSelectedChange ? (
          <div className={styles['item__checkbox']}>
            <Checkbox
              color="primary"
              checked={props.selected}
              onChange={event => props.onSelectedChange!(offer.id, event.target.checked)}
            />
          </div>
        ) : null}
        <div className={rootClasses}>
          <div className={styles['item__photo']}>
            <Link to={routes.offer} params={{ sku: offer.sku, slug: offer.slug }}>
              <Img
                classes={classNames(styles['photo__image'], !offer.isActive && styles['photo__image--inactive'])}
                src={offer.photos.catalogList?.[0].src || ''}
              />
            </Link>
            <div className={styles['photo__country']}>
              <span className={classNames(styles['country__flag'], 'flag-icon', `flag-icon-${offer.bottleLocation.toLowerCase()}`)} />
              <span className={styles['country__name']}> {countries.alpha2ToAlpha3(offer.bottleLocation)}</span>
            </div>
            {!offer.isPublic && <Img src="/ico/hidden.svg" classes={styles['photo__icon--hidden']} />}
          </div>
          <div className={styles['item__info']}>
            <div className={styles['info__title']}>
              <Link to={routes.offer} params={{ sku: offer.sku, slug: offer.slug }}>
                {offer.title}
              </Link>
              <div className={styles['info__subtitle']}>{offer.subtitle}</div>
            </div>
            {mine ? (
              <div className={styles['item__nav--top']}>
                <div className={styles['nav']}>
                  <div className={styles['nav__items']}>
                    {offer.isActive ? (
                      <div className={styles['nav__item']}>
                        <a className={classNames('primary', styles['nav__link'])} onClick={onArchive}>
                          <img className={styles['nav__link__icon']} src="/icons/offer__action--archive.svg" alt="Archive" />
                          Archive
                        </a>
                      </div>
                    ) : null}
                    <div className={styles['nav__item']}>
                      <a className={classNames('primary', styles['nav__link'])} onClick={onRemove}>
                        <img className={styles['nav__link__icon']} src="/icons/offer__action--remove.svg" alt="Remove" />
                        Remove
                      </a>
                    </div>
                    <div className={styles['nav__item']}>
                      <Link classes={classNames('primary', styles['nav__link'])} to={routes.offerEdit} params={{ id: offer.id }}>
                        <img className={styles['nav__link__icon']} src="/icons/offer__action--edit.svg" alt="Edit" />
                        Edit
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles['item__actions']}>
              <FavoriteButton onAdd={props.onAddToFavorite} onRemove={props.onRemoveFromFavorite} isFavorite={props.isFavorite} />
              <ShareButton onClick={props.onShareClick} />
            </div>
          </div>
          <div className={styles['item__price']}>
            <Typography variant="h5" classes={{ root: styles['price__gross'] }}>
              {priceGrossFormatted} EUR
            </Typography>
            <Typography variant="caption">{priceNetFormatted} EUR Net</Typography>
            <Typography variant="caption">{pricePerLiterFormatted} EUR / Liter</Typography>
            <div className={styles['item__availability']}>
              {offer.isActive && !offer.isArchived ? (
                <>
                  {offer.availability.slug ? (
                    <Img src={`/icons/availability_${offer.availability.slug}.svg`} classes={styles['availability__icon']} />
                  ) : null}
                  Available
                </>
              ) : (
                <>
                  <Img src="/icons/availability_sold.svg" classes={styles['availability__icon']} />
                  Archived
                </>
              )}
            </div>
          </div>
          {mine ? (
            <div className={styles['item__nav--bottom']}>
              <Divider classes={{ root: styles['item__divider'] }} />
              <div className={styles['nav']}>
                {props.onSelectedChange ? (
                  <div className={styles['nav__checkbox']}>
                    <Checkbox
                      color="primary"
                      checked={props.selected}
                      onChange={event => props.onSelectedChange!(offer.id, event.target.checked)}
                    />
                  </div>
                ) : null}
                <div className={styles['nav__items']}>
                  {offer.isActive ? (
                    <div className={styles['nav__item']}>
                      <a className={classNames('primary', styles['nav__link'])} onClick={onArchive}>
                        <img className={styles['nav__link__icon']} src="/icons/offer__action--archive.svg" alt="Archive" />
                        Archive
                      </a>
                    </div>
                  ) : null}
                  <div className={styles['nav__item']}>
                    <a className={classNames('primary', styles['nav__link'])} onClick={onRemove}>
                      <img className={styles['nav__link__icon']} src="/icons/offer__action--remove.svg" alt="Remove" />
                      Remove
                    </a>
                  </div>
                  <div className={styles['nav__item']}>
                    <Link classes={classNames('primary', styles['nav__link'])} to={routes.offerEdit} params={{ id: offer.id }}>
                      <img className={styles['nav__link__icon']} src="/icons/offer__action--edit.svg" alt="Edit" />
                      Edit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {archiveStep === ACTION_STEP.TO_DO || archiveStep === ACTION_STEP.IN_PROGRESS ? (
        <Dialog
          open
          withLogo={false}
          onClose={onArchiveCanceled}
          displayMode={DISPLAY_MODE.fullScreenMobileOnly}
          classes={{ root: styles['remove-popup'] }}
        >
          <div className={styles['remove-popup__content']}>
            <Typography variant="subtitle1">Please confirm that you want to archive this offer.</Typography>
            <div className={styles['remove-popup__navi']}>
              <Button variant="contained" color="default" onClick={() => onArchiveCanceled()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onArchiveConfirmed()}
                disabled={archiveStep === ACTION_STEP.IN_PROGRESS}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>
      ) : null}
      {deleteStep === ACTION_STEP.TO_DO || deleteStep === ACTION_STEP.IN_PROGRESS ? (
        <Dialog
          open
          withLogo={false}
          onClose={onDeleteCanceled}
          displayMode={DISPLAY_MODE.fullScreenMobileOnly}
          classes={{ root: styles['remove-popup'] }}
        >
          <div className={styles['remove-popup__content']}>
            <Typography variant="subtitle1">Please confirm that you want to remove this offer.</Typography>
            <div className={styles['remove-popup__navi']}>
              <Button variant="contained" color="default" onClick={() => onDeleteCanceled()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onDeleteConfirmed()}
                disabled={deleteStep === ACTION_STEP.IN_PROGRESS}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>
      ) : null}
    </>
  )
}

export default OfferListItemView
