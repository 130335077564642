import _ from 'lodash'
import { defaultInitData } from '../contexts/InitContext'
import { Result as MatchMediaType } from '../hooks/matchMedia'
import InitDataType, { SlideType } from '../interfaces/InitData.type'

const parseInitData = (data: Record<string, any>, matchMedia: MatchMediaType): Omit<InitDataType, 'loaded'> => {
  const size = matchMedia.lg ? 'lg' : matchMedia.sm ? 'md' : 'xs'
  const catalogSize = matchMedia.md ? 'lg' : matchMedia.sm ? 'md' : 'xs'

  let slides: SlideType[] =
    data?.homePage?.slides && _.isArray(data.homePage.slides) ? _.cloneDeep(data.homePage.slides) : defaultInitData.homePage.slides || []
  slides = slides.filter((item: Record<string, any>) => item.size === undefined || item.size === size)

  let catalogSlides: SlideType[] =
    data?.catalog?.slides && _.isArray(data.catalog.slides) ? _.cloneDeep(data.catalog.slides) : defaultInitData.catalog.slides || []
  catalogSlides = catalogSlides.filter((item: Record<string, any>) => item.size === undefined || item.size === catalogSize)

  return {
    offers: parseInt(data?.offers, 10) || defaultInitData.offers,
    homePage: {
      mainBanner: data?.homePage?.mainBanner || defaultInitData.homePage.mainBanner,
      slides,
      topSearches: data?.homePage?.topSearches || defaultInitData.homePage.topSearches,
      mostWanted: data?.homePage?.mostWanted || defaultInitData.homePage.mostWanted,
    },
    catalog: {
      slides: catalogSlides,
    },
  }
}

export default parseInitData
