import * as Yup from 'yup'
import countries from './countries'
import DateUtil, { moment } from './date'
import { normalizeCurrencyValue } from './tax'
import store from '../services/store'
/*eslint no-template-curly-in-string: "off"*/

function equalTo(ref: any, msg: any) {
  return Yup.mixed().test('equal-to', `${'${path}'} must be the same as ${ref.path}`, function (this: Yup.TestContext, value: any) {
    return value === this.parent[ref.path]
  })
}

function isValidDateRange(
  minMomentDate: moment.Moment,
  maxMomentDate: moment.Moment,
  wrongDateMessage: string,
  wrongMinDateMessage: string,
  wrongMaxDateMessage: string,
  onlyYear: boolean = false,
) {
  //@ts-ignore
  return this.test('is-valid-date-range', wrongDateMessage, function (value: string) {
    //@ts-ignore
    const { path, createError } = this
    const minDate = onlyYear ? moment(minMomentDate.format('Y'), 'Y') : minMomentDate
    const maxDate = onlyYear ? moment(maxMomentDate.format('Y'), 'Y') : maxMomentDate

    //TODO: possible bug when DateField will has passed forced dateFormat
    const momentDate = moment(
      value,
      onlyYear ? ' Y' : DateUtil.getDateFormatPattern(<moment.Moment>(<unknown>moment), 'L'),
      store.getState().app.language.fullIsoCode.toLowerCase(),
      false,
    )
    //@ts-ignore
    if (!value && this.schema._nullable) {
      return true
    }
    if (!momentDate.isValid()) {
      const message = wrongDateMessage
      return createError({
        path,
        message,
      })
    }
    if (minDate > momentDate) {
      const message = wrongMinDateMessage
      return createError({
        path,
        message,
      })
    }

    if (maxDate < momentDate) {
      const message = wrongMaxDateMessage
      return createError({
        path,
        message,
      })
    }

    return true
  })
}

function isValidCountryIsoCode(message: string) {
  //@ts-ignore
  return this.test('is-valid-country-iso-code', message, function (value: string) {
    //@ts-ignore
    const { path, createError } = this
    const isoCode = countries.getAlpha2Code(value, 'en') //FIXME if multilanguage added
    //@ts-ignore
    if (!value && this.schema._nullable) {
      return true
    }
    if (countries.isValid(isoCode)) {
      return true
    }

    return createError({
      path,
      message,
    })
  })
}

function isValidPriceRange(
  minValue: number | false,
  maxValue: number | false,
  wrongValueFormat: string,
  wrongMinValueMessage?: string,
  wrongMaxValueMessage?: string,
) {
  //@ts-ignore
  return this.test('is-valid-price-rangee', wrongValueFormat, function (value: string) {
    //@ts-ignore
    const { path, createError } = this
    const price = parseFloat(normalizeCurrencyValue(value))

    if (minValue && minValue > price)
      return createError({
        path,
        wrongMinValueMessage,
      })

    if (maxValue && maxValue < price)
      return createError({
        path,
        wrongMaxValueMessage,
      })

    if (Number.isNaN(price)) {
      return createError({
        path,
        wrongValueFormat,
      })
    }
    return true
  })
}

Yup.addMethod(Yup.string, 'equalTo', equalTo)
Yup.addMethod(Yup.string, 'isValidDateRange', isValidDateRange)
Yup.addMethod(Yup.date, 'isValidDateRange', isValidDateRange)
Yup.addMethod(Yup.string, 'isValidCountryIsoCode', isValidCountryIsoCode)
Yup.addMethod(Yup.string, 'isValidPriceRange', isValidPriceRange)

export default Yup
