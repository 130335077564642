import { Typography } from '@material-ui/core'
import React from 'react'
import styles from '../GDPRPage.module.scss'

const GDPRPageEN = () => {
  const bodyClass = { root: styles['list__text'] }
  const [a] = React.useState()

  return (
    <>
      <Typography variant="h3" classes={{ root: styles['heading'] }}>
        Privacy Policy
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Unless otherwise stated below, the provision of your personal data is not required by law or contract, nor is it necessary for the
        conclusion of the contract. You do not have the obligation to provide your data. No effect shall arise as a result of your failure
        to provide data. This shall apply only insofar as no other information is provided in the following processing procedures.
        <br />
        &quot;Personal data&quot; shall mean any information relating to an identified or identifiable natural person.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Server log files
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        You can visit our websites without providing any personal information. <br />
        Whenever you access our website, usage data shall be transmitted to us or our web host / IT service provider by your internet
        browser and stored in log files (the so-called server log files). Such stored data shall include, for example, the name of the site
        accessed, date and time of access, IP address, amount of data transferred and the requesting provider. The processing is carried out
        on the basis of Art. 6 Para. 1 lit. f of the DSGVO (German Data Protection Act) in pursuit of our predominant legitimate interest in
        ensuring trouble-free operation of our website and improvement of our offer.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Customer’s contact by e-mail at his/her own initiative
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        If you contact us by e-mail at your own initiative, we will only collect your personal data (name, e-mail address, message text) to
        the extent that you provide the same. Data processing serves to process and answer your contact requests.
        <br />
        If the purpose of the contact is to carry out pre-contractual measures (e.g. advice in case of intended purchase, preparation of
        offers) or if it concerns a contract already concluded between us, such data processing will be carried out on the basis of Art. 6
        para. 1 lit. b of the DSGVO.
        <br />
        If contact is established for other reasons, data processing will be carried out on the basis of Art. 6 Para. 1 letter f of the
        DSGVO due to our predominantly justified interest in processing and answering your enquiry. In this case, you have the right to
        object at any time, for reasons arising from your particular situation, to the processing of personal data relating to you on the
        basis of Art. 6 Paragraph 1 letter f of the Data Protection Act.
        <br />
        We will use your e-mail address only to process your requests. Your data will then be deleted in compliance with the statutory
        retention periods, unless you have consented to further processing and use.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Collection and processing when using our contact form
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        When you use our contact form, we will only collect your personal data (name, e-mail address, message text) to the extent that you
        have provided the same. Data processing serves the purpose of establishing contact. By sending your message, you agree to the
        processing of the transmitted data. Such processing is based on Art. 6 para. 1 lit. a of the DSGVO with your consent.
        <br />
        You may revoke your consent at any time by notifying us, however, this will not affect the lawfulness of any processing on the basis
        of your consent prior to its revocation. We will only use your e-mail address to process your requests. Your data will then be
        deleted unless you have agreed to further processing and use.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Orders
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Collection, processing and transfer of personal data for orders
        <br />
        When you place an order, we will collect and process your personal data only to the extent necessary to fulfil and process your
        order and to process your enquiries. Provision of data is necessary for the conclusion of the contract. Failure to provide the data
        will lead to the inability to conclude any contract. Processing is based on Art. 6 para. 1 lit. b of the DSGVO and is necessary for
        the performance of your contract.
        <br />
        Your data will be transferred, for example, to shipping companies and dropshipping providers you have chosen, payment service
        providers, order processing service providers and IT service providers. In each case, we will strictly observe the applicable legal
        requirements. Data transfer is limited to a minimum extent.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Cookies
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Our website uses cookies. Cookies are small text files that are stored in the internet browser or by the internet browser on a
        user&apos;s computer system. If a user calls up a website, a cookie may be stored in the user&apos;s operating system. This cookie
        contains a characteristic string of characters that enables the browser to be uniquely identified when the website is called up
        again.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Cookies are stored on your computer. Accordingly, you have full control over the use of cookies. By selecting the appropriate
        technical settings in your internet browser, you will be notified before any cookies are set and you can decide individually whether
        or not to accept them, and to prevent the storage of cookies and transmission of the data they contain. Any cookies which are
        already stored can be deleted at any time. However, we would like to point out that you may not be able to use all functions of this
        website to their full extent if you delete the cookies.
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        Under the following links you can learn how to manage cookies of the most important browsers (including deactivating them):
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            Chrome:&nbsp;
            <a href="https://support.google.com/accounts/answer/61416?hl=en" className="primary" target="_blank" rel="noreferrer">
              https://support.google.com/accounts/answer/61416?hl=en
            </a>
          </li>
          <li className={styles['list__item']}>
            Internet Explorer:&nbsp;
            <a
              href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              className="primary"
              target="_blank"
              rel="noreferrer"
            >
              https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
            </a>
          </li>
          <li className={styles['list__item']}>
            Mozilla Firefox:&nbsp;
            <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" className="primary" target="_blank" rel="noreferrer">
              https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
            </a>
          </li>
          <li className={styles['list__item']}>
            Safari:&nbsp;
            <a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac" className="primary" target="_blank" rel="noreferrer">
              https://support.apple.com/en-ie/guide/safari/sfri11471/mac
            </a>
          </li>
        </ul>
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Cookies necessary for technical reasons
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Unless otherwise stated in the data protection declaration below, we will only use cookies that are necessary for technical reasons
        for the purpose of making our offer more user-friendly, effective and secure. Furthermore, cookies enable our systems to recognize
        your browser even after a page change and to offer services to you. Some functions of our website cannot be offered without the use
        of cookies. This, the browser needs to be recognized even after a page change.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        The use of cookies or comparable technologies is based on § 15 paragraph 3 sentence 1 of the TMG. Your personal data is processed
        based on Art. 6 para. 1 lit. f of the DSGVO in pursuit of our predominant legitimate interest to ensure optimal functionality of the
        website and a user-friendly and effective design of our offer.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        You have the right to object to the processing of your personal data at any time for reasons arising from your particular situation.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Rights of data subjects and storage period
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Storage period
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        After the contract has been completely processed, the data will be stored initially for the duration of the warranty period, and
        thereafter, in consideration of any legal, in particular tax and commercial law, retention periods, and then deleted after such
        period has expired, unless you have consented to further processing and use.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Rights of data subjects
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        If the legal requirements are met, you may exercise the following rights in accordance with Art. 15 to 20 of the DSGVO: right of
        information, rectification, deletion, restriction of processing, and the right of data transferability.
        <br />
        In addition, according to Art. 21 para. 1 of the DSGVO, you have the right to object to processing based on Art. 6 para. 1 f of the
        DSGVO, as well as processing for the purpose of direct advertising.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Users&apos; personal data will be deleted automatically after 5 years from the last login. Users can request deletion of personal
        data at any time by sending an email to{' '}
        <a href="mailto:info@whiskymarket.com" className="primary" target="_blank" rel="noreferrer">
          info@whiskymarket.com
        </a>
        . Offers published by users will not be deleted.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Right of appeal to the supervisory authority
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        In accordance with Art. 77 of the DSGVO, you have the right to submit a complain to the supervisory authority if you believe that
        your personal data is being processed unlawfully.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Right of objection
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        If the personal data processing activities listed herein are based on our legitimate interest pursuant to Art. 6 Par. 1 letter f of
        the DSGVO, you have the right to object to such activities at any time for reasons arising from your particular situation, with
        effect in the future.
        <br />
        After the objection has been made, the processing of the data concerned will be terminated unless we can evidence and provide any
        compelling reasons for processing which need to be protected and outweigh your interests, rights and freedoms, or if the processing
        serves the assertion, exercise or defence of legal claims.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        If personal data is processed for purposes of direct advertising, you can object to such processing at any time by giving us a
        notice. Upon receipt of your notice, we will stop processing the data in question for the purpose of direct marketing.
      </Typography>
    </>
  )
}

GDPRPageEN.getInitialProps = function () {
  return {}
}

export default GDPRPageEN
