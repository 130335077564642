import {
  Button,
  CircularProgress,
  FormControl,
  Paper
  } from '@material-ui/core'
import classNames from 'classnames'
import {
  Field,
  Form,
  Formik,
  FormikHelpers
  } from 'formik'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styles from './ResetPasswordForm.module.scss'
import schema from './ResetPasswordForm.schema'
import useAuth from '../../hooks/auth/auth'
import { ChangePasswordPayloadType } from '../../services/auth/auth.types'
import { AxiosError, AxiosResponse } from '../../utils/axios'
import handleError from '../../utils/handleError'
import FormTextField from '../Form/FormTextField/FormTextField'
import { routes } from '../Link'
import notify from '../Notification/notify'
import Trans from '../Trans/Trans'

type UrlParamsType = {
  userId: string
  token: string
}

type Props = {}

const ResetPasswordForm = (props: Props) => {
  const { changePassword } = useAuth()
  const history = useHistory()
  const params = useParams<UrlParamsType>()

  const onSubmit = (values: ChangePasswordPayloadType, formikBag: FormikHelpers<ChangePasswordPayloadType>) => {
    changePassword(
      values,
      (response: AxiosResponse) => {
        notify.success('You have been successfully logged in')
        history.push(routes.home)
      },
      (error: AxiosError) => {
        formikBag.setSubmitting(false)
        handleError(error, formikBag)
      },
    )
  }

  return (
    <Formik
      initialValues={
        {
          userId: params.userId,
          token: params.token,
          password: '',
          repeatedPassword: '',
        } as ChangePasswordPayloadType
      }
      validationSchema={schema}
      onSubmit={(values, formikBag) => {
        onSubmit(values, formikBag)
      }}
      render={formikBag => (
        <Paper elevation={0} classes={{ root: styles['paper'] }}>
          <Form className={styles['reset-password']}>
            <input type="hidden" name="userId" value={formikBag.values.userId} />
            <input type="hidden" name="token" value={formikBag.values.token} />
            <Field
              type="password"
              name="password"
              label={<Trans ns="ResetPasswordForm" id="ResetPasswordForm.NewPassword" msg="New password" />}
              component={FormTextField}
              fullWidth
              classes={{
                root: styles['reset-password__password'],
              }}
            />
            <Field
              type="password"
              name="repeatedPassword"
              label={<Trans ns="ResetPasswordForm" id="ResetPasswordForm.RepeatPassword" msg="Repeat password" />}
              component={FormTextField}
              fullWidth
              classes={{
                root: styles['reset-password__password-repeated'],
              }}
            />
            <FormControl fullWidth className={classNames(styles['reset-password__button--submit'])}>
              <Button variant="contained" color="primary" onClick={formikBag.submitForm} disabled={formikBag.isSubmitting}>
                {formikBag.isSubmitting ? (
                  <CircularProgress size="1.6rem" />
                ) : (
                  <Trans ns="ResetPasswordForm" id="ResetPasswordForm.ButtonSubmit" msg="Change password" />
                )}
              </Button>
            </FormControl>
          </Form>
        </Paper>
      )}
    />
  )
}

export default ResetPasswordForm
