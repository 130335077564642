import { moment } from '../../../utils/date'
import Yup from '../../../utils/yup'

export const YEAR_MIN = 1800
export const YEAR_MAX = new Date().getFullYear() + 1
export const ABV_MIN = 1
export const ABV_MAX = 100
export const SIZE_MIN = 1
export const SIZE_MAX = 4999

const schema = Yup.object().shape({
  bottleType: Yup.string().nullable(),
  distillery: Yup.string().nullable().max(70, 'Can contain 70 signs').trim(),
  bottler: Yup.string().nullable().max(50, 'Can contain 50 signs').trim(),
  category: Yup.string().nullable().trim(),
  distilledFrom: Yup.number()
    .nullable()
    .typeError('Must be a number')
    .min(YEAR_MIN, `Must be greater or equal ${YEAR_MIN}`),
  distilledTo: Yup.number()
    .nullable()
    .typeError('Must be a number')
    .min(YEAR_MIN, `Must be greater or equal ${YEAR_MIN}`)
    .when('distilledFrom', {
      is: val => val != null && !Number.isNaN(val),
      then: Yup.number().min(Yup.ref('distilledFrom'), m => `Must be greater or equal ${m.min || YEAR_MIN}`)
    })
    .max(YEAR_MAX, 'It seems like to be from future'),
  bottledFrom: Yup.number()
    .nullable()
    .typeError('Must be a number')
    .min(YEAR_MIN, `Must be greater or equal ${YEAR_MIN}`),
  bottledTo: Yup.number()
    .nullable()
    .typeError('Must be a number')
    .min(YEAR_MIN, `Must be greater or equal ${YEAR_MIN}`)
    .when('bottledFrom', {
      is: val => val != null && !Number.isNaN(val),
      then: Yup.number().min(Yup.ref('bottledFrom'), m => `Must be greater or equal ${m.min || YEAR_MIN}`)
    })
    .max(YEAR_MAX, 'It seems like to be from future'),
  caskNumber: Yup.string().nullable().min(1, 'Must contain 1 sign at least').max(10, 'Can contain 10 signs').trim(),
  abvFrom: Yup.number()
    .nullable()
    .typeError('Must be a number')
    .min(ABV_MIN, `Must be greater or equal ${ABV_MIN}`)
    .max(ABV_MAX, `Must be lower or equal ${ABV_MAX}`),
  abvTo: Yup.number()
    .nullable()
    .typeError('Must be a number')
    .min(ABV_MIN, `Must be greater or equal ${ABV_MIN}`)
    .when('bottledFrom', {
      is: val => val != null && !Number.isNaN(val),
      then: Yup.number().min(Yup.ref('abvFrom'), m => `Must be greater or equal ${m.min || ABV_MIN}`)
    })
    .max(ABV_MAX, `Must be lower or equal ${ABV_MAX}`),
  sizeFrom: Yup.number()
    .nullable()
    .typeError('Must be a number')
    .min(SIZE_MIN, `Must be greater or equal ${SIZE_MIN}`)
    .max(SIZE_MAX, `Must be lower or equal ${SIZE_MAX}`),
  sizeTo: Yup.number()
    .nullable()
    .typeError('Must be a number')
    .min(SIZE_MIN, `Must be greater or equal ${SIZE_MIN}`)
    .when('bottledFrom', {
      is: val => val != null && !Number.isNaN(val),
      then: Yup.number().min(Yup.ref('sizeFrom'), m => `Must be greater or equal ${m.min || SIZE_MIN}`)
    })
    .max(SIZE_MAX, `Must be lower or equal ${SIZE_MAX}`),
  bottleLocation: Yup.string()
    .nullable()
    //@ts-ignore
    .isValidCountryIsoCode('Must be valid country name'),
})

export default schema
