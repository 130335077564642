import axios from '../../utils/axios'

type Params = {
  userId?: string | null
  query: string
}

export function search(params: Params) {
  return axios({
    method: 'GET',
    url: `/search?userId=${params.userId || ''}&query=${encodeURIComponent(params.query)}`,
  })
}
