import { Button, Typography } from '@material-ui/core'
import classNames from 'classnames'
import {
  Field,
  Form,
  Formik,
  FormikHelpers,
  FormikProps
  } from 'formik'
import _ from 'lodash'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from './SellForm.module.scss'
import schema from './SellForm.schema'
import { FormType } from './SellForm.types'
import { renderSelectField, renderTextarea, renderTextField } from './SellFormFields'
import { FileUploadListType, SellTopic } from '../../interfaces/Contact.type'
import { UserType } from '../../interfaces/User.type'
import FormCheckboxField from '../Form/FormCheckboxField'
import ImageUpload, { MODE_TYPE } from '../ImageUpload'
import Img from '../Img'
import notify from '../Notification/notify'
import Trans from '../Trans'

export type Props = {
  user: UserType
  topics: SellTopic[]
  files: FileUploadListType
  minFileSize: number
  maxFileSize: number
  onFileSizeError: (tooBig: boolean, tooSmall: boolean) => void
  onFileTypeError: (fileType: string) => void
  onFileError: (file: File | null, reason: string) => void
  onFileUpload: (file: File, base64: string, imageKey: string, main?: boolean) => void
  onFormSubmit: (values: FormType, formiKBag: FormikProps<FormType>) => void
}

const MIME_TYPES = {
  '.png': 'image/png',
  '.jpg': 'image/jpg',
  '.jpeg': 'image/jpeg',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const ACCEPT_TYPES = Object.values(MIME_TYPES)
const ACCEPT = `image/*,${ACCEPT_TYPES.join(',')},capture=camera`

const SellFormView = (props: Props) => {
  const [isVerified, setIsVerified] = React.useState(false)

  const fileProps = {
    onSizeError: props.onFileSizeError,
    maxSize: props.maxFileSize,
    minSize: props.minFileSize,
    onImageUpload: props.onFileUpload,
    onTypeError: props.onFileTypeError,
    onError: props.onFileError,
    disabled: false,
  }

  const phone = props.user.data?.phoneCode && props.user.data?.phone ? `${props.user.data?.phoneCode} ${props.user.data?.phone}` : ''

  const handleRecaptchaSolved = () => {
    setIsVerified(true)
  }

  return (
    <div className={styles['form__container']}>
      <Formik
        initialValues={
          {
            name: '',
            email: props.user.data?.email || '',
            phone,
            topic: '',
            description: '',
            files: ([] as unknown) as FileUploadListType,
            tos: false,
          } as FormType
        }
        validationSchema={schema}
        onSubmit={(values: FormType, formikBag: FormikHelpers<FormType>) => {
          if (isVerified) {
            props.onFormSubmit(values, formikBag as FormikProps<FormType>)
          } else {
            formikBag.setSubmitting(false)
            notify.error(<Trans ns="SellForm" id="RecaptchaError" msg="You'll need to solve a ReCAPTCHA" />)
          }
        }}
      >
        {(formikBag: FormikProps<FormType>) => {
          return (
            <Form className={styles['contact-form']}>
              <div className={styles['contact__field__container']}>
                {renderTextField(
                  'name',
                  'text',
                  <Trans ns="SellForm" id="Name" msg="Name*" />,
                  {
                    InputProps: {
                      placeholder: 'e.g. John Smith',
                    },
                  },
                  { root: classNames(styles['contact__field--half'], styles['contact__field--name']) },
                )}
                {renderTextField(
                  'email',
                  'string',
                  <Trans ns="SellForm" id="Email" msg="Email*" />,
                  {
                    InputProps: {
                      placeholder: 'e.g. smith@outlook.com',
                    },
                  },
                  { root: classNames(styles['contact__field--half'], styles['contact__field--email']) },
                )}
                {renderTextField(
                  'phone',
                  'string',
                  <Trans ns="SellForm" id="PhoneNumber" msg="Phone number" />,
                  {
                    InputProps: {
                      placeholder: 'e.g. 123 456 789',
                    },
                  },
                  { root: classNames(styles['contact__field--half'], styles['contact__field--phone']) },
                )}
                {renderSelectField(
                  'topic',
                  <Trans ns="SellForm" id="Topic" msg="Topic*" />,
                  props.topics,
                  { InputProps: {} },
                  { root: classNames(styles['contact__field--half'], styles['contact__field--topic']) },
                )}
              </div>
              {renderTextarea('description', <Trans ns="SellForm" id="Description" msg="Type your question here" />, {
                InputProps: { placeholder: 'Start typing...' },
                helperText: (
                  <>
                    <Trans ns="SellForm" id="DescriptionLimit" msg="Max 5000 signs" />
                  </>
                ),
              })}
              <div className={styles['contact-form__help']}>
                <div className={styles['help']}>
                  <Img src="/icons/info.svg" />
                  <span className={styles['help__text']}>
                    This field is optional. If you choose option &quot;I want to sell a bottle/bottles&quot; in the dropdown list, we will
                    contact you anyway.
                  </span>
                </div>
              </div>
              <div className={styles['upload__container']}>
                <Typography variant="body1" classes={{ root: styles['upload__main-label'] }}>
                  <Trans ns="SellForm" id="UploadAttachment" msg="Attachment" />
                </Typography>
                <div className={styles['upload']}>
                  <ImageUpload
                    key="photo1"
                    classes={classNames(styles['upload__photobox'])}
                    {...fileProps}
                    onChange={(file: File, base64: string, key: string) => {
                      props.onFileUpload(file, base64, key, true)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                    }}
                    image={props.files?.photo1?.data || null}
                    imageKey="photo1"
                    accept={ACCEPT}
                    acceptedTypes={ACCEPT_TYPES}
                    mode={MODE_TYPE.FILE}
                  />
                  <ImageUpload
                    key="photo2"
                    classes={styles['upload__photobox']}
                    {...fileProps}
                    onChange={(file: File, base64: string, key: string) => {
                      props.onFileUpload(file, base64, key, false)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                    }}
                    image={props.files?.photo2?.data || null}
                    imageKey="photo2"
                    accept={ACCEPT}
                    acceptedTypes={ACCEPT_TYPES}
                    mode={MODE_TYPE.FILE}
                  />
                  <ImageUpload
                    key="photo3"
                    classes={styles['upload__photobox']}
                    {...fileProps}
                    onChange={(file: File, base64: string, key: string) => {
                      props.onFileUpload(file, base64, key, false)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                    }}
                    image={props.files?.photo3?.data || null}
                    imageKey="photo3"
                    accept={ACCEPT}
                    acceptedTypes={ACCEPT_TYPES}
                    mode={MODE_TYPE.FILE}
                  />
                  <ImageUpload
                    key="photo4"
                    classes={styles['upload__photobox']}
                    {...fileProps}
                    onChange={(file: File, base64: string, key: string) => {
                      props.onFileUpload(file, base64, key, false)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                    }}
                    image={props.files?.photo4?.data || null}
                    imageKey="photo4"
                    accept={ACCEPT}
                    acceptedTypes={ACCEPT_TYPES}
                    mode={MODE_TYPE.FILE}
                  />
                  <ImageUpload
                    key="photo5"
                    classes={styles['upload__photobox']}
                    {...fileProps}
                    onChange={(file: File, base64: string, key: string) => {
                      props.onFileUpload(file, base64, key, false)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                    }}
                    image={props.files?.photo5?.data || null}
                    imageKey="photo5"
                    accept={ACCEPT}
                    acceptedTypes={ACCEPT_TYPES}
                    mode={MODE_TYPE.FILE}
                  />
                </div>
              </div>
              <div className={styles['help__container']}>
                <div className={classNames(styles['help'], styles['help--upload'])}>
                  <Img src="/icons/info.svg" />
                  <span className={styles['help__text']}>You can upload files that are jpg, png, doc, docx or xls.</span>
                </div>
              </div>
              <div className={classNames(styles['captcha__container'])}>
                <ReCAPTCHA onChange={handleRecaptchaSolved} sitekey="6Ler8yAaAAAAANbJJuW52hus01Ni_JasUlWD5nx-" />
              </div>
              <div className={styles['contact__tos']}>
                <Field type="checkbox" name="tos" label="I accept the Terms Of Service" component={FormCheckboxField} fullWidth />
              </div>
              <div className={styles['button-container']}>
                <Button
                  onClick={async () => {
                    const result = await formikBag.validateForm()

                    if (Object.keys(result).length) {
                      notify.error(<Trans ns="SellForm" id="GlobalError" msg="Some fields needs correction" />)
                    }

                    formikBag.submitForm()
                  }}
                  variant="contained"
                  color="primary"
                  className={styles['button-container__button--add']}
                  disabled={formikBag.isSubmitting}
                >
                  <Trans ns="SellForm" id="ButtonSend" msg="Send" />
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default SellFormView
