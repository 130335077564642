import { Button, CircularProgress, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router'
import styles from './Catalog.module.scss'
import SortBy from './SortBy'
import InitContext from '../../contexts/InitContext'
import { SortByDirectionType, SortByOrderType } from '../../interfaces/Filters.type'
import { OfferType } from '../../interfaces/Offer.type'
import OfferTileItem from '../OfferTileItem/OfferTileItem'
import Trans from '../Trans'
import { routes } from '../Link'
import useAuth from '../../hooks/auth'
import useOffer from '../../hooks/offer'
import useShare from '../../hooks/share'
import notify from '../Notification/notify'
import createOfferUrl from '../../utils/createOfferUrl'

type Props = {
  items?: OfferType[]
  itemsCounter: number | null
  onLoadMore: () => void
  onSortChange: (order: SortByOrderType, direction: SortByDirectionType) => void
  showMoreButton: boolean
  loading?: boolean
  sortOrder: string
  sortDirection: string
}

const Catalog = (props: Props) => {
  const initData = React.useContext(InitContext)
  const { addToFavorite, removeOfferFromFavorite, isFavorite } = useOffer()
  const history = useHistory()
  const { isAuth } = useAuth()
  const { share } = useShare()

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => {
    event.preventDefault()

    const offerUrl = createOfferUrl(offer)

    share({
      url: offerUrl,
      // title: offer.title,
      // text: offer.subtitle || offer.description,
      clipboardText: offerUrl,
    })
    .then((type: string) => {
      if (type === 'clipboard') {
        notify.success('The URL of the offer has been copied to the clipboard')
      }
    })
    .catch(e => notify.error(e))
  }

  const handleAddToFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => {
    event.preventDefault()

    if (!isAuth) {
      history.push(routes.signIn)
      return
    }

    addToFavorite(offer)
  }

  const handleRemoveOfferFromFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => {
    event.preventDefault()
    
    if (!isAuth) {
      history.push(routes.signIn)
      return
    }

    removeOfferFromFavorite(offer)
  }

  return (
    <>
      <div className={classNames(styles['catalog__container'])}>
        <div className={classNames(styles['container__results'])}>
          <div className={classNames(styles['catalog__heading'])}>
            {!props.items || props.items?.length === 0 ? (
              <Typography variant="h5">
                <Trans ns="Catalog" id="NoResults" msg="No results found." />
              </Typography>
            ) : (
              <div className={styles['heading__container']}>
                <div className={styles['heading']}>
                  <Typography variant="h5">
                    <Trans ns="Catalog" id="SearchHeadline" msg="Search results:" />
                  </Typography>
                  {props.itemsCounter !== null && initData.loaded ? (
                    <Typography variant="caption">
                      {props.itemsCounter} of {initData.offers}
                    </Typography>
                  ) : null}
                </div>
                <SortBy
                  classes={{ root: styles['sort-by__container'] }}
                  order={props.sortOrder}
                  direction={props.sortDirection}
                  onChange={props.onSortChange}
                />
              </div>
            )}
          </div>
          <div className={classNames(styles['catalog__items'])}>
            {props.items?.map((item: OfferType) => {
              return (
                <OfferTileItem
                  key={item.id}
                  offer={item}
                  isFavorite={isFavorite(item)}
                  onAddToFavorite={handleAddToFavorite}
                  onRemoveFromFavorite={handleRemoveOfferFromFavorite}
                  onShareClick={handleShareClick}
                  classes={{ item: styles['catalog__item'] }}
                />
              )
            })}
          </div>
        </div>
      </div>
      {props.showMoreButton && (
        <Button
          variant="contained"
          color="secondary"
          classes={{ root: styles['button-show-more'] }}
          disabled={props.loading}
          onClick={props.onLoadMore}
        >
          {props.loading ? <CircularProgress size={17} /> : <Trans ns="Catalog" id="ShowMore" msg="Show more" />}
        </Button>
      )}
    </>
  )
}

export default Catalog
