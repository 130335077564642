import { FormikErrors, FormikHelpers } from 'formik'
import _ from 'lodash'
import { AxiosError, AxiosResponse } from './axios'
import notify from '../components/Notification/notify'

const GLOBAL_ERROR = '__global'

type ErrorType = {
  propertyPath: string
  message: string
}

//prevalidaiton on formik only
export const handleFormikValidation = (errors: FormikErrors<any>, formik: null | FormikHelpers<any> = null) => {
  for (const prop in errors) {
    const val = errors[prop]
    if (typeof val === 'string') {
      formik?.setFieldError(prop, val)
      formik?.setFieldTouched(prop)
    }
  }
}

const handleError = (error: AxiosError, formik: null | FormikHelpers<any> = null) => {
  const response: AxiosResponse | undefined = error.response
  if (!response) {
    notify.error('Unknown error, something went wrong. Try again')
    return
  }

  const errors: ErrorType[] = response.data?.errors || []
  if (!_.isEmpty(errors)) {
    errors.forEach((err: ErrorType) => {
      if (err.propertyPath === GLOBAL_ERROR) {
        notify.error(err.message)
      } else if (formik) {
        formik.setFieldError(err.propertyPath, err.message)
      }
    })
  } else {
    notify.error('Unknown error, something went wrong. Try again')
  }
}

export default handleError
