import BigNumber from 'bignumber.js'
import Dinero from 'dinero.js'
import _ from 'lodash'
/*eslint no-useless-escape: "off"*/

export const TAX_DEFAULT_RATE = 19
const FIXED_PRECISION = 2

export const getTaxAmount = (netto: string | number, taxRate: string | number = TAX_DEFAULT_RATE) => {
  const taxRateNormalized = taxRate >= 1 ? new BigNumber(taxRate).dividedBy(100) : new BigNumber(taxRate)
  const taxAmount = new BigNumber(netto).multipliedBy(taxRateNormalized)
  return taxAmount.toFixed(FIXED_PRECISION).toString()
}

export const getGrossAmount = (netto: string | number, taxRate: string | number = TAX_DEFAULT_RATE) => {
  const taxAmount = getTaxAmount(netto, taxRate)
  const taxAmountBN = new BigNumber(taxAmount)
  if (Number.isNaN(taxAmountBN.toNumber())) return 0
  const gross = new BigNumber(netto).plus(taxAmount)
  return gross.toFixed(FIXED_PRECISION).toString()
}

export const normalizeCurrencyValue = (value: string | number) => {
  if (!value) return '0'

  const regex = /[\,\s\_]/gi
  return `${value}`.replace(regex, '')
}

export const priceToInt = (value: string | number, divide?: boolean) => {
  const regex = /[\,\.\s\_]/gi
  const intVal = _.parseInt(`${value}`.replace(regex, ''))
  if (divide) {
    return new BigNumber(intVal).dividedBy(100).toNumber()
  }
  return intVal
}

export const intToPrice = (value: number, multiply?: boolean, normalized?: boolean) => {
  const intVal = multiply ? new BigNumber(value).multipliedBy(100).toNumber() : value
  const val = Number.isNaN(intVal) ? '' : Dinero({ amount: intVal }).toFormat('0,0.00')
  const valNormalized = Number.isNaN(intVal) ? '' : Dinero({ amount: intVal }).toFormat('0.00')
  return normalized ? valNormalized : val
}

export default {}
