import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { Redirect } from 'react-router-dom'
import styles from './ResetPasswordPage.module.scss'
import { routes } from '../../components/Link/Link'
import PageLayout from '../../components/PageLayout/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import useAuth from '../../hooks/auth/auth'

type Props = {}

const ResetPasswordPage = (props: Props) => {
  const { isAuth } = useAuth()

  if (isAuth) {
    return <Redirect to={routes.home} />
  }

  return (
    <PageLayout classes={classNames(styles['reset-password-page'], pageStyles['content--sides-padding'])}>
      <Typography variant="h3">Change your password</Typography>
      <div className={styles['new-user']} />
      <ResetPasswordForm />
    </PageLayout>
  )
}

export default ResetPasswordPage
