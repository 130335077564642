import _ from 'lodash'
import { useEffect, useState } from 'react'
import { BreakpointsType, BreakpointType, MatchMediaType } from './matchMedia.types'

const createMatchMedia = (alias: BreakpointType, query: string): MatchMediaType => {
  return {
    mediaQuery: window.matchMedia(query),
    alias,
  }
}

const breakpoints: BreakpointsType = {
  xs: createMatchMedia('xs', '(min-width: 400px)'),
  sm: createMatchMedia('sm', '(min-width: 768px)'),
  md: createMatchMedia('md', '(min-width: 1024px)'),
  lg: createMatchMedia('lg', '(min-width: 1200px)'),
}

export type Result = {
  xs: boolean
  sm: boolean
  md: boolean
  lg: boolean
}

type StateType = Record<BreakpointType, boolean>

const useMatchMedia = (): Result => {
  const [matches, setMatches] = useState<StateType | null>(null)

  const handleResize = (mediaQuery: MediaQueryList, event: MediaQueryListEvent) => {
    const matchesCopy: Record<string, boolean> = {}
    let matchedBreakpoint

    for (const key in breakpoints) {
      const item = breakpoints[key as BreakpointType]

      if (item.mediaQuery.media === mediaQuery.media) {
        matchedBreakpoint = item
        break
      }
    }

    for (const key in breakpoints) {
      const item = breakpoints[key as BreakpointType]
      matchesCopy[item.alias] = window.matchMedia(item.mediaQuery.media).matches
    }

    if (matchedBreakpoint) {
      matchesCopy[matchedBreakpoint.alias] = mediaQuery.matches
      setMatches(matchesCopy as StateType)
    }
  }

  useEffect(() => {
    const matchesCopy: Record<string, boolean> = _.cloneDeep(matches) || {}

    for (const key in breakpoints) {
      const item = breakpoints[key as BreakpointType]
      matchesCopy[item.alias] = window.matchMedia(item.mediaQuery.media).matches

      if ('addEventListener' in item.mediaQuery) {
        // @ts-ignore
        item.mediaQuery.addEventListener('change', handleResize)
      } else {
        // @ts-ignore
        item.mediaQuery.addListener(handleResize)
      }
    }

    if (!matches) {
      setMatches(matchesCopy as StateType)
    }

    return () => {
      for (const key in breakpoints) {
        const item = breakpoints[key as BreakpointType]

        if ('removeEventListener' in item.mediaQuery) {
          // @ts-ignore
          item.mediaQuery.removeEventListener('change', handleResize)
        } else {
          // @ts-ignore
          item.mediaQuery.removeListener(handleResize)
        }
      }
    }
  })

  return {
    xs: (matches && matches[breakpoints.xs.alias]!) || false,
    sm: (matches && matches[breakpoints.sm.alias]!) || false,
    md: (matches && matches[breakpoints.md.alias]!) || false,
    lg: (matches && matches[breakpoints.lg.alias]!) || false,
  }
}

export default useMatchMedia
