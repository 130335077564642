import { Button, Divider, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import useMatchMedia from '../../hooks/matchMedia'
import { OfferType } from '../../interfaces/Offer.type'
import UserType from '../../interfaces/User.type'
import countries from '../../utils/countries'
import Gallery from '../Gallery'
import Img from '../Img'
import Link, { routes } from '../Link'
import { resolveRoute, resolveTo } from '../Link/linkResolver'
import Trans from '../Trans'
import FavoriteButton from '../UI/FavoriteButton'
import ShareButton from '../UI/ShareButton'
import ContactForm from './ContactForm'
import styles from './Offer.module.scss'

type Props = {
  offer: OfferType
  user?: UserType
  priceNet: string
  priceGross: string
  pricePerLiter: string
  showAllDetails: boolean
  setShowAllDetails: React.Dispatch<React.SetStateAction<boolean>>
  phone: string
  hasFilledDetails: boolean
  language: string
  onChatMessage: () => {}
  chatLoading: boolean
  onPhoneCall: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onPrint: () => void
  print: boolean
  revealCallButton: boolean
  isAuth: boolean
  isFavorite: boolean
  onShareClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => void
  onAddToFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => void
  onRemoveFromFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => void
}

const ATTRIBUTE_COUNTRY = 'bottleLocation'
const ATTRIBUTES_MAX = 10
let attributesCounter = 0

const OfferView = (props: Props) => {
  const [attrCounter, setAttrCounter] = React.useState<number>(0)
  const offer = props.offer
  const {
    onPhoneCall,
    priceNet,
    priceGross,
    pricePerLiter,
    setShowAllDetails,
    showAllDetails,
    phone,
    language,
    hasFilledDetails,
    onChatMessage,
    chatLoading,
    revealCallButton,
    print,
    onPrint,
  } = props

  const inVerification = offer.user.verified === false && offer.user.verificationRequestedAt !== null
  const isVerified = offer.user.verified
  const isBusiness = offer.user.isBusiness
  const isMd = useMatchMedia().md
  const editRoute = `${routes.offerEdit}?back_path=${resolveRoute(resolveTo(routes.offer), { slug: offer.slug, sku: offer.sku })}`

  const renderAttribute = (_offer: OfferType, _language: string, attribute: string, label: JSX.Element) => {
    let attrValue: string | number | null = _.get(_offer, attribute, null)

    if (!attrValue) {
      return null
    }

    if (attribute === ATTRIBUTE_COUNTRY) {
      attrValue = countries.getName(attrValue, _language)
    }
    attributesCounter++
    return (
      <li className={styles['details__item']} key={`attr-${attribute}`}>
        <span className={styles['details__attr']}>{label}</span>
        <span className={styles['details__value']}>{attrValue}</span>
      </li>
    )
  }

  React.useEffect(() => {
    setAttrCounter(attributesCounter)
    if (attributesCounter <= ATTRIBUTES_MAX) setShowAllDetails(true)
    if (print) onPrint()
  }, [])

  return (
    <div className={styles['offer']}>
      <Gallery
        classes={{
          slider: styles['offer__gallery'],
          slideImage: styles['gallery__image'],
          images: classNames(!props.offer.isActive && styles['gallery__image--inactive']),
        }}
        items={offer.photos}
      />
      <div className={styles['offer__info']}>
        <div className={styles['offer__info__details']}>
          <div className={styles['offer__badges']}>
            {offer.isLastCall && (
              <Button size="small" color="primary" variant="contained" classes={{ root: styles['offer__label--last-call'] }}>
                Last call
              </Button>
            )}
            {(!offer.isActive || offer.isArchived) && (
              <Button size="small" color="default" variant="contained" classes={{ root: styles['offer__label--last-call'] }}>
                Archived
              </Button>
            )}
            {!offer.isPublic && (
              <Button
                disabled={chatLoading}
                size="small"
                color="secondary"
                variant="contained"
                classes={{ root: styles['offer__label--hidden'] }}
              >
                Hidden
              </Button>
            )}
          </div>
          <div className={styles['offer__actions']}>
            <FavoriteButton
              onAdd={e => props.onAddToFavorite(e, props.offer)}
              onRemove={e => props.onRemoveFromFavorite(e, props.offer)}
              isFavorite={props.isFavorite}
            />
            <ShareButton onClick={e => props.onShareClick(e, props.offer)} />
          </div>
        </div>
        <Typography variant="h3" classes={{ root: styles['offer__title'] }}>
          {offer.title}
        </Typography>
        <div className={styles['offer__basic']}>
          <div className={styles['basic__left']}>
            <Typography variant="caption" classes={{ root: styles['offer__subtitle'] }}>
              {offer.subtitle}
            </Typography>
            <Typography variant="h5" classes={{ root: styles['offer__price'] }}>
              {priceGross} EUR {offer.gst ? `(incl.${new BigNumber(offer.gstRate).multipliedBy(100)}% VAT)` : null}
            </Typography>
            <Typography variant="caption">{priceNet} EUR Net</Typography>
            <Typography variant="caption">{pricePerLiter} EUR/liter</Typography>
            <Typography variant="body1" classes={{ root: styles['offer__swap'] }}>
              {offer.swap && offer.negotiable
                ? 'Marketplace offer/Whiskymarket.com offer'
                : offer.swap
                ? 'Whiskymarket.com offer'
                : offer.negotiable
                ? 'Marketplace offer'
                : ''}
            </Typography>
          </div>
          <div className={styles['basic__right']}>
            {offer.isActive ? (
              <>
                <div className={styles['basic__expiration']}>Expiration date: {offer.expiresAt}</div>
                <div className={styles['basic__availability']}>
                  <Img src="/icons/availability_now.svg" classes={styles['availability__icon']} alt="Availability" />
                  <span className={styles['availability__name']}>Available {/*offer.availability.name*/}</span>
                </div>
              </>
            ) : (
              <div className={styles['basic__availability']}>
                <Img src="/icons/availability_sold.svg" classes={styles['availability__icon']} alt="Availability" />
                <span className={styles['availability__name']}>Archived</span>
              </div>
            )}
          </div>
          <div className={styles['basic__buy-now']}>
            {offer.isActive && offer.buyNowAvailable && offer.buyNowLink.length > 0 ? (
              <a
                href={offer.buyNowLink}
                className={classNames(styles['buy-now__link'], 'transparent')}
                onClick={undefined}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  color="primary"
                  variant="outlined"
                  startIcon={<Img src="/icons/buy-now.svg" classes={styles['buy-now__icon']} />}
                  classes={{
                    root: styles['buy-now__button'],
                    label: classNames(styles['buy-now__label'], { [styles['buy-now__label--revealed']]: revealCallButton }),
                  }}
                >
                  Buy now
                </Button>
              </a>
            ) : null}
          </div>
        </div>
        <Divider classes={{ root: styles['offer__divider'] }} />
        <div className={styles['seller']}>
          <div className={styles['seller__data']}>
            <Typography variant="h5" classes={{ root: styles['seller__headline'] }}>
              <Trans ns="Offer" id="SellerFrom" msg="Attendant:" />
            </Typography>
            <div className={styles['seller__container']}>
              <img src={offer.user.avatar} className={styles['seller__avatar']} alt="avatar" />
              <div className={styles['seller__name-container']}>
                <div className={styles['name-container__user-box']}>
                  {isBusiness ? (
                    <img src="/icons/user_shop.svg" alt="Shop" />
                  ) : isVerified ? (
                    <img src="/icons/user_verified.svg" alt="Verified" />
                  ) : (
                    <img src="/icons/user.svg" alt="regular" />
                  )}
                  <span className={styles['user-box__name']}>{offer.user.username}</span>
                </div>
                <Typography variant="body1">{isBusiness ? 'Professional' : 'Basic'}</Typography>
                <span className={styles['name-container__verification']}>
                  {isVerified ? 'Verified' : inVerification ? 'Verification in progress' : 'Not verified'}
                </span>
              </div>
            </div>
          </div>
          <div className={styles['contact']}>
            {offer.user.deletedAt == null && phone.length > 0 ? (
              <a href={`tel:${phone}`} className="transparent" onClick={onPhoneCall}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={revealCallButton ? null : <Img src="/icons/phone.svg" classes={styles['contact__icon']} />}
                  classes={{
                    root: styles['contact__button'],
                    label: classNames(styles['contact__label'], { [styles['contact__label--revealed']]: revealCallButton }),
                  }}
                >
                  {revealCallButton ? phone : 'Call'}
                </Button>
              </a>
            ) : null}
            {offer.user.deletedAt == null ? (
              <Button
                disabled={chatLoading}
                onClick={onChatMessage}
                color="primary"
                variant="contained"
                startIcon={<Img src="/icons/message.svg" classes={styles['contact__icon']} />}
                classes={{ root: styles['contact__button'], label: styles['contact__label'] }}
              >
                Message
              </Button>
            ) : null}
            {offer.user.deletedAt == null && offer.user.email.length > 0 ? (
              <a href={`mailto:${offer.user.email!}?subject=${offer.title}`} className="transparent">
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Img src="/icons/email.svg" classes={styles['contact__icon']} />}
                  classes={{ root: styles['contact__button'], label: styles['contact__label'] }}
                >
                  Email
                </Button>
              </a>
            ) : null}
            {props.isAuth && offer.user.id === props.user?.data?.id ? (
              <Link to={editRoute} params={{ id: offer.id }} silent>
                <Button
                  color="default"
                  variant="contained"
                  startIcon={revealCallButton ? null : <EditIcon className={styles['contact__icon']} />}
                  classes={{
                    root: styles['contact__button'],
                    label: classNames(styles['contact__label'], { [styles['contact__label--revealed']]: revealCallButton }),
                  }}
                >
                  Edit
                </Button>
              </Link>
            ) : null}
            <Link
              classes={classNames('primary', styles['seller__other-items'])}
              to={routes.userOffers}
              params={{ id: offer.user?.id?.toString() || ' ' }}
            >
              Attendant&apos;s other items
            </Link>
          </div>
        </div>
        <Divider classes={{ root: styles['offer__divider'] }} />

        {isMd && offer.user.deletedAt == null && (
          <div className={styles['offer__contact__container']}>
            <Typography variant="h5" classes={{ root: styles['description__heading'] }}>
              <Trans ns="Offer" id="ContactHeading" msg="Ask for details" />
            </Typography>
            <div className={styles['offer__contact']}>
              <ContactForm offer={offer} onSubmit={async () => {}} />
            </div>
          </div>
        )}
      </div>
      <div className={styles['offer__left']}>
        <div className={styles['offer__details']}>
          <Typography variant="h5" classes={{ root: styles['details__heading'] }}>
            <Trans ns="Offer" id="DetailsHeading" msg="Details" />
          </Typography>
          <ul className={styles['details__list']}>
            {renderAttribute(offer, language, 'bottle.type', <Trans ns="Offer" id="BottleType" msg="Type:" />)}
            {renderAttribute(offer, language, 'bottle.category.name', <Trans ns="Offer" id="DetailsKind" msg="Category:" />)}
            {renderAttribute(
              offer,
              language,
              'bottle.distillery.name',
              <Trans ns="Offer" id="DetailsDistillery" msg="Distillery/Brand:" />,
            )}
            {renderAttribute(offer, language, 'bottle.bottler.name', <Trans ns="Offer" id="DetailsBottler" msg="Bottler:" />)}
            {renderAttribute(offer, language, 'bottle.distilled', <Trans ns="Offer" id="DetailsDistilled" msg="Distilled:" />)}
            {renderAttribute(offer, language, 'bottle.bottled', <Trans ns="Offer" id="DetailsBottled" msg="Bottled:" />)}
            {renderAttribute(offer, language, 'age', <Trans ns="Offer" id="DetailsAge" msg="Age:" />)}
            {renderAttribute(offer, language, 'bottle.abv', <Trans ns="Offer" id="DetailsStrength" msg="Strength:" />)}
            {renderAttribute(offer, language, 'bottle.size', <Trans ns="Offer" id="DetailsSize" msg="Size (ml):" />)}
            {renderAttribute(offer, language, 'bottle.caskNumber', <Trans ns="Offer" id="DetailsCaskNo" msg="Cask No:" />)}
            {renderAttribute(offer, language, 'bottle.caskType.name', <Trans ns="Offer" id="DetailsCaskType" msg="Cask Type:" />)}
            {!showAllDetails && hasFilledDetails ? (
              <li>
                <a
                  className={classNames('primary', styles['details__link'])}
                  onClick={() => {
                    setShowAllDetails(true)
                  }}
                >
                  <Trans ns="Offer" id="ShowMore" msg="Show more" />
                </a>
              </li>
            ) : null}
            {showAllDetails && hasFilledDetails ? (
              <>
                {renderAttribute(
                  offer,
                  language,
                  'bottle.outturn',
                  <Trans ns="Offer" id="DetailsOutturn" msg="Outturn (number of bottles produced):" />,
                )}
                {/* {renderAttribute(
                  offer,
                  language,
                  'condition.name',
                  <Trans ns="Offer" id="DetailsBoxCondition" msg="Status of the bottle and box:" />,
                )}
                {renderAttribute(offer, language, 'fillLevel.name', <Trans ns="Offer" id="DetailsAvailability" msg="Fill level:" />)}
                {renderAttribute(offer, language, 'availability.name', <Trans ns="Offer" id="DetailsAvailability" msg="Availability:" />)}
                {renderAttribute(offer, language, 'package.name', <Trans ns="Offer" id="DetailsPackage" msg="Package:" />)}
                {renderAttribute(offer, language, 'quantity', <Trans ns="Offer" id="DetailsAvailability" msg="Quantity:" />)} */}
                {renderAttribute(offer, language, 'bottleLocation', <Trans ns="Offer" id="DetailsLocalization" msg="Bottle location:" />)}
                {attrCounter > ATTRIBUTES_MAX ? (
                  <li>
                    <a
                      className={classNames('primary', styles['details__link'])}
                      onClick={() => {
                        setShowAllDetails(false)
                      }}
                    >
                      <Trans ns="Offer" id="ShowMore" msg="Show less" />
                    </a>
                  </li>
                ) : null}
              </>
            ) : null}
          </ul>
        </div>
        <div className={styles['offer__description']}>
          {offer.description?.length > 0 ? (
            <>
              <Typography variant="h5" classes={{ root: styles['description__heading'] }}>
                <Trans ns="Offer" id="DescriptionHeading" msg="Item description" />
              </Typography>
              <div className={styles['description__text']}>{offer.description}</div>
            </>
          ) : null}

          <div className={styles['offer__adult-only']}>
            <Img src="/icons/adult_only.svg" classes={styles['adult-only__icon']} alt="Aduly Only" />
            <Trans
              ns="Offer"
              id="OnlyAdultAlert"
              msg="We would like to point out that alcoholic beverages are only shipped to people over the age of 18. This product is only given to people of legal age."
            />
            <br />
            <Trans
              ns="Offer"
              id="OnlyAdultAlert"
              msg="Wir möchten Sie darauf hinweisen, dass der Versand von alkoholischen Getränke nur an Personen über 18 Jahren erfolgt. Dieser Artikel wird nur an volljährige Personen ausgehändigt."
            />
          </div>
          {!isMd && <Divider classes={{ root: styles['offer__divider'] }} />}
        </div>
      </div>

      {!isMd && offer.user.deletedAt == null && (
        <div className={styles['offer__contact__container']}>
          <Typography variant="h5" classes={{ root: styles['description__heading'] }}>
            <Trans ns="Offer" id="ContactHeading" msg="Ask for details" />
          </Typography>
          <div className={styles['offer__contact']}>
            <ContactForm offer={offer} onSubmit={async () => {}} />
          </div>
        </div>
      )}
    </div>
  )
}

export default OfferView
