import axios from '../../utils/axios'
import { SlideWithSizeType } from './util/getSlides'

export async function getInitData() {
  return axios({
    method: 'GET',
    url: '/search/init',
  }).then(response => {
    const slides: SlideWithSizeType[] = []
    const catalogSlides: SlideWithSizeType[] = []

    response.data.homePage.slides.forEach((item: Record<string, any>) => {
      slides.push({
        src: item.desktopImage,
        srcSet: item.desktopImage,
        url: item.url,
        size: 'lg',
      })

      slides.push({
        src: item.tabletImage,
        srcSet: item.tabletImage,
        url: item.url,
        size: 'md',
      })

      slides.push({
        src: item.mobileImage,
        srcSet: item.mobileImage,
        url: item.url,
        size: 'xs',
      })
    })

    response.data.catalog.slides.forEach((item: Record<string, any>) => {
      catalogSlides.push({
        src: item.desktopImage,
        srcSet: item.desktopImage,
        url: item.url,
        size: 'lg',
      })

      catalogSlides.push({
        src: item.tabletImage,
        srcSet: item.tabletImage,
        url: item.url,
        size: 'md',
      })

      catalogSlides.push({
        src: item.mobileImage,
        srcSet: item.mobileImage,
        url: item.url,
        size: 'xs',
      })
    })

    return {
      ...response,
      data: {
        ...response.data,
        homePage: {
          mainBanner: response.data.homePage.mainBanner,
          slides,
          topSearches: response.data.homePage.topSearches,
          mostWanted: response.data.homePage.mostWanted,
        },
        catalog: {
          slides: catalogSlides,
        },
      },
    }
  })
}
