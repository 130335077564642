import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styles from './Search.module.scss'
import SearchInput from './SearchInput'
import { routes } from '../Link'

type Props = {
  classes?: {
    root?: string
    form?: string
  }
}

const Search = (props: Props) => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const handleSearchClick = (value: string) => {
    history.push({ pathname: routes.catalog, search: `query=${encodeURIComponent(value)}` })
  }

  return (
    <div className={classNames(props?.classes?.root, styles['search'])}>
      <div className={classNames(props?.classes?.form)}>
        <SearchInput
          onSearchClick={handleSearchClick}
          onEnterClick={handleSearchClick}
          value={queryParams.get('query')} 
        />
      </div>
    </div>
  )
}

export default Search
