import { InputProps } from '@material-ui/core/Input'
import { InputLabelProps } from '@material-ui/core/InputLabel'
import { TextFieldProps } from '@material-ui/core/TextField'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styles from './DateField.module.scss'
import { moment } from '../../../utils/date'

const defaultProps = Object.freeze({
  pickerProps: {} as any,
  dateFormat: moment.localeData().longDateFormat('L') as string,
  variant: 'outlined' as any,
  InputProps: { notched: false } as InputProps,
  InputLabelProps: { shrink: true } as InputLabelProps,
  withMargins: true as boolean,
  setFieldValue: _.noop as (field: string, value: any, shouldValidate?: boolean) => void,
  setFieldTouched: _.noop as (field: string, isTouched?: boolean, shouldValidate?: boolean) => void,
  fullWidth: false as boolean,
  onFieldChange: _.noop as (value: string) => void,
})

type Props = typeof defaultProps & TextFieldProps

/**
 TODO: Known bug when initial value was passed and you will change locale (in props) it will pass wrong input value to text field (not update)
 maybe it will be resolved in future updates of package (memoization problem?)
 https://github.com/mui-org/material-ui-pickers/pull/1101
 **/

const DateField = (props: Props) => {
  const classes = {
    root: classNames({ [styles['date-field--with-margins']]: props.withMargins }, props.classes?.root),
  }
  const localMomentFormat = moment.localeData().longDateFormat('L')
  const [dateFormat, setDateFormat] = useState<string>(props.dateFormat || localMomentFormat)
  const [dateValue, setDateValue] = useState<string | null>((props.value as string) || null)
  const [prevDateFormat, setPrevDateFormat] = useState<string>(props.dateFormat || localMomentFormat)
  //const [prevDateValue, setPrevDateValue] = useState<string | null>((props.value as string) || null)

  function handleOnChange(date: MaterialUiPickersDate, value?: string | null | undefined) {
    if (!(date instanceof moment)) {
      date = moment(value as string, dateFormat)
    }

    const formattedDate: string = date.format(dateFormat).toString()
    let newValue = value

    if (date.isValid()) {
      newValue = formattedDate
    }

    setDateValue(newValue as string)
    props.setFieldValue(props.name as string, newValue)
    props.setFieldTouched(props.name as string)
    props.onFieldChange(newValue as string)
  }

  useEffect(() => {
    if (dateValue) {
      const contextDateFormat: string = props.dateFormat || localMomentFormat
      const newDateMoment = moment(dateValue, dateFormat, true)
      let formikValue = dateValue
      if (newDateMoment.isValid()) {
        const convertedStringDate: string = newDateMoment.format(contextDateFormat)
        setPrevDateFormat(dateFormat)
        //setPrevDateValue(dateValue)
        setDateValue(convertedStringDate)
        setDateFormat(contextDateFormat)
        formikValue = convertedStringDate
      }
      props.setFieldValue(props.name as string, formikValue)
      props.setFieldTouched(props.name as string)
    }
  }, [dateValue, dateFormat !== prevDateFormat])

  const extraProps = props.pickerProps || {}
  return (
    <KeyboardDatePicker
      classes={classes}
      orientation="portrait"
      label={props.label}
      keyboardIcon={<CalendarTodayIcon />}
      rightArrowIcon={<ChevronRightIcon />}
      leftArrowIcon={<ChevronLeftIcon />}
      onChange={handleOnChange}
      {...extraProps}
      fullWidth={props.fullWidth}
      cancelLabel="Cancel"
      okLabel="Use"
      format={dateFormat}
      value={dateValue as string}
      inputValue={dateValue as string}
      helperText={props.helperText}
      error={props.error}
      inputVariant="outlined"
      InputProps={props.InputProps}
      InputLabelProps={props.InputLabelProps}
    />
  )
}

DateField.defaultProps = defaultProps

export default DateField
