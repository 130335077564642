import { MenuItem } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './RoRPage.module.scss'
import RoRPageDE from './translations/RoRPageDE'
import RoRPageEN from './translations/RoRPageEN'
import Img from '../../components/Img'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import SelectField from '../../components/UI/SelectField'
import useCms from '../../hooks/cms'

const RoRPage = () => {
  const { language, switchLanguage, languages } = useCms()

  const changeLanguage = (event: React.ChangeEvent<{ name?: string | undefined; value: any }>) => {
    switchLanguage(languages[event.target.value])
  }

  return (
    <PageLayout classes={classNames(pageStyles['content--sides-padding'], styles['page'])}>
      <Img src="/img/logo_landing.svg" classes={styles['logo']} />
      <SelectField
        onChange={changeLanguage}
        value={language.isoCode}
        classes={{
          root: styles['language'],
        }}
      >
        <MenuItem value={languages.en.isoCode}>{languages.en.name}</MenuItem>
        <MenuItem value={languages.de.isoCode}>{languages.de.name}</MenuItem>
      </SelectField>
      {language.isoCode === languages.en.isoCode ? <RoRPageEN /> : <RoRPageDE />}
    </PageLayout>
  )
}

export default RoRPage
