import classNames from 'classnames'
import React from 'react'
import styles from './LandingPageLayout.module.scss'
import ChildrenType from '../../../interfaces/Children.type'
import Footer from '../../Footer'
import Img from '../../Img'

type Classes = {
  root?: string
  header?: string
  container?: string
}

type Props = {
  headerContent?: ChildrenType
  children?: ChildrenType
  classes?: Classes
}

const LandingPageLayout = (props: Props) => {
  return (
    <div className={classNames(styles['root'], props.classes?.root)}>
      <header className={classNames(styles['header'], props.classes?.header)}>
        <Img src="/lp/logo.png" alt="Whisky Market" classes={styles['header__logo']} />
        <div className={styles['header__text-block']}>{props.headerContent}</div>
      </header>
      <section className={classNames(styles['section'], props.classes?.container)}>{props.children}</section>
      <Footer mode="simplified" />
    </div>
  )
}

export default LandingPageLayout
