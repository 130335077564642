import _ from 'lodash'
import { Action as ReduxAction, applyMiddleware, compose, createStore, Dispatch as ReduxDispatch, Store as ReduxStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import reducers, { StateType } from './reducers'
import sagas from './sagas'

type StoreType = ReduxStore<StateType, ReduxAction> & {
  dispatch: ReduxDispatch
  __PERSISTOR: Persistor
}

const isBrowser = typeof window !== 'undefined'

const isDevMode = process.env.NODE_ENV !== 'production'
const sagaMiddleware = createSagaMiddleware()
const middleware = applyMiddleware(sagaMiddleware)
const reduxTool = isBrowser && isDevMode && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose
let store: StoreType

if (isBrowser) {
  const persistConfig = {
    key: 'WM',
    storage,
  }
  // @ts-ignore
  store = createStore(persistReducer(persistConfig, reducers), compose(middleware, reduxTool))
  store.__PERSISTOR = persistStore(store)
} else {
  // @ts-ignore
  store = createStore(reducers, compose(middleware, reduxTool))
  store.__PERSISTOR = persistStore(store)
}

sagaMiddleware.run(sagas)
store.subscribe(() => {})

export default store
