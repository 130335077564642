import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React from 'react'
import { FiltersType } from '../../../../interfaces/Filters.type'
import FormTextField from '../../../Form/FormTextField/FormTextField'

type Classes = {
  root?: string
}

const defaultProps = Object.freeze({
  type: 'text' as 'text' | 'number',
})

type Props = typeof defaultProps & {
  form: FormikProps<FormikValues | any>
  name: FieldNameType
  placeholder?: string
  label?: string
  classes?: Classes
  min?: number
  max?: number
  onChange?: (e: React.ChangeEvent<any>) => void
}

type FieldNameType = keyof FiltersType

const InputField = (props: Props) => {
  if (!props.name || props.form.values[props.name] === undefined) {
    return null
  }

  return (
    <FormTextField
      classes={{ root: props.classes?.root }}
      form={props.form}
      label={props.label!}
      inputProps={{
        min: props.min,
        max: props.max,
        type: props.type,
        placeholder: props?.placeholder || '',
      }}
      field={{
        name: props.name,
        value: props.form.values[props.name],
        onChange: props.form.handleChange,
        onBlur: props.form.handleBlur,
      }}
      fullWidth
    />
  )
}

InputField.defaultProps = defaultProps

export default InputField
