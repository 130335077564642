import Yup from '../../../utils/yup'

const schema = Yup.object().shape({
  subject: Yup.string()
    .trim()
    .required('Required')
    .min(3, 'Can contain 3 signs'),
  message: Yup.string()
    .trim()
    .required()
    .min(3, 'Can contain 3 signs'),
})

export default schema
