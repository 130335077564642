import qs from 'qs'
import React from 'react'
import Helmet from 'react-helmet'
import { useLocation, useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import styles from './OfferPage.module.scss'
import createMetaDescription from './util/createMetaDescription'
import createMetaTitle from './util/createMetaTitle'
import { resolveRoute } from '../../components/Link/linkResolver'
import LoadingSpinner from '../../components/LoadingSpinner'
import Offer from '../../components/Offer'
import PageLayout from '../../components/PageLayout'
import routes from '../../components/WebApp/config/routes'
import usePrerender from '../../hooks/prerender'
import OfferType from '../../interfaces/Offer.type'
import * as api from '../../services/offer/offer.api'

type UrlParamsType = {
  sku: string
  slug: string
}

const OfferPage = () => {
  const params = useParams<UrlParamsType>()
  const search = qs.parse(useLocation().search, { ignoreQueryPrefix: true })
  const [offer, setOffer] = React.useState<OfferType>()
  const [error, setError] = React.useState<string>('')
  const printMode = !!search.print
  const requestedUrl = usePrerender().getRequestedUrl()
  const description = offer ? createMetaDescription(offer) : ''

  React.useEffect(() => {
    const fetch = async () => {
      let response = null
      try {
        response = await api.readOfferBySku(params.sku)
        setOffer(response?.data!)
        window.scrollTo(0, 0)
      } catch (e) {
        console.error(e)

        if (e?.response?.data?.message) {
          setError(e.response.data.message)
        } else {
          setError('Offer was not found')
        }
      }
    }

    fetch()
  }, [])

  if (offer && params.slug !== offer.slug) {
    return <Redirect to={resolveRoute(routes.offer, { sku: offer.sku, slug: offer.slug })} />
  }

  return (
    <PageLayout>
      {error ? (
        <h1 className={styles['error']}>{error}</h1>
      ) : offer ? (
        <>
          <Helmet>
            <title>{offer.title} - whiskymarket.com</title>
            <meta property="og:title" content={createMetaTitle(offer)} />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            {requestedUrl ? <meta property="og:url" content={requestedUrl} /> : null}
            {offer.photos.large?.[0].src ? <meta property="og:image" content={offer.photos.large[0].src} /> : null}
          </Helmet>
          <Offer offerId={offer.id} offer={offer} print={printMode} />
        </>
      ) : (
        <LoadingSpinner center />
      )}
    </PageLayout>
  )
}

export default OfferPage
