import { InputProps } from '@material-ui/core/Input'
import { InputLabelProps } from '@material-ui/core/InputLabel'
import { FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import TextField from '../../UI/TextField'

type Props = {
  field: {
    name: string
    value: any
    onChange: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  }
  form: FormikProps<any>
  label?: React.ReactNode | string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  InputProps?: InputProps
  classes?: object
  onChange?: (value: string) => void
  inputProps?: object
  InputLabelProps?: InputLabelProps
}

const FormTextField: React.FC<Props> = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
  return (
    <TextField
      {..._.pick(props, ['type', 'label', 'fullWidth', 'classes', 'children', 'InputProps', 'inputProps', 'InputLabelProps'])}
      {...fields}
      onChange={(event: React.FocusEvent<HTMLInputElement>) => {
        fields.onChange(event)
        props.onChange ? props.onChange(event.currentTarget.value) : _.noop
      }}
      error={Boolean(_.has(touched, fields.name) && _.has(errors, fields.name))}
      helperText={_.has(touched, fields.name) && _.has(errors, fields.name) ? _.get(errors, fields.name) : props.helperText || ''}
    />
  )
}

export default FormTextField
