import axios from '../../utils/axios'

export type NewsletterInPayloadType = {
  email: string
  tos: boolean
}

export type NewsletterOutPayloadType = {
  id: string
  token: string
}

export type NewsletterGetPayloadType = {
  id: string
  token: string
}

export type NewsletterGetMessagePayloadType = {
  id: string
  token: string
  messageId: string
}

export type NewsletterConfirmPayloadType = {
  id: string
  token: string
}

export type NewsletterUnsubscribePayloadType = {
  id: string
  token: string
}

export type NewsletterSendPayloadType = {
  subject: string
  message: string
}

export function createNewsletter(payload: NewsletterInPayloadType) {
  return axios({
    method: 'POST',
    url: '/newsletter',
    data: payload,
  })
}

export function deleteNewsletter(payload: NewsletterOutPayloadType) {
  return axios({
    method: 'DELETE',
    url: `/newsletter/${payload.id}/${payload.token}`,
  })
}

export function get(payload: NewsletterGetPayloadType) {
  return axios({
    method: 'GET',
    url: `/newsletter/${payload.id}/${payload.token}`,
  })
}

export function getMessage(payload: NewsletterGetMessagePayloadType) {
  return axios({
    method: 'GET',
    url: `/newsletter/${payload.id}/${payload.token}/message/${payload.messageId}`,
  })
}

export function confirm(payload: NewsletterConfirmPayloadType) {
  return axios({
    method: 'PUT',
    url: `/newsletter/${payload.id}/${payload.token}/subscribe`,
  })
}

export function unsubscribe(payload: NewsletterUnsubscribePayloadType) {
  return axios({
    method: 'DELETE',
    url: `/newsletter/${payload.id}/${payload.token}`,
  })
}

export function send(payload: NewsletterSendPayloadType) {
  return axios({
    method: 'POST',
    url: '/newsletter/send',
    data: {
      template: 'newsletter_default',
      from: 'Whisky Market <newsletter@email.whiskymarket.com>',
      to: 'newsletter@email.whiskymarket.com',
      subject: payload.subject,
      variables: {
        content: payload.message,
      },
    },
  })
}
