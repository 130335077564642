import { IconButton, SnackbarContent } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import classNames from 'classnames'
import React from 'react'
import styles from './Notification.module.scss'
import { NotificationInterface, NotificationTypes } from '../../services/notification/notification.types'

interface Props extends NotificationInterface {
  onClose?: (event: React.SyntheticEvent<any, Event>, reason: string) => void
  className?: string
  isStatic?: boolean
}

const iconTypes = {
  [NotificationTypes.SUCCESS]: CheckCircleIcon,
  [NotificationTypes.WARNING]: WarningIcon,
  [NotificationTypes.ERROR]: ErrorIcon,
  [NotificationTypes.INFO]: InfoIcon,
}

const contentClasses = {
  [NotificationTypes.SUCCESS]: styles['snackbar__content--success'],
  [NotificationTypes.WARNING]: styles['snackbar__content--warning'],
  [NotificationTypes.ERROR]: styles['snackbar__content--error'],
  [NotificationTypes.INFO]: styles['snackbar__content--info'],
}

type SnackbarContentType = React.ComponentType<Props>

const Notification = React.forwardRef((props: Props, ref: React.Ref<SnackbarContentType>) => {
  const { id, type, content, className, onClose, isStatic } = props
  const typeKey = type.toLowerCase()
  const Icon = iconTypes[typeKey as NotificationTypes]
  const typeClasses = contentClasses[typeKey as NotificationTypes]
  const messageId = `snackbar-message-${id}`
  const closeId = `snackbar-close-${id}`

  let action = null

  if (onClose) {
    action = [
      <IconButton
        key={closeId}
        aria-label="Close"
        color="inherit"
        onClick={(event: React.SyntheticEvent<any, Event>) => {
          onClose(event, '')
        }}
      >
        <CloseIcon className={styles['notification__icon']} />
      </IconButton>,
    ]
  }

  return (
    <SnackbarContent
      ref={ref}
      classes={{
        root: classNames(styles['snackbar__content'], { [styles['snackbar__content--static']]: isStatic }),
        action: styles['snackbar__action'],
      }}
      className={classNames(typeClasses, className)}
      aria-describedby="client-snackbar"
      message={
        <span id={messageId} className={styles['notification__message']}>
          <Icon className={classNames(styles['notification__icon'], styles['notification__icon--left'])} />
          <div>{content}</div>
        </span>
      }
      action={action}
    />
  )
})

export default Notification
