const emailMaxChars = 120
const passwordMinChars = 6
const firstNameMaxChars = 32
const lastNameMaxChars = 32
const streetMaxChars = 100
const cityMaxChars = 50
const stateMaxChars = 50
const postCodeMaxChars = 8
const phoneMinChars = 7
const phoneMaxChars = 22
const firstNameMinChars = 2
const lastNameMinChars = 2

export {
  emailMaxChars,
  passwordMinChars,
  firstNameMaxChars,
  lastNameMaxChars,
  streetMaxChars,
  cityMaxChars,
  stateMaxChars,
  postCodeMaxChars,
  phoneMinChars,
  phoneMaxChars,
  firstNameMinChars,
  lastNameMinChars,
}
