import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import OfferListItemView from './OfferListItemView'
import DomainContext from '../../contexts/DomainContext'
import useAuth from '../../hooks/auth'
import useOffer from '../../hooks/offer'
import OfferType from '../../interfaces/Offer.type'
import * as api from '../../services/offer/offer.api'
import { routes } from '../Link'
import notify from '../Notification/notify'
import { USER_OFFERS_TYPE } from '../User/UserOffers'
import { MODE_TYPE } from '../User/UserSettings'
import createOfferUrl from '../../utils/createOfferUrl'
import useShare from '../../hooks/share'

export enum DELETE_STEP {
  DELETED = 'DELETED',
  NOT_DELETED = 'NOT_DELETED',
  IN_PROGRESS = 'IN_PROGRESS',
  DELETING = 'DELETING',
}

export enum ACTION_STEP {
  NONE = 'NONE',
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

type Props = {
  offer: OfferType
  selected: boolean
  ownerType: USER_OFFERS_TYPE
  onOfferRemove: () => void
  onOfferUpdate: (offer: OfferType) => void
  onSelectedChange?: (offerId: string, isChecked: boolean) => void
}

const OfferListItem = (props: Props) => {
  const history = useHistory()
  const { isAuth } = useAuth()
  const { share } = useShare()
  const { offer, ownerType, onOfferRemove, onOfferUpdate } = props
  const mine = ownerType == USER_OFFERS_TYPE.MY
  const userType = offer.user.isBusiness ? MODE_TYPE.BUSINESS : MODE_TYPE.PRIVATE
  const userTypeLabel = userType === MODE_TYPE.PRIVATE ? (offer.user.verified ? 'Verified' : 'Basic') : 'Professional'
  const [archiveStep, setArchiveStep] = useState<ACTION_STEP>(ACTION_STEP.NONE)
  const [deleteStep, setDeleteStep] = useState<ACTION_STEP>(ACTION_STEP.NONE)
  const [sharingBoxOpen, setSharingBoxOpen] = useState<boolean>(false)
  const domain = useContext(DomainContext)
  const shareUrl = encodeURIComponent(`${domain.host}/offer/${offer.id}`)
  const { addToFavorite, removeOfferFromFavorite, isFavorite } = useOffer()

  const onArchive = async () => {
    if (archiveStep === ACTION_STEP.NONE || archiveStep === ACTION_STEP.DONE) {
      setArchiveStep(ACTION_STEP.TO_DO)
    }
  }

  const onArchiveConfirmed = async () => {
    try {
      setArchiveStep(ACTION_STEP.IN_PROGRESS)
      const response = await api.archiveOffer(offer.id)
      notify.success('You offer has been archived.')
      setArchiveStep(ACTION_STEP.DONE)
      onOfferUpdate(response.data as OfferType)
    } catch (error) {
      console.error(error)
    }
  }

  const onArchiveCanceled = () => {
    setArchiveStep(ACTION_STEP.NONE)
  }

  const onActivate = async () => {
    try {
      const response = await api.activateOffer(offer.id)
      notify.success('You offer has been activated.')
      onOfferUpdate(response.data as OfferType)
    } catch (error) {
      console.error(error)
    }
  }

  const onRemove = () => {
    if (deleteStep === ACTION_STEP.NONE || deleteStep === ACTION_STEP.DONE) {
      setDeleteStep(ACTION_STEP.TO_DO)
    }
  }

  const onDeleteConfirmed = async () => {
    try {
      setDeleteStep(ACTION_STEP.IN_PROGRESS)
      await api.deleteOffer(offer.id)
      notify.success('You offer has been deleted.')
      setDeleteStep(ACTION_STEP.DONE)
      onOfferRemove()
    } catch (error) {
      console.error(error)
    }
  }

  const onDeleteCanceled = () => {
    setDeleteStep(ACTION_STEP.NONE)
  }

  const openShareBox = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setSharingBoxOpen(true)
  }

  const onShareBoxClose = () => {
    setSharingBoxOpen(false)
  }

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    const offerUrl = createOfferUrl(offer)

    share({
      url: offerUrl,
      // title: offer.title,
      // text: offer.subtitle || offer.description,
      clipboardText: offerUrl,
    })
    .then((type: string) => {
      if (type === 'clipboard') {
        notify.success('The URL of the offer has been copied to the clipboard')
      }
    })
    .catch(e => notify.error(e))
  }

  const handleAddToFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    if (!isAuth) {
      history.push(routes.signIn)
      return
    }

    addToFavorite(offer)
  }

  const handleRemoveOfferFromFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    
    if (!isAuth) {
      history.push(routes.signIn)
      return
    }

    removeOfferFromFavorite(offer)
  }

  return (
    <OfferListItemView
      archiveStep={archiveStep}
      deleteStep={deleteStep}
      openShareBox={openShareBox}
      onArchive={onArchive}
      onArchiveConfirmed={onArchiveConfirmed}
      onArchiveCanceled={onArchiveCanceled}
      onActivate={onActivate}
      onRemove={onRemove}
      onShareBoxClose={onShareBoxClose}
      offer={offer}
      mine={mine}
      isFavorite={isFavorite(offer)}
      typeLabel={userTypeLabel}
      sharingBoxOpen={sharingBoxOpen}
      shareUrl={shareUrl}
      selected={props.selected}
      ownerType={ownerType}
      onShareClick={handleShareClick}
      onAddToFavorite={handleAddToFavorite}
      onRemoveFromFavorite={handleRemoveOfferFromFavorite}
      onDeleteConfirmed={onDeleteConfirmed}
      onDeleteCanceled={onDeleteCanceled}
      onSelectedChange={mine ? props.onSelectedChange : undefined}
    />
  )
}

export default OfferListItem
