import _ from 'lodash'
import React from 'react'

const defaultProps = Object.freeze({
  tag: 'span' as string,
  ns: 'App' as string,
})

type Props = typeof defaultProps & {
  id: string
  values?: any
  classes?: string
  msg: string
  onClick?: () => void
}

const Trans = (props: Props) => {
  const { tag, msg } = props
  const tagProps = {
    className: props.classes,
    onClick: props.onClick,
  }

  return React.createElement(tag, tagProps, [msg])
}

Trans.defaultProps = defaultProps

export default Trans
