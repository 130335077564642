import UserType from '../../interfaces/User.type'

export type SignUpPayloadType = {
  email: string
  tos: boolean
  newsletter: boolean
  password: string
  repeatedPassword: string
  birthday: string | number
}

export type SignInPayloadType = {
  email: string
  password: string
}

export type GoogleSignInPayloadType = {
  accessToken: string
  idToken: string
}

export type ResetPasswordPayloadType = {
  email: string
}

export type ChangePasswordPayloadType = {
  userId: string
  token: string
  password: string
  repeatedPassword: string
}

export enum ActionTypes {
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
}

export interface SignInSuccessActionInterface {
  type: ActionTypes.SIGN_IN_SUCCESS
  payload: UserType
}

export interface SignUpSuccessActionInterface {
  type: ActionTypes.SIGN_UP_SUCCESS
  payload: UserType
}

export interface LogOutSuccessActionInterface {
  type: ActionTypes.LOG_OUT_SUCCESS
}

export type ActionsType = SignInSuccessActionInterface | SignUpSuccessActionInterface | LogOutSuccessActionInterface
