import {
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  TextField
  } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import classNames from 'classnames'
import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React from 'react'
import Autosuggest, { SuggestionHighlightedParams, SuggestionsFetchRequestedParams } from 'react-autosuggest'
import { useHistory } from 'react-router'
import styles from './Search.module.scss'
import InitContext from '../../contexts/InitContext'
import SearchItemType from '../../interfaces/SearchItem.type'
import * as api from '../../services/search/search.api'

type DebouncedFuncType = _.DebouncedFunc<(inputValue: string) => Promise<void>>

type Props = {
  userId?: string | null
  name?: string | null
  value?: string | null
  placeholder?: string | null
  form?: FormikProps<FormikValues | any>
  onChange?: (value: string) => void
  onSearchClick: (value: string) => void
  onEnterClick?: (value: string) => void
}

const SearchInput = (props: Props) => {
  const history = useHistory()
  const anchorRef = React.useRef<any>(null)
  const initData = React.useContext(InitContext)
  const [, setSearchRequest] = React.useState<DebouncedFuncType>()
  const [suggestions, setSuggestions] = React.useState<SearchItemType[]>([])
  const [value, setValue] = React.useState(props.value || '')
  const [selectedSuggestion, setSelectedSuggestion] = React.useState<SearchItemType | null>(null)

  React.useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  const updateSuggestions = async (inputValue: string): Promise<void> => {
    try {
      const response = await api.search({ userId: props.userId, query: inputValue })
      setSuggestions(response.data?.items || [])
    } catch (error) {
      console.error(error)
      setSuggestions([])
    }
  }

  const handleSuggestionsFetchRequested = async (params: SuggestionsFetchRequestedParams) => {
    const inputValue = _.deburr(params.value.trim()).toLowerCase()

    if (inputValue.length < 3) {
      setSuggestions([])
      return
    }

    const request = _.debounce(updateSuggestions, 300)

    setSearchRequest((prevRequest: DebouncedFuncType | undefined) => {
      if (prevRequest?.cancel) {
        prevRequest.cancel()
      }

      return request
    })

    request(inputValue)
  }

  const handleSuggestionsClearRequested = () => { }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionSelected={(event, { suggestion }: { suggestion: SearchItemType }) => {
        history.push(suggestion.url)
      }}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      getSuggestionValue={(item: SearchItemType) => item.value}
      onSuggestionHighlighted={(params: SuggestionHighlightedParams) => {
        setSelectedSuggestion(params.suggestion || null)
      }}
      renderSuggestion={(item: SearchItemType, { isHighlighted }: { isHighlighted: boolean }) => (
        <MenuItem
          component="div"
          className={styles['autocompelte__item']}
          selected={isHighlighted}
        >
          {item.value}
        </MenuItem>
      )}
      renderSuggestionsContainer={options => {
        return (
          <Popper
            open={Boolean(options.children)}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            style={{
              zIndex: 2000,
            }}
          >
            <Paper
              square
              style={{
                width: anchorRef.current ? anchorRef.current.clientWidth + 43 : null,
              }}
              {...options.containerProps}
              className={classNames(options.containerProps.className, styles['suggestions__list'])}
            >
              {options.children}
            </Paper>
          </Popper>
        )
      }}
      renderInputComponent={(inputProps: any) => {
        return (
          <TextField
            {...inputProps}
            value={value}
            variant="outlined"
            InputProps={{
              name: props.name || undefined,
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    props.onSearchClick(value)
                  }}
                >
                  <SearchIcon
                    classes={{ root: styles['search__icon'] }}
                    color="primary"
                  />
                </InputAdornment>
              ),
            }}
          />
        )
      }}
      inputProps={{
        placeholder: props.placeholder || (initData.loaded && initData.offers > 0 ? `Search from ${initData.offers} offers...` : 'Search here...'),
        value,
        onChange: (event, { newValue, method }) => {
          setValue(newValue)

          if (props.onChange) {
            props.onChange(newValue)
          }

          if (method === 'enter' && props.onEnterClick) {
            props.onEnterClick(newValue)
          }
        },
        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
          const key = event.key || event.keyCode;

          if (key === 'Enter' || key === 13) {
            if (selectedSuggestion) {
              history.push(selectedSuggestion.url)
            } else if (props.onEnterClick) {
              props.onEnterClick(value)
            }
          }
        },
        //@ts-ignore
        inputRef: anchorRef,
      }}
    />
  )
}

export default SearchInput
