import { OfferMessagePayloadType, SellMessagePayloadType } from './contact.types'
import axios from '../../utils/axios'

export const getSellTopics = () => {
  return axios({
    method: 'GET',
    url: '/contact/topic',
  })
}

export const sendSellMessage = (payload: SellMessagePayloadType) => {
  return axios({
    method: 'POST',
    url: '/contact',
    data: payload,
  })
}

export const sendOfferMessage = (payload: OfferMessagePayloadType) => {
  return axios({
    method: 'POST',
    url: `/contact/offer/${payload.offerId}`,
    data: payload,
  })
}
