import { AxiosPromise } from 'axios'
import _ from 'lodash'
import { OfferSelectFieldValueType } from '../../../../interfaces/Offer.type'
import * as api from '../../../../services/offer/offer.api'

const makeRequest = async (value: string, request: (value: string) => AxiosPromise<any>) => {
  try {
    const inputValue = _.deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length

    if (!inputLength) {
      return
    }

    const response = await request(value)
    return response.data.map((suggestion: OfferSelectFieldValueType) => suggestion.name)
  } catch (error) {
    console.error(error)
  }

  return []
}

export const getDistillerySuggestions = async (value: string) => makeRequest(value, v => api.searchDistilleryByQuery(v))

export const getBottlerSuggestions = async (value: string) => makeRequest(value, v => api.searchBottlerByQuery(v))
