import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './DetailedInformation.module.scss'
import { UserType } from '../../../../interfaces/User.type'
import ImageUpload, { MODE_TYPE as IMAGE_MODE_TYPE } from '../../../ImageUpload'
import Trans from '../../../Trans'
import { MODE_TYPE } from '../UserSettings'
import { SettingsImageUploadType } from '../UserSettings.types'

type Props = {
  user: UserType
  userType: MODE_TYPE
  imageUploading?: boolean
  maxImageSize: number
  minImageSize: number
  onAvatarUpload: (file: File, base64: string, imageKey: string) => void
  image: SettingsImageUploadType | null
  onImageSizeError: (tooBig: boolean, tooSmall: boolean) => void
  onImageTypeError: (fileType: string) => void
  onImageError: (file: File | null, reason: string) => void
}

const DetailedInformation = (props: Props) => {
  const {
    onImageError,
    onImageSizeError,
    onImageTypeError,
    maxImageSize,
    minImageSize,
    onAvatarUpload,
    imageUploading,
    userType,
    user,
  } = props

  return (
    <>
      <Typography variant="h5" classes={{ root: styles['information__headline'] }}>
        <Trans ns="UserSettingsMyDetails" id="InformationHeadline" msg="Detailed information" />
      </Typography>
      <span className={styles['information__hint']}>
        {userType === MODE_TYPE.PRIVATE ? (
          <>
            To gain more creditability and sell your bottles even faster please provide us with some more details about yourself. Offers
            from VERIFIED users are more likely to be chosen by the buyers, so do not hesitate and fill in the forms below (optional).
          </>
        ) : (
          <>
            To gain more creditability and sell your bottles even faster please provide us with some more details about Company. Offers from
            VERIFIED users are more likely to be chosen by the buyers, so do not hesitate and fill in the forms below (optional).{' '}
          </>
        )}
      </span>
      <div className={styles['photo-box']}>
        <Trans ns="DetailedInformation" id="PhotoHeadline" msg="Photo" classes={styles['photo-box__headline']} tag="div" />
        <ImageUpload
          image={user.data?.avatar}
          mode={IMAGE_MODE_TYPE.AVATAR}
          classes={styles['photo-box__upload']}
          primary
          onError={onImageError}
          onSizeError={onImageSizeError}
          onTypeError={onImageTypeError}
          minSize={minImageSize}
          maxSize={maxImageSize}
          onChange={(file: File, base64: string, imageKey: string) => {
            onAvatarUpload(file, base64, imageKey)
          }}
          imageKey="avatar_photo"
          changeLabel="Change"
          imageUploading={imageUploading}
        />
      </div>
    </>
  )
}

export default DetailedInformation
