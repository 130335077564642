import { FormikProps } from 'formik'
import React, { useContext } from 'react'
import { FormType } from './ContactForm.types'
import ContactFormView from './ContactFormView'
import UserContext from '../../../contexts/UserContext'
import { OfferType } from '../../../interfaces/Offer.type'
import { UserType } from '../../../interfaces/User.type'
import * as api from '../../../services/contact/contact.api'
import handleError from '../../../utils/handleError'
import notify from '../../Notification/notify'
import Trans from '../../Trans'

const defaultProps = Object.freeze({
  language: 'en' as string,
})

type Props = typeof defaultProps & {
  offer: OfferType
  onSubmit: (values: FormType, user: UserType) => Promise<void>
}

const ContactForm = (props: Props) => {
  const user: UserType = useContext(UserContext)

  const onFormSubmit = async (values: FormType, formikBag: FormikProps<FormType>) => {
    try {
      formikBag.setSubmitting(true)

      await api.sendOfferMessage({
        offerId: props.offer.id,
        name: values.name,
        email: values.email,
        content: values.description || '',
        context: {
          phone: values.phone || '',
        },
      })

      formikBag.setSubmitting(false)
      formikBag.resetForm()

      notify.success(
        <Trans ns="OfferContactForm" id="SentSuccessfully" msg="Thanks for contacting us! We will be in touch with you shortly." />,
      )
    } catch (error) {
      formikBag.setSubmitting(false)
      handleError(error, formikBag)
    }
  }

  return <ContactFormView user={user} offer={props.offer} onFormSubmit={onFormSubmit} />
}

ContactForm.defaultProps = defaultProps

export default ContactForm
