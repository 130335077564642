import { InputProps } from '@material-ui/core/Input'
import { TextFieldProps } from '@material-ui/core/TextField'
import { FormikProps } from 'formik'
import _ from 'lodash'
import * as React from 'react'
import { AutosuggestProps } from 'react-autosuggest'
import AutocompleteField, { AutocompleteClassesType } from '../../UI/AutocompleteField'

const defaultProps = Object.freeze({
  multiSection: false as boolean,
})

type Props<TSuggestionType, TSectionType> = AutosuggestProps<TSuggestionType, TSectionType> & {
  field: {
    name: string
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onBlur: (event: any) => void
  }
  form: FormikProps<TSectionType>
  label?: React.ReactNode | string
  formControlProps?: TextFieldProps
  error?: boolean
  helperText?: string
  variant?: string
  multiSection?: boolean
  classes?: {
    root?: string
  }
  autocompleteClasses?: AutocompleteClassesType
  InputProps?: InputProps
  withMargins?: boolean
  inputRef?: React.MutableRefObject<any>
  fullWidth?: boolean
  submitOnEnter?: boolean
} & typeof defaultProps

const FormAutocompleteField = <TSuggestionType, TSectionType>(props: Props<TSuggestionType, TSectionType>) => {
  const { errors, touched } = props.form
  const { field, formControlProps, helperText } = props

  return (
    //@ts-ignore
    <AutocompleteField<TSuggestionType, TSectionType>
      {...props}
      {...props.field}
      InputProps={{
        ...(props.InputProps || {}),
        name: props.field.name,
        value: props.field.value,
      }}
      error={Boolean(_.has(touched, field.name) && _.has(errors, field.name))}
      helperText={_.has(touched, field.name) && _.has(errors, field.name) ? _.get(errors, field.name) : helperText}
      formControlProps={formControlProps}
      submitOnEnter={props.submitOnEnter === undefined || props.submitOnEnter}
    />
  )
}

FormAutocompleteField.defaultProps = defaultProps

export default FormAutocompleteField
