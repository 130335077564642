import { moment } from '../../utils/date'
import { passwordMinChars } from '../../utils/validation'
import Yup from '../../utils/yup'

const birthdayMinDate = moment().subtract(120, 'years')
const birthdayMaxDate = moment().subtract(18, 'years')

const schema = Yup.object().shape({
  email: Yup.string()
    .ensure()
    .required('Requried')
    .email('It must be a valid email address')
    .trim(),
  password: Yup.string()
    .ensure()
    .min(passwordMinChars, 'Password must contain at least 6 characters')
    .required('Required'),
  repeatedPassword: Yup.string()
    .ensure()
    // @ts-ignore
    .equalTo(Yup.ref('password'), 'Passwords must match')
    .required('Required'),
  birthday: Yup.string()
    .ensure()
    .required('Required')
    // @ts-ignore
    .isValidDateRange(birthdayMinDate, birthdayMaxDate, 'Wrong fromat of date', 'Not possible you still alive', 'You are to young'),
  tos: Yup.boolean()
    .required('Required')
    .oneOf([true], 'Terms not accepted'),
})

export default schema
