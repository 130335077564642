import _ from 'lodash'
import qs from 'qs'
import {
  DEFAULT_ACTIVITY,
  DEFAULT_DIRECTION,
  DEFAULT_ORDER,
  FiltersType
  } from '../interfaces/Filters.type'

type Query = object | string | undefined | null

export const parse = (inQuery: Query): FiltersType => {
  const defaultFilters = getDefault()
  const query = inQuery == null ? {} : _.isString(inQuery) ? qs.parse(inQuery, { ignoreQueryPrefix: true }) : inQuery
  const filters = _.pick(_.merge(defaultFilters, query), Object.keys(defaultFilters)) as FiltersType

  const booleanKeys = ['active']

  for (const key of booleanKeys) {
    const filterKey = key as keyof FiltersType
    if (filters[filterKey] === 'false') {
      filters[filterKey] = false as never
    }
  }

  return filters
}

export const stringify = (inQuery: Query): string => {
  const query = parse(inQuery) as Record<string, any>
  const numberKeys = [
    'distilledFrom',
    'distilledTo',
    'bottledFrom',
    'bottledTo',
    'ageFrom',
    'ageTo',
    'abvFrom',
    'abvTo',
    'sizeFrom',
    'sizeTo',
  ] as Array<keyof typeof query>

  for (const key of numberKeys) {
    const value = Number(query[key])
    if (Number.isNaN(value)) {
      delete query[key]
    } else {
      query[key] = value
    }
  }

  const booleanKeys = ['active']

  for (const key of booleanKeys) {
    if (query[key] === false) {
      query[key] = 'false'
    }
  }

  return qs.stringify(_.omitBy(query, value => !value))
}

export const merge = (leftQuery: Query, rightQuery: Query): FiltersType => {
  const leftFilters = parse(leftQuery)
  const rightFilters = parse(rightQuery)
  return _.merge(leftFilters, rightFilters)
}

export const isEqual = (leftQuery: Query, rightQuery: Query): boolean => {
  const leftFilters = parse(leftQuery)
  const rightFilters = parse(rightQuery)
  return _.isEqual(leftFilters, rightFilters)
}

export const isEmpty = (inQuery: Query): boolean => {
  const query = parse(inQuery)
  const keys = Object.keys(query) as Array<keyof typeof query>
  return keys.filter(key => !_.isEmpty(query[key])).length < 1
}

export const getDefault = (): FiltersType => ({
  price: '',
  priceFrom: '',
  priceTo: '',
  bottleType: '',
  category: '',
  distillery: '',
  bottler: '',
  distilledFrom: '',
  distilledTo: '',
  bottledFrom: '',
  bottledTo: '',
  age: '',
  ageFrom: '',
  ageTo: '',
  abvFrom: '',
  abvTo: '',
  sizeFrom: '',
  sizeTo: '',
  caskNumber: '',
  caskType: '',
  condition: '',
  fillLevel: '',
  availability: '',
  package: '',
  quantity: '',
  bottleLocation: '',
  query: '',
  activity: DEFAULT_ACTIVITY,
  active: true,
  order: DEFAULT_ORDER,
  direction: DEFAULT_DIRECTION,
})

export default {
  parse,
  getDefault,
}
