import React from 'react'
import { Redirect, useParams } from 'react-router'
import { resolveRoute } from '../../components/Link/linkResolver'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import PageLayout from '../../components/PageLayout/PageLayout'
import routes from '../../components/WebApp/config/routes'
import OfferType from '../../interfaces/Offer.type'
import * as api from '../../services/offer/offer.api'

type UrlParamsType = {
  id: string
}

const OfferOldPage = () => {
  const params = useParams<UrlParamsType>()
  const [offer, setOffer] = React.useState<OfferType>()

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const response = await api.readOffer(params.id)
        setOffer(response?.data!)
      } catch (error) {
        console.error(error)
      }
    }
    fetch()
  }, [])

  return offer ? (
    <Redirect to={resolveRoute(routes.offer, { sku: offer.sku, slug: offer.slug })} />
  ) : (
    <PageLayout>
      <LoadingSpinner center />
    </PageLayout>
  )
}

export default OfferOldPage
