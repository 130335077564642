import { Button, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router'
import BaseSlider from 'react-slick'
import Arrow from './Arrow'
import Filters from './Filters'
import styles from './Home.module.scss'
import { BasicFiltersType } from '../../interfaces/Filters.type'
import {
  MainBannerType,
  MostWantedType,
  SlideType,
  TopSearchType
  } from '../../interfaces/InitData.type'
import OfferType, { OfferAdType, OfferFieldsValuesType } from '../../interfaces/Offer.type'
import catalogStyles from '../Catalog/Catalog.module.scss'
import Img from '../Img/Img'
import Link, { routes } from '../Link/Link'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import OfferAdItem from '../OfferAdItem/OfferAdItem'
import OfferTileItem from '../OfferTileItem/OfferTileItem'
import PageLayout from '../PageLayout/PageLayout'
import pageStyles from '../PageLayout/PageLayout.module.scss'
import Slider, { Slide } from '../Slider'
import Trans from '../Trans/Trans'
import useAuth from '../../hooks/auth'
import useOffer from '../../hooks/offer'
import useShare from '../../hooks/share'
import createOfferUrl from '../../utils/createOfferUrl'
import notify from '../Notification/notify'

type Props = {
  mainBanner?: MainBannerType
  slides?: SlideType[]
  topSearches?: TopSearchType[]
  mostWanted?: MostWantedType[]
  fieldsValues?: OfferFieldsValuesType
  offers: Array<OfferType | OfferAdType>
  onSlideClick: (slide: Slide) => void
  onSubmit: (inFilters: BasicFiltersType) => Promise<void>
}

const isOffer = (object: OfferType | OfferAdType): object is OfferType => {
  return object.type !== 'ad'
}

const Home = (props: Props) => {
  const [formValues, setFormValues] = React.useState<Record<string, any>>({})
  const { addToFavorite, removeOfferFromFavorite, isFavorite } = useOffer()
  const history = useHistory()
  const { isAuth } = useAuth()
  const { share } = useShare()

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => {
    event.preventDefault()

    const offerUrl = createOfferUrl(offer)

    share({
      url: offerUrl,
      // title: offer.title,
      // text: offer.subtitle || offer.description,
      clipboardText: offerUrl,
    })
    .then((type: string) => {
      if (type === 'clipboard') {
        notify.success('The URL of the offer has been copied to the clipboard')
      }
    })
    .catch(e => notify.error(e))
  }

  const handleAddToFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => {
    event.preventDefault()

    if (!isAuth) {
      history.push(routes.signIn)
      return
    }

    addToFavorite(offer)
  }

  const handleRemoveOfferFromFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => {
    event.preventDefault()
    
    if (!isAuth) {
      history.push(routes.signIn)
      return
    }

    removeOfferFromFavorite(offer)
  }

  const sliderSettings = {
    className: styles['top-searches__slider'],
    dots: false,
    infinite: true,
    rows: 2,
    slidesPerRow: 1,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: <Arrow type="prev" />,
    nextArrow: <Arrow type="next" />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          rows: 1,
          slidesPerRow: 1,
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          rows: 1,
          slidesPerRow: 1,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ],
  }

  return (
    <PageLayout classes={classNames(styles['container'], pageStyles['content--sides-padding'])} headerMode="simplified">
      {
        props.topSearches && props.mostWanted && props.slides && props.mainBanner ? (
          <>
            <div className={classNames(styles['section'], styles['section--search'])}>
              <div className={styles['slider__container']}>
                <Slider slides={props.slides} onItemClick={props.onSlideClick} />
              </div>
              <div className={styles['search__container']}>
                <h1>
                  <span className={styles['search__headline']}>
                    <span className={styles['search__headline__text']}>
                      Let&apos;s find something special
                    </span>
                  </span>
                </h1>
                <div className={styles['search']}>
                  <Filters
                    onChanged={filters => {
                      setFormValues(filters)
                    }}
                    onSubmit={props.onSubmit}
                    fieldsValues={props.fieldsValues}
                  />
                </div>
                <div className={styles['top-searches']}>
                  <Typography className={styles['top-searches__headline']} variant="h3">Top searches</Typography>
                  <BaseSlider {...sliderSettings}>
                    {props.topSearches.map(item => (
                      <div key={item.url} className={styles['top-searches__item']}>
                        <a className={styles['top-searches__link']} href={item.url}>
                          <span className={styles['top-searches__text']}>{item.name}</span>
                        </a>
                      </div>
                    ))}
                  </BaseSlider>
                </div>
                <div className={styles['advanced-search']}>
                  <Link
                    classes={styles['advanced-search__link']}
                    to={routes.catalog}
                    state={{
                      showAdvancedSearch: true,
                      ...formValues,
                    }}
                  >
                    Advanced search
                  </Link>
                </div>
              </div>
            </div>
            <div className={classNames(styles['section'], styles['section--main-banner'])}>
              <div className={styles['main-banner']}>
                <Link
                  to={props.mainBanner?.url!}
                  onClick={() => {
                    history.push(props.mainBanner?.url!)
                  }}
                >
                  {props.mainBanner.type === 'video' ? (
                    <ReactPlayer
                      url={props.mainBanner.src}
                      className={styles['banner']}
                      playing
                      loop
                      muted
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <Img classes={styles['banner']} src={props.mainBanner.src} />
                  )}
                </Link>
              </div>
            </div>
            <div className={classNames(styles['section'], styles['section--popular-offers'])}>
              <div className={styles['popular-offers']}>
                <Typography className={styles['popular-offers__headline']} variant="h3">Popular offers</Typography>
                {props.offers?.length > 0 ? (
                  <div className={classNames(catalogStyles['catalog__items'])}>
                    {props.offers.map((item: OfferType | OfferAdType) => {
                      return (
                        isOffer(item) ? (
                          <OfferTileItem
                            key={item.id}
                            offer={item}
                            isFavorite={isFavorite(item)}
                            onAddToFavorite={handleAddToFavorite}
                            onRemoveFromFavorite={handleRemoveOfferFromFavorite}
                            onShareClick={handleShareClick}
                            classes={{ item: catalogStyles['catalog__item'] }}
                          />
                        ) : (
                          <OfferAdItem ad={item} key={`${item.url}~${item.image}`} classes={{ item: catalogStyles['catalog__item'] }} />
                        )
                      )
                    })}
                  </div>
                ) : (
                  <LoadingSpinner center />
                )}
              </div>
              <div className={styles['popular-offers__show-all']}>
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ root: styles['button--show-all'] }}
                  onClick={() => history.push(routes.catalog)}
                >
                  <Trans ns="Home" id="ShowAll" msg="Show all" />
                </Button>
              </div>
            </div>
            <div className={classNames(styles['section'], styles['section--most-wanted'])}>
              <div className={styles['most-wanted']}>
                <Typography className={styles['most-wanted__headline']} variant="h3">Most wanted</Typography>
                <ul className={styles['most-wanted__list']}>
                  {props.mostWanted.map(item => (
                    <li key={item.url} className={styles['most-wanted__item']}>
                      <a className={styles['most-wanted__link']} href={item.url}>{item.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        ) : (
          <LoadingSpinner center />
        )
      }
    </PageLayout>
  )
}

export default Home
