import styles from '../shared.module.scss'

const labelStyle = {
  transform: 'translate(0px, -18px) scale(1)',
  fontWeight: 500,
  fontSize: styles['text--md'],
  color: styles['color__gray2'],
}

export default {
  outlined: {
    ...labelStyle,
    '&$shrink': labelStyle,
  },
}
