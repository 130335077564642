import { Button } from '@material-ui/core'
import React from 'react'
import { useParams } from 'react-router'
import styles from './UserConfirmPage.module.scss'
import LandingPageLayout from '../../../components/Layout/LandingPageLayout/LandingPageLayout'
import Link, { routes } from '../../../components/Link/Link'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Notification from '../../../components/Notification/Notification'
import * as NotificationType from '../../../components/Notification/Notification.types'
import Trans from '../../../components/Trans/Trans'
import * as api from '../../../services/auth/auth.api'

type UrlParamsType = {
  id: string
  token: string
}

const UserConfirmPage = () => {
  const params = useParams<UrlParamsType>()
  const [content, setContent] = React.useState<React.ReactNode>()
  const [error, setError] = React.useState<boolean>(false)

  React.useEffect(() => {
    const confirm = async () => {
      try {
        await api.confirm(params.id, params.token)
        setError(false)
        setContent(<Trans ns="Auth" id="AccountConfirmed" msg="Your account has been confirmed." />)
      } catch (err) {
        const errors = err?.response?.data?.errors || []
        setError(true)
        setContent(errors[0]?.message || 'Something went wrong. Try again')
        console.error(err)
      }
    }

    confirm()
  }, [])

  return (
    <LandingPageLayout
      classes={{
        header: styles['header'],
        container: styles['container'],
      }}
      headerContent={
        content ? (
          <div className={styles['content']}>
            <Notification
              isStatic
              id={0}
              type={error ? NotificationType.ERROR : NotificationType.SUCCESS}
              content={content}
              className={styles['notification']}
            />
            <Button variant="contained" type="submit" color="primary">
              <Link silent to={routes.catalog}>
                <Trans ns="Common" id="GoToCatalog" msg="Go to catalog" />
              </Link>
            </Button>
          </div>
        ) : (
          <LoadingSpinner center />
        )
      }
    />
  )
}

export default UserConfirmPage
