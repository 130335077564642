export enum PASSWORD_FORM_MODE {
  SHOW = 'show',
  HIDE = 'hide',
}

export type SettingsFormType = {
  email: string
  username: string
  name: string
  surname: string
  birthday: string
  address1: string
  address2: string
  postCode: string
  city: string
  country: string
  phoneCode: string
  phone: string
  aboutMe: string
  verification: boolean
  companyName: string
  gst: boolean
  gstRate: number
  taxId: string
  website: string
  isBusiness: boolean
}

export type SettingsImageUploadType = {
  id?: string
  data: string
}
