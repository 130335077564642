import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Link as RouterLink, LinkProps, useLocation } from 'react-router-dom'
import styles from './Link.module.scss'
import { resolveRoute, resolveTo, ToType } from './linkResolver'
import ChildrenType from '../../interfaces/Children.type'
import routes from '../WebApp/config/routes'

type Props = {
  to: string
  params?: object
  state?: object
  search?: string
  children: ChildrenType
  classes?: string
  onClick?: () => void
  /* Make children as link, but without links styling */
  silent?: boolean
  target?: '_blank'
}

const Link = (props: Props) => {
  const classes = classNames(props.classes, { [styles['link--silent']]: props.silent })
  const toParams = typeof props.params === 'object' ? props.params || {} : {}
  const isExternal = props.to.startsWith('http:') || props.to.startsWith('https:')
  let to = props.to

  try {
    if (!_.isEmpty(props.params)) {
      const route = resolveTo(props.to)
      to = resolveRoute(route, toParams)
    }
  } catch (err) {
    console.warn(err)
  }

  return isExternal ? (
    <a href={to} target={props.target} className={classes}>
      {props.children}
    </a>
  ) : (
    <RouterLink
      to={{
        pathname: to,
        state: props.state,
        search: props.search,
      }}
      className={classes}
      target={props.target}
      onClick={props.onClick}
    >
      {props.children}
    </RouterLink>
  )
}

export { routes }
export default Link
