import React from 'react'
import styles from './FavoriteButton.module.scss'
import Img from '../../Img'

type Props = {
  isFavorite: boolean
  onAdd: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onRemove: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const FavoriteButton = (props: Props) => {
  return (
    <button className={styles['button']} type="button" onClick={e => props.isFavorite ? props.onRemove(e) : props.onAdd(e)}>
      {props.isFavorite ? <Img classes={styles['icon']} src="/icons/heart2_fill.svg" /> : <Img src="/icons/heart2.svg" />}
    </button>
  )
}

export default FavoriteButton
