import Dinero from 'dinero.js'
/*eslint no-useless-escape: "off"*/

type Options = {
  maxValue?: number
  valueFormat?: string
  normalizedFormat?: string
}

const defaultOptions: Options = {
  valueFormat: '0,0.00',
  normalizedFormat: '0.00',
}

type Result = {
  intValue: number
  value: string
  normalized: string
}

const REGEX = /[\,\.\s\_]/gi

const parsePrice = (value: string | number | null | undefined | boolean, options?: Options): Result => {
  const parsedValue = value === true ? 0 : typeof value === 'number' ? value : parseInt((value || '').replace(REGEX, ''), 10)

  if (Number.isNaN(parsedValue)) {
    return {
      intValue: 0,
      value: '',
      normalized: '',
    }
  }

  const intValue = Math.min(parsedValue, options?.maxValue || Number.POSITIVE_INFINITY)

  return {
    intValue,
    value: Dinero({ amount: intValue }).toFormat(options?.valueFormat || defaultOptions.valueFormat),
    normalized: Dinero({ amount: intValue }).toFormat(options?.normalizedFormat || defaultOptions.normalizedFormat),
  }
}

export default parsePrice
