type Input = Record<string, any>
type Result = Input

const advancedFilters = [
  'category', 'bottler', 'distilledFrom', 'distilledTo', 'bottledFrom', 'bottledTo', 'caskType', 'abvFrom', 'abvTo', 'sizeFrom', 'sizeTo', 'bottleLocation'
]

const getAdvancedFilters = (values: Input): Result => Object.keys(values)
  .filter(key => advancedFilters.includes(key) && values[key])
  .reduce((prev, curr) => ({
    ...prev,
    [curr]: values[curr],
  }), {})

export default getAdvancedFilters
