import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import styles from './PageLayout.module.scss'
import DomainContext from '../../contexts/DomainContext'
import ChildrenType from '../../interfaces/Children.type'
import { clearCatalog } from '../../services/catalog/catalog.actions'
import Footer from '../Footer'
import Header, { ModeType } from '../Header'

const OFFER_PAGE_REGEX = /^\/offer\/([a-zA-Z0-9-]+)\/([\d]+)$/

const defaultProps = Object.freeze({
  headerMode: 'normal' as ModeType,
})

type Props = typeof defaultProps & {
  children?: ChildrenType
  classes?: string
}

const PageLayout = (props: Props) => {
  const [layoutExtraMargin, setLayoutExtraMargin] = React.useState(0)
  const host = _.trimEnd(process.env.REACT_APP_APP_URL, '/')
  const dispatch = useDispatch()
  const location = useLocation()

  React.useEffect(() => {
    if (location.pathname !== '/catalog' && !OFFER_PAGE_REGEX.test(location.pathname)) {
      dispatch(clearCatalog())
    }

    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <DomainContext.Provider
      value={{
        host,
      }}
    >
      <Header
        mode={props.headerMode}
        onCookiesConsentShown={height => setLayoutExtraMargin(height)}
        onCookiesConsentHide={() => setLayoutExtraMargin(0)}
      />
      <main className={classNames(styles['main'], styles[`main--${props.headerMode}`])} style={{ marginTop: layoutExtraMargin }}>
        <section className={classNames(styles['content'], props.classes)}>{props.children}</section>
      </main>
      <Footer />
    </DomainContext.Provider>
  )
}

PageLayout.defaultProps = defaultProps

export default PageLayout
