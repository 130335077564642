import { useDispatch, useSelector } from 'react-redux'
import OfferType from '../../interfaces/Offer.type'
import * as offerActions from '../../services/offer/offer.actions'
import { StateType } from '../../services/reducers'
import * as userApi from '../../services/user/user.api'

const useOffer = () => {
  const dispatch = useDispatch()
  const favorites = useSelector((state: StateType) => state.offer.favorites)

  const getFavorites = async (): Promise<OfferType[]> => userApi.getFavorites().then(response => response.data.items)

  const isFavorite = (offer: OfferType) => favorites.findIndex(item => item.offerId === offer.id) >= 0

  const initFavorites = (offerIds: string[]) => dispatch(offerActions.setFavoriteOffers(offerIds))

  const addToFavorite = async (offer: OfferType) => {
    await userApi.addOfferToFavorite(offer.id)
    dispatch(offerActions.addOfferToFavorite(offer))
  }

  const removeOfferFromFavorite = async (offer: OfferType) => {
    await userApi.removeOfferFromFavorite(offer.id)
    dispatch(offerActions.removeOfferFromFavorite(offer))
  }

  const temporarilyRemoveOfferFromFavorite = (offer: OfferType) => dispatch(offerActions.temporarilyRemoveOfferFromFavorite(offer))

  return {
    getFavorites,
    isFavorite,
    initFavorites,
    addToFavorite,
    removeOfferFromFavorite,
    temporarilyRemoveOfferFromFavorite,
  }
}

export default useOffer
