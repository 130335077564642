import Yup from '../../utils/yup'

const schema = Yup.object().shape({
  email: Yup.string()
    .ensure()
    .required('Requried')
    .email('It must be a valid email address')
    .trim(),
  tos: Yup.boolean()
    .required('Required')
    .oneOf([true], 'Terms not accepted'),
})

export default schema
