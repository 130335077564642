import styles from '../shared.module.scss'

export default {
  root: {
    '&$contained': {
      margin: '5px 0px',
    },
  },
  contained: {
    margin: '5px 0px',
    fontSize: styles['text__sm'],
  },
}
