import { MenuItem } from '@material-ui/core'
import { AxiosError } from 'axios'
import BigNumber from 'bignumber.js'
import { FormikHelpers, FormikProps } from 'formik'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest'
import { Redirect } from 'react-router'
import { ObjectSchema } from 'yup'
import styles from './OfferForm.module.scss'
import OfferFormView from './OfferFormView'
import UserContext from '../../contexts/UserContext'
import { BottleAddType } from '../../interfaces/Bottle.type'
import { UserType } from '../../interfaces/User.type'
import * as api from '../../services/offer/offer.api'
import countriesi18n from '../../utils/countries'
import handleError from '../../utils/handleError'
import { getGrossAmount, normalizeCurrencyValue, TAX_DEFAULT_RATE } from '../../utils/tax'
import { routes } from '../Link'
import notify from '../Notification/notify'
import Trans from '../Trans'
import OfferType, {
  ImageUploadListType,
  OfferFieldsValuesType,
  OfferFormType,
  OfferSelectFieldValueType,
} from '../../interfaces/Offer.type'
import {
  AutocompleteFieldType,
  FIELD_AUTO_BASE_SEARCH,
  FIELD_AUTO_BOTTLER,
  FIELD_AUTO_DISTILLERY,
  FIELD_AUTO_LOCATION,
  FORM_MODE_BASE_FILLED,
  FORM_MODE_SELF,
  FormModeType,
  OfferFormAutocompletePayloadType,
} from './OfferForm.types'

const defaultProps = Object.freeze({
  isMobile: false as boolean,
  language: 'en' as string,
})

type Props = typeof defaultProps & {
  fieldsValues: OfferFieldsValuesType
  offer?: OfferType
  heading: string | React.ReactNode
  cta: string | React.ReactNode
  confirmationMessage: string
  schema: ObjectSchema<any>
  onFormSubmit: (values: OfferFormType) => Promise<string>
  onBackClick: () => void
}

const SUGGEST_TIMEOUT = 600
const UPLOAD_IMAGE_MAX_SIZE = 2560 //kB
const UPLOAD_IMAGE_MIN_SIZE = 5 //kB
const FIELD_GROSS_NAME = 'priceGross'
const FIELD_LITER_PRICE_NAME = 'pricePerLiter'
const FIELD_TITLE_NAME = 'title'
const FIELD_PRICE_MAX_NUM = 100000000000000000000

function renderBaseSuggestion(item: BottleAddType, { query, isHighlighted }: { query: string; isHighlighted: boolean }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div className={styles['autocompelte__input']}>{item.title}</div>
    </MenuItem>
  )
}

function renderTextSuggestion(item: string, { query, isHighlighted }: { query: string; isHighlighted: boolean }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div className={styles['autocompelte__input']}>{item}</div>
    </MenuItem>
  )
}

const onImageSizeError = (tooBig: boolean, tooSmall: boolean) => {
  if (tooSmall) notify.error(<Trans ns="OfferForm" id="ImageTooSmall" msg="Image size must be at least 5 kB" />)
  if (tooBig) notify.error(<Trans ns="OfferForm" id="ImageTooSmall" msg="Image size cannot be at bigger than 5120 kB" />)
}

const onImageTypeError = (fileType: string) => {
  notify.error(<Trans ns="OfferForm" id="ImageFileTypeError" msg="File extension must be one of PNG, JPG or JPEG" />)
}

const onImageError = (file: File | null, reason: string) => {
  notify.error(<Trans ns="OfferForm" id="ImageUnknownError" msg="Unknown error occured, please try again." />)
}

const updateGross = (netto: string, taxRate: string, formikBag: FormikProps<OfferFormType>) => {
  const normalizedNetto = normalizeCurrencyValue(netto)
  const gross = getGrossAmount(normalizedNetto, taxRate)
  formikBag.setFieldValue(FIELD_GROSS_NAME, gross)
  setLiterPrice(gross as string, formikBag.values.size, formikBag)
}

const setLiterPrice = (price: string, size: number | string, formikBag: FormikProps<OfferFormType>) => {
  const intSize = parseInt(size as string, 10)
  if (Number.isNaN(intSize)) {
    return
  }
  const pricePerLiter =
    intSize > 1000
      ? new BigNumber(size).dividedBy(1000).multipliedBy(normalizeCurrencyValue(price))
      : new BigNumber(1000).dividedBy(size).multipliedBy(normalizeCurrencyValue(price))
  formikBag.setFieldValue(FIELD_LITER_PRICE_NAME, pricePerLiter.toFixed(2).toString())
}

const setTitle = (formikBag: FormikProps<any>, key?: string, newValue?: string | number) => {
  const values = _.cloneDeep(formikBag.values)
  if (key) {
    values[key] = newValue
  }

  const title = `${values.distillery! + ' '}${values.distilled ? values.distilled + '/' : ''}${values.bottled! + ' '}${values.age!}${values.age ? 'YO ' : ''
    }${values.abv}${values.abv ? '% ' : ''}${values.caskNumber ? 'Cask' : ''} ${values.caskNumber}`

  formikBag.setFieldValue(FIELD_TITLE_NAME, title)
}

const OfferForm = (props: Props) => {
  const offerDistillery = props.offer?.bottle.distillery.name || ''
  const offerBottler = props.offer?.bottle.bottler.name || ''
  const bottleLocation = props.offer?.bottleLocation ? countriesi18n.getName(props.offer?.bottleLocation, props.language) : ''

  const initialPhotos =
    props.offer?.photos.original
      .map(photo => ({
        id: photo.parentId,
        data: photo.src,
        main: photo.main,
      }))
      .reduce(
        (prev, curr, index) => ({
          ...prev,
          [`photo${index + 1}`]: curr,
        }),
        {},
      ) || {}

  const [useCounter, setUseCounter] = useState<number>(1)
  const user: UserType = useContext(UserContext)
  const { fieldsValues } = props
  const [offerAdded, setOfferAdded] = useState<boolean>(false)
  const [formMode, setFormMode] = useState<FormModeType>(FORM_MODE_SELF)
  const [selectedBaseItem, setSelectedBaseItem] = useState<BottleAddType>({} as BottleAddType)
  const [baseSearchSuggestions, setBaseSearchSuggestions] = useState<BottleAddType[]>([])
  const [distillerySuggestions, setDistillerySuggestions] = useState<string[]>([offerDistillery].filter(Boolean))
  const [bottlerSuggestions, setBottlerSuggestions] = useState<string[]>([offerBottler].filter(Boolean))
  const [bottleLocationSuggestions, setBottleLocationSuggestions] = useState<string[]>([bottleLocation].filter(Boolean))
  const [images, setImages] = useState<ImageUploadListType>(initialPhotos)
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([])
  const [backPath, setBackPath] = useState<string | null>(null)
  const [distilleryInputValue, setDistilleryInputValue] = useState<string>(offerDistillery)
  const [bottlerInputValue, setBottlerInputValue] = useState<string>(offerBottler)
  const [locationInputValue, setLocationInputValue] = useState<string>(bottleLocation)

  const onDialogClose = () => {
    props.onBackClick()
  }

  const onImageUpload = (file: File, base64: string, imageKey: string, main: boolean = false) => {
    const newImages = _.cloneDeep(images)
    newImages[imageKey] = {
      data: base64,
      main,
    }
    setImages(newImages)
  }

  const onImageDelete = (key: string, id: string | null) => {
    if (key in images) {
      const newImages = _.cloneDeep(images)
      delete newImages[key]
      setImages(newImages)
    }

    if (id) {
      setImagesToDelete([...imagesToDelete, id])
    }
  }

  const onFormSubmit = async (values: OfferFormType, formikBag: FormikProps<OfferFormType>) => {
    const payload = _.cloneDeep(values)
    payload.images = Object.keys(images)
      .sort()
      .map(key => images[key])
    payload.imagesToDelete = [...imagesToDelete]
    payload.price = normalizeCurrencyValue(payload.price)
    payload.pricePerLiter = normalizeCurrencyValue(payload.pricePerLiter)
    payload.priceGross = normalizeCurrencyValue(payload.priceGross as string)
    payload.gst = payload.gstRate > 0
    payload.bottleLocation = countriesi18n.getAlpha2Code(payload.bottleLocation.trim(), props.language)
    payload.gstRate = new BigNumber(payload.gstRate).dividedBy(100).toString()

    if (payload.bottler) {
      payload.bottler = payload.bottler.trim()
    }

    try {
      const inBackPath = await props.onFormSubmit(payload)
      setOfferAdded(true)
      notify.success(props.confirmationMessage)

      setTimeout(() => {
        formikBag.setSubmitting(false)
        setBackPath(inBackPath)
      }, 3500)
    } catch (error) {
      formikBag.setSubmitting(false)
      handleError(error as AxiosError, formikBag)
    }
  }

  const autoFields = {
    FIELD_AUTO_BASE_SEARCH: {
      name: FIELD_AUTO_BASE_SEARCH,
      suggestions: baseSearchSuggestions,
      onSuggestionSelected: (
        event: React.FormEvent<any>,
        data: SuggestionSelectedEventData<BottleAddType>,
        formikBag: FormikProps<OfferFormAutocompletePayloadType>,
      ) => {
        autoFields.FIELD_AUTO_BASE_SEARCH.onSelected(data.suggestionValue, formikBag)
        setFormMode(FORM_MODE_BASE_FILLED)
        setSelectedBaseItem(data.suggestion)

        const entries = Object.entries(data.suggestion)
        for (const [key, value] of entries) {
          formikBag.setFieldTouched(key)
          formikBag.setFieldValue(key, value)
        }
      },
      onSelected: (value: string, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        formikBag.setFieldTouched(FIELD_AUTO_BASE_SEARCH)
        formikBag.setFieldValue(FIELD_AUTO_BASE_SEARCH, value)
      },
      getSuggestions: (value: string, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        clearTimeout(autoFields.FIELD_AUTO_BASE_SEARCH.timer!)
        autoFields.FIELD_AUTO_BASE_SEARCH.timer = setTimeout(async () => {
          try {
            const inputValue = _.deburr(value.trim()).toLowerCase()
            const inputLength = inputValue.length
            let suggestions = []
            if (!inputLength) return

            try {
              suggestions = (await api.readItemFromBase(value)).data.items
            } catch (error) {
              console.error(error)
            }
            setBaseSearchSuggestions(suggestions)
          } catch (error) {
            console.error(error)
            // handleError(error, formikBag)
            setBaseSearchSuggestions([])
          }
        }, SUGGEST_TIMEOUT)
      },
      getSuggestionValue: (item: BottleAddType) => item.title,
      onSuggestionsClearRequested: () => { },
      onSuggestionsFetchRequested: (request: SuggestionsFetchRequestedParams, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        autoFields.FIELD_AUTO_BASE_SEARCH.getSuggestions(request.value, formikBag)
      },
      renderSuggestion: renderBaseSuggestion,
    } as AutocompleteFieldType<BottleAddType, OfferFormAutocompletePayloadType>,

    FIELD_AUTO_DISTILLERY: {
      name: FIELD_AUTO_DISTILLERY,
      suggestions: distillerySuggestions,
      onSuggestionSelected: (
        event: React.FormEvent<any>,
        data: SuggestionSelectedEventData<string>,
        formikBag: FormikProps<OfferFormAutocompletePayloadType>,
      ) => {
        autoFields.FIELD_AUTO_DISTILLERY.onSelected(data.suggestionValue, formikBag)
      },
      onSelected: (value: string, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        setDistilleryInputValue(value)
        setTitle(formikBag, FIELD_AUTO_DISTILLERY, value)
        formikBag.setFieldTouched(FIELD_AUTO_DISTILLERY)
        formikBag.setFieldValue(FIELD_AUTO_DISTILLERY, value)
      },
      getSuggestions: (value: string, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        clearTimeout(autoFields.FIELD_AUTO_DISTILLERY.timer!)
        autoFields.FIELD_AUTO_DISTILLERY.timer = setTimeout(async () => {
          try {
            const inputValue = _.deburr(value.trim()).toLowerCase()
            const inputLength = inputValue.length
            if (!inputLength) return

            const response = await api.searchDistilleryByQuery(value, undefined, true)
            const suggestions = response.data.map((suggestion: OfferSelectFieldValueType) => {
              return suggestion.name
            })
            setDistillerySuggestions(suggestions.slice(0, 20))
          } catch (error) {
            setDistillerySuggestions([])
            //handleError(error, formikBag)
          }
        }, SUGGEST_TIMEOUT)
      },
      getSuggestionValue: (item: string) => item,
      onSuggestionsClearRequested: () => { },
      onSuggestionsFetchRequested: (request: SuggestionsFetchRequestedParams, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        autoFields.FIELD_AUTO_DISTILLERY.getSuggestions(request.value, formikBag)
      },
      renderSuggestion: renderTextSuggestion,
      inputValue: distilleryInputValue,
      correct: distillerySuggestions.includes(distilleryInputValue.trim()),
    } as AutocompleteFieldType<string, OfferFormAutocompletePayloadType>,

    FIELD_AUTO_BOTTLER: {
      name: FIELD_AUTO_BOTTLER,
      suggestions: bottlerSuggestions,
      onSuggestionSelected: (
        event: React.FormEvent<any>,
        data: SuggestionSelectedEventData<string>,
        formikBag: FormikProps<OfferFormAutocompletePayloadType>,
      ) => {
        autoFields.FIELD_AUTO_BOTTLER.onSelected(data.suggestionValue, formikBag)
      },
      onSelected: (value: string, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        setBottlerInputValue(value)
        formikBag.setFieldTouched(FIELD_AUTO_BOTTLER)
        formikBag.setFieldValue(FIELD_AUTO_BOTTLER, value)
      },
      getSuggestions: (value: string, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        clearTimeout(autoFields.FIELD_AUTO_BOTTLER.timer!)
        autoFields.FIELD_AUTO_BOTTLER.timer = setTimeout(async () => {
          try {
            const inputValue = _.deburr(value.trim()).toLowerCase()
            const inputLength = inputValue.length
            if (!inputLength) return

            const response = await api.searchBottlerByQuery(value)
            const suggestions = response.data.map((suggestion: OfferSelectFieldValueType) => {
              return suggestion.name
            })
            setBottlerSuggestions(suggestions.slice(0, 20))
          } catch (error) {
            //handleError(error, formikBag)
          }
        }, SUGGEST_TIMEOUT)
      },
      getSuggestionValue: (item: string) => item,
      onSuggestionsClearRequested: () => { },
      onSuggestionsFetchRequested: (request: SuggestionsFetchRequestedParams, formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
        autoFields.FIELD_AUTO_BOTTLER.getSuggestions(request.value, formikBag)
      },
      renderSuggestion: renderTextSuggestion,
      inputValue: bottlerInputValue,
      correct: bottlerSuggestions.includes(bottlerInputValue.trim()),
    } as AutocompleteFieldType<string, OfferFormAutocompletePayloadType>,

    FIELD_AUTO_LOCATION: {
      name: FIELD_AUTO_LOCATION,
      suggestions: bottleLocationSuggestions,
      onSuggestionSelected: (event: React.FormEvent<any>, data: SuggestionSelectedEventData<string>, formikBag: FormikProps<string>) => {
        autoFields.FIELD_AUTO_LOCATION.onSelected(data.suggestionValue, formikBag)
      },
      onSelected: (value: string, formikBag: FormikProps<string>) => {
        setLocationInputValue(value)
        formikBag.setFieldTouched(FIELD_AUTO_LOCATION)
        formikBag.setFieldValue(FIELD_AUTO_LOCATION, value)
      },
      getSuggestions: async (value: string, formikBag: FormikProps<string>) => {
        const inputValue = _.deburr(value.trim()).toLowerCase()
        const inputLength = inputValue.length
        let count = 0
        if (!inputLength) return

        const countries = Object.values(countriesi18n.getNames(props.language))
        const suggestions = countries.filter((suggestion: string) => {
          const keep = count < 20 && suggestion.slice(0, inputLength).toLowerCase() === inputValue

          if (keep) {
            count += 1
          }

          return keep
        })
        setBottleLocationSuggestions(suggestions)
      },
      getSuggestionValue: (item: string) => item,
      onSuggestionsClearRequested: () => { },
      onSuggestionsFetchRequested: (request: SuggestionsFetchRequestedParams, formikBag: FormikProps<string>) => {
        autoFields.FIELD_AUTO_LOCATION.getSuggestions(request.value, formikBag)
      },
      renderSuggestion: renderTextSuggestion,
      inputValue: locationInputValue,
      correct: bottleLocationSuggestions.includes(locationInputValue.trim()),
    } as AutocompleteFieldType<string, string>,
  }

  const onAddAnother = (formikBag: FormikHelpers<OfferFormType>) => {
    formikBag.resetForm()
    setOfferAdded(false)
    setImages({})
    setUseCounter((prev: number) => prev + 1)
  }

  return !user.data?.isAdmin && !user.data?.isModerator && !user.data?.isProUser ? (
    <Redirect to={routes.catalog} />
  ) : backPath ? (
    <Redirect to={backPath} />
  ) : (
    <OfferFormView
      isStandalone={!props.isMobile}
      formMode={formMode}
      setFormMode={setFormMode}
      autoFields={autoFields}
      selectedBaseItem={selectedBaseItem}
      maxImageSize={UPLOAD_IMAGE_MAX_SIZE}
      minImageSize={UPLOAD_IMAGE_MIN_SIZE}
      onImageUpload={onImageUpload}
      onImageDelete={onImageDelete}
      images={images}
      onFormSubmit={onFormSubmit}
      onImageSizeError={onImageSizeError}
      onImageTypeError={onImageTypeError}
      updateGross={updateGross}
      gstRate={TAX_DEFAULT_RATE} //TODO form user profile or default
      maxPrice={FIELD_PRICE_MAX_NUM}
      fieldsValues={fieldsValues}
      setLiterPrice={setLiterPrice}
      setTitle={setTitle}
      onImageError={onImageError}
      onDialogClose={onDialogClose}
      onBack={onDialogClose}
      withGst
      user={user}
      offer={props.offer}
      heading={props.heading}
      cta={props.cta}
      schema={props.schema}
      offerAdded={offerAdded}
      onAddAnother={onAddAnother}
      useCounter={useCounter}
    />
  )
}

OfferForm.defaultProps = defaultProps

export default OfferForm
