import { Badge } from '@material-ui/core'
import React, { useContext } from 'react'
import styles from './ConversationItem.module.scss'
import UserContext from '../../../contexts/UserContext'
import { ConversationType } from '../../../interfaces/Chat.type'
import Date from '../../../utils/date'
import Img from '../../Img'
import { CONVERSATION_TYPE } from '../Chat.types'

type Props = {
  item: ConversationType
  inConversation?: boolean
  type: CONVERSATION_TYPE
}

const ConversationItem = (props: Props) => {
  const { item, inConversation, type } = props
  const user = useContext(UserContext)
  const mineConversation = item.sender.id === user.data?.id
  const archivedByMe = !!(mineConversation && item.archivedBySenderAt! > 0)
  let author = mineConversation ? item.receiver : item.sender

  if (type === CONVERSATION_TYPE.ARCHIVE) {
    author = type === CONVERSATION_TYPE.ARCHIVE && archivedByMe ? item.receiver : item.sender
  }

  return (
    <div className={styles['item']}>
      <div className={styles['item__user']}>
        <img src={author.avatar} className={styles['item__avatar']} alt="avatar" />
        <span className={styles['item__username']}>{author.username}</span>
      </div>
      <div className={styles['item__offer']}>
        <span className={styles['item__offer-title']}>{item.title}</span>
        {inConversation ? null : <span className={styles['item__message-first-line']}>{item.lastMessage?.content}</span>}
      </div>
      <div className={styles['item__details']}>
        <span className={styles['details__date']}>{Date.timestampToFormat(item.createdAt, 'DD MMM, HH:mm', true)}</span>
        {item.readAt || inConversation ? (
          <div className={styles['details__counter-box']}>
            <Badge badgeContent={item.messageCount} color="secondary" max={99}>
              <Img src="/icons/chat_mail.svg" classes={styles['details__counter']} />
            </Badge>
          </div>
        ) : (
          <>
            <div className={styles['details__counter-box']}>
              <Badge badgeContent={item.messageCount} color="primary" max={99}>
                <Img src="/icons/chat_mail.svg" classes={styles['details__counter']} />
              </Badge>
            </div>
            <span className={styles['details__unread']}>Unread message</span>
          </>
        )}
      </div>
    </div>
  )
}

export default ConversationItem
