import CircularProgress from '@material-ui/core/CircularProgress'
import classNames from 'classnames'
import React from 'react'
import styles from './LoadingSpinner.module.scss'

type Props = {
  center?: boolean
}

const LoadingSpinner = (props: Props) => {
  const classes = classNames({
    [styles['spinner--center']]: props.center,
  })

  return (
    <div className={classes}>
      <CircularProgress />
    </div>
  )
}

export default LoadingSpinner
