import { ActionsType, ActionTypes } from './user.types'
import UserType, { UserDataType } from '../../interfaces/User.type'

export function signSuccess(data: UserType): ActionsType {
  return {
    type: ActionTypes.READ_PROFILE_SUCCESS,
    payload: data,
  }
}

export function readUserDataSuccess(data: UserDataType): ActionsType {
  return {
    type: ActionTypes.READ_USER_DATA_SUCCESS,
    payload: data,
  }
}
