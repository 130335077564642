import { Button } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button/Button'
import FacebookIcon from '@material-ui/icons/Facebook'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import styles from './FacebookButton.module.scss'

type Props = ButtonProps & {
  label?: string | React.ReactNode
  classes?: object
}

const FacebookButton = (props: Props) => {
  const allClasses = _.merge({}, props.classes, {
    root: classNames(styles['facebook'], _.get(props, 'classes.root', {})),
  })

  return (
    <Button color="primary" variant="contained" startIcon={<FacebookIcon />} classes={allClasses} {..._.omit(props, ['classes'])}>
      {props.label}
    </Button>
  )
}

export default FacebookButton
