/*eslint-disable*/
//disable-sort-imports
//@ts-nocheck

import { LocationDescriptorObject, Path, LocationState } from 'history'

export type ToType =
  | (LocationDescriptorObject<LocationState> & {
      name?: string
      params?: object
    })
  | Path

const cache = {}

function resolveTo(to: ToType): string {
  if (typeof to === 'string') {
    return to
  }

  if (!to.name) {
    if (!to.pathname) {
      throw new Error('Fill in "name" or "pathname"')
    }

    return to.pathname
  }

  if (to.pathname) {
    throw new Error('Cannot specify both "name" and "pathname"')
  }

  return to.name
}

function resolveRoute(route: string, params: object): string {
  if (route in cache) {
    return cache[route](params)
  }

  const path = route.replace(/(\([^)]+\))/g, '')

  cache[route] = function(_params: object) {
    let result = path
    let search = ''
    Object.keys(_params).forEach(key => {
      const regExp = new RegExp(`:${key}`, 'g')
      if (result.match(regExp) === null) {
        search = `${search.length === 0 ? '?' : `${search}&`}${key}=${_params[key]}`
      }
      result = result.replace(regExp, _params[key])
    })

    return search.length > 0 ? `${result}${search}` : result
  }

  return cache[route](params)
}

export { resolveTo, resolveRoute }
