import { CircularProgress, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './Chat.module.scss'
import { CONVERSATION_TYPE } from './Chat.types'
import ConversationItem from './ConversationItem'
import { ConversationType } from '../../interfaces/Chat.type'
import Img from '../Img'
import Link, { routes } from '../Link'

type Props = {
  setConversationBox: (type: CONVERSATION_TYPE) => void
  conversationType: CONVERSATION_TYPE
  conversations: ConversationType[]
  isLoading: boolean
  activeConversation?: ConversationType | null
}

const ChatView = (props: Props) => {
  const { conversationType, conversations, setConversationBox, isLoading } = props

  const menu = [
    {
      type: CONVERSATION_TYPE.INBOX,
      label: 'Inbox',
      active: conversationType === CONVERSATION_TYPE.INBOX,
      onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setConversationBox(CONVERSATION_TYPE.INBOX),
    },
    {
      type: CONVERSATION_TYPE.OUTBOX,
      label: 'Outbox',
      active: conversationType === CONVERSATION_TYPE.OUTBOX,
      onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setConversationBox(CONVERSATION_TYPE.OUTBOX),
    },
    {
      type: CONVERSATION_TYPE.ARCHIVE,
      label: 'Archive',
      active: conversationType === CONVERSATION_TYPE.ARCHIVE,
      onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => setConversationBox(CONVERSATION_TYPE.ARCHIVE),
    },
  ]

  return (
    <div className={styles['chat']}>
      <Typography variant="h3">Messages</Typography>
      <div className={styles['chat__menu']}>
        {menu.map(item => {
          return (
            <a
              onClick={item.onClick}
              key={`menu-${item.label}`}
              className={classNames(styles['menu__item'], { primary: !item.active, [styles['menu__item--disabled']]: item.active })}
            >
              {item.label}
            </a>
          )
        })}
      </div>
      {conversations && conversations.length > 0 && !isLoading ? (
        <div className={styles['chat__conversations-box']}>
          <div className={styles['conversations-box__head']}>
            <div className={styles['head__user']}>User</div>
            <div className={styles['head__offer']}>Offer</div>
            <div className={styles['head__date']}>Date</div>
          </div>
          <div className={styles['conversation-box__list']}>
            {conversations.map((item: ConversationType) => (
              <Link silent classes="primary" to={routes.conversation} params={{ id: item.id }} key={`item-${item.id}`}>
                <ConversationItem item={item} type={conversationType} />
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <div className={styles['chat__conversations-box--empty']}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Img src="/icons/chat_mail.svg" />
              <span className={styles['conversations-box--empty__alert']}>You have no messages</span>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ChatView
