export type BottleAddType = {
  id?: string
  title: string
  category: string
  distillery: string
  bottler: string
  distilled?: string
  bottled?: string
  age?: string
  abv?: string
  size: string
  caskNumber?: string
  caskType?: string | ''
  outturn?: string
}

export enum BottleKindType {
  WHISKY = 'whisky',
}

export type AttributeType = {
  id: string
  name: string
  slug?: string
}

export type BottleType = {
  id?: string
  title: string
  type: string
  category: AttributeType
  distillery: AttributeType
  bottler: AttributeType
  distilled: number
  bottled: number
  abv?: number | null
  size: number
  caskNumber?: string
  caskType?: AttributeType
  outturn: number
}
