import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import { FormControlProps } from '@material-ui/core/FormControl'
import { InputLabelProps } from '@material-ui/core/InputLabel'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import styles from './CheckboxField.module.scss'

const defaultProps = Object.freeze({
  variant: 'outlined' as any,
  InputProps: {} as CheckboxProps,
  InputLabelProps: { shrink: true } as InputLabelProps,
  withMargins: true as boolean,
  helperText: '' as string,
  emptyHelperText: true as boolean,
})

type Props = CheckboxProps &
  typeof defaultProps & {
    formControlProps?: FormControlProps
    error?: boolean
    label?: React.ReactNode | string | number
    fullWidth?: boolean
    emptyHelperText?: boolean
  }

const CheckboxField = (props: Props) => {
  const classes = {
    root: classNames(props.classes?.root),
  }

  const errorHelper =
    props.error || props.emptyHelperText ? (
      <FormHelperText
        error
        classes={{
          root: styles['helper-text'],
        }}
      >
        {props.error ? props.helperText : ''}
      </FormHelperText>
    ) : null

  const inputProps = {
    ...props.InputProps,
  }
  //@ts-ignore
  return (
    <FormControl {...props.formControlProps} fullWidth={props.fullWidth} classes={classes}>
      <FormControlLabel
        label={props.label}
        control={
          <Checkbox
            color="primary"
            name={props.name}
            value={props.value}
            checked={!!props.value}
            onChange={props.onChange}
            {...inputProps}
          />
        }
      />
      {errorHelper}
    </FormControl>
  )
}

CheckboxField.defaultProps = defaultProps
export default CheckboxField
