import styles from '../shared.module.scss'

export default {
  root: {
    width: `calc(${styles['field__width']} - 0px)`,
  },

  track: {
    color: styles['color__default--dark'],
    height: '2px',
  },

  rail: {
    height: '2px',
  },

  thumb: {
    '&::after': {
      top: '-2px',
      left: '-2px',
      right: '-2px',
      bottom: '-2px',
      border: `1px solid ${styles['color__default--dark']}`,
    },
  },
}
