import { ChangePasswordPayloadType, UserFormPayloadType } from './user.types'
import axios from '../../utils/axios'

export function updatePassword(payload: ChangePasswordPayloadType) {
  return axios({
    method: 'PUT',
    url: '/user/me/change-password',
    data: payload,
  })
}

export function updateUser(payload: UserFormPayloadType) {
  return axios({
    method: 'PUT',
    url: '/user/me',
    data: payload,
  })
}

//TODO: change to get
export function readUser() {
  return axios({
    method: 'GET',
    url: '/user/me',
    data: {},
  })
}

//payload = base64
export function updateAvatar(payload: string) {
  return axios({
    method: 'POST',
    url: '/user/me/avatar',
    data: {
      image: {
        id: null,
        data: payload,
        main: true,
      },
    },
  })
}

export function readMyOffers(url?: string, all?: boolean, query?: string) {
  return axios({
    method: 'GET',
    url: url || `/user/me/offer?all=${String(all)}&query=${encodeURIComponent(query || '')}`,
  })
}

export function readOffers(id: string, url?: string, all?: boolean, query?: string) {
  return axios({
    method: 'GET',
    url: url || `/user/${id}/offer?all=${String(all)}&query=${encodeURIComponent(query || '')}`,
  })
}

export const addOfferToFavorite = (offerId: string) => axios({
  method: 'POST',
  url: `/user/me/offer/${offerId}/favorite`,
})

export const removeOfferFromFavorite = (offerId: string) => axios({
  method: 'DELETE',
  url: `/user/me/offer/${offerId}/favorite`,
})

export const getFavorites = () => axios({
  method: 'GET',
  url: '/user/me/offer/favorite',
})
