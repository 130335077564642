import { CreateOfferPayloadType, UpdateOfferPayloadType } from './offer.types'
import { BottleKindType } from '../../interfaces/Bottle.type'
import axios from '../../utils/axios'

export function readItemFromBase(query: string, type: BottleKindType = BottleKindType.WHISKY) {
  return axios({
    method: 'GET',
    url: `/search/bottle/?query=${encodeURIComponent(query)}&bottleType=${encodeURIComponent(type)}`,
  })
}

export function searchDistilleryByQuery(query: string, type: BottleKindType = BottleKindType.WHISKY, other?: boolean) {
  return axios({
    method: 'GET',
    url: `/search/distillery?bottleType=${encodeURIComponent(type)}&query=${encodeURIComponent(query)}&other=${other ? 1 : 0}`,
  })
}

export function searchBottlerByQuery(query: string, type: BottleKindType = BottleKindType.WHISKY) {
  return axios({
    method: 'GET',
    url: `/search/bottler?bottleType=${encodeURIComponent(type)}&query=${encodeURIComponent(query)}`,
  })
}

export function createOffer(payload: CreateOfferPayloadType) {
  return axios({
    method: 'POST',
    url: '/offer',
    data: payload,
  })
}

export function updateOffer(id: string, payload: UpdateOfferPayloadType) {
  return axios({
    method: 'PUT',
    url: `/offer/${id}`,
    data: payload,
  })
}

export function archiveOffer(id: string) {
  return axios({
    method: 'PUT',
    url: `/offer/${id}/archive`,
  })
}

export function activateOffer(id: string) {
  return axios({
    method: 'PUT',
    url: `/offer/${id}/activate`,
  })
}

export function markAsSold(id: string) {
  return axios({
    method: 'PUT',
    url: `/offer/${id}/sold`,
  })
}

export function searchFieldsHints(type: BottleKindType = BottleKindType.WHISKY) {
  return axios({
    method: 'GET',
    url: `/search/offer/bottle-attribute?bottleType=${type}`,
  })
}

export function readOffer(id: string) {
  return axios({
    method: 'GET',
    url: `/offer/${id}`,
  })
}

export function readOfferBySku(sku: string) {
  return axios({
    method: 'GET',
    url: `/offer/sku/${sku}`,
  })
}

export function search(query: false | string = false) {
  const url = query ? `/search/offer?${query}` : '/search/offer'
  return axios({
    method: 'GET',
    url,
  })
}

export function deleteOffer(offerId: string) {
  return axios({
    method: 'DELETE',
    url: `/offer/${offerId}`,
  })
}
