import { Button } from '@material-ui/core'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import styles from './GoogleButton.module.scss'
import { GoogleSignInPayloadType } from '../../../services/auth/auth.types'
import Img from '../../Img'

type Props = {
  label?: React.ReactNode
  classes?: {
    root?: string
  }
  onSuccess: (response: GoogleSignInPayloadType) => void
  onError: (error: string) => void
}

const GoogleButton = (props: Props) => {
  const [GAPILoading, setGAPILoading] = useState<boolean>(false)
  const [GAPILoaded, setGAPILoaded] = useState<boolean>(false)
  const { onSuccess, classes, onError } = props
  const allClasses = _.merge({}, props.classes, {
    root: classNames(styles['google'], classes?.root!),
  })

  useEffect(() => {
    if (!GAPILoading && !GAPILoaded && window?.gapi) {
      setGAPILoading(true)
      window?.gapi?.load('auth2', () => {
        setGAPILoaded(true)
        setGAPILoading(false)
      })
    }
  }, [window?.gapi])

  return GAPILoaded ? (
    <Button
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        try {
          window.gapi.auth2
            .getAuthInstance()
            .signIn()
            .then((user: gapi.auth2.GoogleUser) => {
              onSuccess({
                accessToken: user.getAuthResponse().access_token,
                idToken: user.getAuthResponse().id_token,
              })
              console.log('GAPI Auth', user.getAuthResponse())
            })
            .catch(error => {
              onError(error)
            })
        } catch (error) {
          console.error(error)
        }
      }}
      variant="contained"
      startIcon={<Img src="/icons/google.png" classes={styles['google__icon']} />}
      classes={allClasses}
      disabled={!GAPILoaded}
    >
      {props.label}
    </Button>
  ) : null
}

export default GoogleButton
