const parseJson = (string?: string | null | undefined) => {
  const jsonString = (string || '')
    .replace(/\\n/g, '\\n')  
    .replace(/\\'/g, '\\\'')
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f')
    .replace(/[\u0000-\u0019]+/g, '')

  return JSON.parse(jsonString)
}

export default parseJson
