import {
  Button,
  FormControlLabel,
  Radio,
  Typography
  } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import { FormikHelpers, useFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import AutocompleteField from './Field/AutocompleteField'
import InputField from './Field/InputField'
import RadioField from './Field/RadioField'
import SelectField from './Field/SelectField'
import styles from './Filters.module.scss'
import schema, {
  ABV_MAX,
  ABV_MIN,
  SIZE_MAX,
  SIZE_MIN,
  YEAR_MAX,
  YEAR_MIN
  } from './Filters.schema'
import getAdvancedFilters from './util/getAdvancedFilters'
import { getBottlerSuggestions, getDistillerySuggestions } from './util/suggestions'
import { ACTIVITY_ACTIVE, ACTIVITY_ALL, FiltersType } from '../../../interfaces/Filters.type'
import { OfferFieldsValuesType } from '../../../interfaces/Offer.type'
import countriesi18n from '../../../utils/countries'
import { getDefault as getDefaultFilters, merge as mergeFilters } from '../../../utils/filters'
import parsePrice from '../../../utils/parsePrice'
import FormFieldCountry from '../../Form/FormFieldCountry'

const defaultProps = Object.freeze({
  language: 'en' as string,
  currencySign: '€' as string,
})

type Classes = {
  root?: string
  content?: string
}

type Props = typeof defaultProps & {
  onSubmit: (onFilterSubmit: FiltersType) => void
  onReset: () => void
  classes?: Classes
  loading: boolean
  filters: FiltersType
  fieldsValues: OfferFieldsValuesType
  showAdvancedSearch: boolean
}

type SelectValue = {
  name: string
  value: string
  min?: number
  max?: number
}

type RefType = React.MutableRefObject<any> | ((instance: any) => void) | null

export const ageValues: SelectValue[] = [
  { name: '5 YO', value: '5 YO', max: 5 },
  { name: '6-10 YO', value: '6-10 YO', min: 6, max: 10 },
  { name: '11-15 YO', value: '11-15 YO', min: 11, max: 15 },
  { name: '16-25 YO', value: '16-25 YO', min: 16, max: 25 },
  { name: 'above 25 YO ', value: 'above 25 YO ', min: 26 },
]

export const priceValues: SelectValue[] = [
  { name: '€0-€1000', value: '€0-€1000', max: 1000 },
  { name: '€1000-€2000', value: '€1000-€2000', min: 1000, max: 2000 },
  { name: '€2000-€5000', value: '€2000-€5000', min: 2000, max: 5000 },
  { name: '€5000-€10000', value: '€5000-€10000', min: 5000, max: 10000 },
  { name: '€10000 & above', value: '€10000 & above', min: 10000 },
]

const Filters = React.forwardRef((props: Props, ref: RefType) => {
  const { classes, fieldsValues } = props
  const initialValues = getDefaultFilters()
  const initialFiltersFromProps = _.cloneDeep(props.filters)
  initialFiltersFromProps!.bottleLocation = props.filters.bottleLocation
    ? countriesi18n.getName(props.filters.bottleLocation as string, props.language) || ''
    : props.filters.bottleLocation

  const formik = useFormik({
    initialValues: mergeFilters(initialValues, initialFiltersFromProps),
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values: FiltersType, formikBag: FormikHelpers<FiltersType>) => {
      const newValues = _.cloneDeep(values)

      if (newValues.age) {
        const ageValue = ageValues.find(item => item.value === newValues.age)

        if (ageValue) {
          newValues.ageFrom = ageValue.min || ''
          newValues.ageTo = ageValue.max || ''
        }
      } else {
        newValues.ageFrom = ''
        newValues.ageTo = ''
      }

      if (newValues.price) {
        const priceValue = priceValues.find(item => item.value === newValues.price)

        if (priceValue) {
          newValues.priceFrom = priceValue.min ? parsePrice(priceValue.min * 100).normalized : ''
          newValues.priceTo = priceValue.max ? parsePrice(priceValue.max * 100).normalized : ''
        }
      } else {
        newValues.priceFrom = ''
        newValues.priceTo = ''
      }

      newValues.active = newValues.activity === ACTIVITY_ACTIVE
      newValues.bottleLocation = newValues.bottleLocation
        ? countriesi18n.getAlpha2Code(newValues?.bottleLocation as string, props.language) || ''
        : newValues.bottleLocation

      props.onSubmit(newValues)
    },
  })

  const [showAdvancedFilters, setShowAdvancedFilters] = React.useState(Object.keys(getAdvancedFilters(formik.values)).length > 0 || props.showAdvancedSearch)

  const handleShowAdvancedFilters = () => {
    setShowAdvancedFilters((v: boolean) => !v)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    formik.submitForm()
  }

  const fieldClasses = {
    root: classNames(styles['field']),
  }

  const fieldRadioClasses = {
    root: classNames(styles['field'], styles['field--radio']),
  }

  return (
    <div ref={ref} className={classes?.root!}>
      <form onSubmit={handleSubmit} className={styles['form']}>
        <div className={styles['filters__container--primary']}>
          <div className={classNames(styles['header'], props.classes?.content)}>
            <Typography variant="h4" className={styles['header__text']}>Let&apos;s find something special</Typography>
          </div>
          <div className={classNames(styles['filters'], props.classes?.content)}>
            <div className={styles['form__field']}>
              <SelectField
                form={formik}
                name="bottleType"
                label="Type"
                classes={fieldClasses}
                values={fieldsValues['bottleType']?.values || []}
              />
            </div>
            <div className={styles['form__field']}>
              <AutocompleteField
                form={formik}
                name="distillery"
                label="Distillery"
                placeholder="Write"
                classes={fieldClasses}
                onLoadSuggestions={getDistillerySuggestions}
                submitOnEnter={false}
              />
            </div>
            <div className={styles['form__field']}>
              <SelectField form={formik} name="age" label="Age" classes={fieldClasses} values={ageValues} />
            </div>
            <div className={styles['form__field']}>
              <SelectField form={formik} name="price" label="Price" classes={fieldClasses} values={priceValues} />
            </div>
          </div>
        </div>
        {showAdvancedFilters ? (
          <div className={styles['filters__container--advanced']}>
            <div className={classNames(styles['header'], props.classes?.content)}>
              <Typography variant="h5">Advanced filters</Typography>
              <CloseIcon className={styles['close-icon']} onClick={handleShowAdvancedFilters} />
            </div>
            <div className={classNames(styles['filters'], props.classes?.content)}>
              <div className={styles['form__field']}>
                <SelectField
                  form={formik}
                  name="category"
                  label="Category"
                  classes={fieldClasses}
                  values={fieldsValues['category']?.values || []}
                />
              </div>
              <div className={styles['form__field']}>
                <AutocompleteField
                  form={formik}
                  name="bottler"
                  label="Bottler"
                  placeholder="Write"
                  classes={fieldClasses}
                  onLoadSuggestions={getBottlerSuggestions}
                  submitOnEnter={false}
                />
              </div>
              <div className={styles['form__field']}>
                <div className={styles['form__distilled']}>
                  <InputField
                    form={formik}
                    type="text"
                    name="distilledFrom"
                    label="Distilled from"
                    placeholder="Write"
                    classes={fieldClasses}
                    min={YEAR_MIN}
                    max={YEAR_MAX}
                  />
                  <InputField
                    form={formik}
                    type="text"
                    name="distilledTo"
                    label="to"
                    placeholder="Write"
                    classes={fieldClasses}
                    min={YEAR_MIN}
                    max={YEAR_MAX}
                  />
                </div>
              </div>
              <div className={styles['form__field']}>
                <div className={styles['form__bottled']}>
                  <InputField
                    form={formik}
                    type="text"
                    name="bottledFrom"
                    label="Bottled from"
                    placeholder="Write"
                    classes={fieldClasses}
                    min={YEAR_MIN}
                    max={YEAR_MAX}
                  />
                  <InputField
                    form={formik}
                    type="text"
                    name="bottledTo"
                    label="to"
                    placeholder="Write"
                    classes={fieldClasses}
                    min={YEAR_MIN}
                    max={YEAR_MAX}
                  />
                </div>
              </div>
              <div className={styles['form__field']}>
                <InputField form={formik} name="caskNumber" label="Cask No" placeholder="Write" classes={fieldClasses} />
              </div>
              <div className={styles['form__field']}>
                <SelectField
                  form={formik}
                  name="caskType"
                  label="Cask Type"
                  classes={fieldClasses}
                  values={fieldsValues['caskType']?.values || []}
                />
              </div>
              <div className={styles['form__field']}>
                <div className={styles['form__abv']}>
                  <InputField
                    form={formik}
                    type="text"
                    name="abvFrom"
                    label="Strength from (%)"
                    placeholder="Write"
                    classes={fieldClasses}
                    min={ABV_MIN}
                    max={ABV_MAX}
                  />
                  <InputField
                    form={formik}
                    type="text"
                    name="abvTo"
                    label="to"
                    placeholder="Write"
                    classes={fieldClasses}
                    min={ABV_MIN}
                    max={ABV_MAX}
                  />
                </div>
              </div>
              <div className={styles['form__field']}>
                <div className={styles['form__size']}>
                  <InputField
                    form={formik}
                    type="text"
                    name="sizeFrom"
                    label="Size from (ml)"
                    placeholder="Write"
                    classes={fieldClasses}
                    min={SIZE_MIN}
                    max={SIZE_MAX}
                  />
                  <InputField
                    form={formik}
                    type="text"
                    name="sizeTo"
                    label="to"
                    placeholder="Write"
                    classes={fieldClasses}
                    min={SIZE_MIN}
                    max={SIZE_MAX}
                  />
                </div>
              </div>
              <div className={styles['form__field']}>
                <FormFieldCountry<FiltersType>
                  form={formik}
                  fieldName="bottleLocation"
                  placeholder="Write"
                  field={{
                    name: 'bottleLocation',
                    value: formik.values.bottleLocation,
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                  }}
                  label="Bottle location"
                  classes={fieldClasses}
                  withMargins={false}
                />
              </div>
              <div className={classNames(styles['form__field'], styles['form__field--full-width'])}>
                <RadioField form={formik} name="activity" label="Offers" classes={fieldRadioClasses}>
                  <FormControlLabel
                    className={styles['form__active__label']}
                    value={ACTIVITY_ACTIVE}
                    control={<Radio color="primary" />}
                    label={ACTIVITY_ACTIVE}
                  />
                  <FormControlLabel
                    className={styles['form__active__label']}
                    value={ACTIVITY_ALL}
                    control={<Radio color="primary" />}
                    label={ACTIVITY_ALL}
                  />
                </RadioField>
              </div>
            </div>
          </div>
        ) : null}
        {showAdvancedFilters ? (
          <div className={classNames(styles['form__buttons'], styles['form__buttons--advanced'])}>
            <div className={classNames(styles['buttons__container'], props.classes?.content)}>
              <Button
                variant="contained"
                color="default"
                onClick={() => {
                  formik.resetForm()
                  props.onReset()
                }}
                classes={{
                  root: classNames(styles['buttons__button'], styles['buttons__button--reset']),
                }}
              >
                Reset
              </Button>
              <Button
                disabled={props.loading}
                variant="contained"
                type="submit"
                color="primary"
                classes={{
                  root: classNames(styles['buttons__button'], styles['buttons__button--submit']),
                }}
              >
                Show results
              </Button>
            </div>
          </div>
        ) : (
          <div className={classNames(styles['form__buttons'], styles['form__buttons--primary'])}>
            <div className={classNames(styles['buttons__container'], props.classes?.content)}>
              <a className={classNames(styles['buttons__button'], styles['buttons__button--filters'])} onClick={handleShowAdvancedFilters}>
                Advanced search
              </a>
              <Button
                disabled={props.loading}
                variant="contained"
                type="submit"
                color="primary"
                classes={{
                  root: classNames(styles['buttons__button'], styles['buttons__button--primary'], styles['buttons__button--submit']),
                }}
              >
                Show results
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  )
})

Filters.defaultProps = defaultProps

export default Filters
