import React from 'react'
import styles from './Header.module.scss'
import HeaderLinks from './HeaderLinks'
import CookiesConsent from '../CookiesConsent'
import Link, { routes } from '../Link'
import Logo from '../Logo'
import Menu from '../Menu'
import Search from '../Search'

export type ModeType = 'normal' | 'simplified'

const defaultProps = Object.freeze({
  mode: 'normal' as ModeType,
})

type Props = typeof defaultProps & {
  onCookiesConsentShown: (height: number) => void
  onCookiesConsentHide: () => void
}

const Header = (props: Props) => {
  return (
    <div id="header" className={styles['container']}>
      <CookiesConsent onShown={props.onCookiesConsentShown} onHide={props.onCookiesConsentHide} />
      <header className={styles['header']}>
        <div className={styles['header__logo-box']}>
          <Link to={routes.home}>
            <Logo classes={styles['logo-box__logo']} />
          </Link>
        </div>
        <HeaderLinks classes={styles['header__links']} />
        <Menu classes={styles['header__menu']} />
        {props.mode === 'simplified' ? (
          <div className={styles['header__search__root']} />
        ) : (
          <Search
            classes={{
              root: styles['header__search__root'],
              form: styles['header__search__form'],
            }}
          />
        )}
      </header>
    </div>
  )
}

Header.defaultProps = defaultProps

export default Header
