import classNames from 'classnames'
import React, { useContext } from 'react'
import styles from './HeaderLinks.module.scss'
import UserContext from '../../../contexts/UserContext'
import Img from '../../Img'
import Link, { routes } from '../../Link'
import Trans from '../../Trans'

const defaultProps = Object.freeze({
  classes: '' as string,
})

type Props = typeof defaultProps

const HeaderLinks = (props: Props) => {
  const cssClass = classNames(props.classes, styles['links'])
  const user = useContext(UserContext)
  return (
    <div className={cssClass}>
      {/* <Link to={routes.buy} classes={styles['links__item']}>
        <Trans id="Buy" ns="HeaderLinks" msg="Buy" />
      </Link> */}
      <Link to={routes.sell} classes={styles['links__item']}>
        <Trans id="Sell" ns="HeaderLinks" msg="Sell" />
      </Link>
      <Link to={routes.faq} classes={styles['links__item']}>
        <Img src="/icons/menu__faq.svg" alt="Faq" classes={styles['item__img']} />
      </Link>
      {/* {user.data?.isAdmin ? (
        <Link to={routes.offerAdd} classes={styles['links__item']}>
          <Trans id="AddAnOffer" ns="HeaderLinks" msg="Add an Offer" />
        </Link>
      ) : null} */}
      {/* <Hidden xsDown>
        <Link to={routes.uiKit} classes={styles['links__item']}>
          <Img src="/icons/concierge.svg" alt="Concierge" classes={styles['item__img']} />
          Concierge
        </Link>
      </Hidden> */}
    </div>
  )
}

HeaderLinks.defaultProps = defaultProps

export default HeaderLinks
