import { MenuItem } from '@material-ui/core'
import { SelectProps } from '@material-ui/core/Select'
import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React from 'react'
import { OfferFieldsValuesType, OfferSelectFieldValueType } from '../../../../interfaces/Offer.type'
import FormSelectField from '../../../Form/FormSelectField'

type Classes = {
  root?: string
}

type Value = {
  id?: string
  name: string
  value: string
  [key: string]: any
}

type Props = {
  form: FormikProps<FormikValues | any>
  name: SelectNameType | 'age' | 'price'
  values: OfferSelectFieldValueType[] | Value[]
  label?: string
  classes?: Classes
  inputProps?: SelectProps
}

type SelectNameType = keyof OfferFieldsValuesType

const SelectField = (props: Props) => {
  if (!props.name || props.form.values[props.name] === undefined) {
    return null
  }

  return (
    <FormSelectField
      classes={{ root: props.classes?.root }}
      form={props.form}
      label={props.label!}
      field={{
        name: props.name,
        value: props.form.values[props.name],
        onChange: props.form.handleChange,
        onBlur: props.form.handleBlur,
      }}
      fullWidth
      InputProps={props.inputProps}
    >
      <MenuItem value="">Choose</MenuItem>
      {props.values.map((item: OfferSelectFieldValueType | Value) => (
        <MenuItem value={item.id || item.value} key={`${props.name}-${item.name}-${item.id || item.value}`}>
          {item.name}
        </MenuItem>
      ))}
    </FormSelectField>
  )
}

export default SelectField
