import { Button, CircularProgress, Divider, FormControl, Paper } from '@material-ui/core'
import classNames from 'classnames'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import styles from './SignInForm.module.scss'
import schema from './SignInForm.schema'
import useAuth from '../../hooks/auth'
import { SignInPayloadType } from '../../services/auth/auth.types'
import { AxiosError, AxiosResponse } from '../../utils/axios'
import handleError from '../../utils/handleError'
import FormTextField from '../Form/FormTextField'
import Link, { routes } from '../Link'
import notify from '../Notification/notify'
import SignSocial from '../SignSocial'
import Trans from '../Trans'

type Props = {}

const SignInForm = (props: Props) => {
  const { signIn } = useAuth()

  const onSubmit = async (values: SignInPayloadType, formikBag: FormikHelpers<SignInPayloadType>) => {
    await signIn(
      values,
      (response: AxiosResponse) => {
        //   formikBag.setSubmitting(false)
        notify.success('You have been successfully logged in')
      },
      (error: AxiosError) => {
        formikBag.setSubmitting(false)
        handleError(error, formikBag)
      },
    )
  }

  return (
    <Formik
      initialValues={
        {
          email: '',
          password: '',
        } as SignInPayloadType
      }
      validationSchema={schema}
      onSubmit={(values, formikBag) => {
        onSubmit(values, formikBag)
      }}
      render={formikBag => (
        <Paper elevation={0} classes={{ root: styles['paper'] }}>
          <Form className={styles['sign-in']}>
            <Field
              name="email"
              label={<Trans ns="SignInForm" id="SignInForm.Email" msg="E-mail" />}
              component={FormTextField}
              fullWidth
              classes={{
                root: styles['sign-in__email'],
              }}
            />
            <Field
              type="password"
              name="password"
              label={<Trans ns="SignInForm" id="SignInForm.Password" msg="Password" />}
              component={FormTextField}
              fullWidth
              classes={{
                root: styles['sign-in__password'],
              }}
            />
            <Link to={routes.forgotPassword} classes={classNames('primary', styles['sign-in__reset-pass'])}>
              <Trans ns="SignInForm" id="SignInForm.ForgotPasswordLink" msg="Forgot password?" />
            </Link>
            <FormControl fullWidth className={classNames(styles['sign-in__button'])}>
              <Button variant="contained" color="primary" onClick={formikBag.submitForm} disabled={formikBag.isSubmitting}>
                {formikBag.isSubmitting ? (
                  <CircularProgress size="1.6rem" />
                ) : (
                  <Trans ns="SignInForm" id="SignInForm.ButtonSignIn" msg="Sign In" />
                )}
              </Button>
            </FormControl>
          </Form>

          <FormControl fullWidth classes={{ root: styles['sign-in__socials'] }}>
            <Divider classes={{ root: styles['sign-in__divider'] }} />
            <SignSocial
              classes={{
                facebook: styles['sign-in__facebook'],
                google: styles['sign-in__google'],
                weChat: styles['sign-in__we-chat'],
              }}
            />
          </FormControl>
        </Paper>
      )}
    />
  )
}

export default SignInForm
