import { ActionTypes, types } from './catalog.types'
import { FiltersType } from '../../interfaces/Filters.type'
import OfferType, { OfferFieldsValuesType } from '../../interfaces/Offer.type'

export function clearCatalog(): ActionTypes {
  return {
    type: types.CLEAR_CATALOG,
  }
}

export function setItems(items: OfferType[]): ActionTypes {
  return {
    type: types.CATALOG_SET_ITEMS,
    items,
  }
}

export function setLoadMoreUrl(loadMoreUrl: string | null): ActionTypes {
  return {
    type: types.CATALOG_SET_LOAD_MORE_URL,
    loadMoreUrl,
  }
}

export function setItemsCounter(itemsCounter: number | null): ActionTypes {
  return {
    type: types.CATALOG_SET_ITEMS_COUNTER,
    itemsCounter,
  }
}

export function setFilters(filters: FiltersType | null): ActionTypes {
  return {
    type: types.CATALOG_SET_FILTERS,
    filters,
  }
}

export function setFieldsValues(fieldsValues: OfferFieldsValuesType | null): ActionTypes {
  return {
    type: types.CATALOG_SET_FIELDS_VALUES,
    fieldsValues,
  }
}
