import React from 'react'
import buildUrl from './buildUrl'

type Props = {
  src: string
  srcSet?: string
  alt?: string
  classes?: string
  style?: object
  base64?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Img = (props: Props) => {
  const { alt, classes, onClick, src, base64 } = props
  return (
    <img
      src={base64 ? src : buildUrl(src)}
      alt={alt}
      onClick={onClick}
      style={props.style}
      className={classes}
      srcSet={props.srcSet ? buildUrl(props.srcSet) : undefined}
    />
  )
}

export default Img
