import React from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import Chat from '../../components/Chat'
import { routes } from '../../components/Link'
import PageLayout from '../../components/PageLayout'
import useAuth from '../../hooks/auth'

type UrlParamsType = {
  id: string
}

const MessagesPage = () => {
  const params = useParams<UrlParamsType>()
  const conversationId = params.id || null
  const { isAuth } = useAuth()

  if (!isAuth) {
    return <Redirect to={routes.signIn} />
  }
  return (
    <PageLayout>
      <Chat conversationId={conversationId!} />
    </PageLayout>
  )
}

export default MessagesPage
