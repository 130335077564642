import { Typography } from '@material-ui/core'
import React from 'react'
import styles from '../RoRPage.module.scss'

const RoRPageEN = () => {
  const bodyClass = { root: styles['list__text'] }
  return (
    <>
      <Typography variant="h3" classes={{ root: styles['heading'] }}>
        Consumer’s right of withdrawal
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        (A consumer is any natural person who enters into a legal transaction for purposes that are predominantly neither commercial nor
        related to their independent professional activity).
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Cancellation policy
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Right of withdrawal
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        You have the right to cancel this contract within 14 days without a cause.
        <br />
        The revocation period shall be 14 days from the date of revocation,
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            where you or a third party designated by you, other than the carrier, have taken possession of the goods, provided that you have
            ordered one or more items of goods as part of a single order and that the goods are delivered as part of a single delivery;
          </li>
          <li className={styles['list__item']}>
            at which you or a third party designated by you, other than the carrier, have taken possession of the last goods, provided that
            you have ordered several items of goods as part of a single order and they are delivered separately;
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        In order to exercise your right of revocation, you must inform us
        <b>
          (JP Whiskymarket.com GmbH, Oehlertring 1, 12169 Berlin, telephone number: +49 (0) 152 15 25 30 5 77 55, e-mail address:
          <a href="mailto:info@whiskymarket.com" className="primary">
            info@whiskymarket.com
          </a>
          )
        </b>
        by way of an express statement (e.g. a letter, fax or e-mail sent by post) of your decision to revoke this contract. You may use the
        attached sample revocation form for this purpose, although this is not mandatory.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        In order to comply with the revocation period, it is sufficient that you send the notification of your exercise of the right to
        revoke the contract before the end of the revocation period.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Effects of revocation
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        If you revoke this Contract, we shall reimburse you for all payments we have received from you, including delivery costs (with the
        exception of additional costs resulting from the fact that you have chosen a different type of delivery than the cheapest standard
        delivery offered by us), immediately, and in any case, no later than 14 days from the date on which we receive your notification of
        withdrawal from the contract. For the purpose of this reimbursement, we shall use the same method of payment that you used for the
        original transaction, unless expressly agreed otherwise with you; in no event will you be charged for the reimbursement.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        We may refuse to make the aforementioned reimbursement until you have returned the goods to us or until you have provided us with
        proof that you have returned the goods, whichever is earlier.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        You must return or hand over the goods to us immediately and in any event no later than 14 days from the day you notify us of the
        cancellation of this contract. This period shall be deemed to have been observed if you send the goods before the expiry of the
        14-day period.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        You shall bear the direct costs of returning the goods.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        You will only have to pay for any loss of value of the goods if such loss is due to handling of the goods that is not necessary for
        testing their condition, properties and functionality.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Reasons for exclusion or extinction
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        The right of withdrawal shall not apply to any contracts
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            for the delivery of goods which are not prefabricated and for the production of which an individual selection or determination
            by the consumer is decisive or which are clearly tailored to the personal needs of the consumer;
          </li>
          <li className={styles['list__item']}>
            for the delivery of easily perishable goods or whose expiration date would be quickly exceeded;
          </li>
          <li className={styles['list__item']}>
            for the supply of alcoholic beverages whose price was agreed upon at the conclusion of the contract but which can be supplied at
            the earliest 30 days after the conclusion of the contract and whose current value depends on fluctuations in the market beyond
            the control of the trader;
          </li>
          <li className={styles['list__item']}>
            for the supply of newspapers, periodicals or magazines, with the exception of subscription contracts.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        The right of withdrawal shall expire prematurely for any contracts
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            for the delivery of sealed goods that are not suitable for return for reasons of health protection or hygiene, if their seal has
            been removed after delivery;
          </li>
          <li className={styles['list__item']}>
            for the delivery of goods if, after delivery, they have been inseparably mixed with other goods due to their nature;
          </li>
          <li className={styles['list__item']}>
            for the delivery of sound or video recordings or computer software in a sealed package if the seal has been removed after
            delivery.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Sample revocation form
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        (If you want to cancel the contract, please fill out this form and return it to us ).
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            To JP Whiskymarket.com GmbH, Oehlertring 1, 12169 Berlin, Germany, Phone: +49 (0) 152 5305 77 55, E-Mail:
            <a href="mailto:info@whiskymarket.com" className="primary">
              info@whiskymarket.com
            </a>
            ;
          </li>
          <li className={styles['list__item']}>
            I/we (*) hereby revoke the contract concluded by me/us (*) for the purchase of the following goods (*)/the provision of the
            following services (*)
          </li>
          <li className={styles['list__item']}>Ordered on (*)/ received on (*)</li>
          <li className={styles['list__item']}>Name of the consumer(s)</li>
          <li className={styles['list__item']}>Address of the consumer(s)</li>
          <li className={styles['list__item']}>Signature of the consumer(s) (only in case of hard copy (paper) communication)</li>
          <li className={styles['list__item']}>Date</li>
        </ul>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        (*) Delete as applicable.
      </Typography>
    </>
  )
}

RoRPageEN.getInitialProps = function() {
  return {}
}

export default RoRPageEN
