import _ from 'lodash'
import {
  ActionTypes,
  SetFieldValuesInterface,
  SetFiltersInterface,
  SetItemsCounterInterface,
  SetItemsInterface,
  SetLoadMoreUrlInterface,
  State,
  types
  } from './catalog.types'

const initialState: Readonly<State> = {
  items: [],
  loadMoreUrl: null,
  itemsCounter: null,
  filters: null,
  fieldsValues: null,
}

const catalogReducer = (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case types.CLEAR_CATALOG:
      return reduceClearCatalog()

    case types.CATALOG_SET_ITEMS:
      return reduceSetItems(state, action)

    case types.CATALOG_SET_LOAD_MORE_URL:
      return reduceSetLoadMoreUrl(state, action)

    case types.CATALOG_SET_ITEMS_COUNTER:
      return reduceSetItemsCounter(state, action)

    case types.CATALOG_SET_FILTERS:
      return reduceSetFilters(state, action)

    case types.CATALOG_SET_FIELDS_VALUES:
      return reduceSetFieldValues(state, action)
  }

  return state
}

const reduceClearCatalog = (): State => initialState

const reduceSetItems = (state: State, action: SetItemsInterface): State => {
  const newState = _.cloneDeep(state)
  newState.items = action.items
  return newState
}

const reduceSetLoadMoreUrl = (state: State, action: SetLoadMoreUrlInterface): State => {
  const newState = _.cloneDeep(state)
  newState.loadMoreUrl = action.loadMoreUrl
  return newState
}

const reduceSetItemsCounter = (state: State, action: SetItemsCounterInterface): State => {
  const newState = _.cloneDeep(state)
  newState.itemsCounter = action.itemsCounter
  return newState
}

const reduceSetFilters = (state: State, action: SetFiltersInterface): State => {
  const newState = _.cloneDeep(state)
  newState.filters = action.filters
  return newState
}

const reduceSetFieldValues = (state: State, action: SetFieldValuesInterface): State => {
  const newState = _.cloneDeep(state)
  newState.fieldsValues = action.fieldsValues
  return newState
}

export default catalogReducer
