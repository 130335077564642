import { Typography } from '@material-ui/core'
import React from 'react'
import styles from '../AboutUsPage.module.scss'

const AboutUsPageEN = () => {
  const bodyClass = { root: styles['list__text'] }
  return (
    <>
      <Typography variant="h3" classes={{ root: styles['heading'] }}>
        Imprint
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Legal provider identification:
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        JP Whiskymarket.com GmbH
        <br />
        Oehlertring 1<br />
        12169 Berlin
        <br />
        Germany
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Represented by the managing directors: Jakub Baginski and Piotr Kossowski
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Phone: +49 (0) 152 5305 77 55
        <br />
        E-Mail: info@whiskymarket.com
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Entered in the commercial register of the Berlin (Charlottenburg) district court
        <br />
        Commercial register number HRB198729 B
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Alternative dispute resolution:
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        The European Commission provides a platform for the extrajudicial online settlement of disputes (the OS platform), available at{' '}
        <a href="https://ec.europa.eu/odr" rel="noreferrer" target="_blank" className="primary">
          https://ec.europa.eu/odr
        </a>
        .
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        We reserve the right not to participate in dispute resolution procedures before consumer arbitration boards.
      </Typography>
    </>
  )
}

AboutUsPageEN.getInitialProps = function() {
  return {}
}

export default AboutUsPageEN
