import _ from 'lodash'
import { ActionTypes, State, types } from './app.types'

const initialState: Readonly<State> = {
  language: {
    isoCode: 'en',
    fullIsoCode: ' en-US',
    name: 'English',
    flag: 'us',
  },
  acceptedAgeAgreement: false,
  acceptedCookies: true,
}

const reduceAgeAgreementAccept = (state: State): State => {
  const newState = _.cloneDeep(state)
  newState.acceptedAgeAgreement = true
  return newState
}

const reduceCookiesAccept = (state: State): State => {
  const newState = _.cloneDeep(state)
  newState.acceptedCookies = true
  return newState
}

const reduceCookiesRefuse = (state: State): State => {
  const newState = _.cloneDeep(state)
  newState.acceptedCookies = false
  return newState
}

const appReducer = (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case types.AGE_AGREEMENT_ACCEPT:
      return reduceAgeAgreementAccept(state)

    case types.COOKIES_ACCEPT:
      return reduceCookiesAccept(state)

    case types.COOKIES_REFUSE:
      return reduceCookiesRefuse(state)
  }

  return state
}

export default appReducer
