import { Button, Typography } from '@material-ui/core'
import { UnregisterCallback } from 'history'
import React from 'react'
import { useHistory } from 'react-router'
import styles from './RouterPrompt.module.scss'
import Dialog, { DISPLAY_MODE } from '../Dialog'

const defaultProps = Object.freeze({
  title: 'There are unsaved changes. Are you sure that you want to leave this page without saving?' as React.ReactNode | string,
})

type Props = typeof defaultProps & {
  when: boolean
  onClose?: (event: React.MouseEvent<any, MouseEvent>, reason: 'backdropClick' | 'cancelClick') => void
  onConfirmClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const RouterPrompt = (props: Props) => {
  const history = useHistory()
  const [showPrompt, setShowPrompt] = React.useState(false)
  const [currentPath, setCurrentPath] = React.useState('')

  React.useEffect(() => {
    let unblock: UnregisterCallback | null = null

    if (props.when) {
      unblock = history.block(location => {
        setCurrentPath(location.pathname)
        setShowPrompt(true)
        return false
      })
    } else {
      history.block(() => {})
    }

    return () => {
      unblock?.()
    }
  }, [history, props.when])

  const handleClose = (event: React.MouseEvent<HTMLDivElement | SVGSVGElement, MouseEvent>) => {
    setShowPrompt(false)
    props.onClose?.(event, 'backdropClick')
  }

  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowPrompt(false)
    props.onClose?.(event, 'cancelClick')
  }

  const handleConfirmClick = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      await Promise.resolve(props.onConfirmClick?.(event))
      setShowPrompt(false)
      history.block(() => {})
      history.push(currentPath)
    },
    [currentPath, history, props.onConfirmClick],
  )

  return showPrompt ? (
    <Dialog
      open={props.when && showPrompt}
      withLogo={false}
      onClose={handleClose}
      displayMode={DISPLAY_MODE.fullScreenMobileOnly}
      classes={{ root: styles['dialog'] }}
    >
      <div className={styles['dialog__content']}>
        <Typography variant="subtitle1">{props.title}</Typography>
        <div className={styles['dialog__navigation']}>
          <Button variant="contained" color="default" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirmClick}>
            Confirm
          </Button>
        </div>
      </div>
    </Dialog>
  ) : null
}

RouterPrompt.defaultProps = defaultProps

export default RouterPrompt
