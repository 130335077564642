import MomentUtils from '@date-io/moment'
import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import { useSelector } from 'react-redux'
import usePrerender from '../../hooks/prerender'
import ChildrenType from '../../interfaces/Children.type'
import { StateType } from '../../services/reducers'
import { moment } from '../../utils/date'
import AgeAgreement from '../AgeAgreement'
import AgeProvider from '../AgeProvider/AgeProvider'
import NotificationContainer from '../Notification/NotificationContainer'
import '../WebApp/styles/reset.module.scss'
import '../WebApp/styles/styles.module.scss'
import theme, { createGenerateId } from '../WebApp/styles/theme'

type Props = {
  children?: ChildrenType
}

const WebLayout = (props: Props) => {
  const notifications = useSelector((state: StateType) => state.notification.messages)
  const language = useSelector((state: StateType) => state.app.language)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StylesProvider generateClassName={createGenerateId()} injectFirst>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={language.isoCode} libInstance={moment}>
          <AgeProvider>
            <React.Fragment key="main">
              {props.children}
              <NotificationContainer direction="top" autoHideDuration={3000} messages={notifications} />
              {usePrerender().isPrerender ? null : <AgeAgreement />}
            </React.Fragment>
          </AgeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  )
}

export default WebLayout
