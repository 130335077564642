import {
  Button,
  Divider,
  FormControl,
  InputAdornment,
  Typography
  } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps
  } from 'formik'
import _ from 'lodash'
import React, { BaseSyntheticEvent, useEffect } from 'react'
import { ObjectSchema } from 'yup'
import styles from './OfferForm.module.scss'
import { BottleAddType } from '../../interfaces/Bottle.type'
import { UserType } from '../../interfaces/User.type'
import * as api from '../../services/offer/offer.api'
import countriesi18n from '../../utils/countries'
import Dialog, { DISPLAY_MODE } from '../Dialog'
import FormCheckboxField from '../Form/FormCheckboxField'
import FormFieldPhoneCode from '../Form/FormFieldPhoneCode'
import FormSwitchField from '../Form/FormSwitchField'
import FormTextField from '../Form/FormTextField'
import ImageUpload from '../ImageUpload'
import Img from '../Img'
import notify from '../Notification/notify'
import { ACTION_STEP } from '../OfferListItem'
import RouterPrompt from '../RouterPrompt'
import Trans from '../Trans'
import OfferType, {
  ImageUploadListType,
  OfferFieldsValuesType,
  OfferFormType,
  OfferExpiresInType,
  VISIBILITY_PUBLIC,
  VISIBILITY_PRIVATE,
  EXPIRES_IN_VALUES,
} from '../../interfaces/Offer.type'
import {
  renderAutocompleteField,
  renderAutocompleteSelectField,
  renderCurrencyField,
  renderDateField,
  renderSelectField,
  renderTextField,
} from './OfferFields'
import {
  FormModeType,
  OfferFormAutocompletePayloadType,
  FORM_MODE_BASE,
  FORM_MODE_BASE_FILLED,
  FORM_MODE_SELF,
  FIELD_AUTO_BASE_SEARCH,
} from './OfferForm.types'

const AVAILABLITY_NOW = 'now'

const defaultProps = Object.freeze({
  isStandalone: false as boolean,
  currencySign: '€' as string,
  language: 'en' as string,
})

export type Props = typeof defaultProps & {
  offerAdded: boolean
  setFormMode: React.Dispatch<React.SetStateAction<FormModeType>>
  autoFields: any
  formMode: FormModeType
  selectedBaseItem: BottleAddType
  maxImageSize: number
  minImageSize: number
  onImageUpload: (file: File, base64: string, imageKey: string, main?: boolean) => void
  images: ImageUploadListType
  onFormSubmit: (values: OfferFormType, formiKBag: FormikProps<OfferFormType>) => void
  onImageSizeError: (tooBig: boolean, tooSmall: boolean) => void
  onImageTypeError: (fileType: string) => void
  onImageError: (file: File | null, reason: string) => void
  onImageDelete: (key: string, id: string | null) => void
  updateGross: (netto: string, gstRate: string, formikBag: FormikProps<OfferFormType>) => void
  gstRate: string | number
  withGst?: boolean
  maxPrice: number
  fieldsValues: OfferFieldsValuesType
  setLiterPrice: (price: string, size: string | number, formikBag: FormikProps<OfferFormType>) => void
  setTitle: (formikBag: FormikProps<any>, key?: string, newValue?: string | number) => void
  onDialogClose?: () => void
  user: UserType
  onBack?: () => void
  onAddAnother: (formiKBag: FormikHelpers<OfferFormType>) => void
  useCounter: number
  offer?: OfferType
  heading: string | React.ReactNode
  cta: string | React.ReactNode
  schema: ObjectSchema<any>
}

const OfferForm = (props: Props) => {
  const {
    useCounter,
    isStandalone,
    formMode,
    setFormMode,
    autoFields,
    selectedBaseItem,
    onFormSubmit,
    updateGross,
    fieldsValues,
    setLiterPrice,
    setTitle,
    onDialogClose,
    user,
    offerAdded,
    onBack,
    onAddAnother,
  } = props
  const imageProps = {
    onSizeError: props.onImageSizeError,
    maxSize: props.maxImageSize,
    minSize: props.minImageSize,
    onImageUpload: props.onImageUpload,
    onTypeError: props.onImageTypeError,
    onError: props.onImageError,
    disabled: offerAdded,
  }

  const [canEditExpirationDate, setCanEditExpirationDate] = React.useState(!props.offer)
  const [isBlocking, setIsBlocking] = React.useState(false)
  const [archiveStep, setArchiveStep] = React.useState<ACTION_STEP>(ACTION_STEP.NONE)
  const [expiresAt, setExpiresAt] = React.useState(props.offer?.expiresAt || '')
  const bigFormikRef = React.useRef<FormikProps<OfferFormType>>() //ugly but works

  useEffect(() => {
    bigFormikRef.current?.resetForm()
  }, [props.selectedBaseItem.title])

  const onArchive = async () => {
    if (archiveStep === ACTION_STEP.NONE || archiveStep === ACTION_STEP.DONE) {
      setArchiveStep(ACTION_STEP.TO_DO)
    }
  }

  const onArchiveConfirmed = async () => {
    try {
      setArchiveStep(ACTION_STEP.IN_PROGRESS)
      const response = await api.archiveOffer(props.offer?.id || '')

      if (response.data?.expiresAt) {
        setExpiresAt(response.data.expiresAt)
      }

      notify.success('You offer has been archived.')
      setArchiveStep(ACTION_STEP.DONE)
    } catch (error) {
      console.error(error)
    }
  }

  const onArchiveCanceled = () => {
    setArchiveStep(ACTION_STEP.NONE)
  }

  const renderModeBaseHeading = () => {
    return (
      <>
        <FormControl fullWidth classes={{ root: styles['offer__grid--half'] }}>
          <Button
            variant="contained"
            color={formMode === FORM_MODE_BASE || formMode === FORM_MODE_BASE_FILLED ? 'primary' : 'secondary'}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setFormMode(FORM_MODE_BASE)
            }}
          >
            <Trans ns="OfferForm" id="SelectFromTheBaseButton" msg="Select from the base" />
          </Button>
        </FormControl>
        <Typography
          color={formMode !== FORM_MODE_SELF ? 'secondary' : 'primary'}
          variant="caption"
          classes={{ root: classNames(styles['offer__base-label'], styles['offer__grid--half']) }}
        >
          <Trans ns="OfferForm" id="SelectFromTheBaseHelperText" msg="Fastest way to add offer" />
        </Typography>
      </>
    )
  }

  const renderModeSelfHeading = () => {
    return (
      <>
        <FormControl fullWidth classes={{ root: styles['offer__grid--half'] }}>
          <Button
            variant="contained"
            color={formMode === FORM_MODE_SELF ? 'primary' : 'secondary'}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setFormMode(FORM_MODE_SELF)
            }}
          >
            <Trans ns="OfferForm" id="FillOfferByYourself" msg="Fill offer by yourself" />
          </Button>
        </FormControl>
        <Typography
          color={formMode === FORM_MODE_SELF ? 'secondary' : 'primary'}
          variant="caption"
          classes={{ root: classNames(styles['offer__base-label'], styles['offer__grid--half']) }}
        >
          <Trans ns="OfferForm" id="FillOfferByYourselfHelperText" msg="I want to do it by myself" />
        </Typography>
      </>
    )
  }

  const renderModeBaseForm = () => {
    if (formMode === FORM_MODE_SELF) {
      return null
    }
    return (
      <Formik
        initialValues={
          {
            query: '',
            type: 'whisky',
          } as OfferFormAutocompletePayloadType
        }
        onSubmit={(values: OfferFormAutocompletePayloadType, formikBag: FormikHelpers<OfferFormAutocompletePayloadType>) => { }}
      >
        {(formikBag: FormikProps<OfferFormAutocompletePayloadType>) => {
          return (
            <Form className={styles['offer__grid--full']} id="add-offer-form">
              <Field key={FIELD_AUTO_BASE_SEARCH} name={FIELD_AUTO_BASE_SEARCH} fullWidth>
                {(_props: FieldProps<string, OfferFormAutocompletePayloadType>) => {
                  return renderAutocompleteField<BottleAddType, OfferFormAutocompletePayloadType>(
                    <Trans ns="OfferForm" id="BaseSearchLabel" msg="Item" />,
                    autoFields.FIELD_AUTO_BASE_SEARCH,
                    _props,
                    formikBag,
                    {
                      inputProps: {
                        ..._props.field,
                        onChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          autoFields.FIELD_AUTO_BASE_SEARCH.onSelected(event.currentTarget?.value, formikBag)
                        },
                      },
                      formControlProps: {
                        fullWidth: true,
                      },
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      },
                    },
                  )
                }}
              </Field>
            </Form>
          )
        }}
      </Formik>
    )
  }

  const handleImageDelete = (imageKey: string, oldId: string | null) => {
    setIsBlocking(true)
    props.onImageDelete(imageKey, oldId)
  }

  const handleImageUpload = (file: File, base64: string, imageKey: string, main?: boolean) => {
    setIsBlocking(true)
    props.onImageUpload(file, base64, imageKey, main)
  }

  const renderForm = () => {
    if (formMode === FORM_MODE_BASE) {
      return null
    }

    const gst = props.offer ? props.offer.gst : props.withGst
    const age = parseInt(`${props.offer?.age}`, 10)
    const availabilityNowId = fieldsValues.availability.values.find(item => item.slug === AVAILABLITY_NOW)?.id

    return (
      <Formik
        initialValues={_.merge(
          {
            title: props.offer?.title || '',
            subtitle: props.offer?.subtitle || '',
            bottleType: props.offer
              ? props.fieldsValues.bottleType.values.find(item => item.name === props.offer?.bottle.type)?.id || ''
              : '',
            category: props.offer?.bottle.category.id || '',
            distillery: props.offer?.bottle.distillery.name || '',
            bottler: props.offer?.bottle.bottler.name || '',
            distilled: `${props.offer?.bottle.distilled || ''}`,
            bottled: `${props.offer?.bottle.bottled || ''}`,
            age: Number.isNaN(age) ? '' : age,
            abv: props.offer?.bottle.abv || '',
            size: props.offer?.bottle.size || '700',
            caskNumber: props.offer?.bottle.caskNumber || '',
            caskType: props.offer?.bottle?.caskType?.id || '',
            outturn: props.offer?.bottle.outturn || '',
            condition: props.offer?.condition.id || '',
            fillLevel: props.offer?.fillLevel.id || '',
            availability: props.offer?.availability
              ? props.offer?.availability?.id || availabilityNowId
              : availabilityNowId,
            package: props.offer?.package.id || '',
            quantity: `${props.offer ? props.offer.quantity || '' : 1}`,
            bottleLocation: props.offer?.bottleLocation ? countriesi18n.getName(props.offer?.bottleLocation, props.language) : '',
            price: props.offer?.priceNet || '',
            priceGross: props.offer?.priceGross || '',
            gst, //TODO from user profile
            gstRate: gst ? (props.offer?.gstRate ? new BigNumber(props.offer.gstRate).multipliedBy(100).toString() : props.gstRate) : 0,
            negotiable: props.offer?.negotiable || false,
            swap: props.offer?.swap || false,
            pricePerLiter: props.offer?.pricePerLiterGross || '',
            description: props.offer?.description || '',
            phoneCode: props.offer?.phoneCode || user.data?.phoneCode || '',
            phone: props.offer?.phone || user.data?.phone || '',
            email: props.offer?.user.email || user.data?.email || '',
            buyNowAvailable: props.offer?.buyNowAvailable || false,
            buyNowLink: props.offer?.buyNowLink || '',
            images: ([] as unknown) as ImageUploadListType,
            imagesToDelete: ([] as unknown) as string[],
            expiresIn: '' as OfferExpiresInType,
            visibility: props.offer && !props.offer.isPublic ? VISIBILITY_PRIVATE : VISIBILITY_PUBLIC,
            hidden: props.offer && !props.offer.isPublic,
          } as OfferFormType,
          selectedBaseItem,
          { title: props.offer?.title || '' },
        )}
        validationSchema={props.schema}
        onSubmit={(values: OfferFormType, formikBag: FormikHelpers<OfferFormType>) => {
          setIsBlocking(false)
          onFormSubmit(values, formikBag as FormikProps<OfferFormType>)
        }}
      >
        {(formikBag: FormikProps<OfferFormType>) => {
          bigFormikRef.current = formikBag

          if (!isBlocking && formikBag.dirty) {
            setIsBlocking(true)
          }

          return (
            <Form className={classNames(styles['offer__grid--full'], styles['offer__form'])}>
              <RouterPrompt when={isBlocking && !offerAdded} />
              <Typography variant="h5" classes={{ root: styles['offer__basic-info'] }}>
                Basic Informations
              </Typography>
              {renderTextField(
                'title',
                'text',
                <Trans ns="OfferForm" id="Title" msg="Title" />,
                {
                  helperText: (
                    <Trans
                      ns="OfferForm"
                      id="TitleHelp"
                      msg="The title of your offer is completed automatically based on the data from the form below."
                    />
                  ),
                  InputProps: {
                    disabled: true,
                  },
                },
                { root: styles['form__grid--full'] },
              )}
              <Divider classes={{ root: classNames(styles['offer__divider'], styles['form__grid--full']) }} />
              {renderTextField(
                'subtitle',
                'text',
                <Trans ns="OfferForm" id="Subtitle" msg="Subtitle" />,
                {
                  helperText: <Trans ns="OfferForm" id="SubtitleHelp" msg="Max 35 signs. Field not required." />,
                  InputProps: { disabled: offerAdded },
                },
                { root: styles['form__grid--full'] },
              )}
              {renderSelectField(
                'bottleType',
                <Trans ns="OfferForm" id="BottleType" msg="Type*" />,
                { root: styles['form__grid--half'] },
                fieldsValues.bottleType?.values || [],
                { InputProps: { disabled: offerAdded } },
              )}
              {renderSelectField(
                'category',
                <Trans ns="OfferForm" id="Category" msg="Category*" />,
                { root: styles['form__grid--half'] },
                fieldsValues.category?.values || [],
                { InputProps: { disabled: offerAdded } },
              )}
              <Field name="distillery" fullWidth>
                {(_props: FieldProps<string, OfferFormType>) => {
                  return renderAutocompleteSelectField<string, OfferFormType>(
                    <Trans ns="OfferForm" id="Distillery" msg="Distillery/Brand*" />,
                    autoFields.FIELD_AUTO_DISTILLERY,
                    _props,
                    formikBag,
                    {
                      inputProps: {
                        ..._props.field,
                      },
                      formControlProps: {
                        fullWidth: true,
                      },
                      InputProps: {
                        disabled: offerAdded,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      },
                      autocompleteClasses: { container: styles['form__grid--half'] },
                      onChange: (value: string) => {
                        formikBag.setFieldValue('distillery', value)
                      },
                    },
                  )
                }}
              </Field>
              <Field name="bottler" fullWidth>
                {(_props: FieldProps<string, OfferFormType>) => {
                  return renderAutocompleteField<string, OfferFormType>(
                    <Trans ns="OfferForm" id="Bottler" msg="Bottler" />,
                    autoFields.FIELD_AUTO_BOTTLER,
                    _props,
                    formikBag,
                    {
                      inputProps: {
                        ..._props.field,
                      },
                      formControlProps: {
                        fullWidth: true,
                      },
                      InputProps: {
                        disabled: offerAdded,
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      },
                      autocompleteClasses: { container: styles['form__grid--half'] },
                      onChange: (value: string) => {
                        formikBag.setFieldValue('bottler', value)
                      },
                    },
                  )
                }}
              </Field>
              {renderDateField('distilled', <Trans ns="OfferForm" id="Distilled" msg="Distilled" />, {
                pickerProps: {
                  disabled: offerAdded,
                  autoOk: true,
                  openTo: 'year',
                  views: ['year'],
                  placeholder: 'e.g. 1980',
                  minDate: Date.parse('Jan 1, 1800'),
                  maxDate: new Date(),
                },
                dateFormat: 'YYYY',
                onChange: (value: string) => {
                  setTitle(formikBag, 'distilled', value)
                },
              })}
              {renderDateField('bottled', <Trans ns="OfferForm" id="Bottled" msg="Bottled" />, {
                pickerProps: {
                  disabled: offerAdded,
                  autoOk: true,
                  openTo: 'year',
                  views: ['year'],
                  placeholder: 'e.g. 1980',
                  minDate: Date.parse('Jan 1, 1800'),
                  maxDate: new Date(),
                },
                dateFormat: 'YYYY',
                onChange: (value: string) => {
                  setTitle(formikBag, 'bottled', value)
                },
              })}
              {renderTextField('age', 'number', <Trans ns="OfferForm" id="Age" msg="Age(YO)" />, {
                InputProps: {
                  disabled: offerAdded,
                  onWheel: (e: BaseSyntheticEvent) => {
                    e.target.blur()
                  },
                },
                onChange: (value: string) => {
                  setTitle(formikBag, 'age', value)
                },
              })}
              {renderTextField('abv', 'number', <Trans ns="OfferForm" id="Age" msg="Strength" />, {
                InputProps: {
                  startAdornment: <InputAdornment position="start">%</InputAdornment>,
                  disabled: offerAdded,
                  onWheel: (e: BaseSyntheticEvent) => {
                    e.target.blur()
                  },
                },
                onChange: (value: string) => {
                  setTitle(formikBag, 'abv', value)
                },
              })}
              {renderTextField('size', 'number', <Trans ns="OfferForm" id="Size" msg="Size(ml)*" />, {
                InputProps: {
                  disabled: offerAdded,
                  onWheel: (e: BaseSyntheticEvent) => {
                    e.target.blur()
                  },
                },
                onChange: (value: string) => {
                  setLiterPrice(formikBag.values.priceGross || formikBag.values.price, value, formikBag)
                },
              })}
              {renderTextField('caskNumber', 'string', <Trans ns="OfferForm" id="CaskNumber" msg="Cask No" />, {
                InputProps: { disabled: offerAdded },
                onChange: (value: string) => {
                  setTitle(formikBag, 'caskType', value)
                },
              })}
              {renderSelectField(
                'caskType',
                <Trans ns="OfferForm" id="CaskType" msg="Cask Type" />,
                { root: styles['form__cask-type'] },
                fieldsValues.caskType?.values || [],
                { InputProps: { disabled: offerAdded } },
              )}
              {renderTextField('outturn', 'number', <Trans ns="OfferForm" id="Outturn" msg="Outturn (number of bottles produced)" />, {
                InputProps: {
                  disabled: offerAdded,
                  onWheel: (e: BaseSyntheticEvent) => {
                    e.target.blur()
                  },
                },
              })}
              {/* {renderSelectField(
                'condition',
                <Trans ns="OfferForm" id="StatusOfBottle" msg="Status of the bottle and box" />,
                { root: styles['form__grid--half'] },
                fieldsValues.condition?.values || [],
                { InputProps: { disabled: offerAdded } },
              )}
              {renderSelectField(
                'fillLevel',
                <Trans ns="OfferForm" id="FillLevel" msg="Fill level" />,
                { root: styles['form__grid--half'] },
                fieldsValues.fillLevel?.values || [],
                { InputProps: { disabled: offerAdded } },
              )}
              {renderSelectField(
                'availability',
                <Trans ns="OfferForm" id="Availability" msg="Availability" />,
                { root: styles['form__grid--half'] },
                fieldsValues.availability?.values || [],
                { InputProps: { disabled: offerAdded } },
              )}
              {renderSelectField(
                'package',
                <Trans ns="OfferForm" id="Package" msg="Package" />,
                { root: styles['form__grid--half'] },
                fieldsValues.package?.values || [],
                { InputProps: { disabled: offerAdded } },
              )}
              {renderTextField('quantity', 'number', <Trans ns="OfferForm" id="Quantity" msg="Quantity" />, {
                InputProps: {
                  disabled: offerAdded,
                  onWheel: (e: BaseSyntheticEvent) => {
                    e.target.blur()
                  },
                },
                inputProps: { min: '0' },
              })} */}
              <Field name="bottleLocation" fullWidth>
                {(_props: FieldProps<string, OfferFormType>) => {
                  return renderAutocompleteField<string, OfferFormType>(
                    <Trans ns="OfferForm" id="BottleLocation" msg="Bottle location*" />,
                    autoFields.FIELD_AUTO_LOCATION,
                    _props,
                    formikBag,
                    {
                      onChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        setTitle(formikBag)
                      },
                      inputProps: {
                        ..._props.field,
                      },
                      formControlProps: {
                        fullWidth: true,
                      },
                      InputProps: {
                        disabled: offerAdded,
                        placeholder: 'Country',
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      },
                      autocompleteClasses: { container: styles['form__grid--half'] },
                    },
                  )
                }}
              </Field>
              <Field
                type="checkbox"
                name="gst"
                label={<Trans ns="OfferForm" id="WithGST" msg="VAT/GST Invoice" />}
                component={FormSwitchField}
                classes={{ root: styles['form__grid--full'] }}
                InputProps={{ disabled: offerAdded }}
                onChange={(value: string, checked: boolean) => {
                  if (!checked) {
                    formikBag.setFieldValue('gstRate', '0.00')
                  } else {
                    formikBag.setFieldValue('gstRate', props.gstRate)
                  }
                  formikBag.setFieldValue('priceGross', '0.00')
                  formikBag.setFieldValue('price', '0.00')
                  formikBag.setFieldValue('pricePerLiter', '0.00')

                  //formikBag
                }}
              />
              {formikBag.values.gst ? (
                <>
                  {renderCurrencyField(props, 'price', <Trans ns="OfferForm" id="Price" msg="Price Net*" />, {
                    disabled: offerAdded,
                    onChange: (value: string) => {
                      updateGross(value, formikBag.values.gstRate as string, formikBag)
                    },
                  })}

                  {renderCurrencyField(props, 'priceGross', <Trans ns="OfferForm" id="PriceGross" msg="Price Gross*" />, {
                    disabled: true,
                  })}

                  {renderTextField(
                    'gstRate',
                    'number',
                    <Trans ns="OfferForm" id="GstRate" msg="VAT*" />,
                    {
                      inputProps: {
                        min: '0',
                      },
                      InputProps: {
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        disabled: offerAdded,
                      },
                      onChange: (value: string) => {
                        updateGross(formikBag.values.price, value, formikBag)
                      },
                    },
                    { root: styles['form__gst'] },
                  )}
                </>
              ) : (
                renderCurrencyField(props, 'price', <Trans ns="OfferForm" id="Price" msg="Price" />, {
                  InputProps: { disabled: offerAdded },
                  onChange: (value: string) => {
                    updateGross(value, '0', formikBag)
                  },
                })
              )}
              <div className={styles['form__grid--full']}>
                <Field
                  type="checkbox"
                  name="negotiable"
                  label={<Trans ns="OfferForm" id="Negotiable" msg="Marketplace offer" />}
                  component={FormCheckboxField}
                  InputProps={{ disabled: offerAdded }}
                />
                <Field
                  type="checkbox"
                  name="swap"
                  label={<Trans ns="OfferForm" id="Swap" msg="Whiskymarket.com offer" />}
                  component={FormCheckboxField}
                  InputProps={{ disabled: offerAdded }}
                />
              </div>
              {renderCurrencyField(props, 'pricePerLiter', <Trans ns="OfferForm" id="PricePerLiter" msg="Price per liter" />, {
                disabled: true,
              })}
              <div className={classNames(styles['form__grid--full'], styles['form__buy-now__row'])}>
                <div className={classNames(styles['form__buy-now__column'], styles['form__buy-now__column--first'])}>
                  <Field
                    type="checkbox"
                    name="buyNowAvailable"
                    label={<Trans ns="OfferForm" id="BuyNowAvailable" msg="Available to Buy Now" />}
                    component={FormCheckboxField}
                    InputProps={{ disabled: offerAdded }}
                  />
                </div>
                <div className={styles['form__buy-now__column']}>
                  {renderTextField('buyNowLink', 'buyNowLink', <Trans ns="OfferForm" id="BuyNow" msg="Buy Now" />, {
                    InputProps: { disabled: offerAdded, placeholder: 'Paste link' },
                  })}
                </div>
              </div>
              <Typography variant="h5" classes={{ root: classNames(styles['images__label'], styles['form__grid--full']) }}>
                <Trans ns="OfferForm" id="AddPictures" msg="Add pictures" />
              </Typography>
              <div className={classNames(styles['images-section'], styles['form__grid--full'])}>
                <div className={styles['images-section__image-box']}>
                  <Img src="/icons/bottle_avatar.svg" classes={styles['image-box__image']} />
                </div>
                <div className={styles['images__help']}>
                  <Trans
                    ns="OfferForm"
                    id="ImagesHelp"
                    msg="We strongly recommend you put at least three pictures of your bottle, including front label, back label and the seal/cork. If the bottle comes with a box – please make sure the buyer can take a look at it as well. Remember, the better the pictures are, the most likely you sell your bottle."
                  />
                </div>
              </div>
              <div className={classNames(styles['images-upload'], styles['form__grid--full'])}>
                <Typography variant="body1" classes={{ root: styles['upload__main-label'] }}>
                  <Trans ns="OfferForm" id="UploadMainImage" msg="Main image" />
                </Typography>
                <div className={styles['upload__container']}>
                  <ImageUpload
                    key={`photo1-${useCounter}`}
                    classes={classNames(styles['upload__photobox'], styles['upload__photobox--main'])}
                    primary
                    {...imageProps}
                    onChange={(file: File, base64: string, imageKey: string, oldId: string | null) => {
                      if (oldId) {
                        handleImageDelete(imageKey, oldId)
                      }
                      handleImageUpload(file, base64, imageKey, true)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                      handleImageDelete(imageKey, oldId)
                    }}
                    image={props.images?.photo1?.data || null}
                    imageId={props.images?.photo1?.id || null}
                    imageKey="photo1"
                  />
                  <ImageUpload
                    key={`photo2-${useCounter}`}
                    classes={styles['upload__photobox']}
                    {...imageProps}
                    onChange={(file: File, base64: string, imageKey: string, oldId: string | null) => {
                      if (oldId) {
                        handleImageDelete(imageKey, oldId)
                      }
                      handleImageUpload(file, base64, imageKey, false)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                      handleImageDelete(imageKey, oldId)
                    }}
                    image={props.images?.photo2?.data || null}
                    imageId={props.images?.photo2?.id || null}
                    imageKey="photo2"
                  />
                  <ImageUpload
                    key={`photo3-${useCounter}`}
                    classes={styles['upload__photobox']}
                    {...imageProps}
                    onChange={(file: File, base64: string, imageKey: string, oldId: string | null) => {
                      if (oldId) {
                        handleImageDelete(imageKey, oldId)
                      }
                      handleImageUpload(file, base64, imageKey, false)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                      handleImageDelete(imageKey, oldId)
                    }}
                    image={props.images?.photo3?.data || null}
                    imageId={props.images?.photo3?.id || null}
                    imageKey="photo3"
                  />
                  <ImageUpload
                    key={`photo4-${useCounter}`}
                    classes={styles['upload__photobox']}
                    {...imageProps}
                    onChange={(file: File, base64: string, imageKey: string, oldId: string | null) => {
                      if (oldId) {
                        handleImageDelete(imageKey, oldId)
                      }
                      handleImageUpload(file, base64, imageKey, false)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                      handleImageDelete(imageKey, oldId)
                    }}
                    image={props.images?.photo4?.data || null}
                    imageId={props.images?.photo4?.id || null}
                    imageKey="photo4"
                  />
                  <ImageUpload
                    key={`photo5-${useCounter}`}
                    classes={styles['upload__photobox']}
                    {...imageProps}
                    onChange={(file: File, base64: string, imageKey: string, oldId: string | null) => {
                      if (oldId) {
                        handleImageDelete(imageKey, oldId)
                      }
                      handleImageUpload(file, base64, imageKey, false)
                    }}
                    onDeleteClick={(event, imageKey, oldId) => {
                      handleImageDelete(imageKey, oldId)
                    }}
                    image={props.images?.photo5?.data || null}
                    imageId={props.images?.photo5?.id || null}
                    imageKey="photo5"
                  />
                </div>
              </div>
              <div className={classNames(styles['form__description-box'], styles['form__grid--full'])}>
                <Typography variant="h5" classes={{ root: styles['description__label'] }}>
                  <Trans ns="OfferForm" id="AddDescription" msg="Add description" />
                </Typography>
                <Field
                  name="description"
                  component={FormTextField}
                  type="text"
                  fullWidth
                  InputProps={{
                    multiline: true,
                    rows: 10,
                    placeholder: 'Add description of your offer',
                    disabled: offerAdded,
                  }}
                  helperText={<Trans ns="OfferForm" id="DescriptionLimit" msg="Max 1000 signs" />}
                />
              </div>
              <div className={classNames(styles['form__contact-box'], styles['form__grid--full'])}>
                <Typography variant="h5" classes={{ root: styles['contact-box__headline'] }}>
                  <Trans ns="OfferForm" id="ContactHeadline" msg="Contact information" />
                </Typography>
                <div className={styles['contact-box__help']}>
                  <Img src="/icons/info.svg" />
                  <span className={styles['contact-box__text']}>
                    We will keep the following data to make things easier for you next time. You can change it at any time in Account
                    Settings.
                  </span>
                </div>
                {renderTextField(
                  'email',
                  'string',
                  <Trans ns="OfferForm" id="Email" msg="Email" />,
                  {
                    InputProps: { disabled: true },
                  },
                  {
                    root: styles['form__email'],
                  },
                )}
                <div className={styles['form__phone']}>
                  <FormFieldPhoneCode
                    InputProps={{ disabled: offerAdded }}
                    form={formikBag}
                    field={{
                      name: 'phoneCode',
                      value: formikBag.values.phoneCode,
                      onChange: formikBag.handleChange,
                      onBlur: formikBag.handleBlur,
                    }}
                    label="Country Code"
                    classes={{ root: styles['phone__code'] }}
                  />
                  {renderTextField(
                    'phone',
                    'string',
                    <Trans ns="OfferForm" id="Phone" msg="Phone" />,
                    { InputProps: { disabled: offerAdded } },
                    {
                      root: styles['phone__number'],
                    },
                  )}
                </div>
                {canEditExpirationDate || !props.offer ? (
                  <>
                    {renderSelectField(
                      'expiresIn',
                      <Trans ns="OfferForm" id="ExpiresIn" msg="Expiration date*" />,
                      { root: styles['form__expires-in'] },
                      EXPIRES_IN_VALUES.map(days => ({
                        id: days,
                        name: `${days} days`,
                      })),
                    )}
                    {props.offer ? (
                      <div className={styles['expires-in__links']}>
                        <a
                          className={classNames('primary', styles['expires-in__change'])}
                          onClick={() => {
                            setCanEditExpirationDate(false)
                          }}
                        >
                          Cancel
                        </a>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    {renderTextField(
                      'expiresAt',
                      'string',
                      <Trans ns="OfferForm" id="ExpiresIn" msg="Expiration date*" />,
                      {
                        InputProps: {
                          disabled: true,
                          value: expiresAt,
                        },
                      },
                      {
                        root: styles['form__email'],
                      },
                    )}
                    <div className={styles['expires-in__links']}>
                      {props.offer && !props.offer.isArchived && archiveStep !== ACTION_STEP.DONE ? (
                        <a className="primary" onClick={onArchive}>
                          Archive now
                        </a>
                      ) : null}
                      <a
                        className="primary"
                        onClick={() => {
                          setCanEditExpirationDate(true)
                        }}
                      >
                        Change
                      </a>
                    </div>
                  </>
                )}
                <Field
                  type="checkbox"
                  name="hidden"
                  label={<Trans ns="OfferForm" id="Hidden" msg="Hide offer temporarily" />}
                  component={FormSwitchField}
                  classes={{ root: styles['form__hidden'] }}
                  InputProps={{ disabled: offerAdded }}
                  onChange={(value: string, checked: boolean) => {
                    formikBag.setFieldValue('visibility', checked ? VISIBILITY_PRIVATE : VISIBILITY_PUBLIC)
                  }}
                />
              </div>
              <div className={styles['offer__buttons']}>
                <Button onClick={onBack} variant="contained" color="default" classes={{ root: styles['offer__button-cancel'] }}>
                  <Trans ns="OfferForm" id="ButtonCancelOffer" msg="Cancel" />
                </Button>
                <Button
                  onClick={async () => {
                    const result = await formikBag.validateForm()
                    if (Object.keys(result).length) {
                      notify.error(<Trans ns="OfferForm" id="GlobalError" msg="Some fields needs correction" />)
                    }
                    formikBag.submitForm()
                  }}
                  variant="contained"
                  color="primary"
                  classes={{ root: styles['offer__button-add'] }}
                  disabled={formikBag.isSubmitting}
                >
                  {props.cta}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }

  const render = () => (
    <>
      <Typography variant="h3" classes={{ root: styles['offer__heading'] }}>
        {props.heading}
      </Typography>
      {props.offer ? null : (
        <>
          {renderModeBaseHeading()}
          {renderModeBaseForm()}
          {renderModeSelfHeading()}
        </>
      )}
      {renderForm()}
      {archiveStep === ACTION_STEP.TO_DO || archiveStep === ACTION_STEP.IN_PROGRESS ? (
        <Dialog
          open
          withLogo={false}
          onClose={onArchiveCanceled}
          displayMode={DISPLAY_MODE.fullScreenMobileOnly}
          classes={{ root: styles['remove-popup'] }}
        >
          <div className={styles['remove-popup__content']}>
            <Typography variant="subtitle1">Please confirm that you want to archive this offer.</Typography>
            <div className={styles['remove-popup__navi']}>
              <Button variant="contained" color="default" onClick={() => onArchiveCanceled()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onArchiveConfirmed()}
                disabled={archiveStep === ACTION_STEP.IN_PROGRESS}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>
      ) : null}
    </>
  )

  const renderInDialog = () => {
    return (
      <Dialog open withLogo onClose={onDialogClose} classes={{ root: styles['dialog__container'] }}>
        {render()}
      </Dialog>
    )
  }

  const renderStandalone = () => {
    return <div className={styles['offer__box']}>{render()}</div>
  }

  return <>{isStandalone ? renderStandalone() : renderInDialog()}</>
}

OfferForm.defaultProps = defaultProps

export default OfferForm
