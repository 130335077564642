import _ from 'lodash'
import { ActionsType, ActionTypes, State } from './user.types'
import { ActionsType as AuthActionsType, ActionTypes as AuthActionTypes } from '../auth/auth.types'

const initialState: Readonly<State> = {}

function userReducer(state: State = initialState, action: ActionsType | AuthActionsType): State {
  switch (action.type) {
    case ActionTypes.READ_PROFILE_SUCCESS: {
      return {
        ..._.cloneDeep(action.payload),
        authorization: state.authorization,
      }
    }

    case AuthActionTypes.LOG_OUT_SUCCESS: {
      return _.cloneDeep(initialState)
    }

    case ActionTypes.READ_USER_DATA_SUCCESS: {
      const newState = _.cloneDeep(state)
      newState.data = _.cloneDeep(action.payload)
      return newState
    }
  }
  return state
}

export default userReducer
