import { FormikProps } from 'formik'
import React from 'react'
import styles from './Conversation.module.scss'
import { MessageFormType } from './Conversation.types'
import FormTextField from '../../Form/FormTextField'

type Props = {
  formik: FormikProps<MessageFormType>
  //formRef: React.RefObject<HTMLFormElement>
}

const MessageForm = (props: Props) => {
  const { formik } = props
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormTextField
        classes={{ root: styles['form__field'] }}
        form={formik}
        field={{
          name: 'content',
          value: formik.values.content,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        InputProps={{
          placeholder: 'Message content',
          multiline: true,
          rows: 5,
        }}
        fullWidth
      />
    </form>
  )
}

export default MessageForm
