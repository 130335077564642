import styles from '../shared.module.scss'

export default {
  h1: {
    fontSize: styles['text__4xl'],
    fontWeight: 500,
  },
  h2: {
    fontSize: styles['text__3xl'],
    fontWeight: 500,
  },
  h3: {
    fontSize: styles['text__2xl'],
    fontWeight: 900,
  },
  h4: {
    fontSize: styles['text__xl'],
    fontWeight: 500,
  },
  h5: {
    fontSize: styles['text__lg'],
    fontWeight: 500,
  },
  h6: {
    fontSize: styles['text__md'],
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: styles['text__xl'],
  },
  subtitle2: {
    fontSize: styles['text__xl'],
    fontWeight: 500,
  },
  body2: {
    fontSize: styles['text__sm'],
    fontWeight: 500,
    color: styles['color__gray1'],
  },
  caption: {
    fontSize: styles['text__sm'],
    color: styles['color__gray1'],
  },
  colorSecondary: {
    color: styles['color__gray1'],
  },
}
