import { MenuItem } from '@material-ui/core'
import countries from 'country-codes-list'
import { FormikProps } from 'formik'
import React from 'react'
import styles from './FormPrivate.module.scss'
import FormDateField from '../../../Form/FormDateField'
import FormFieldCountry from '../../../Form/FormFieldCountry'
import FormFieldPhoneCode from '../../../Form/FormFieldPhoneCode'
import FormSelectField from '../../../Form/FormSelectField'
import FormTextField from '../../../Form/FormTextField'
import { SettingsFormType } from '../UserSettings.types'

type Props = {
  formik: FormikProps<SettingsFormType>
  formRef: React.RefObject<HTMLFormElement>
}

const FormPrivate = (props: Props) => {
  const { formik, formRef } = props
  const phoneCodes = countries.customList('countryCode', '{countryCallingCode}')
  const phoneItems: JSX.Element[] = []

  for (const key in phoneCodes) {
    const value = phoneCodes[key]
    if (!value) continue
    phoneItems.push(
      <MenuItem value={value} key={`pc-${key}`}>
        <span className={`flag-icon flag-icon-${key.toLowerCase()}`} />
        <span>{value}</span>
      </MenuItem>,
    )
  }

  return (
    <form onSubmit={formik.handleSubmit} ref={formRef}>
      <FormTextField
        form={formik}
        field={{
          name: 'name',
          value: formik.values.name,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Name"
        fullWidth
        helperText="Required for verification"
      />
      <FormTextField
        form={formik}
        field={{
          name: 'surname',
          value: formik.values.surname,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Surname"
        fullWidth
        helperText="Required for verification"
      />
      <FormDateField
        form={formik}
        field={{
          name: 'birthday',
          value: formik.values.birthday,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Date of birth"
        fullWidth
      />
      <FormTextField
        form={formik}
        field={{
          name: 'address1',
          value: formik.values.address1,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Address 1"
        fullWidth
      />
      <FormTextField
        form={formik}
        field={{
          name: 'address2',
          value: formik.values.address2,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Address 2"
        fullWidth
      />
      <div className={styles['form__location']}>
        <FormTextField
          form={formik}
          field={{
            name: 'postCode',
            value: formik.values.postCode,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="ZIP Code"
          classes={{ root: styles['location__postCode'] }}
        />
        <FormTextField
          form={formik}
          field={{
            name: 'city',
            value: formik.values.city,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="City"
          classes={{ root: styles['location__city'] }}
        />
      </div>
      <FormFieldCountry<SettingsFormType>
        form={formik}
        fieldName="country"
        field={{
          name: 'country',
          value: formik.values.country,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Country"
        helperText="Requried for verification"
      />
      <div className={styles['form__phone']}>
        <FormFieldPhoneCode
          form={formik}
          field={{
            name: 'phoneCode',
            value: formik.values.phoneCode,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="Country Code"
          classes={{ root: styles['phone__code'] }}
        />
        <FormTextField
          form={formik}
          field={{
            name: 'phone',
            value: formik.values.phone,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="Phone number"
          classes={{ root: styles['phone__number'] }}
        />
      </div>
      <FormTextField
        form={formik}
        field={{
          name: 'aboutMe',
          value: formik.values.aboutMe,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="About me"
        InputProps={{
          multiline: true,
          rows: 5,
        }}
      />
    </form>
  )
}

export default FormPrivate
