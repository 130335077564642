import { Button, Typography } from '@material-ui/core'
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import styles from './NewsletterSend.module.scss'
import validationSchema from './NewsletterSend.schema'
import { NewsletterFormType } from './NewsletterSend.types'
import { UserType } from '../../../interfaces/User.type'
import handleError from '../../../utils/handleError'
import FormTextField from '../../Form/FormTextField'
import notify from '../../Notification/notify'
import Trans from '../../Trans/Trans'
import * as api from '../Newsletter.api'

type Props = {
  userData: UserType
}

const NewsletterSend = (props: Props) => {
  const onSubmit = async (values: NewsletterFormType, formikBag: FormikHelpers<NewsletterFormType>) => {
    formikBag.setSubmitting(true)

    try {
      await api.send({ subject: values.subject, message: values.message })
      formikBag.setSubmitting(false)
      formikBag.resetForm()
      notify.success(<Trans ns="Newsletter" id="NewsletterSentSuccess" msg="Newsletter has been sent." />)
    } catch (error) {
      console.error(error)
      handleError(error, formikBag)
      formikBag.setSubmitting(false)
    }
  }

  return (
    <div className={styles['newsletter']}>
      <Typography variant="h3" classes={{ root: styles['newsletter__headline'] }}>
        <Trans ns="Newsletter" id="NewsletterSend" msg="Send newsletter" />
      </Typography>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          subject: '',
          message: '',
        }}
        onSubmit={(values: NewsletterFormType, formikBag: FormikHelpers<NewsletterFormType>) => {
          onSubmit(values, formikBag)
        }}
      >
        {(formikBag: FormikProps<NewsletterFormType>) => {
          return (
            <Form>
              <Field name="subject" fullWidth>
                {(_props: FieldProps<string, NewsletterFormType>) => {
                  return <FormTextField {..._props} label="Subject" fullWidth InputProps={{ type: 'subject' }} />
                }}
              </Field>
              <Field name="message" fullWidth>
                {(_props: FieldProps<string, NewsletterFormType>) => {
                  return (
                    <FormTextField
                      {..._props}
                      label="Message"
                      fullWidth
                      InputProps={{
                        multiline: true,
                        rows: 10,
                      }}
                    />
                  )
                }}
              </Field>
              <div className={styles['navigation']}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formikBag.submitForm}
                  disabled={formikBag.isSubmitting}
                  classes={{ root: styles['navigation__button-save'] }}
                >
                  <Trans ns="Newsletter" id="SendButton" msg="Send" />
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NewsletterSend
