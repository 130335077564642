import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import * as React from 'react'
import DateField from '../../UI/DateField'

const defaultProps = Object.freeze({
  variant: 'outlined' as 'outlined' | 'standard' | 'filled',
})

type Props = typeof defaultProps & {
  field: {
    name: string
    value: any
    onChange: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  }
  form: FormikProps<FormikValues | any>
  label: React.ReactNode | string
  error?: boolean
  helperText?: string
  fullWidth: boolean
  dateFormat?: string
  pickerProps?: object
  onChange?: (value: string) => void
}

const FormDateField = (props: Props) => {
  const { form }: { form: FormikProps<FormikValues> } = props
  const field = props.field

  return (
    <DateField
      {..._.pick(form, ['setFieldValue', 'setFieldTouched'])}
      {..._.pick(props, ['variant', 'type', 'label', 'fullWidth', 'classes', 'children', 'dateFormat', 'pickerProps'])}
      {...props.field}
      onFieldChange={(value: string) => {
        props.onChange ? props.onChange(value) : _.noop()
      }}
      fullWidth={props.fullWidth}
      error={Boolean(_.has(form.touched, field.name) && _.has(form.errors, field.name))}
      helperText={_.has(form.touched, field.name) && _.has(form.errors, field.name) ? _.get(form.errors, field.name) : ''}
    />
  )
}

FormDateField.defaultProps = defaultProps

export default FormDateField
