import { createBrowserHistory } from 'history'
import React from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { Router } from 'react-router-dom'
import routes from './config/routes'
import InitContext, { defaultInitData } from '../../contexts/InitContext'
import UserContext from '../../contexts/UserContext'
import useMatchMedia from '../../hooks/matchMedia/matchMedia'
import usePrerender from '../../hooks/prerender'
import AboutUsPage from '../../pages/about-us'
import OfferAddPage from '../../pages/add-an-offer/OfferAddPage'
import BuyPage from '../../pages/buy'
import CatalogPage from '../../pages/catalog'
import ContactPage from '../../pages/contact'
import OfferEditPage from '../../pages/edit-an-offer/OfferEditPage'
import FaqPage from '../../pages/faq'
import ForgotPasswordPage from '../../pages/forgot-password/ForgotPasswordPage'
import HomePage from '../../pages/home'
import MessagesPage from '../../pages/messages'
import NewsletterOutPage from '../../pages/newsletter-out/NewsletterOutPage'
import NewsletterConfirmPage from '../../pages/newsletter/confirm/NewsletterConfirmPage'
import NewsletterMessagePage from '../../pages/newsletter/message/NewsletterMessagePage'
import NewsletterUnsubscribePage from '../../pages/newsletter/unsubscribe/NewsletterUnsubscribePage'
import OfferOldPage from '../../pages/offer-old/OfferOldPage'
import OfferPage from '../../pages/offer/OfferPage'
import OffersFavoritesPage from '../../pages/offers/favorites'
import OffersPage from '../../pages/offers'
import GDPRPage from '../../pages/personal-data'
import UserConfirmPage from '../../pages/profile/confirm'
import NewsletterSendPage from '../../pages/profile/newsletter/NewsletterSendPage'
import ProfileSettingsPage from '../../pages/profile/settings'
import ResetPasswordPage from '../../pages/reset-password/ResetPasswordPage'
import RoRPage from '../../pages/right-of-resign'
import SellPage from '../../pages/sell'
import SignInPage from '../../pages/signin/SignInPage'
import SignUpPage from '../../pages/signup/SignUpPage'
import TosPage from '../../pages/tos'
import * as api from '../../services/app/app.api'
import { StateType } from '../../services/reducers'
import { AxiosResponse } from '../../utils/axios'
import parseInitData from '../../utils/parseInitData'
import WebLayout from '../WebLayout'

const App = () => {
  const user = useSelector((state: StateType) => state.user)
  const history = React.useRef(createBrowserHistory({ forceRefresh: true }))
  const requestedUrl = usePrerender().getRequestedUrl()
  const [apiInitData, setApiInitData] = React.useState(defaultInitData)
  const [initData, setInitData] = React.useState(defaultInitData)
  const matchMedia = useMatchMedia()

  React.useEffect(() => {
    const loadInitData = async () => {
      try {
        const response: AxiosResponse = await api.getInitData()
        setApiInitData(response.data)
      } catch (err) {
        console.error(err)
      }
    }

    loadInitData()
  }, [])

  React.useEffect(() => {
    const data = parseInitData(apiInitData, matchMedia)
    setInitData({ ...data, loaded: true })
  }, [matchMedia.lg, matchMedia.md, matchMedia.xs, apiInitData])

  return (
    <InitContext.Provider value={initData}>
      <UserContext.Provider value={user}>
        <Helmet>
          <title>Whisky Market - whiskymarket.com</title>
          <meta property="og:title" content="Whisky Market - whiskymarket.com" />
          <meta
            name="description"
            content="Regardless who you are - a whisky trader, a private collector, or a shop owner - whiskymarket.com is the place to be! WhiskyMarket.com is the world’s marketplace for all whisky enthusiasts searching for, selling or buying spirits."
          />
          <meta
            property="og:description"
            content="Regardless who you are - a whisky trader, a private collector, or a shop owner - whiskymarket.com is the place to be! WhiskyMarket.com is the world’s marketplace for all whisky enthusiasts searching for, selling or buying spirits."
          />
          <meta name="keywords" content="whisky, whiskymarket, market, whisky market, whiskymarket.com marketplace, whisky marketplace" />
          <meta property="og:image" content="https://cdn.whiskymarket.com/img/slides/desktop/slide-01.png" />
          <meta property="og:type" content="website" />
          {requestedUrl ? <meta property="og:url" content={requestedUrl} /> : null}
        </Helmet>
        <WebLayout>
          <Router history={history.current}>
            <Switch>
              <Route exact path={routes.home}>
                <HomePage />
              </Route>
              <Route exact path={routes.signIn}>
                <SignInPage />
              </Route>
              <Route exact path={routes.signUp}>
                <SignUpPage />
              </Route>
              <Route exact path={routes.forgotPassword}>
                <ForgotPasswordPage />
              </Route>
              <Route exact path={routes.resetPassword}>
                <ResetPasswordPage />
              </Route>
              <Route exact path={routes.newsletterOut}>
                <NewsletterOutPage />
              </Route>
              <Route exact path={routes.newsletterConfirm}>
                <NewsletterConfirmPage />
              </Route>
              <Route exact path={routes.newsletterUnsubscribe}>
                <NewsletterUnsubscribePage />
              </Route>
              <Route exact path={routes.newsletterMessage}>
                <NewsletterMessagePage />
              </Route>
              <Route exact path={routes.newsletterSend}>
                <NewsletterSendPage />
              </Route>
              <Route exact path={routes.offerAdd}>
                <OfferAddPage />
              </Route>
              <Route exact path={routes.offerEdit}>
                <OfferEditPage />
              </Route>
              <Route exact path={routes.offerOld}>
                <OfferOldPage />
              </Route>
              <Route exact path={routes.offer}>
                <OfferPage />
              </Route>
              <Route exact path={routes.userFavoriteOffers}>
                <OffersFavoritesPage />
              </Route>
              <Route exact path={routes.myOffers}>
                <OffersPage />
              </Route>
              <Route exact path={routes.userOffers}>
                <OffersPage />
              </Route>
              <Route exact path={routes.userConfirm}>
                <UserConfirmPage />
              </Route>
              <Route exact path={routes.userSettings}>
                <ProfileSettingsPage />
              </Route>
              <Route exact path={routes.messages}>
                <MessagesPage />
              </Route>
              <Route exact path={routes.conversation}>
                <MessagesPage />
              </Route>
              <Route exact path={routes.catalog}>
                <CatalogPage />
              </Route>
              <Route exact path={routes.contact}>
                <ContactPage />
              </Route>
              <Route exact path={routes.faq}>
                <FaqPage />
              </Route>
              <Route exact path={routes.tos}>
                <TosPage />
              </Route>
              <Route exact path={routes.aboutUs}>
                <AboutUsPage />
              </Route>
              <Route exact path={routes.ror}>
                <RoRPage />
              </Route>
              <Route exact path={routes.gdpr}>
                <GDPRPage />
              </Route>
              <Route exact path={routes.buy}>
                <BuyPage />
              </Route>
              <Route exact path={routes.sell}>
                <SellPage />
              </Route>
            </Switch>
          </Router>
        </WebLayout>
      </UserContext.Provider>
    </InitContext.Provider>
  )
}

export default App
