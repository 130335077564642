import React from 'react'
import { useHistory } from 'react-router-dom'
import schema from './OfferAddForm.schema'
import { OfferFieldsValuesType, OfferFormType } from '../../../interfaces/Offer.type'
import * as api from '../../../services/offer/offer.api'
import { routes } from '../../Link'
import Trans from '../../Trans'
import OfferForm from '../OfferForm'

const defaultProps = Object.freeze({
  isMobile: false as boolean,
  language: 'en' as string,
})

type Props = typeof defaultProps & {
  fieldsValues: OfferFieldsValuesType
}

const OfferAddForm = (props: Props) => {
  const history = useHistory()

  const handleFormSubmit = async (payload: OfferFormType): Promise<string> => {
    const result = await api.createOffer(payload)

    if (!result.data.id) {
      throw new Error('Offer id was not found')
    }

    return routes.myOffers
  }

  const handleBackClick = () => {
    history.push(routes.home)
  }

  return (
    <OfferForm
      {...props}
      onFormSubmit={handleFormSubmit}
      onBackClick={handleBackClick}
      heading={<Trans ns="OfferAddForm" id="Heading" msg="Add your offer" />}
      cta={<Trans ns="OfferForm" id="ButtonAddOffer" msg="Add offer" />}
      confirmationMessage="'Congratulations! Your offer has been successfully added. It will go live shortly, once it has been reviewed by one of our Team members.'"
      schema={schema}
    />
  )
}

OfferAddForm.defaultProps = defaultProps

export default OfferAddForm
