import { Button, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import { FormikHelpers, FormikProps, useFormik } from 'formik'
import _ from 'lodash'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { animateScroll as scroll } from 'react-scroll'
import { Dispatch } from 'redux'
import ChangePassword from './ChangePassword'
import DetailedInformation from './DetailedInformation'
import FormBusiness from './FormBusiness'
import businessVerifySchema from './FormBusiness/FormBusinessVerify.schema'
import FormPrivate from './FormPrivate'
import privateSchema from './FormPrivate/FormPrivate.schema'
import privateVerifySchema from './FormPrivate/FormPrivateVerify.schema'
import MyAccount from './MyAccount'
import MyDetails from './MyDetails'
import styles from './UserSettings.module.scss'
import { PASSWORD_FORM_MODE, SettingsFormType, SettingsImageUploadType } from './UserSettings.types'
import Verification from './Verification'
import { UserType } from '../../../interfaces/User.type'
import * as userActions from '../../../services/user/user.actions'
import * as userApi from '../../../services/user/user.api'
import { ActionsType as UserActionsType, UserFormPayloadType } from '../../../services/user/user.types'
import Date from '../../../utils/date'
import handleError from '../../../utils/handleError'
import notify from '../../Notification/notify'
import Trans from '../../Trans'

export enum MODE_TYPE {
  PRIVATE = 'private',
  BUSINESS = 'business',
}

const UPLOAD_IMAGE_MAX_SIZE = 2560 //kB - update translation below, no dynamic trans
const UPLOAD_IMAGE_MIN_SIZE = 5 //kB - update translation below, no dynamic trans

type Props = {
  userData: UserType
}

const UserSettings = (props: Props) => {
  const dispatch: Dispatch<UserActionsType> = useDispatch()
  const { userData } = props
  const [userType, setUserType] = useState<MODE_TYPE>(userData.data?.isBusiness ? MODE_TYPE.BUSINESS : MODE_TYPE.PRIVATE)
  const [passwordFormMode, setPasswordFormMode] = useState<PASSWORD_FORM_MODE>(PASSWORD_FORM_MODE.HIDE)
  const [image, setImage] = useState<SettingsImageUploadType | null>(null)
  const [imageUploading, setImageUploading] = useState<boolean>(false)
  const [verifyNow, setVerifyNow] = useState<boolean>(false)
  const formRef = React.useRef<HTMLFormElement>(null)

  const onImageSizeError = (tooBig: boolean, tooSmall: boolean) => {
    if (tooSmall) notify.error(<Trans ns="UserSettings" id="ImageTooSmall" msg="Image size must be at least 5 kB" />)
    if (tooBig) notify.error(<Trans ns="UserSettings" id="ImageTooSmall" msg="Image size cannot be at bigger than 5120 kB" />)
  }

  const onImageTypeError = (fileType: string) => {
    notify.error(<Trans ns="UserSettings" id="ImageFileTypeError" msg="File extension must be one of PNG, JPG or JPEG" />)
  }

  const onImageError = (file: File | null, reason: string) => {
    notify.error(<Trans ns="UserSettings" id="ImageUnknownError" msg="Unknown error occured, please try again." />)
  }

  const onAvatarUpload = async (file: File, base64: string, imageKey: string) => {
    setImage({
      data: base64,
    })

    setImageUploading(true)
    try {
      const avatarResult = await userApi.updateAvatar(base64)
      setImageUploading(false)
    } catch (error) {
      notify.error(<Trans ns="UserSettings" id="ImageUploadError" msg="Unknown error occured, please try again." />)
      setImageUploading(false)
    }
  }

  const onResendEmail = () => {
    notify.success(<Trans ns="UserSettings" id="ResendEmailDone" msg="Please check your mailbox." />)
  }

  const onVerifyNow = () => {
    setVerifyNow(true)
    if (formRef.current?.offsetTop !== undefined) {
      scroll.scrollTo(formRef.current.offsetTop)
    }
  }

  const formik = useFormik({
    validationSchema: userType === MODE_TYPE.BUSINESS ? businessVerifySchema : verifyNow ? privateVerifySchema : privateSchema,
    initialValues: {
      isBusiness: userType === MODE_TYPE.BUSINESS,
      email: userData.data?.email || '',
      username: userData.data?.username || '',
      companyName: userData.data?.companyName || '',
      website: userData.data?.website || '',
      address1: userData.data?.address1 || '',
      address2: userData.data?.address2 || '',
      postCode: userData.data?.postCode || '',
      city: userData.data?.city || '',
      country: userData.data?.country || '',
      phoneCode: userData.data?.phoneCode || '',
      phone: userData.data?.phone || '',
      aboutMe: userData.data?.aboutMe || '',
      gst: userData.data?.gst || false,
      gstRate: userData.data?.gstRate || 0,
      taxId: userData.data?.taxId || '',
      verification: true,
      name: userData.data?.name || '',
      surname: userData.data?.surname || '',
      birthday: Date.timestampToDateString((userData.data?.birthday as any) as number) || '',
    },
    onSubmit: async (values: SettingsFormType, formikBag: FormikHelpers<SettingsFormType>) => {
      //formikBag.setSubmitting(true)
      const payload = _.cloneDeep(values)
      payload.isBusiness = userType === MODE_TYPE.BUSINESS
      payload.verification = true
      payload.verification = verifyNow
      payload.birthday = (Date.dateToUnixTimestamp(payload.birthday as string) as any) as string
      payload.gstRate = new BigNumber(payload.gstRate).dividedBy(100).toNumber()

      try {
        const result = await userApi.updateUser((payload as any) as UserFormPayloadType)
        formikBag.setSubmitting(false)
        dispatch(userActions.readUserDataSuccess(result.data))
        notify.success(<Trans ns="UserSettings" id="UserBusinessUpdatedSuccess" msg="Changes was saved." />)
      } catch (error) {
        console.error(error)
        handleError(error, formikBag)
        formikBag.setSubmitting(false)
      }
    },
  })

  const onSaveSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const result = await formik.validateForm()
    if (Object.keys(result).length) {
      notify.error(<Trans ns="UserSettings" id="FormErrorGlobal" msg="Some fields needs correction" />)
    }
    formik.handleSubmit()
  }

  return (
    <div className={styles['settings']}>
      <Typography variant="h3" classes={{ root: styles['settings__headline'] }}>
        <Trans ns="UserSettings" id="SettingsHeadline" msg="Settings" />
      </Typography>
      <MyAccount user={userData} userType={userType} onVerifyNow={onVerifyNow} setUserType={setUserType} />
      <MyDetails formik={formik} user={userData} />
      <ChangePassword open={passwordFormMode === PASSWORD_FORM_MODE.SHOW} setMode={setPasswordFormMode} />
      <DetailedInformation
        image={image}
        user={userData}
        userType={userType}
        onImageError={onImageError}
        onImageSizeError={onImageSizeError}
        onImageTypeError={onImageTypeError}
        onAvatarUpload={onAvatarUpload}
        minImageSize={UPLOAD_IMAGE_MIN_SIZE}
        maxImageSize={UPLOAD_IMAGE_MAX_SIZE}
        imageUploading={imageUploading}
      />
      {userType === MODE_TYPE.BUSINESS ? (
        <FormBusiness formik={formik as FormikProps<SettingsFormType>} formRef={formRef} />
      ) : (
        <FormPrivate formik={formik as FormikProps<SettingsFormType>} formRef={formRef} />
      )}
      <Verification
        confirmed={!!userData.data?.confirmedAt}
        verified={!!userData.data?.verifiedAt}
        userType={userType}
        onResendEmail={onResendEmail}
        onVerifyNow={onVerifyNow}
      />
      <Button
        variant="contained"
        color="primary"
        classes={{ root: styles['button-save'] }}
        onClick={onSaveSubmit}
        disabled={formik.isSubmitting}
      >
        {verifyNow || userType === MODE_TYPE.BUSINESS ? (
          <Trans ns="UserSettings" id="VerifyButton" msg="Save and verify" />
        ) : (
          <Trans ns="UserSettings" id="SaveButton" msg="Save" />
        )}
      </Button>
    </div>
  )
}

export default UserSettings
