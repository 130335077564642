import { useState } from 'react'

type LANGUAGE_TYPE = {
  name: string
  isoCode: string
}

export type LANGUAGES_TYPE = {
  [key: string]: LANGUAGE_TYPE
}

const useCms = () => {
  const languages = {
    en: {
      name: 'English',
      isoCode: 'en',
    },
    de: {
      name: 'Deutsch',
      isoCode: 'de',
    },
  } as LANGUAGES_TYPE

  const [language, setLanguage] = useState<LANGUAGE_TYPE>(languages.de)
  const switchLanguage = (lang: LANGUAGE_TYPE) => {
    setLanguage(lang)
  }

  return {
    switchLanguage,
    language,
    languages,
  }
}

export default useCms
