import { moment } from '../../utils/date'
import Yup from '../../utils/yup'

const birthdayMinDate = moment().subtract(120, 'years')
const birthdayMaxDate = moment().subtract(18, 'years')

const schema = Yup.object().shape({
  birthday: Yup.string()
    .ensure()
    .required('Required')
    // @ts-ignore
    .isValidDateRange(birthdayMinDate, birthdayMaxDate, 'Wrong fromat of date', 'Not possible you still alive', 'You are to young'),
})

export default schema
