import { FormikProps } from 'formik'
import { SuggestionHighlightedParams, SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest'

export type OfferFormAutocompletePayloadType = {
  query: string
  type: string
}

export type AutocompleteFieldType<SuggestionT, FormT> = {
  timer?: NodeJS.Timeout
  name: string
  inputValue: string
  correct: boolean
  suggestions: SuggestionT[]
  onSuggestionSelected: (
    event: React.FormEvent<any>,
    data: SuggestionSelectedEventData<SuggestionT>,
    formikBag?: FormikProps<FormT>,
  ) => void
  onSelected: (value: string, formikBag: FormikProps<FormT>) => void
  getSuggestions: (value: string, formikBag: FormikProps<FormT>) => void
  getSuggestionValue: (item: SuggestionT) => string
  onSuggestionsClearRequested: () => void
  onSuggestionsFetchRequested: (request: SuggestionsFetchRequestedParams, formikBag?: FormikProps<FormT>) => void
  onSuggestionHighlighted?: (params: SuggestionHighlightedParams) => void
  renderSuggestion: (item: any, { query, isHighlighted }: { query: string; isHighlighted: boolean }) => JSX.Element
}

export type FormModeType = 'base' | 'self' | 'base-filled'
export const FIELD_AUTO_BASE_SEARCH = 'baseSearch'
export const FIELD_AUTO_DISTILLERY = 'distillery'
export const FIELD_AUTO_BOTTLER = 'bottler'
export const FIELD_AUTO_LOCATION = 'bottleLocation'
export const FORM_MODE_BASE = 'base'
export const FORM_MODE_SELF = 'self'
export const FORM_MODE_BASE_FILLED = 'base-filled'
