import { Slide } from '../../components/Slider'
import { Result as MatchMediaType } from '../../hooks/matchMedia'

type Result = Slide[]

const getSlides = (matchMedia: MatchMediaType): Result => {
  return matchMedia.lg
    ? [
        {
          src: '/img/slides/baner-1-desktop.png',
          url: 'https://shop.whiskymarket.com/',
        },
        {
          src: '/img/slides/baner-2-desktop.png',
          url: 'https://whiskymarket.com/contact',
        },
        {
          src: '/img/slides/baner-3-desktop.png',
          url: 'https://whiskymarket.com/signup',
        },
      ]
    : matchMedia.xs
    ? [
        {
          src: '/img/slides/baner-1-tablet.png',
          srcSet: '/img/slides/baner-1-tablet@2x.png',
          url: 'https://shop.whiskymarket.com/',
        },
        {
          src: '/img/slides/baner-2-tablet.png',
          srcSet: '/img/slides/baner-2-tablet@2x.png',
          url: 'https://whiskymarket.com/contact',
        },
        {
          src: '/img/slides/baner-3-tablet.png',
          srcSet: '/img/slides/baner-3-tablet@2x.png',
          url: 'https://whiskymarket.com/signup',
        },
      ]
    : [
        {
          src: '/img/slides/baner-1-mobile.png',
          srcSet: '/img/slides/baner-1-mobile@2x.png',
          url: 'https://shop.whiskymarket.com/',
        },
        {
          src: '/img/slides/baner-2-mobile.png',
          srcSet: '/img/slides/baner-2-mobile@2x.png',
          url: 'https://whiskymarket.com/contact',
        },
        {
          src: '/img/slides/baner-3-mobile.png',
          srcSet: '/img/slides/baner-3-mobile@2x.png',
          url: 'https://whiskymarket.com/signup',
        },
      ]
}

export default getSlides
