import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import * as actions from '../../services/auth/auth.actions'
import * as api from '../../services/auth/auth.api'
import * as offerActions from '../../services/offer/offer.actions'
import { ActionTypes as OfferActionsType } from '../../services/offer/offer.types'
import { StateType } from '../../services/reducers'
import store from '../../services/store'
import {
  AxiosError,
  AxiosErrorCallback,
  AxiosResponse,
  AxiosSuccessCallback
  } from '../../utils/axios'
import useOffer from '../offer'
import {
  ActionsType,
  ChangePasswordPayloadType,
  GoogleSignInPayloadType,
  ResetPasswordPayloadType,
  SignInPayloadType,
  SignUpPayloadType,
} from '../../services/auth/auth.types'

const useAuth = () => {
  const dispatch: Dispatch<ActionsType | OfferActionsType> = useDispatch()
  const { initFavorites } = useOffer()

  const isAuth: boolean = useSelector((state: StateType): boolean => {
    //add token verify
    return !!(state.user.authorization && state.user.data?.id)
  })

  const signInSuccessCallback = (response: AxiosResponse, callbackSuccess: null | AxiosSuccessCallback = null) => {
    _.isFunction(callbackSuccess) ? callbackSuccess(response) : null
    initFavorites(response.data?.data?.favoriteOfferIds || [])
    setTimeout(() => {
      dispatch(actions.signInSuccess(response.data))
    }, 250)
  }

  const signIn = async (
    data: SignInPayloadType,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    try {
      const response: AxiosResponse = await api.signIn(data)
      console.log('sign in', response)
      signInSuccessCallback(response, callbackSuccess)
    } catch (error) {
      console.error(error)
      _.isFunction(callbackError) ? callbackError(error as AxiosError) : null
    }
  }

  const signUp = async (
    data: SignUpPayloadType,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    try {
      const response: AxiosResponse = await api.signUp(data)
      console.log('sign up', response)
      signInSuccessCallback(response, callbackSuccess)
    } catch (error) {
      console.error(error)
      _.isFunction(callbackError) ? callbackError(error as AxiosError) : null
    }
  }

  const logOut = () => {
    store.__PERSISTOR.purge()

    try {
      const GoogleAuth = gapi.auth2.getAuthInstance()
      GoogleAuth?.signOut?.()
      GoogleAuth?.disconnect?.()
    } catch (e) {
      // continue regardless of error
    }

    dispatch(offerActions.clearFavoriteOffers())
    dispatch(actions.logOutSuccess())
  }

  const facebookAuth = async (
    token: string,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    try {
      const response: AxiosResponse = await api.facebook(token)
      console.log('facebook', response)
      signInSuccessCallback(response, callbackSuccess)
    } catch (error) {
      console.error(error)
      _.isFunction(callbackError) ? callbackError(error as AxiosError) : null
    }
  }

  const googleAuth = async (
    payload: GoogleSignInPayloadType,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    try {
      const response: AxiosResponse = await api.google(payload)
      console.log('google to api', response)
      signInSuccessCallback(response, callbackSuccess)
    } catch (error) {
      console.error(error)
      _.isFunction(callbackError) ? callbackError(error as AxiosError) : null
    }
  }

  const resetPassword = async (
    payload: ResetPasswordPayloadType,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    try {
      const response: AxiosResponse = await api.resetPassword(payload)
      console.log('reset password', response)
      signInSuccessCallback(response, callbackSuccess)
    } catch (error) {
      console.error(error)
      _.isFunction(callbackError) ? callbackError(error as AxiosError) : null
    }
  }

  const changePassword = async (
    payload: ChangePasswordPayloadType,
    callbackSuccess: null | AxiosSuccessCallback = null,
    callbackError: null | AxiosErrorCallback = null,
  ) => {
    try {
      const response: AxiosResponse = await api.changePassword(payload)
      console.log('change password', response)
      signInSuccessCallback(response, callbackSuccess)
    } catch (error) {
      console.error(error)
      _.isFunction(callbackError) ? callbackError(error as AxiosError) : null
    }
  }

  return {
    signIn,
    signUp,
    logOut,
    isAuth,
    facebookAuth,
    googleAuth,
    resetPassword,
    changePassword,
  }
}

export default useAuth
