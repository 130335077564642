import { Button } from '@material-ui/core'
import qs from 'qs'
import React from 'react'
import { useParams } from 'react-router'
import styles from './NewsletterMessagePage.module.scss'
import LandingPageLayout from '../../../components/Layout/LandingPageLayout'
import Link, { routes } from '../../../components/Link/Link'
import * as api from '../../../components/Newsletter/Newsletter.api'
import Notification from '../../../components/Notification'
import * as NotificationType from '../../../components/Notification/Notification.types'
import Trans from '../../../components/Trans'
import { AxiosResponse } from '../../../utils/axios'

type UrlSearchType = {
  id: string
  token: string
  messageId: string
}

const NewsletterMessagePage = () => {
  const [error, setError] = React.useState<string | boolean>(false)
  const [content, setContent] = React.useState<string | null>()
  const urlParams = useParams<UrlSearchType>()

  React.useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response: AxiosResponse = await api.getMessage({ id: urlParams.id, token: urlParams.token, messageId: urlParams.messageId })
        setContent(response.data.message.content || null)
      } catch (err) {
        const errors = err?.response?.data?.errors || []
        setError(errors[0]?.message || 'Something went wrong. Try again')
        setContent(null)
        console.error(err)
      }
    }
    fetchMessage()
  }, [])

  //const content = props.error ? props.error : null

  return content ? (
    <div
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  ) : (
    <LandingPageLayout
      classes={{
        header: styles['header'],
        container: styles['container'],
      }}
      headerContent={
        <div className={styles['content']}>
          <Notification
            isStatic
            id={0}
            type={error ? NotificationType.ERROR : NotificationType.SUCCESS}
            content={error || null}
            className={styles['notification']}
          />
          <Button variant="contained" type="submit" color="primary">
            <Link silent to={routes.catalog}>
              <Trans ns="Common" id="GoToCatalog" msg="Go to catalog" />
            </Link>
          </Button>
        </div>
      }
    />
  )
}

// NewsletterMessagePage.getInitialProps = async function(context: NextPageContext) {
//   const id = context.query.id as string
//   const token = context.query.token as string
//   const messageId = context.query.messageId as string

//   let error: string | null = null
//   let content: string | null = null

//   try {
//     const response: AxiosResponse = await api.getMessage({ id, token, messageId })
//     content = response.data.message.content || null
//   } catch (err) {
//     const errors = err?.response?.data?.errors || []
//     error = errors[0]?.message || 'Something went wrong. Try again'
//     console.error(err)
//   }

//   return {
//     error,
//     content,
//   }
// }

export default NewsletterMessagePage
