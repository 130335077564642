import { InputAdornment } from '@material-ui/core'
import { FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import parsePrice from '../../../utils/parsePrice'
import TextField from '../../UI/TextField'

type Props = {
  field: {
    name: string
    value: any
    onChange: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  }
  form: FormikProps<any>
  label: React.ReactNode | string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  currencySign: string
  disabled?: boolean
  onChange: (value: string) => void
  maxValue?: number
  classes?: {
    root?: string
  }
  inputProps?: object
}

const FormCurrencyField: React.FC<Props> = ({ field: { ...fields }, form: { touched, errors, setFieldValue }, ...props }) => {
  return (
    <TextField
      {..._.pick(props, ['type', 'label', 'fullWidth', 'classes', 'children', 'disabled', 'inputProps', 'classes'])}
      {...fields}
      error={Boolean(_.has(touched, fields.name) && _.has(errors, fields.name))}
      helperText={_.has(touched, fields.name) && _.has(errors, fields.name) ? _.get(errors, fields.name) : ''}
      InputProps={{
        notched: false,
        startAdornment: <InputAdornment position="start">{props.currencySign}</InputAdornment>,
      }}
      onChange={(event: React.FocusEvent<HTMLInputElement>) => {
        const parsed = parsePrice(event.target.value, {
          maxValue: props.maxValue,
        })

        setFieldValue(fields.name, parsed.value)
        props.onChange(parsed.normalized)
      }}
    />
  )
}

export default FormCurrencyField
