import { Button, CircularProgress, FormControl } from '@material-ui/core'
import classNames from 'classnames'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import { createNewsletter, NewsletterInPayloadType } from './Newsletter.api'
import styles from './Newsletter.module.scss'
import schema from './Newsletter.schema'
import { AxiosResponse } from '../../utils/axios'
import handleError from '../../utils/handleError'
import FormCheckboxField from '../Form/FormCheckboxField'
import FormTextField from '../Form/FormTextField'
import notify from '../Notification/notify'
import Trans from '../Trans'

type Classes = {
  root?: string
  button?: string
  checkbox?: string
  input?: string
}

const defaultProps = Object.freeze({
  buttonLabel: (<Trans ns="Newsletter" id="Newsletter.SignToNewsletter" msg="Add me to newsletter" />) as JSX.Element | string,
  controlLabel: (<Trans ns="Newsletter" id="Newsletter.Email" msg="E-mail" />) as JSX.Element | string | undefined,
})

type Props = typeof defaultProps & {
  classes?: Classes
  placeholder?: string
}

const Newsletter = (props: Props) => {
  const onSubmit = async (values: NewsletterInPayloadType, formikBag: FormikHelpers<NewsletterInPayloadType>) => {
    try {
      await createNewsletter(values)
      formikBag.setSubmitting(false)
      notify.success("You've successfully subscribed. Please check your email for confirmation.")
      formikBag.resetForm()
    } catch (error) {
      formikBag.setSubmitting(false)
      handleError(error, formikBag)
      console.error(error)
    }
  }

  return (
    <Formik
      initialValues={
        {
          email: '',
          tos: false,
        } as NewsletterInPayloadType
      }
      validationSchema={schema}
      onSubmit={(values, formikBag) => {
        onSubmit(values, formikBag)
      }}
      render={formikBag => (
        <Form className={props.classes?.root}>
          <Field
            name="email"
            label={props.controlLabel}
            component={FormTextField}
            fullWidth
            classes={{ root: styles['newsletter__field'] }}
            InputProps={{
              placeholder: props.placeholder!,
            }}
            InputLabelProps={{
              classes: {
                root: props.classes?.input!,
              },
            }}
          />
          <Field
            type="checkbox"
            name="tos"
            label="I accept the Terms Of Service"
            component={FormCheckboxField}
            fullWidth
            classes={{ root: classNames(styles['newsletter__checkbox'], props.classes?.checkbox!) }}
          />
          <FormControl fullWidth>
            <Button
              variant="contained"
              color="primary"
              onClick={formikBag.submitForm}
              disabled={formikBag.isSubmitting}
              className={props.classes?.button}
            >
              {formikBag.isSubmitting ? <CircularProgress size="1.6rem" /> : props.buttonLabel}
            </Button>
          </FormControl>
        </Form>
      )}
    />
  )
}

Newsletter.defaultProps = defaultProps

export default Newsletter
