import classNames from 'classnames'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { routes } from '../../../components/Link'
import PageLayout from '../../../components/PageLayout'
import pageStyles from '../../../components/PageLayout/PageLayout.module.scss'
import UserSettings from '../../../components/User/UserSettings'
import UserContext from '../../../contexts/UserContext'
import useAuth from '../../../hooks/auth'

const SettingsPage = () => {
  const user = useContext(UserContext)
  const { isAuth } = useAuth()

  if (!isAuth) {
    return <Redirect to={routes.signIn} />
  }

  return (
    <PageLayout classes={classNames(pageStyles['content--sides-padding'])}>
      <UserSettings userData={user} />
    </PageLayout>
  )
}

export default SettingsPage
