import _ from 'lodash'
import { RefObject, useEffect, useState } from 'react'

const useOnLoadImages = (ref: RefObject<HTMLElement>) => {
  const [status, setStatus] = useState(false)
  const refCopy = _.cloneDeep(ref)

  const updateStatus = (images: HTMLImageElement[]) => {
    setStatus(isLoaded(images))
  }

  const isLoaded = (images: HTMLImageElement[]) => images.map(image => image.complete).every(item => item)

  useEffect(() => {
    const handleLoad = () => {
      updateStatus(imagesLoaded)
    }

    const handleError = () => {
      updateStatus(imagesLoaded)
    }

    if (refCopy.current === null) {
      return
    }

    const imagesLoaded = Array.from(refCopy.current.querySelectorAll('img'))

    if (status) {
      return
    }

    if (imagesLoaded.length === 0 || isLoaded(imagesLoaded)) {
      setStatus(true)
      return
    }

    imagesLoaded.forEach(image => {
      image.addEventListener('load', handleLoad)
      image.addEventListener('error', handleError)
    })

    return () => {
      imagesLoaded.forEach(image => {
        image.removeEventListener('load', handleLoad)
        image.removeEventListener('error', handleError)
      })
    }
  }, [refCopy])

  return status
}

export default useOnLoadImages
