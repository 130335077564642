import axiosLib, { AxiosError as _AxiosError, AxiosResponse as _AxiosResponse, AxiosTransformer } from 'axios'
import _ from 'lodash'
import store from '../services/store'

export type AxiosResponse = _AxiosResponse
export type AxiosError = _AxiosError
export type AxiosSuccessCallback = (response: AxiosResponse) => void
export type AxiosErrorCallback = (response: AxiosError) => void

const transformResponse = _.isArray(axiosLib.defaults.transformResponse)
  ? axiosLib.defaults.transformResponse
  : [axiosLib.defaults.transformResponse as AxiosTransformer]

const axios = axiosLib.create({
  timeout: 30000,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data, headers) => {
      data = JSON.stringify(data)

      if (store.getState().user.authorization) {
        headers['Authorization'] = store.getState().user.authorization
      }

      return data
    },
  ],
  transformResponse: [
    ...transformResponse,
    (data, headers: Record<string, string>) => {
      let token: string | null = null

      if (headers) {
        const key = Object.keys(headers)
          .filter(_key => _key.toLowerCase() === 'x-authorization')
          .pop()

        token = key ? headers[key] : null
      }

      if (store.getState() && token) {
        store.getState().user.authorization = token
      }

      return data
    },
  ],
})

axios.interceptors.request.use(config => {
  //user data form store
  //console.log('store', store.getState().user)
  config.baseURL = process.env.REACT_APP_API_URL as string
  return config
})

axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
)

export default axios
