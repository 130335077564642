import {
  Button,
  CircularProgress,
  MenuItem,
  Typography
  } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router'
import { USER_OFFERS_TYPE } from './UserOffers'
import styles from './UserOffers.module.scss'
import OfferType, { MASS_ACTION_ARCHIVE, MASS_ACTION_CLEAR_SELECTION, MASS_ACTION_REMOVE } from '../../../interfaces/Offer.type'
import { UserDataType } from '../../../interfaces/User.type'
import Dialog, { DISPLAY_MODE } from '../../Dialog'
import Link, { routes } from '../../Link'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import notify from '../../Notification/notify'
import OfferListItem, { ACTION_STEP } from '../../OfferListItem'
import SearchInput from '../../Search/SearchInput'
import Trans from '../../Trans'
import SelectField from '../../UI/SelectField'
import UserMiniProfile from '../../UserMiniProfile'

type MassActionItem = {
  name: string
  value: string
}

type Props = {
  ownerType: USER_OFFERS_TYPE
  items: OfferType[] | false
  itemsCounter: number
  user: UserDataType
  loadingItems: boolean
  onLoadMore: (all?: boolean) => void
  loadMoreUrl?: string
  onSearchClick: (value: string) => void
  onOfferRemove: () => void
  onOfferUpdate: (offer: OfferType) => void
  onOffersRemove: (ids: string[]) => Promise<void>
  onOffersArchive: (ids: string[]) => Promise<void>
}

const UserOffersView = (props: Props) => {
  const { user, ownerType, items, loadingItems, onLoadMore, loadMoreUrl, onOfferRemove, onOfferUpdate } = props
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const query = queryParams.get('query')
  const mine = ownerType == USER_OFFERS_TYPE.MY
  const [selectedItems, setSelectedItems] = React.useState<string[]>([])
  const [lastSelectedItems, setLastSelectedItems] = React.useState<string[]>([])
  const [massActionValue, setMassActionValue] = React.useState<string>('')
  const [deleteStep, setDeleteStep] = React.useState<ACTION_STEP>(ACTION_STEP.NONE)
  const [archiveStep, setArchiveStep] = React.useState<ACTION_STEP>(ACTION_STEP.NONE)
  const [loadingStep, setLoadingStep] = React.useState<ACTION_STEP>(ACTION_STEP.NONE)

  const getDefaultMassAction = (): MassActionItem => ({
    name: selectedItems.length > 0 ? 'Choose action' : 'Select an item',
    value: '',
  })

  React.useEffect(() => {
    if (selectedItems.length === 0) {
      setMassActionValue(getDefaultMassAction().value)
    }
  }, [selectedItems])

  const massActions: MassActionItem[] = [getDefaultMassAction()]

  if (selectedItems.length > 0) {
    massActions.push(...[{
      name: 'Remove',
      value: MASS_ACTION_REMOVE,
    }, {
      name: 'Archive',
      value: MASS_ACTION_ARCHIVE,
    }, {
      name: 'Clear selection',
      value: MASS_ACTION_CLEAR_SELECTION,
    }])
  }

  const clearSelectedItems = () => {
    setLastSelectedItems([])
    setSelectedItems([])
  }

  const onSelectedChange = (offerId: string, checked: boolean) => {
    let newSelectedItems = [...selectedItems]

    if (checked) {
      newSelectedItems.push(offerId)
    } else {
      newSelectedItems = newSelectedItems.filter(id => id !== offerId)
    }

    setSelectedItems(newSelectedItems)
  }

  const onMassActionSelected = async (event: React.ChangeEvent<{ name?: string | undefined; value: any }>) => {
    const action = event.target.value
    setMassActionValue(action)

    if (action) {
      if (action === MASS_ACTION_REMOVE) {
        setLastSelectedItems([...selectedItems])
        setDeleteStep(ACTION_STEP.TO_DO)
      } else if (action === MASS_ACTION_ARCHIVE) {
        setLastSelectedItems([...selectedItems])
        setArchiveStep(ACTION_STEP.TO_DO)
      } else if (action === MASS_ACTION_CLEAR_SELECTION) {
        clearSelectedItems()
      }
    }
  }

  const onDeleteConfirmed = async () => {
    try {
      setDeleteStep(ACTION_STEP.IN_PROGRESS)
      await props.onOffersRemove([...lastSelectedItems])
      notify.success('You offers have been deleted.')
      setDeleteStep(ACTION_STEP.DONE)
    } catch (error) {
      console.error(error)
    }

    clearSelectedItems()
  }

  const onDeleteCanceled = () => {
    setDeleteStep(ACTION_STEP.NONE)
    clearSelectedItems()
  }

  const onArchiveConfirmed = async () => {
    try {
      setArchiveStep(ACTION_STEP.IN_PROGRESS)
      await props.onOffersArchive([...selectedItems])
      notify.success('You offers have been archived.')
      setArchiveStep(ACTION_STEP.DONE)
    } catch (error) {
      console.error(error)
    }

    clearSelectedItems()
  }

  const onArchiveCanceled = () => {
    setArchiveStep(ACTION_STEP.NONE)
    clearSelectedItems()
  }

  if (items !== false && items.length === 0 && user) {
    return (
      <div className={classNames(styles['search__container'], mine && styles['content--mine'])}>
        <Typography variant="h2">No offers found at this moment.</Typography>
        {query ? (
          <Link classes={classNames('primary', styles['search__show-all'])} to={routes.catalog} search={`query=${encodeURIComponent(query)}`}>
            <Trans ns="UserOffers" id="SearchShowAll" msg="Show results for all offers" />
          </Link>
        ) : null}
      </div>
    )
  }

  if (items === false || (items && items.length === 0)) {
    return <CircularProgress classes={{ root: styles['loading'] }} />
  }

  return (
    <>
      <div className={styles['offers']}>
        <Typography variant="h3" classes={{ root: classNames(styles['offers__headline'], mine && styles['content--mine']) }}>
          {ownerType === USER_OFFERS_TYPE.MY ? (
            <Trans ns="UserOffers" id="MyOffersHeadline" msg="My Offers" />
          ) : (
            <>
              <Trans ns="UserOffers" id="OtherOffersHeadline" msg="Offers:" /> <a className="primary text">{user.username}</a>
            </>
          )}
        </Typography>
        <div className={classNames(styles['search__container'], mine && styles['content--mine'])}>
          <SearchInput
            onSearchClick={props.onSearchClick}
            userId={user.id}
            name="query"
            value={query}
            placeholder={props.itemsCounter > 0 ? `Search from ${props.itemsCounter} ${user.username}'s offer${props.itemsCounter > 1 ? 's' : ''}` : 'Search'}
          />
          {query ? (
            <Link classes={classNames('primary', styles['search__show-all'])} to={routes.catalog} search={`query=${encodeURIComponent(query)}`}>
              <Trans ns="UserOffers" id="SearchShowAll" msg="Show results for all offers" />
            </Link>
          ) : null}
        </div>
        {mine ? (
          <SelectField
            classes={{ root: classNames(styles['content--mine'], styles['mass-actions']) }}
            onChange={onMassActionSelected}
            value={massActionValue}
            displayEmpty
          >
            {massActions.map(item => <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
          </SelectField>
        ) : null}
        {ownerType === USER_OFFERS_TYPE.OTHER ? <UserMiniProfile user={user!} classes={{ root: styles['mini-profile'] }} /> : null}
        <ul className={styles['offers__list']}>
          {items &&
            items?.map(item => (
              <li key={`li-${item.id}`}>
                <OfferListItem
                  onOfferRemove={onOfferRemove}
                  onOfferUpdate={onOfferUpdate}
                  onSelectedChange={onSelectedChange}
                  offer={item}
                  selected={selectedItems.includes(item.id)}
                  ownerType={ownerType}
                  key={`oi-${item.id}`}
                />
              </li>
            ))}
        </ul>
        {loadMoreUrl != null && items && items.length > 0 ? (
          <div className={styles['buttons']}>
            {loadingItems ? <CircularProgress size={17} /> : (
              <>
                {mine ? (
                  <Button
                    variant="text"
                    color="primary"
                    classes={{ root: styles['button--show-all'] }}
                    disabled={loadingItems}
                    onClick={() => {
                      onLoadMore(true)
                    }}
                  >
                    <Trans ns="UserOffers" id="ShowAll" msg="Show all" />
                  </Button>

                ) : null}
                <Button
                  variant="text"
                  color="primary"
                  classes={{ root: styles['button--show-more'] }}
                  disabled={loadingItems}
                  onClick={() => {
                    onLoadMore(false)
                  }}
                >
                  <Trans ns="UserOffers" id="ShowMore" msg="Show more" />
                </Button>
              </>
            )}
          </div>
        ) : null}
      </div>
      {deleteStep === ACTION_STEP.TO_DO || deleteStep === ACTION_STEP.IN_PROGRESS ? (
        <Dialog
          open
          withLogo={false}
          onClose={onDeleteCanceled}
          displayMode={DISPLAY_MODE.fullScreenMobileOnly}
          classes={{ root: styles['remove-popup'] }}
        >
          <div className={styles['remove-popup__content']}>
            <Typography variant="subtitle1">Please confirm that you want to remove these offers.</Typography>
            <div className={styles['remove-popup__navi']}>
              <Button variant="contained" color="default" onClick={() => onDeleteCanceled()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onDeleteConfirmed()}
                disabled={deleteStep === ACTION_STEP.IN_PROGRESS}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>
      ) : null}
      {archiveStep === ACTION_STEP.TO_DO || archiveStep === ACTION_STEP.IN_PROGRESS ? (
        <Dialog
          open
          withLogo={false}
          onClose={onArchiveCanceled}
          displayMode={DISPLAY_MODE.fullScreenMobileOnly}
          classes={{ root: styles['remove-popup'] }}
        >
          <div className={styles['remove-popup__content']}>
            <Typography variant="subtitle1">Please confirm that you want to archive these offers.</Typography>
            <div className={styles['remove-popup__navi']}>
              <Button variant="contained" color="default" onClick={() => onArchiveCanceled()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onArchiveConfirmed()}
                disabled={archiveStep === ACTION_STEP.IN_PROGRESS}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Dialog>
      ) : null}
      {loadingStep === ACTION_STEP.TO_DO || loadingStep === ACTION_STEP.IN_PROGRESS ? (
        <Dialog
          open
          withLogo={false}
          hideCloseIcon
          displayMode={DISPLAY_MODE.fullScreenMobileOnly}
          classes={{ root: styles['loading-popup'] }}
        >
          <div className={styles['loading-popup__content']}>
            <Typography variant="subtitle1">Please wait...</Typography>
            <div className={styles['loading-popup__spinner']}>
              <LoadingSpinner />
            </div>
          </div>
        </Dialog>
      ) : null}
    </>
  )
}

export default UserOffersView
