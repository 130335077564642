import { routes } from '../components/Link'
import { resolveRoute } from '../components/Link/linkResolver'
import OfferType from '../interfaces/Offer.type'

const createOfferUrl = (offer: OfferType) => {
  const currentLocation = window.location

  if (currentLocation && offer.sku && offer.slug) {
    const path = resolveRoute(routes.offer, { sku: offer.sku, slug: offer.slug })
    const port = currentLocation.port ? `:${currentLocation.port}` : ''
    return `${currentLocation.protocol}//${currentLocation.hostname}${port}${path}`
  }

  return ''
}

export default createOfferUrl
