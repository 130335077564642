import { FormikProps } from 'formik'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { FormType } from './SellForm.types'
import SellFormView from './SellFormView'
import UserContext from '../../contexts/UserContext'
import { FileUploadListType, SellTopic } from '../../interfaces/Contact.type'
import { UserType } from '../../interfaces/User.type'
import * as api from '../../services/contact/contact.api'
import handleError from '../../utils/handleError'
import notify from '../Notification/notify'
import Trans from '../Trans'

const defaultProps = Object.freeze({
  language: 'en' as string,
})

type Props = typeof defaultProps & {
  topics: SellTopic[]
}

const FILE_IMAGE_MAX_SIZE = 2560 //kB
const FILE_IMAGE_MIN_SIZE = 5 //kB

const onFileSizeError = (tooBig: boolean, tooSmall: boolean) => {
  if (tooSmall) notify.error(<Trans ns="SellForm" id="ImageTooSmall" msg="Image size must be at least 5 kB" />)
  if (tooBig) notify.error(<Trans ns="SellForm" id="ImageTooSmall" msg="Image size cannot be at bigger than 5120 kB" />)
}

const onFileTypeError = () => {
  notify.error(<Trans ns="SellForm" id="ImageFileTypeError" msg="File extension must be one of JPG, JPEG, PNG, DOC, DOCX or XLS" />)
}

const onFileError = () => {
  notify.error(<Trans ns="SellForm" id="ImageUnknownError" msg="Unknown error occured, please try again." />)
}

const SellForm = (props: Props) => {
  const user: UserType = useContext(UserContext)
  const [files, setFiles] = useState<FileUploadListType>({})

  const onFileUpload = (file: File, base64: string, imageKey: string, main: boolean = false) => {
    const newFiles = _.cloneDeep(files)
    newFiles[imageKey] = {
      data: base64,
      filename: file.name,
    }
    setFiles(newFiles)
  }

  const onFormSubmit = async (values: FormType, formikBag: FormikProps<FormType>) => {
    try {
      await api.sendSellMessage({
        name: values.name,
        email: values.email,
        subject: props.topics.find(topic => topic.id === values.topic)?.name || '',
        content: values.description,
        context: {
          phone: values.phone || '',
        },
        files: Object.values(files),
      })
      formikBag.setSubmitting(false)
      formikBag.resetForm()
      setFiles({})
      notify.success(<Trans ns="SellForm" id="SentSuccessfully" msg="Thanks for contacting us! We will be in touch with you shortly." />)
    } catch (error) {
      formikBag.setSubmitting(false)
      handleError(error, formikBag)
    }
  }

  return (
    <SellFormView
      user={user}
      topics={props.topics}
      files={files}
      minFileSize={FILE_IMAGE_MIN_SIZE}
      maxFileSize={FILE_IMAGE_MAX_SIZE}
      onFileSizeError={onFileSizeError}
      onFileTypeError={onFileTypeError}
      onFileError={onFileError}
      onFileUpload={onFileUpload}
      onFormSubmit={onFormSubmit}
    />
  )
}

SellForm.defaultProps = defaultProps

export default SellForm
