import Yup from '../../../utils/yup'
//import Yup from '@miten/whisky-market-shared/src'
const schema = Yup.object().shape({
  //content: Yup.chat().content(),
  content: Yup.string()
    .required('Requried')
    .min(1, 'Must contain at least 1 sign')
    .max(5000, 'Maxium length is 5 000 signs'),
})

export default schema
