import {
  MenuItem,
  Paper,
  Popper,
  TextField
  } from '@material-ui/core'
import { InputProps } from '@material-ui/core/Input'
import { InputLabelProps } from '@material-ui/core/InputLabel'
import { TextFieldProps } from '@material-ui/core/TextField'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useRef } from 'react'
import Autosuggest, { AutosuggestProps } from 'react-autosuggest'
import styles from './AutocompleteField.module.scss'

export type AutocompleteClassesType = {
  container?: string
  suggestionsContainerOpen?: string
  suggestionsList?: string
  suggestion?: string
}

const defaultProps = Object.freeze({
  autocompleteClasses: {} as AutocompleteClassesType,
  variant: 'outlined',
  InputLabelProps: { shrink: true } as InputLabelProps,
  withMargins: true as boolean,
  multiSection: false,
  renderSuggestion: (suggestion: any, { query, isHighlighted }: { query: string; isHighlighted: boolean }) => {
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div className={styles['value']}>{suggestion}</div>
      </MenuItem>
    )
  },
})

type Props<TSuggestionType, TSectionType> = typeof defaultProps &
  AutosuggestProps<TSuggestionType, TSectionType> &
  TextFieldProps & {
    formControlProps?: Object
    onChange(event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>): void
    InputProps?: InputProps
    inputRef?: React.MutableRefObject<any>
    fullWidth?: boolean
    submitOnEnter: boolean
  }

const AutocompleteField = <TSuggestionType, TSectionType>(props: Props<TSuggestionType, TSectionType>) => {
  const refPopper = useRef<any>(props.inputRef!)
  const allInputProps = _.merge(
    {
      id: props.name,
      autoComplete: 'off',
      notched: false,
    },
    props.InputProps,
  )

  const renderTextComponent = (innerProps: any) => {
    const classes = {
      root: classNames({ [styles['autocomplete--with-margins']]: props.withMargins }, styles.input, props.classes?.root),
    }
    return (
      <TextField
        variant={props.variant}
        classes={classes}
        {...props.inputProps}
        {...innerProps}
        label={props.label}
        InputProps={allInputProps}
        InputLabelProps={props.InputLabelProps}
        {..._.pick(props, ['error', 'helperText'])}
      />
    )
  }

  const autoComplete = (
    <Autosuggest<TSuggestionType, TSectionType>
      {..._.omit(props, ['InputProps', 'InputLabelProps', 'withMargins', 'formControlProps'])}
      renderInputComponent={renderTextComponent}
      inputProps={{
        placeholder: _.get(props, 'placeholder', ''),
        value: _.get(props, 'value', '') as string,
        onChange: (event, { newValue }) => {
          //@ts-ignore TODO: bad practice!
          props.onChange(event)
        },
        //@ts-ignore
        onBlur: props.onBlur,
        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (props.submitOnEnter) {
            return;
          }

          const key = event.key || event.keyCode;

          if (key === 'Enter' || key === 13) {
            event.preventDefault()
          }
        },
        //@ts-ignore
        inputRef: refPopper,
      }}
      theme={{
        container: classNames(
          styles['container'],
          { [styles['container--full-width']]: props.fullWidth },
          props.autocompleteClasses?.container,
        ),
        suggestionsContainerOpen: classNames(styles['suggestions__container--open'], props.autocompleteClasses?.suggestionsContainerOpen),
        suggestionsList: classNames(styles['suggestions__list'], props.autocompleteClasses?.suggestionsList),
        suggestion: classNames(styles['suggestion'], props.autocompleteClasses?.suggestion),
      }}
      renderSuggestionsContainer={options => {
        return (
          <Popper
            anchorEl={refPopper.current}
            open={Boolean(options.children)}
            placement="bottom-start"
            style={{
              zIndex: 2000,
            }}
          >
            <Paper
              style={{
                width: refPopper.current ? refPopper.current.clientWidth : null,
              }}
              square
              {...options.containerProps}
            >
              {options.children}
            </Paper>
          </Popper>
        )
      }}
    />
  )

  return autoComplete
}

AutocompleteField.defaultProps = defaultProps

export default AutocompleteField
