import React from 'react'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import { routes } from '../../components/Link/Link'
import LoadingSpinner from '../../components/LoadingSpinner'
import OfferEditForm from '../../components/OfferForm/OfferEditForm'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import useAuth from '../../hooks/auth/auth'
import * as api from '../../services/offer/offer.api'
import OfferType, {
  OfferFieldsValuesType,
  OfferSelectFieldType,
  SELECT_FIELD_NAME_AVAILABILITY,
  SELECT_FIELD_NAME_CASK_TYPE,
  SELECT_FIELD_NAME_BOTTLE_TYPE,
  SELECT_FIELD_NAME_CATEGORY,
  SELECT_FIELD_NAME_CONDITION,
  SELECT_FIELD_NAME_FILL_LEVEL,
  SELECT_FIELD_NAME_PACKAGE,
} from '../../interfaces/Offer.type'

type UrlParamsType = {
  id: string
}

const OfferEditPage = () => {
  const params = useParams<UrlParamsType>()
  const { isAuth } = useAuth()
  const [offer, setOffer] = React.useState<OfferType | null>(null)
  const [fieldsValues, setFieldsValues] = React.useState<OfferFieldsValuesType>()

  React.useEffect(() => {
    api
      .searchFieldsHints()
      .then(response => {
        setFieldsValues({
          bottleType: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_BOTTLE_TYPE)[0],
          category: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CATEGORY)[0],
          caskType: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CASK_TYPE)[0],
          condition: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CONDITION)[0],
          fillLevel: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_FILL_LEVEL)[0],
          availability: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_AVAILABILITY)[0],
          package: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_PACKAGE)[0],
        })
      })
      .catch(error => console.log(error))

    api
      .readOffer(params.id)
      .then(response => {
        setOffer(response?.data!)
      })
      .catch(error => console.error(error))
  }, [])

  if (!isAuth) {
    return <Redirect to={routes.signIn} />
  }

  return (
    <PageLayout classes={pageStyles['content--sides-padding']}>
      {fieldsValues && offer ? <OfferEditForm isMobile={isMobile} offer={offer} fieldsValues={fieldsValues} /> : <LoadingSpinner center />}
    </PageLayout>
  )
}

export default OfferEditPage
