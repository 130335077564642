import _ from 'lodash'
import qs from 'qs'
import React from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import schema from './OfferEditForm.schema'
import OfferType, { OfferFieldsValuesType, OfferFormType, OfferImageUploadType } from '../../../interfaces/Offer.type'
import * as api from '../../../services/offer/offer.api'
import { routes } from '../../Link'
import Trans from '../../Trans'
import OfferForm from '../OfferForm'

const defaultProps = Object.freeze({
  isMobile: false as boolean,
  language: 'en' as string,
})

type Props = typeof defaultProps & {
  fieldsValues: OfferFieldsValuesType
  offer: OfferType
}

const OfferEditForm = (props: Props) => {
  const history = useHistory()
  const query = qs.parse(useLocation().search, { ignoreQueryPrefix: true })
  const backPath = (query?.back_path as string | null) || routes.myOffers

  const handleFormSubmit = async (values: OfferFormType): Promise<string> => {
    const payload = _.cloneDeep(values)
    const result = await api.updateOffer(props.offer.id as string, payload)

    if (!result.data.id) {
      throw new Error('Offer id was not found')
    }

    return backPath
  }

  const handleBackClick = () => {
    history.push(backPath)
  }

  return (
    <OfferForm
      {...props}
      onFormSubmit={handleFormSubmit}
      onBackClick={handleBackClick}
      heading={<Trans ns="OfferEditForm" id="Heading" msg="Edit your offer" />}
      cta={<Trans ns="OfferForm" id="ButtonEditOffer" msg="Update offer" />}
      confirmationMessage="'Congratulations! Your offer has been successfully edited. It will go live shortly, once it has been reviewed by one of our Team members.'"
      schema={schema}
    />
  )
}

OfferEditForm.defaultProps = defaultProps

export default OfferEditForm
