import UserType, { UserDataType } from '../../interfaces/User.type'

export enum ActionTypes {
  READ_PROFILE_SUCCESS = 'READ_PROFILE_SUCCESS',
  READ_USER_DATA_SUCCESS = 'READ_USER_DATA_SUCCESS',
}

export interface ReadProfileSuccessActionInterface {
  type: ActionTypes.READ_PROFILE_SUCCESS
  payload: UserType
}

export interface ReadUserDataSuccessActionInterface {
  type: ActionTypes.READ_USER_DATA_SUCCESS
  payload: UserDataType
}

export type ChangePasswordPayloadType = {
  oldPassword: string
  newPassword: string
  repeatPassword?: string
}

export type UserFormPayloadType = {
  email: string
  username: string
  name: string
  surname: string
  birthday: number
  address1: string
  address2: string
  postCode: string
  city: string
  country: string
  phoneCode: string
  phone: string
  aboutMe: string
  verification: boolean
  companyName: string
  gst: boolean
  gstRate: string | number
  taxId: string
  website: string
}

export type ActionsType = ReadProfileSuccessActionInterface | ReadUserDataSuccessActionInterface
export type State = UserType
