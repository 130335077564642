import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { Redirect } from 'react-router-dom'
import styles from './SignInPage.module.scss'
import Link, { routes } from '../../components/Link'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import SignInForm from '../../components/SignInForm'
import Trans from '../../components/Trans'
import useAuth from '../../hooks/auth'

type Props = {}

const SignInPage = (props: Props) => {
  const { isAuth } = useAuth()
  if (isAuth) {
    return <Redirect to={routes.home} />
    return null
  }
  return (
    <PageLayout classes={classNames(styles['sign-in-page'], pageStyles['content--sides-padding'])}>
      <div className={styles['content']}>
        <div className={styles['info__container']}>
          <div className={styles['promo']}>
            <div className={styles['promo__text']}>
              <div>
                <b>BUY &amp; SELL</b> <span>SPIRITS ON-LINE</span>
              </div>
            </div>
          </div>
          <div className={styles['info']}>
            <Trans id="SignInPage.NewUser" msg="You have to be logged in to fully benefit from our platform." ns="SignInPage" />
            <br /><br />
            <div className={styles['info__line--1']}>
              Regardless who you are - a whisky trader a private collector, or a shop owner this is the place to be!
            </div>
            <div className={styles['info__line--2']}>
              The world’s marketplace for all whisky and rum enthusiasts searching for, selling or buying spirits.
            </div>
          </div>
          <div className={styles['enjoy']}>
            <div className={styles['enjoy__arrows']}>
              <div className={styles['enjoy__arrow']} />
              <div className={styles['enjoy__arrow']} />
            </div>
            <div className={styles['enjoy__lines']}>
              <span className={classNames(styles['enjoy__line'], styles['enjoy__line--1'])}>SIGN IN</span>
              <span className={classNames(styles['enjoy__line'], styles['enjoy__line--2'])}>ENJOY OUR PLATFORM</span>
            </div>
          </div>
          <div className={styles['new-user']}>
            <Trans id="SignInPage.DontHaveAccount" msg="Don’t have an account yet?" ns="SignInPage" />
            &nbsp;
            <Link to={routes.signUp} classes="primary">
              <Trans id="SignInPage.CreateAnAccount" msg="Create an account in just 15 seconds" ns="SignInPage" />
            </Link>
          </div>
        </div>
        <div className={styles['form__container']}>
          <Typography variant="h3">Sign In</Typography>
          <p>&nbsp;</p>
          <SignInForm />
        </div>
      </div>
    </PageLayout>
  )
}

export default SignInPage
