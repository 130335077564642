import { createMuiTheme, jssPreset } from '@material-ui/core/styles'
import { create, GenerateId } from 'jss'
import { Rule, StyleSheet } from 'jss/src/index'
import MuiButtonStyle from './mui/MuiButton'
import MuiDialog from './mui/MuiDialog'
import MuiFormControlStyle from './mui/MuiFormControl'
import MuiFormHelperTextStyle from './mui/MuiFormHelperText'
import MuiFormLabelStyle from './mui/MuiFormLabel'
import MuiInputLabelStyle from './mui/MuiInputLabel'
import MuiOutlinedInputStyle from './mui/MuiOutlinedInput'
import MuiSelectStyle from './mui/MuiSelect'
import MuiSliderStyle from './mui/MuiSlider'
import MuiTextFieldStyle from './mui/MuiTextField'
import MuiTypography from './mui/MuiTypography'
import * as styles from './shared.module.scss'

const createGenerateId = () => {
  return (rule: Rule, sheet?: StyleSheet) => {
    const options = rule.options.parent?.options as any
    const name = options.name ? options.name : 'NN'
    return `WM-${name}-${rule.key}`
  }
}
console.log('s', styles)
const whiskyTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: parseInt(styles['rwd__xs'], 10),
      sm: parseInt(styles['rwd__sm'], 10),
      md: parseInt(styles['rwd__md'], 10),
      lg: parseInt(styles['rwd__lg'], 10),
      xl: parseInt(styles['rwd__xl'], 10),
    },
  },
  palette: {
    type: 'light',
    error: {
      main: styles['color__error'],
    },
    primary: {
      light: styles['color__primary'],
      main: styles['color__primary'],
      dark: styles['color__primary--dark'],
      contrastText: styles['color__text--primary'],
    },
    secondary: {
      light: styles['color__secondary'],
      main: styles['color__secondary'],
      dark: styles['color__secondary--dark'],
      contrastText: styles['color__text--secondary'],
    },
  },

  overrides: {
    MuiButton: MuiButtonStyle,
    MuiFormLabel: MuiFormLabelStyle,
    MuiOutlinedInput: MuiOutlinedInputStyle,
    MuiInputLabel: MuiInputLabelStyle,
    MuiFormHelperText: MuiFormHelperTextStyle,
    MuiTextField: MuiTextFieldStyle,
    MuiSelect: MuiSelectStyle,
    MuiSlider: MuiSliderStyle,
    MuiFormControl: MuiFormControlStyle,
    MuiTypography,
    MuiDialog,
  },

  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },

  shape: {
    //borderRadius: styles['border__radius'],
  },
})

export default whiskyTheme
export { createGenerateId }
