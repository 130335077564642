import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CONVERSATION_TYPE } from './Chat.types'
import ChatView from './ChatView'
import Conversation from './Conversation'
import { ConversationType } from '../../interfaces/Chat.type'
import * as api from '../../services/chat/chat.api'
import * as userActions from '../../services/user/user.actions'
import * as userApi from '../../services/user/user.api'

type Props = {
  conversationId?: string
}

const Chat = (props: Props) => {
  const dispatch = useDispatch()
  const { conversationId } = props
  const [conversationType, setConversationType] = useState<CONVERSATION_TYPE>(CONVERSATION_TYPE.INBOX)
  const [conversations, setConversations] = useState<ConversationType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [activeConversation, setActiveConversation] = useState<ConversationType | null>(null)

  const setConversation = async (conversation: ConversationType | null) => {
    setActiveConversation(conversation)
  }

  const onArchive = async () => {
    if (activeConversation && activeConversation.id) {
      let response = null
      try {
        response = await api.updateConversationArchive(activeConversation.id)
        setActiveConversation(response.data)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const setConversationBox = async (type: CONVERSATION_TYPE) => {
    setConversationType(type)
    setLoading(true)
    //api req
    let response = null

    try {
      if (type === CONVERSATION_TYPE.INBOX) {
        response = await api.readInbox()
      } else if (type === CONVERSATION_TYPE.OUTBOX) {
        response = await api.readOutbox()
      } else if (type === CONVERSATION_TYPE.ARCHIVE) {
        response = await api.readArchive()
      }
      setConversations(response?.data)
    } catch (error) {
      setConversations([])
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetch = async () => {
      let response = null
      if (conversationId) {
        try {
          response = await api.readConversation(conversationId)
          await api.updateConversationReadAt(conversationId)
          setActiveConversation(response.data)
          setTimeout(async () => {
            const userResponse = await userApi.readUser()
            dispatch(userActions.readUserDataSuccess(userResponse.data))
          }, 2000)
        } catch (error) {
          console.error(error)
        }
      } else {
        setConversationBox(CONVERSATION_TYPE.INBOX)
        setActiveConversation(null)
      }
    }
    fetch()
  }, [conversationId])

  if (activeConversation) {
    return (
      <Conversation
        type={conversationType}
        isLoading={loading}
        conversation={activeConversation}
        setConversation={setConversation}
        onArchive={onArchive}
      />
    )
  }

  return (
    <ChatView
      setConversationBox={setConversationBox}
      conversationType={conversationType}
      conversations={conversations}
      isLoading={loading}
      activeConversation={activeConversation}
    />
  )
}

export default Chat
