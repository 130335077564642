import OfferType, { OfferFormType } from '../../interfaces/Offer.type'

export interface State {
  favorites: Array<{
    offerId: string
    status: 'added' | 'temporarily-removed'
  }>
}

export enum types {
  CLEAR_FAVORITE_OFFERS = 'CLEAR_FAVORITE_OFFERS',
  SET_FAVORITE_OFFERS = 'SET_FAVORITE_OFFERS',
  ADD_OFFER_TO_FAVORITE = 'ADD_OFFER_TO_FAVORITE',
  TEMPORARILY_REMOVE_OFFER_FROM_FAVORITE = 'TEMPORARILY_REMOVE_OFFER_FROM_FAVORITE',
  REMOVE_OFFER_FROM_FAVORITE = 'REMOVE_OFFER_FROM_FAVORITE',
}

export interface ClearFavoriteOffers {
  type: types.CLEAR_FAVORITE_OFFERS
}

export interface SetFavoriteOffers {
  type: types.SET_FAVORITE_OFFERS
  offerIds: string[]
}

export interface AddOfferToFavorite {
  type: types.ADD_OFFER_TO_FAVORITE
  offer: OfferType
}

export interface RemoveOfferFromFavorite {
  type: types.REMOVE_OFFER_FROM_FAVORITE
  offer: OfferType
}

export interface TemporarilyRemoveOfferFromFavorite {
  type: types.TEMPORARILY_REMOVE_OFFER_FROM_FAVORITE
  offer: OfferType
}

export type ActionTypes = ClearFavoriteOffers | SetFavoriteOffers | AddOfferToFavorite | RemoveOfferFromFavorite | TemporarilyRemoveOfferFromFavorite

export type ReadItemFromBasePayloadType = {
  query: string
  type: string
}

export type SearchDistilleryPayloadType = {
  query: string
}

export type CreateOfferPayloadType = OfferFormType

export type UpdateOfferPayloadType = OfferFormType
