import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React from 'react'
import ChildrenType from '../../../../interfaces/Children.type'
import { FiltersType } from '../../../../interfaces/Filters.type'
import FormRadioField from '../../../Form/FormRadioField'

type Classes = {
  root?: string
}

type Props = {
  form: FormikProps<FormikValues | any>
  name: FieldNameType
  placeholder?: string
  label?: string
  classes?: Classes
  children: ChildrenType
}

type FieldNameType = keyof FiltersType

const RadioField = (props: Props) => {
  if (!props.name || props.form.values[props.name] === undefined) {
    return null
  }

  return (
    <FormRadioField
      classes={{ root: props.classes?.root }}
      form={props.form}
      label={props.label!}
      field={{
        name: props.name,
        value: props.form.values[props.name],
        onChange: props.form.handleChange,
        onBlur: props.form.handleBlur,
      }}
      fullWidth
    >
      {props.children}
    </FormRadioField>
  )
}

export default RadioField
