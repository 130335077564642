import { CheckboxProps } from '@material-ui/core/Checkbox'
import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React from 'react'
import SwitchControl from '../../UI/SwitchControl'

type Props = {
  field: {
    name: string
    value: any
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onBlur: (event: React.ChangeEvent<HTMLButtonElement>) => void
  }
  form: FormikProps<FormikValues | any>
  label: React.ReactNode | string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  emptyHelperText?: boolean
  onChange?: (value: string, checked: boolean) => void
  classes?: object
  InputProps?: CheckboxProps
}

const FormSwitchField: React.FC<Props> = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
  return (
    <SwitchControl
      {..._.pick(props, ['InputProps', 'type', 'label', 'fullWidth', 'classes', 'children', 'emptyHelperText'])}
      {...fields}
      onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        fields.onChange(event)
        props.onChange ? props.onChange(event.currentTarget.value, checked) : _.noop
      }}
      error={Boolean(_.has(touched, fields.name) && _.has(errors, fields.name))}
      helperText={_.has(touched, fields.name) && _.has(errors, fields.name) ? (_.get(errors, fields.name) as string) : ''}
    />
  )
}

export default FormSwitchField
