import classNames from 'classnames'
import React from 'react'
import styles from './FullscreenImageLayout.module.scss'
import ChildrenType from '../../../interfaces/Children.type'

type Classes = {
  root?: string
  background?: string
  image?: string
  container?: string
}

type Props = {
  alt?: string
  children?: ChildrenType
  classes?: Classes
  image: string
}

const FullscreenImageLayout = (props: Props) => {
  return (
    <div className={classNames(props.classes?.root)}>
      <div className={classNames(styles['background'], props.classes?.container)}>
        <img className={classNames(styles['image'], props.classes?.image)} src={props.image} alt={props.alt} />
      </div>
      <div className={classNames(styles['container'], props.classes?.container)}>{props.children}</div>
    </div>
  )
}

export default FullscreenImageLayout
