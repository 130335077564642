import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './ContactPage.module.scss'
import LoadingSpinner from '../../components/LoadingSpinner'
import PageLayout from '../../components/PageLayout/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import SellForm from '../../components/SellForm/SellForm'
import Trans from '../../components/Trans'
import { SellTopic } from '../../interfaces/Contact.type'
import * as api from '../../services/contact/contact.api'

const SellPage = () => {
  const [topics, setTopics] = React.useState<SellTopic[]>()
  React.useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await api.getSellTopics()
        setTopics(response.data.topics)
      } catch (err) {
        console.error(err)
      }
    }

    fetchTopics()
  }, [])

  const bodyClass = { root: styles['item__body'] }
  return (
    <PageLayout classes={pageStyles['content--sides-padding']}>
      <div className={styles['container']}>
        <Typography variant="h1" classes={{ root: styles['heading'] }}>
          <Trans ns="Contact" id="Heading" msg="Contact" />
        </Typography>
        <Typography variant="body1" classes={bodyClass}>
          <b>Address</b>
          <br />
          JP Whiskymarket.com GmbH
          <br />
          Oehlertring 1<br />
          12169 Berlin
          <br />
          Germany
          <br />
          <br />
          <b>Phone</b>
          <br />
          +49 (0) 152 5305 77 55
          <br />
          <br />
          <b>E-mail</b>
          <br />
          info@whiskymarket.com
        </Typography>
        <Typography variant="h5" classes={{ root: styles['heading'] }}>
          <Trans ns="Contact" id="FormHeading" msg="Send us a message" />
        </Typography>
        {topics ? <SellForm topics={topics} /> : <LoadingSpinner center />}
      </div>
    </PageLayout>
  )
}

SellPage.getInitialProps = async function () {
  return {
    topics: await api.getSellTopics(),
  }
}

export default SellPage
