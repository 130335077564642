import { CreateMessagePayloadType } from './chat.types'
import { ConversationType } from '../../interfaces/Chat.type'
import axios from '../../utils/axios'

export function readConversation(id: string) {
  return axios({
    method: 'GET',
    url: `/chat/conversation/${id}`,
  })
}

export function readInbox() {
  return axios({
    method: 'GET',
    url: '/chat/inbox',
  })
}

export function readOutbox() {
  return axios({
    method: 'GET',
    url: '/chat/outbox',
  })
}

export function readArchive() {
  return axios({
    method: 'GET',
    url: '/chat/archive',
  })
}

export function createConversation(offerId: string) {
  return axios({
    method: 'POST',
    url: '/chat/conversation',
    data: {
      offerId,
    },
  })
}

export function createMessage(payload: CreateMessagePayloadType) {
  return axios({
    method: 'POST',
    url: `/chat/conversation/${payload.conversationId}/message`,
    data: payload,
  })
}

export function updateConversationArchive(conversationId: string) {
  // /chat/conversation/:id:/archive
  return axios({
    method: 'PATCH',
    url: `/chat/conversation/${conversationId}/archive`,
    data: {},
  })
}

export function updateConversationReadAt(conversationId: string) {
  return axios({
    method: 'PATCH',
    url: `/chat/conversation/${conversationId}/read`,
    data: {},
  })
}
