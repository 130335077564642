import React from 'react'
import UserFavoriteOffersView from './UserFavoriteOffersView'
import OfferType from '../../../interfaces/Offer.type'
import { UserDataType } from '../../../interfaces/User.type'

type Props = {
  user: UserDataType
  items: OfferType[]
}

const UserFavoriteOffers = (props: Props) => {
  return <UserFavoriteOffersView user={props.user} items={props.items} />
}

export default UserFavoriteOffers
