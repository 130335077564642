import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from '../TosPage.module.scss'
/* eslint-disable react/no-unescaped-entities */

const TosPageEN = () => {
  const bodyClass = { root: styles['list__text'] }
  return (
    <>
      <Typography variant="h2" classes={{ root: classNames(styles['heading'], styles['text--center']) }}>
        General terms and conditions of use
        <br />
        and customer information
        <br />
        <a href="/" target="_blank" rel="noreferrer" className="primary">
          www.whiskymarket.com
        </a>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Welcome to whiskymarket.com,
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        JP Whiskymarket.com GmbH operates a website for trading in high-quality spirits under whiskymarket.com.
        <br />
        JP Whiskymarket.com GmbH sells goods itself via the website. In this regard, the general terms and conditions and customer
        information apply, which you will find under section B.
        <br />
        Third parties also have the opportunity to present their goods on the website and to use the website as an advertising platform. In
        this regard, the general terms and conditions and customer information apply, available under section A. Third party sales do not
        take place via the website.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="h3" classes={{ root: styles['subheading'] }}>
        A. General Terms of Use for{' '}
        <a href="/" target="_blank" rel="noreferrer" className="primary">
          www.whiskymarket.com
        </a>{' '}
        as an advertising platform
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <div className={styles['tos']}>
          <ol>
            <li>
              Basic provisions
              <ol>
                <li>
                  The following terms of use apply to the use of the advertising platform operated under the domain www.whiskymarket.com
                  (hereinafter referred to as "whiskymarket.com") by JP Whiskymarket.com GmbH (Oehlertring 1, 12169 Berlin; hereinafter
                  referred to as "operator") registered providers (hereinafter referred to as "providers") and customers (hereinafter
                  referred to as "customers"). In the following, providers and customers are collectively referred to as "users".
                </li>
                <li>
                  A consumer within the meaning of the following regulations is any natural person who concludes a legal transaction for
                  purposes that can predominantly neither be attributed to their commercial nor their independent professional activity. An
                  entrepreneur according to § 14 Paragraph 1 BGB is any natural or legal person or a legal partnership who, when concluding
                  a legal transaction, is exercising their independent professional or commercial activity.
                </li>
                <li>Unless otherwise agreed, the inclusion of any own conditions used by the user is contradicted.</li>
              </ol>
            </li>
            <li>
              Subject matter of the contract
              <ol>
                <li>
                  Whiskymarket.com offers a digital advertising platform on which providers can place ads for the sale of goods, especially
                  high-quality spirits, for a fee. The platform serves exclusively to present offers for the sale of goods. Contracts
                  between providers and customers are not concluded via the platform.
                </li>
                <li>
                  The information on the offers of the providers do not represent any legally binding offers from whiskymarket.com.
                  Whiskymarket.com therefore does not guarantee the prices, availability or quality of the goods offered.
                </li>
                <li>
                  Whiskymarket.com only provides the technical option for the providers and customers to use the platform. The platform is
                  used exclusively for the presentation of goods.
                </li>
                <li>
                  The details, in particular the essential features of the operator's products and services for providers and customers, can
                  be found in the product descriptions and the additional information on whiskymarket.com.
                </li>
              </ol>
            </li>
            <li>
              Conclusion of the contract
              <ol>
                <li>The services of whiskymarket.com are free of charge for customers.</li>
                <li>The prerequisite for the use of whiskymarket.com by providers and customers is registration on the platform.</li>
                <li>
                  <u>Registration as a customer</u>
                  <p>
                    The offer of the operator on whiskymarket.com to justify a usage contract with customers is aimed at consumers and
                    entrepreneurs and is binding.
                  </p>
                  <p>
                    A customer can use the “Sign in” button to register on whiskymarket.com and create a user account. To do this, the
                    customer must enter their contact details after calling up the registration page and specify their email address,
                    password and date of birth. Before submitting his contract declaration, the customer has the option at any time to
                    correct his entries, delete them or cancel the registration by closing the browser window or selecting the "back"
                    function of the browser. By submitting his data by confirming the "Sign up" button, the customer accepts the operator's
                    offer to conclude the contract of use.
                  </p>
                </li>
                <li>
                  <u>Registration as a provider</u>
                  <p>
                    The operator's offer on whiskymarket.com to establish a usage contract with providers is aimed at consumers and
                    entrepreneurs and is binding.
                  </p>
                  <p>
                    A provider can use the “Sign in” button to register on whiskymarket.com and create a user account. To do this, the
                    provider must enter his email address and date of birth and set a password after calling up the registration page.
                    Before submitting his contract declaration, the provider has the option at any time to correct or delete his entries or
                    to cancel the registration by closing the browser window or selecting the "back" function of the browser. By sending his
                    data by confirming the "Sign up" button, the provider accepts the operator's offer to conclude the contract of use.
                  </p>
                </li>
                <li>
                  The processing of the order and the transmission of all information required in connection with the conclusion of the
                  contract takes place by e-mail and is partly automated. The user must therefore ensure that the e-mail address he has
                  provided is correct, that the receipt of the e-mails is technically ensured and, in particular, is not prevented by SPAM
                  filters.
                </li>
              </ol>
            </li>
            <li>
              Prices and terms of payment
              <ol>
                <li>
                  If an advertisement is placed by the provider, the operator will charge the provider a fee. Details can be found on
                  whiskymarket.com. The prices published by the operator on whiskymarket.com at the time the contract is concluded apply.
                </li>
                <li>The operator bills the provider for the fees on a monthly basis.</li>
                <li>Unless otherwise stated, the fee is due for payment immediately after it is incurred.</li>
              </ol>
            </li>
            <li>
              Availability
              <ol>
                <li>The operator provides whiskymarket.com with an availability of 98% on a monthly average.</li>
                <li>
                  Excluded from this are maintenance times and times when the server is not available due to technical or other problems
                  that are beyond the control of the operator (force majeure, network disruption outside our network, fault of third
                  parties, etc.).
                </li>
                <li>The provisions of section 10 of these terms and conditions are not affected by this.</li>
              </ol>
            </li>
            <li>
              Use of the platform, restrictions on offering services, ranking
              <ol>
                <li>
                  The advertising texts as well as the images and videos used may refer exclusively to the products and services offered.
                </li>
                <li>
                  Providers who offer products to consumers in the course of their commercial or self-employed occupation are obliged to
                  provide them with the legally prescribed consumer protection information and to fulfill the existing labeling obligations.
                </li>
                <li>
                  In particular, the offering of products with references to pornographic or youth-endangering content, e.g. through
                  appropriate symbols or representations that violate or are suitable for religious feelings, criminal norms, personal
                  rights or other rights of third parties, is prohibited.
                </li>
                <li>
                  If the user violates the aforementioned regulations, the operator is particularly entitled to the rights according to
                  Section 9 of these terms of use and can lead to the provider or individual content being temporarily blocked or deleted.
                </li>
                <li>The ranking of the advertisements on whiskymarket.com is done chronologically. The latest ads are shown at the top.</li>
              </ol>
            </li>
            <li>
              Rights of use to content
              <ol>
                <li>
                  The content available on the platform is predominantly protected by copyright or other property rights and is the property
                  of the operator, the other users or other third parties who have made the respective content available. The compilation of
                  the content as such may be protected as a database or database work within the meaning of §§ 4 Paragraph 2, 87a Paragraph
                  1 of the Copyright Act. Users may only use this content in accordance with these terms of use and the conditions specified
                  on the platform.
                </li>
                <li>
                  By posting content, the user grants the operator a free and transferable right to use the respective content, in
                  particular
                  <p>
                    - to store the content on the operator's server and publish it, in particular to make it publicly available (for example
                    by displaying the content on the platform), this also including publication in newsletters, blogs and social media
                    channels used by the operator, such as
                  </p>
                  <p>
                    - for editing and reproduction, as far as this is necessary for the provision or publication of the respective content,
                    whereby this also includes publication in newsletters, blogs and social media channels used by the operator.
                  </p>
                </li>
                <li>
                  If the user removes the content posted by him from the platform, the right of use granted to the operator remains valid.
                  The operator remains entitled to keep copies made for backup and / or verification purposes. The rights of use granted to
                  the operator, other users of the platform or third parties remain unaffected insofar as the operator is still entitled to
                  continue to use newsletters, blog posts or entries on social media channels that have already been published.
                </li>
              </ol>
            </li>
            <li>
              Responsibility for content, user accounts and release from liability
              <ol>
                <li>
                  The offers published by the providers on whiskymarket.com are not checked for legal violations by the operator. The
                  respective provider is solely responsible for their correctness, completeness and legality. In particular, the operator
                  has no influence on the correctness, completeness and quality of the offers.
                </li>
                <li>
                  Users can publish their own content on the platform. You undertake not to transmit any data, the content of which violates
                  the rights of third parties (in particular copyrights, naming rights, trademark rights) or violates existing laws.
                </li>
                <li>
                  The operator can temporarily block or delete individual users or content of a user if the user publishes illegal content
                  or content that violates the terms of use or if there are specific indications of repeated illegal publication or if the
                  user violates the terms of use, in particular Section 7.
                </li>
                <li>
                  The operator will consider the legitimate interests of the user, in particular the fault, when selecting the measure and
                  justify the blocking or deletion of the user or the content to the user in text form.
                </li>
                <li>
                  In the event of a temporary blocking of a user or content, the operator will immediately release the user or content as
                  soon as the suspicion of a violation of a legal provision or a provision of these terms and conditions is excluded.
                </li>
                <li>
                  The users indemnify the operator from all claims of third parties that they assert due to the violation of their rights
                  through the publication of user materials on whiskymarket.com, in the newsletter or other advertising material in
                  accordance with the order. In addition to claims for damages, the exemption also includes the reimbursement of reasonable
                  costs for legal defense that arise due to the illegal or improper use of whiskymarket.com's services. The exemption
                  presupposes that a comparison or acknowledgment of third party claims is only made with the prior written consent of the
                  user.
                </li>
              </ol>
            </li>
            <li>
              Failure to perform and liability
              <ol>
                <li>
                  The operator's no-fault liability for initial defects in accordance with § 536a BGB is excluded. The operator is otherwise
                  liable according to the principles set out below.
                </li>
                <li>
                  The operator is fully liable for damage resulting from injury to life, limb or health. Furthermore, the operator is liable
                  without limitation in all cases of willful intent and gross negligence, in the case of fraudulent concealment of a defect,
                  in the event of a guarantee and in all other legally regulated cases.
                </li>
                <li>
                  If essential contractual obligations of the operator are affected, the operator's liability in the event of slight
                  negligence is limited to the foreseeable damage typical for the contract. Essential contractual obligations are
                  obligations that arise from the nature of the contract and the breach of which would jeopardize the achievement of the
                  purpose of the contract, as well as obligations that the contract imposes on the operator according to its content to
                  achieve the purpose of the contract, the fulfillment of which make the proper execution of the contract possible in the
                  first place and compliance with which the user can regularly rely on.
                </li>
                <li>
                  If minor contractual obligations are breached, the operator's liability for slightly negligent breaches of duty is
                  excluded.
                </li>
              </ol>
            </li>
            <li>
              Assignment and right of retention
              <ol>
                <li>
                  The assignment of claims against the operator to third parties is only possible with written consent. This applies in
                  particular to a transfer of the user account to a third party.
                </li>
                <li>
                  The user can only exercise a right of retention insofar as it concerns claims from the same contractual relationship.
                </li>
              </ol>
            </li>
            <li>
              Termination and Deletion
              <ol>
                <li>The contract for the use of the platform is concluded for an indefinite period.</li>
                <li>
                  The user has the option at any time to have his user account deleted by notifying the operator in text form (e.g. email)
                  and thus to terminate the contract. The operator can terminate the user contract vis-à-vis the user without giving reasons
                  with a notice period of 14 days.
                </li>
                <li>
                  The right to extraordinary termination for all parties remains unaffected. The operator has an extraordinary right of
                  termination, especially in the event of violations of these terms of use.
                </li>
                <li>The termination must be in text form (e.g. email) to be effective.</li>
              </ol>
            </li>
            <li>
              Contract language, contract text storage
              <ol>
                <li>The contract language is German.</li>
                <li>
                  The operator does not save the full text of the contract. Before submitting the user's registration, the contract data can
                  be printed out or electronically saved using the print function of the browser / email program. After receipt of the
                  registration request by the operator, the order data, the information required by law for distance selling contracts and
                  the general terms and conditions are sent to the user again by email.
                </li>
              </ol>
            </li>
            <li>
              Choice of law, place of performance, place of jurisdiction, alternative dispute resolution
              <ol>
                <li>
                  German law applies. The provisions of the UN sales law expressly do not apply. For consumers, this choice of law only
                  applies insofar as this does not remove the protection granted by mandatory provisions of the law of the state in which
                  the consumer is habitually resident (favourability principle).
                </li>
                <li>
                  The place of performance for all services from the business relationships with the operator and the place of jurisdiction
                  is the operator's registered office, provided that the user is not a consumer but a merchant, a legal entity under public
                  law or a special fund under public law. The same applies if the user does not have a general place of jurisdiction in
                  Germany or the EU or if the place of residence or habitual residence is not known at the time the action is brought. The
                  authority to appeal to the court at another legal place of jurisdiction remains unaffected.
                </li>
                <li>
                  The European Commission provides a platform for out-of-court online dispute resolution (OS platform), available at{' '}
                  <a href="https://ec.europa.eu/odr" target="_blank" rel="noreferrer" className="primary">
                    https://ec.europa.eu/odr
                  </a>
                  .
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="h3" classes={{ root: styles['subheading'] }}>
        B. Terms and Conditions and Customer Information for sales by JP Whiskymarket.com GmbH via{' '}
        <a href="/" target="_blank" rel="noreferrer" className="primary">
          www.whiskymarket.com
        </a>
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        I. General terms and conditions
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <div className={styles['tos']}>
          <ol>
            <li className={styles['normal']}>
              Basic Provisions
              <p>
                (1) The following terms and conditions apply to contracts that you conclude with us as a supplier (JP Whiskymarket.com GmbH)
                via the www.whiskymarket.com website. Unless otherwise agreed, the inclusion of your own terms and conditions, if
                applicable, is contradicted.
              </p>
              <p>
                (2) A consumer within the meaning of the following regulations is any natural person who concludes a legal transaction for
                purposes that can predominantly neither be attributed to their commercial nor their independent professional activity.
                Entrepreneur is any natural or legal person or a legal partnership who, when concluding a legal transaction, is exercising
                their independent professional or commercial activity.
              </p>
            </li>
            <li className={styles['normal']}>
              Conclusion of the contract
              <p>
                (1) The subject of the contract is the sale of goods. Our offers on the Internet are non-binding and not a binding offer to
                conclude a contract.
              </p>
              <p>
                (2) Your inquiries to prepare an offer are non-binding for you. We will make you a binding offer in text form (e.g. by
                email), which you can accept within 5 days.
              </p>
              <p>
                (3) The processing of the order and the transmission of all information required in connection with the conclusion of the
                contract is partially automated by e-mail. You must therefore ensure that the e-mail address you have stored with us is
                correct, that the receipt of e-mails is technically ensured and, in particular, is not prevented by SPAM filters.
              </p>
            </li>
            <li className={styles['normal']}>
              Right of Retention, Retention of Title
              <p>(1) You can only exercise a right of retention insofar as it concerns claims from the same contractual relationship.</p>
              <p>(2) The goods remain our property until the purchase price has been paid in full.m.</p>
              <p>(3) If you are an entrepreneur, the following also applies:</p>
              <p>
                a) We reserve title to the goods until all claims from the current business relationship have been settled in full. Prior to
                the transfer of ownership of the goods subject to retention of title, pledging or security transfer is not permitted.
              </p>
              <p>
                b) You can resell the goods in the ordinary course of business. In this case, you assign to us all claims in the amount of
                the invoice amount that you accrue from the resale, we accept the assignment. You are further authorized to collect the
                claim. However, if you fail to properly meet your payment obligations, we reserve the right to collect the claim ourselves.
              </p>
              <p>
                c) If the reserved goods are combined and mixed, we acquire joint ownership of the new item in the ratio of the invoice
                value of the reserved goods to the other processed items at the time of processing.
              </p>
              <p>
                d) We undertake to release the securities to which we are entitled at your request insofar as the realizable value of our
                securities exceeds the claim to be secured by more than 10%. The selection of the securities to be released is our
                responsibility.
              </p>
            </li>
            <li className={styles['normal']}>
              Warranty
              <p>(1) The statutory warranty rights apply..</p>
              <p>
                (2) As a consumer, you are requested to check the item for completeness, obvious defects and transport damage immediately
                upon delivery and to notify us and the freight forwarder of any complaints as soon as possible. If you fail to do so, this
                has no effect on your statutory warranty claims.
              </p>
              <p>(3) If you are an entrepreneur, the following applies in deviation from the above warranty regulations:</p>
              <p>
                a) Only our own information and the manufacturer's product description are deemed to be agreed as the quality of the item,
                but not other advertising, public promotions and statements by the manufacturer.
              </p>
              <p>
                b) In the event of defects, we guarantee, at our option, repair or subsequent delivery. If the elimination of the defect
                fails, you can either request a reduction in price or withdraw from the contract. The rectification of defects is deemed to
                have failed after an unsuccessful second attempt, unless something else arises, in particular from the nature of the item or
                the defect or other circumstances. In the event of repairs, we do not have to bear the increased costs that arise from the
                shipment of the goods to a location other than the place of performance, provided that the shipment does not correspond to
                the intended use of the goods.
              </p>
              <p>c) The warranty period is one year from delivery of the goods. The shortening of the deadline does not apply:</p>
              <p>
                {' '}
                - culpably caused damage attributable to us from injury to life, limb or health and other damage caused intentionally or
                through gross negligence;
              </p>
              <p> - as far as we have fraudulently concealed the defect or have given a guarantee for the quality of the item;</p>
              <p> - for things that have been used for a building in accordance with their normal use and have caused its defectiveness;</p>
              <p> - In the case of legal recourse claims that you have against us in connection with warranty rights.</p>
            </li>
            <li className={styles['normal']}>
              Choice of law, place of performance, place of jurisdiction
              <p>
                (1) German law applies. For consumers, this choice of law only applies insofar as this does not remove the protection
                granted by mandatory provisions of the law of the state in which the consumer is habitually resident (favourability
                principle)
              </p>
              <p>
                (2) The place of fulfillment for all services from the business relationships with us as well as the place of jurisdiction
                is our registered office, provided you are not a consumer but a merchant, legal entity under public law or public law
                special fund. The same applies if you do not have a general place of jurisdiction in Germany or the EU or if your place of
                residence or habitual abode is not known at the time the action is brought. The authority to appeal to the court at another
                legal place of jurisdiction remains unaffected.
              </p>
              <p>(3) The provisions of the UN Sales Convention expressly do not apply.</p>
            </li>
            <li className={styles['normal']}>
              Protection of minors
              <p>
                (1) When selling goods that are subject to the regulations of the Youth Protection Act, we only enter into contractual
                relationships with customers who have reached the legally prescribed minimum age. Existing age restrictions are indicated in
                the respective item description.
              </p>
              <p>
                (2) By submitting your order, you affirm that you have reached the legally required minimum age and that your details
                regarding your name and address are correct. You are obliged to ensure that only you or persons authorized by you to receive
                the delivery who have reached the legally required minimum receive the goods.
              </p>
              <p>
                (3) Insofar as we are obliged to carry out an age check due to the statutory provisions, we instruct the logistics service
                provider commissioned with the delivery to only hand over the delivery to persons who have reached the legally prescribed
                minimum age. In case of doubt we reserve the right to obtain age check of the person receiving the goods for age control.
              </p>
              <p>
                (4) Insofar as we state in the respective item description that you must be over the age of 18 to purchase the goods, above
                the legally prescribed minimum age, the above paragraphs 1-3 apply with the proviso that you are of legal age instead of the
                legally prescribed minimum age.
              </p>
            </li>
          </ol>
        </div>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        II. Customer information
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <div className={styles['tos']}>
          <ol>
            <li>
              Identity of the seller
              <Typography variant="body1" classes={bodyClass}>
                JP Whiskymarket.com GmbH
                <br />
                Oehlertring 1<br />
                12169 Berlin
                <br />
                Germany
                <br />
                <br />
                Telefon: +49 (0) 15 25 30 5 77 55
                <br />
                E-Mail: info@whiskymarket.com
              </Typography>
              <Typography variant="body1" classes={bodyClass}>
                <b>Alternative dispute resolution:</b>
                <br />
                The European Commission provides a platform for out-of-court online dispute resolution (OS platform), available at{' '}
                <a href="https://ec.europa.eu/odr" target="_blank" rel="noreferrer" className="primary">
                  https://ec.europa.eu/odr
                </a>
                .
              </Typography>
              <Typography variant="body1" classes={bodyClass}>
                We are not prepared to take part in dispute settlement proceedings before consumer arbitration boards.
              </Typography>
            </li>
            <li>
              Information on the formation of the contract
              <Typography variant="body1" classes={bodyClass}>
                The technical steps for the conclusion of the contract, the conclusion of the contract itself and the correction options are
                carried out in accordance with the provisions "Conclusion of the contract" of our general terms and conditions (Part I.)
              </Typography>
            </li>
            <li>
              Contract language, contract text storage
              <ol>
                <li>Contract language is German.</li>
                <li>
                  We do not save the full text of the contract. Before sending the order, the contract data can be printed out or
                  electronically saved using the browser's print function. After we have received the order, the order data, the information
                  required by law for distance sales contracts and the general terms and conditions will be sent to you again by email.
                </li>
                <li>
                  For requests for offers outside of the online shopping cart system, you will receive all contract data as part of a
                  binding offer in text form, e.g. by e-mail, which you can print out or save electronically.
                </li>
              </ol>
            </li>
            <li>
              Essential characteristics of the product or service
              <Typography variant="body1" classes={bodyClass}>
                The essential characteristics of the goods and / or services can be found in the respective offer.
              </Typography>
            </li>
            <li>
              Prices and payment methods
              <ol>
                <li>
                  The prices listed in the respective offers as well as the shipping costs represent total prices. They include all price
                  components including all applicable taxes.
                </li>
                <li>
                  The shipping costs are not included in the purchase price. They can be called up via a correspondingly labeled button on
                  our website or in the respective offer. The shipping costs are shown separately in the course of the ordering process and
                  are to be borne by you in addition, unless free delivery has been promised.
                </li>
                <li>
                  If the delivery is made to countries outside the European Union, additional costs may occur such as customs duties, taxes
                  or money transfer fees (transfer or exchange rate fees of the credit institutions), which are to be borne by you and for
                  which we are not responsible.
                </li>
                <li>
                  Any costs incurred for the transfer of money (transfer or exchange rate fees of the credit institutions) are to be borne
                  by you in cases in which the delivery is made to an EU member state, but the payment has been initiated outside the
                  European Union.
                </li>
                <li>
                  The payment methods available to you are shown under a corresponding button on our website or in the respective offer.
                </li>
                <li>
                  Unless otherwise specified for the individual payment methods, the payment claims from the concluded contract are due for
                  payment immediately.
                </li>
              </ol>
            </li>
            <li>
              Terms of delivery
              <ol>
                <li>
                  The delivery conditions, the delivery date and any existing delivery restrictions can be found under a corresponding
                  button on our website or in the respective offer.
                </li>
                <li>
                  As far as you are a consumer, it is regulated by law that the risk of accidental loss and accidental deterioration of the
                  item sold during shipment is only transferred to you when the goods are handed over, regardless of whether the shipment is
                  insured or uninsured. This does not apply if you have independently commissioned a transport company not named by the
                  entrepreneur or a person otherwise appointed to carry out the shipment.
                </li>
                <li>If you are an entrepreneur, delivery and dispatch are at your own risk.</li>
              </ol>
            </li>
            <li>
              Statutory warranty rights
              <Typography variant="body1" classes={bodyClass}>
                The liability for defects is based on the "Warranty" provision in our General Terms and Conditions (Part I).
              </Typography>
            </li>
          </ol>
        </div>
      </Typography>
    </>
  )
}

TosPageEN.getInitialProps = function () {
  return {}
}

export default TosPageEN
