import { Button } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './ImageUpload.module.scss'
import Img from '../Img'

type Props = {
  classes?: string
  accept: string
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  imagePreview: string | null
  changeLabel: string
  imageUploading?: boolean
  disabled?: boolean
}

const ImageUploadAvatar = (props: Props) => {
  const { accept, onFileChange, imagePreview, changeLabel, imageUploading } = props
  const classes = styles['avatar-box']
  const inputClasses = classNames(styles['avatar-box__input'], {
    [styles['avatar-box__input--disabled']]: imageUploading,
  })
  // <Img base64={imagePreview?.includes('base64')} src={imagePreview || '/img/avatars.svg'} classes={styles['avatar-box__preview']} />
  return (
    <div className={classes}>
      <input
        accept={accept}
        type="file"
        id="avatar-input-file"
        className={inputClasses}
        onChange={onFileChange}
        disabled={props.disabled}
      />

      <img src={imagePreview || '/img/avatars.svg'} alt="avatar" className={styles['avatar-box__preview']} />
      <label htmlFor="avatar-input-file">
        <Button
          component="span"
          variant="contained"
          color="default"
          classes={{ root: styles['avatar-box__button'] }}
          disabled={imageUploading}
        >
          {changeLabel}
        </Button>
      </label>
    </div>
  )
}

export default ImageUploadAvatar
