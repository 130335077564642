import { Button } from '@material-ui/core'
import qs from 'qs'
import React from 'react'
import { useParams } from 'react-router'
import styles from './NewsletterUnsubscribePage.module.scss'
import LandingPageLayout from '../../../components/Layout/LandingPageLayout/LandingPageLayout'
import Link, { routes } from '../../../components/Link/Link'
import * as api from '../../../components/Newsletter/Newsletter.api'
import Notification from '../../../components/Notification/Notification'
import * as NotificationType from '../../../components/Notification/Notification.types'
import Trans from '../../../components/Trans/Trans'

type UrlParamsType = {
  id: string
  token: string
}

const NewsletterUnsubscribePage = () => {
  const params = useParams<UrlParamsType>()
  const [error, setError] = React.useState<string | boolean>(false)

  React.useEffect(() => {
    const unsubscribe = async () => {
      try {
        await api.unsubscribe({ id: params.id, token: params.token })
        setError('')
      } catch (err) {
        const errors = err?.response?.data?.errors || []
        setError(errors[0]?.message || 'Something went wrong. Try again')
        console.error(err)
      }
    }

    unsubscribe()
  }, [])

  const content =
    error === false
      ? null
      : error || <Trans ns="Newsletter" id="NewsletterUnsubscribed" msg="You have been unsubscribed from our newsletter." />

  return (
    <LandingPageLayout
      classes={{
        header: styles['header'],
        container: styles['container'],
      }}
      headerContent={
        <div className={styles['content']}>
          <Notification
            isStatic
            id={0}
            type={error ? NotificationType.ERROR : NotificationType.SUCCESS}
            content={content}
            className={styles['notification']}
          />
          <Button variant="contained" type="submit" color="primary">
            <Link silent to={routes.catalog}>
              <Trans ns="Common" id="GoToCatalog" msg="Go to catalog" />
            </Link>
          </Button>
        </div>
      }
    />
  )
}

export default NewsletterUnsubscribePage
