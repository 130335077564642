import BigNumber from 'bignumber.js'
import Dinero from 'dinero.js'
import _ from 'lodash'
import React, { useState } from 'react'
import { isBrowser } from 'react-device-detect'
import { useHistory } from 'react-router'
import OfferView from './OfferView'
import UserContext from '../../contexts/UserContext'
import useAuth from '../../hooks/auth'
import { OfferType } from '../../interfaces/Offer.type'
import * as chatApi from '../../services/chat/chat.api'
import handleError from '../../utils/handleError'
import { priceToInt } from '../../utils/tax'
import { routes } from '../Link'
import { resolveRoute } from '../Link/linkResolver'
import useOffer from '../../hooks/offer'
import createOfferUrl from '../../utils/createOfferUrl'
import useShare from '../../hooks/share'
import notify from '../Notification/notify'

const defaultProps = Object.freeze({
  language: 'en' as string,
})

type Props = typeof defaultProps & {
  offerId: string
  offer: OfferType
  print: boolean
}

const isEmptyValue = (offer: OfferType, attribute: string) => {
  const val = _.get(offer, attribute, false)
  if (!val) return true
  return false
}

const Offer = (props: Props) => {
  const history = useHistory()
  const { addToFavorite, removeOfferFromFavorite, isFavorite } = useOffer()
  const [chatLoading, setChatLoading] = useState<boolean>(false)
  const [showAllDetails, setShowAllDetails] = useState<boolean>(false)
  const { language, print, offer } = props
  const [revealCallButton, setRevealCallButton] = useState<boolean>(false)
  const { isAuth } = useAuth()
  const { share } = useShare()
  const user = React.useContext(UserContext)
  //@ts-ignore
  const priceGross = new BigNumber(offer.priceGross).multipliedBy(100).toString()
  const priceGrossFormatted = Dinero({ amount: priceToInt(priceGross) }).toFormat('0,0.00')
  //@ts-ignore
  const priceNet = new BigNumber(offer.priceNet).multipliedBy(100).toString()
  const priceNetFormatted = Dinero({ amount: priceToInt(priceNet) }).toFormat('0,0.00')
  //@ts-ignore
  const pricePerLiterConverted = new BigNumber(offer.pricePerLiterGross).multipliedBy(100).toString()
  const pricePerLiter = Dinero({ amount: priceToInt(pricePerLiterConverted) }).toFormat('0,0.00')
  const phone = `${offer.phoneCode}${offer.phone}`.replace(/\s/g, '')
  const areDetailsEmpty =
    isEmptyValue(offer, 'bottle.outturn') &&
    isEmptyValue(offer, 'condition.name') &&
    isEmptyValue(offer, 'fillLevel.name') &&
    isEmptyValue(offer, 'availablity.name') &&
    isEmptyValue(offer, 'package.name') &&
    isEmptyValue(offer, 'quantity') &&
    isEmptyValue(offer, 'bottleLocation')

  const onChatMessage = async () => {
    if (!isAuth) {
      history.push(routes.signIn)
      return
    }
    let response = null
    setChatLoading(true)
    try {
      response = await chatApi.createConversation(offer.id)
      if (response.data?.id) {
        history.push(resolveRoute(routes.conversation, { id: response.data.id }))
      }
    } catch (error) {
      handleError(error)
      console.error(error)
    }
    setChatLoading(false)
  }

  const onPhoneCall = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!isBrowser) return
    event.preventDefault()
    event.stopPropagation()
    setRevealCallButton(true)
  }

  const onPrint = () => {
    window?.print()
  }

  const handleShareClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    const offerUrl = createOfferUrl(offer)

    share({
      url: offerUrl,
      // title: offer.title,
      // text: offer.subtitle || offer.description,
      clipboardText: offerUrl,
    })
    .then((type: string) => {
      if (type === 'clipboard') {
        notify.success('The URL of the offer has been copied to the clipboard')
      }
    })
    .catch(e => notify.error(e))
  }

  const handleAddToFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    if (!isAuth) {
      history.push(routes.signIn)
      return
    }

    addToFavorite(offer)
  }

  const handleRemoveOfferFromFavorite = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    
    if (!isAuth) {
      history.push(routes.signIn)
      return
    }

    removeOfferFromFavorite(offer)
  }

  return (
    <OfferView
      revealCallButton={revealCallButton}
      onPhoneCall={onPhoneCall}
      chatLoading={chatLoading}
      offer={offer}
      user={user}
      priceNet={priceNetFormatted}
      priceGross={priceGrossFormatted}
      setShowAllDetails={setShowAllDetails}
      showAllDetails={showAllDetails}
      pricePerLiter={pricePerLiter}
      phone={phone}
      language={language}
      hasFilledDetails={!areDetailsEmpty}
      onChatMessage={onChatMessage}
      print={print}
      onPrint={onPrint}
      isAuth={isAuth}
      isFavorite={isFavorite(offer)}
      onShareClick={handleShareClick}
      onAddToFavorite={handleAddToFavorite}
      onRemoveFromFavorite={handleRemoveOfferFromFavorite}
    />
  )
}

Offer.defaultProps = defaultProps

export default Offer
