import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  Modal,
  Typography
  } from '@material-ui/core'
import { FormikHelpers, useFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../hooks/auth/auth'
import Children from '../../interfaces/Children.type'
import { StateType } from '../../services/reducers'
import * as userActions from '../../services/user/user.actions'
import * as userApi from '../../services/user/user.api'
import { UserFormPayloadType } from '../../services/user/user.types'
import { AxiosError } from '../../utils/axios'
import Date from '../../utils/date'
import handleError from '../../utils/handleError'
import FormDateField from '../Form/FormDateField/FormDateField'
import notify from '../Notification/notify'
import Trans from '../Trans'
import { SettingsFormType } from '../User/UserSettings/UserSettings.types'
import styles from './AgeProvider.module.scss'
import schema from './AgeProvider.schema'

interface Props {
  children: Children
}

const AgeProvider = (props: Props) => {
  const { isAuth } = useAuth()
  const user = useSelector((state: StateType) => state.user)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState<boolean>(Boolean(isAuth && user.data && !user.data?.birthday))

  const formik = useFormik({
    isInitialValid: false,
    validationSchema: schema,
    initialValues: {
      birthday: user.data?.birthday ? Date.timestampToDateString((user.data?.birthday as any) as number) || '' : '',
    },
    onSubmit: async (values: Partial<SettingsFormType>, formikBag: FormikHelpers<Partial<SettingsFormType>>) => {
      formikBag.setSubmitting(true)

      const payload = _.cloneDeep(values)
      payload.birthday = (Date.dateToUnixTimestamp(payload.birthday as string) as any) as string

      try {
        const result = await userApi.updateUser((payload as any) as UserFormPayloadType)
        dispatch(userActions.readUserDataSuccess(result.data))
        formikBag.setSubmitting(false)
        notify.success('Changes has been saved')
      } catch (error) {
        console.error(error)
        handleError(error as AxiosError, formikBag)
        formikBag.setSubmitting(false)
      }
    },
  })

  React.useEffect(() => {
    const newOpen = Boolean(isAuth && user.data && !user.data?.birthday)
    if (open !== newOpen) {
      setOpen(newOpen)
    }
  }, [isAuth, user.data?.birthday])

  React.useEffect(() => {
    formik.validateForm()
  }, [formik.values.birthday])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    formik.submitForm()
  }

  return (
    <>
      {props.children}
      <Modal
        aria-labelledby="age-modal-title"
        aria-describedby="age-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={styles['modal']}>
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
              <Typography id="age-modal-title" variant="h3" className={styles['title']}>
                <Trans ns="AgeAgreement" id="Leaving" msg="We’re sorry, we can’t let you in..." />
              </Typography>
              <Typography id="age-modal-description" variant="body1" className={styles['subtitle']}>
                To visit our website you have to be of legal drinking age. Fill in your date of birth.
              </Typography>
              <FormDateField
                form={formik}
                onChange={e => {
                  formik.setFieldValue('birthday', e, true)
                }}
                field={{
                  name: 'birthday',
                  value: formik.values.birthday,
                  onChange: formik.handleChange,
                  onBlur: formik.handleBlur,
                }}
                pickerProps={{
                  openTo: 'year',
                }}
                label="Date of birth"
                fullWidth
              />
              <Box className={styles['button__container']}>
                <Button
                  className={styles['button--save']}
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting || !formik.isValid}
                  type="submit"
                >
                  {formik.isSubmitting ? <CircularProgress size="1.6rem" /> : <Trans ns="UserSettings" id="SaveButton" msg="Save" />}
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default AgeProvider
