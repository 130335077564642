import _ from 'lodash'
import qs from 'qs'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Dispatch } from 'redux'
import Catalog from '../../components/Catalog'
import Filters from '../../components/Catalog/Filters'
import { routes } from '../../components/Link'
import LoadingSpinner from '../../components/LoadingSpinner'
import PageLayout from '../../components/PageLayout'
import Slider, { Slide } from '../../components/Slider'
import InitContext from '../../contexts/InitContext'
import useMatchMedia from '../../hooks/matchMedia'
import useOnLoadImages from '../../hooks/onLoadImages'
import { FiltersType, SortByDirectionType, SortByOrderType } from '../../interfaces/Filters.type'
import {
  setFieldsValues,
  setFilters,
  setItems,
  setItemsCounter,
  setLoadMoreUrl
  } from '../../services/catalog/catalog.actions'
import { ActionTypes } from '../../services/catalog/catalog.types'
import * as api from '../../services/offer/offer.api'
import { StateType as AppState } from '../../services/reducers'
import axios from '../../utils/axios'
import { isEqual as areFilterEquals, parse as parseFilters, stringify as stringifyFilters } from '../../utils/filters'
import styles from './CatalogPage.module.scss'
import getSlides from './getSlides'
import {
  OfferSelectFieldType,
  OfferType,
  SELECT_FIELD_NAME_AVAILABILITY,
  SELECT_FIELD_NAME_BOTTLE_TYPE,
  SELECT_FIELD_NAME_CASK_TYPE,
  SELECT_FIELD_NAME_CATEGORY,
  SELECT_FIELD_NAME_CONDITION,
  SELECT_FIELD_NAME_FILL_LEVEL,
  SELECT_FIELD_NAME_PACKAGE,
} from '../../interfaces/Offer.type'

type SearchResultType = {
  count: number | null
  items: OfferType[]
  _links: {
    next: string | null
  }
}

const defaultLocationState = Object.freeze({
  showAdvancedSearch: false as boolean,
  query: undefined as string | undefined,
  bottleType: undefined as string | undefined,
  distillery: undefined as string | undefined,
  age: undefined as string | undefined,
  price: undefined as string | undefined,
})

type StateType = typeof defaultLocationState

const CatalogPage = () => {
  const matchMedia = useMatchMedia()
  const history = useHistory()
  const location = useLocation()
  const dispatch: Dispatch<ActionTypes> = useDispatch()
  const [locationState, setLocationState] = React.useState({ ...defaultLocationState, ...((history.location?.state || {}) as StateType) })
  const filtersRef = React.useRef<any>(null)
  const sliderRef = React.useRef<any>(null)
  const query = qs.parse(location.search, { ignoreQueryPrefix: true })
  const slides = getSlides(matchMedia)
  const [initializing, setInitializing] = React.useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const imagesLoaded = useOnLoadImages(sliderRef)
  const { items, loadMoreUrl, itemsCounter, filters, fieldsValues } = useSelector((state: AppState) => state.catalog)
  const initData = React.useContext(InitContext)

  const load = async (url: string, append: boolean) => {
    const result: SearchResultType = {
      count: itemsCounter,
      items: [],
      _links: { next: null },
    }

    try {
      const response = await axios({
        method: 'GET',
        url,
      })

      result.count = response.data.count
      result.items = response.data.items
      result._links.next = response.data._links.next || null
    } catch (e) {
      console.error(e)
    }

    const newItems = append ? [..._.cloneDeep(items!), ...result.items] : [...result.items]

    dispatch(setItems(newItems))
    dispatch(setLoadMoreUrl(result._links.next))
    dispatch(setItemsCounter(result.count))
  }

  const reload = async () => {
    setInitializing(true)
    dispatch(setLoadMoreUrl(null))
    load(`/search/offer?${stringifyFilters(parseFilters(query!))}`, false)
      .then(() => {
        if (window) {
          window.scrollTo(0, 0)
        }
      })
      .finally(() => {
        setInitializing(false)
      })
  }

  React.useEffect(() => {
    const initFetch = async () => {
      try {
        const response = await api.searchFieldsHints()

        const fieldValues = {
          bottleType: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_BOTTLE_TYPE)[0],
          category: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CATEGORY)[0],
          caskType: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CASK_TYPE)[0],
          condition: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CONDITION)[0],
          fillLevel: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_FILL_LEVEL)[0],
          availability: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_AVAILABILITY)[0],
          package: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_PACKAGE)[0],
        }

        dispatch(setFieldsValues(fieldValues))
        dispatch(setFilters(parseFilters(query!)))
      } catch (error) {
        console.error(error)
      }
    }

    if (!fieldsValues || !filters) {
      initFetch()
    }

    if (items.length === 0) {
      reload()
    }
  }, [])

  React.useEffect(() => {
    if (!areFilterEquals(filters, query)) {
      dispatch(setFilters(parseFilters(query)))
      reload()
    }
  }, [query])

  React.useEffect(() => {
    if (imagesLoaded && locationState.showAdvancedSearch) {
      setTimeout(() => {
        const headerHeight = document?.getElementById('header')?.clientHeight || 0
        const y = filtersRef.current.getBoundingClientRect().top + window.pageYOffset - headerHeight
        window.scrollTo({ top: y, behavior: 'smooth' })
        window.history.replaceState(null, document.title, window.location.href)
      }, 1000)
    }
  }, [imagesLoaded])

  const handleSliderItemClick = (item: Slide) => {
    const openUrl = (url: string) => {
      const isExternal = url.startsWith('http:') || url.startsWith('https:')

      if (isExternal) {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      } else {
        history.push(url)
      }
    }

    if (item.url) {
      openUrl(item.url)
    }
  }

  const handleLoadMore = async (): Promise<void> => {
    if (!loading) {
      setLoading(true)

      if (loadMoreUrl != null) {
        await load(loadMoreUrl, true)
      }

      setLoading(false)
    }
  }

  const handleFiltersSubmit = async (inFilters: FiltersType) => {
    history.push({ pathname: routes.catalog, search: stringifyFilters(inFilters) })
  }

  const handleFiltersReset = () => {
    setLocationState(defaultLocationState)
    history.push({ pathname: routes.catalog, search: '', state: {} })
  }

  const handleSortChange = (order: SortByOrderType, direction: SortByDirectionType) => {
    const inFilters = {
      ...filters,
      order,
      direction,
    }

    history.push({ pathname: routes.catalog, search: stringifyFilters(inFilters) })
  }

  return (
    <PageLayout classes={styles['catalog-page']}>
      {!fieldsValues || !filters ? (
        <LoadingSpinner center />
      ) : (
        <>
          <div className={styles['catalog-page__content']}>
            <Slider ref={sliderRef} slides={initData.catalog.slides || []} onItemClick={handleSliderItemClick} />
          </div>
          <Filters
            ref={filtersRef}
            filters={{
              ...filters,
              query: locationState.query || filters.query,
              bottleType: locationState.bottleType || filters.bottleType,
              distillery: locationState.distillery || filters.distillery,
              age: locationState.age || filters.age,
              price: locationState.price || filters.price,
            }}
            loading={initializing}
            showAdvancedSearch={locationState.showAdvancedSearch}
            onSubmit={handleFiltersSubmit}
            onReset={handleFiltersReset}
            fieldsValues={fieldsValues}
            language="en"
            currencySign="€"
            classes={{
              root: styles['container__filters'],
              content: styles['catalog-page__content'],
            }}
          />
          <div className={styles['catalog-page__content']}>
            <Catalog
              items={items}
              itemsCounter={itemsCounter}
              onLoadMore={handleLoadMore}
              onSortChange={handleSortChange}
              showMoreButton={loadMoreUrl !== null}
              loading={loading}
              sortOrder={filters.order}
              sortDirection={filters.direction}
            />
          </div>
        </>
      )}
    </PageLayout>
  )
}

export default CatalogPage
