// Można używać poza React Routerem
import parseJson from '../../utils/parseJson'

const usePrerender = () => {
  const location = window ? window.location.search : '?prerender=1'
  const search = location.length > 1 ? location.substring(1) : 'prerender=0'
  const query = parseJson('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
  const isPrerender = parseInt(query?.prerender || '0', 10) === 1

  const getRequestedUrl = (): string | null => {
    let result: string | null = null

    if (window) {
      const fixedQuery = Object.keys(query)
        .filter(key => !key.startsWith('prerender'))
        .map(key => `${key}=${query[key]}`)
        .join('&')

      result = `${window.location.origin}${window.location.pathname}${fixedQuery ? `?${encodeURI(fixedQuery)}` : ''}`
    }

    return result
  }

  return {
    isPrerender,
    getRequestedUrl,
  }
}

export default usePrerender
