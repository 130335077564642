import { MenuItem } from '@material-ui/core'
import { InputProps as InputPropsType } from '@material-ui/core/Input'
import { FormikProps } from 'formik'
import React, { useState } from 'react'
import { SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest'
import { AutocompleteFieldType } from '../../../interfaces/Autocomplete.types'
import { AutocompleteClassesType } from '../../UI/AutocompleteField'
import FormAutocompleteField from '../FormAutocompleteField'

export type FieldNameType = 'distillery' | 'bottler' | 'baseSearch'

type Props<FormType> = {
  onSelected?: (form: FormikProps<FormType>, key?: string, newValue?: string | number) => void
  label: string
  field: {
    name: FieldNameType
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onBlur: (event: any) => void
  }
  onChange?: (value: string) => void
  extraProps?: object
  getSuggestions: (value: string) => Promise<any>
  classes?: {
    root?: string
  }
  autocompleteClasses?: AutocompleteClassesType
  form: FormikProps<FormType>
  fullWidth?: boolean
  withMargins?: boolean
  InputProps?: InputPropsType
  submitOnEnter?: boolean
}

const SUGGEST_TIMEOUT = 600
const SUGGESTIONS_LIMIT = 20

const FormFieldSAC = <FormType extends {}>(props: Props<FormType>) => {
  const { onSelected, form, label, onChange, extraProps, getSuggestions, autocompleteClasses, classes, field, InputProps } = props
  const [suggestions, setSuggestions] = useState<string[]>([])
  function renderSuggestion(item: string, { query, isHighlighted }: { query: string; isHighlighted: boolean }) {
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>{item}</div>
      </MenuItem>
    )
  }

  const fieldConfig = {
    name: field.name,
    suggestions,
    onSuggestionSelected: (event: React.FormEvent<string>, data: SuggestionSelectedEventData<string>, _form: FormikProps<FormType>) => {
      fieldConfig.onSelected(data.suggestionValue, _form)
    },
    onSelected: (value: string, _form: FormikProps<FormType>) => {
      onSelected ? onSelected(_form, field.name, value) : null
      _form.setFieldTouched(field.name)
      _form.setFieldValue(field.name, value)
    },
    getSuggestions: (value: string, _form: FormikProps<FormType>) => {
      clearTimeout(fieldConfig.timer!)
      fieldConfig.timer = setTimeout(async () => {
        try {
          const fetchedSuggestions = await getSuggestions(value)
          setSuggestions(fetchedSuggestions.slice(0, SUGGESTIONS_LIMIT))
        } catch (error) {
          setSuggestions([])
        }
      }, SUGGEST_TIMEOUT)
    },
    getSuggestionValue: (item: string) => item,
    onSuggestionsClearRequested: () => { },
    onSuggestionsFetchRequested: (request: SuggestionsFetchRequestedParams, _form: FormikProps<FormType>) => {
      fieldConfig.getSuggestions(request.value, _form)
    },
    renderSuggestion,
  } as AutocompleteFieldType<string, FormType>

  return (
    <FormAutocompleteField<string, FormType>
      inputProps={{
        value: form.getFieldProps(field.name).value,
        onChange: (event: React.FormEvent<HTMLElement>) => { },
      }}
      InputProps={InputProps!}
      form={form}
      autocompleteClasses={autocompleteClasses}
      classes={classes}
      getSuggestionValue={fieldConfig.getSuggestionValue}
      suggestions={fieldConfig.suggestions}
      renderSuggestion={fieldConfig.renderSuggestion}
      onSuggestionsClearRequested={fieldConfig.onSuggestionsClearRequested}
      onSuggestionsFetchRequested={(request: SuggestionsFetchRequestedParams) => {
        fieldConfig.onSuggestionsFetchRequested(request, form)
      }}
      onSuggestionSelected={(event: React.FormEvent<string>, data: SuggestionSelectedEventData<string>) => {
        fieldConfig.onSuggestionSelected(event, data, form)
      }}
      label={label}
      fullWidth
      field={{
        ...field,
        onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          fieldConfig.onSelected(event.currentTarget?.value, form)
          field.onChange(event)
          onChange ? onChange(event.currentTarget?.value!) : null
        },
      }}
      withMargins={props.withMargins}
      submitOnEnter={props.submitOnEnter}
      {...extraProps}
    />
  )
}

export default FormFieldSAC
