const baseRoutes = {
  home: '/',
  signIn: '/signin',
  signUp: '/signup',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:userId/:token',
  newsletterOut: '/newsletter-out',
  newsletterConfirm: '/newsletter/:id/:token/confirm',
  newsletterUnsubscribe: '/newsletter/:id/:token/unsubscribe',
  newsletterMessage: '/newsletter/:id/:token/:messageId',
  newsletterSend: '/profile/newsletter',
  offerAdd: '/add-an-offer',
  offerEdit: '/offer/:id/edit',
  offerOld: '/offer/:id',
  offer: '/offer/:slug/:sku',
  userConfirm: '/profile/confirm/:id/:token',
  userSettings: '/profile/settings',
  myOffers: '/offers/me',
  userOffers: '/offers/:id',
  userFavoriteOffers: '/offers/favorites',
  messages: '/messages',
  conversation: '/message/:id',
  faq: '/faq',
  tos: '/tos',
  aboutUs: '/about-us',
  ror: '/right-of-resign',
  gdpr: '/personal-data',
  bot: '/welcome',
  uiKit: '/ui',
  catalog: '/catalog',
  buy: '/buy',
  sell: '/sell',
  contact: '/contact',
}

const routes = Object.freeze({
  ...baseRoutes,
})

export default routes
