import { Typography } from '@material-ui/core'
import React from 'react'
import styles from '../GDPRPage.module.scss'

const GDPRPageDE = () => {
  const bodyClass = { root: styles['list__text'] }
  const [a] = React.useState()

  return (
    <>
      <Typography variant="h3" classes={{ root: styles['heading'] }}>
        Datenschutzerklärung
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder
        vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet.
        Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige
        Angabe gemacht wird.
        <br />
        &quot;Personenbezogene Daten&ldquo; sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche
        Person beziehen.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Server-Logfiles
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen.
        <br />
        Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren Internet
        Browser übermittelt und in Protokolldaten (sog. Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. der Name
        der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die übertragene Datenmenge und der anfragende Provider. Die
        Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der
        Gewährleistung eines störungsfreien Betriebs unserer Website sowie zur Verbesserung unseres Angebotes.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Initiativ-Kontaktaufnahme des Kunden per E-Mail
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Wenn Sie per E-Mail initiativ mit uns in Geschäftskontakt treten, erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
        Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient der Bearbeitung und Beantwortung
        Ihrer Kontaktanfrage.
        <br />
        Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient
        oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6
        Abs. 1 lit. b DSGVO.
        <br />
        Erfolgt die Kontaktaufnahme aus anderen Gründen erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus
        unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage. In diesem Fall haben Sie das Recht,
        aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden
        Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
        <br />
        Ihre e-mail nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher
        Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Erhebung und Verarbeitung bei Nutzung des Kontaktformulars
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von
        Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient dem Zweck der Kontaktaufnahme. Mit Absenden Ihrer Nachricht
        willigen Sie in die Verarbeitung der übermittelten Daten ein. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO
        mit Ihrer Einwilligung.
        <br />
        Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
        Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage.
        Ihre Daten werden anschließend gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Bestellungen
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei Bestellungen
        <br />
        Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen Daten nur, soweit dies zur Erfüllung und Abwicklung Ihrer
        Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist. Die Bereitstellung der Daten ist für den Vertragsschluss
        erforderlich. Eine Nichtbereitstellung hat zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf
        Grundlage des Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung eines Vertrags mit Ihnen erforderlich.
        <br />
        Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen gewählten Versandunternehmen und Dropshipping Anbieter,
        Zahlungsdienstleister, Diensteanbieter für die Bestellabwicklung und IT-Dienstleister. In allen Fällen beachten wir strikt die
        gesetzlichen Vorgaben. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Cookies
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
        Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des
        Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des
        Browsers beim erneuten Aufrufen der Website ermöglicht.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl
        entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie vor dem Setzen von Cookies benachrichtigt werden und
        über die Annahme einzeln entscheiden sowie die Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern. Bereits
        gespeicherte Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht
        sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch
        deaktivieren) können:
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            Chrome Browser:&nbsp;
            <a href="https://support.google.com/accounts/answer/61416?hl=de" rel="noreferrer" className="primary" target="_blank">
              https://support.google.com/accounts/answer/61416?hl=de
            </a>
          </li>
          <li className={styles['list__item']}>
            Internet Explorer:&nbsp;
            <a
              href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
              className="primary"
              target="_blank"
              rel="noreferrer"
            >
              https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </li>
          <li className={styles['list__item']}>
            Mozilla Firefox:&nbsp;
            <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" className="primary" target="_blank" rel="noreferrer">
              https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
            </a>
          </li>
          <li className={styles['list__item']}>
            Safari:&nbsp;
            <a
              href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              className="primary"
              target="_blank"
              rel="noreferrer"
            >
              https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </a>
          </li>
        </ul>
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Technisch notwendige Cookies
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Soweit nachstehend in der Datenschutzerklärung keine anderen Angaben gemacht werden setzen wir nur diese technisch notwendigen
        Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies
        unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer
        Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach
        einem Seitenwechsel wiedererkannt wird.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des § 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer
        personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der
        Gewährleistung der optimalen Funktionalität der Website sowie einer nutzerfreundlichen und effektiven Gestaltung unseres Angebots.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender
        personenbezogener Daten zu widersprechen.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Betroffenenrechte und Speicherdauer
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Dauer der Speicherung
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Nach vollständiger Vertragsabwicklung werden die Daten zunächst für die Dauer der Gewährleistungsfrist, danach unter
        Berücksichtigung gesetzlicher, insbesondere steuer- und handelsrechtlicher Aufbewahrungsfristen gespeichert und dann nach
        Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Rechte der betroffenen Person
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf
        Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit.
        <br />
        Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f DSGVO
        beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Die personenbezogenen Daten der Nutzer werden automatisch 5 Jahre nach dem letzten Login gelöscht. Die Nutzer können jederzeit die
        Löschung ihrer persönlichen Daten verlangen, indem sie eine E-Mail an{' '}
        <a href="mailto:info@whiskymarket.com" className="primary" target="_blank" rel="noreferrer">
          info@whiskymarket.com
        </a>{' '}
        senden. Die von den Nutzern veröffentlichten Angebote werden nicht gelöscht.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Beschwerderecht bei der Aufsichtsbehörde
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die
        Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Widerspruchsrecht
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1
        lit. f DSGVO, haben Sie das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit
        Wirkung für die Zukunft zu widersprechen.
        <br />
        Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir können zwingende schutzwürdige
        Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder wenn die Verarbeitung der
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Erfolgt die personenbezogene Datenverarbeitung zu Zwecken der Direktwerbung, können Sie dieser Verarbeitung jederzeit durch
        Mitteilung an uns widersprechen. Nach erfolgtem Widerspruch beenden wir die Verarbeitung der betroffenen Daten zum Zwecke der
        Direktwerbung.
      </Typography>
    </>
  )
}

GDPRPageDE.getInitialProps = function () {
  return {}
}

export default GDPRPageDE
