import React from 'react'
import { useParams } from 'react-router'
import { deleteNewsletter } from '../../components/Newsletter/Newsletter.api'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'

type Props = {}

type UrlParamsType = {
  token: string
  id: string
}

const NewsletterOutPage = (props: Props) => {
  const params = useParams<UrlParamsType>()
  const [isDone, setDone] = React.useState<boolean>(false)

  React.useEffect(() => {
    const verifyToken = async () => {
      const done = false
      try {
        await deleteNewsletter({ token: params.token, id: params.id })
        setDone(true)
      } catch (error) {
        console.error(error)
      }
    }

    verifyToken()
  }, [params.token])

  return (
    <PageLayout classes={pageStyles['content--sides-padding']}>
      {isDone ? 'You have been unsubscribed from our newsletter' : 'Please wait...'}
    </PageLayout>
  )
}

export default NewsletterOutPage
