import styles from '../shared.module.scss'

export default {
  root: {
    color: styles['color__gray1'],
    '&$focused': {
      color: styles['color__gray1'],
    },
  },
}
