import { Button, Dialog, Slide } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import Slick from 'react-slick'
import styles from './Gallery.module.scss'
import { OfferImageType, OfferPhotosType } from '../../interfaces/Offer.type'
import Img from '../Img'

type Props = {
  classes?: {
    slider?: string
    slideImage?: string
    images: string
  }
  items: OfferPhotosType
}

const NextArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <Button classes={{ root: className }} onClick={onClick} variant="contained" color="default">
      <Img src="/icons/arrow_right.svg" alt=">" />
    </Button>
  )
}

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props
  return (
    <Button classes={{ root: className }} onClick={onClick} variant="contained">
      <Img src="/icons/arrow_left.svg" alt="<" />
    </Button>
  )
}
/*
const Transition = React.forwardRef<any, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
*/
const Gallery = (props: Props) => {
  const { items } = props
  const [activeImageIdx, setActiveImageIdx] = useState<number>(0)
  const [lightboxOpened, setLightboxOpened] = useState<boolean>(false)
  const sliderRef = useRef<Slick>(null)
  const photosGallery = items.gallery
  const photosIcon = items.icon
  const photosLarge = items.large

  const settings = {
    slide: 'span',
    ref: sliderRef,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    className: classNames(styles['gallery__slider'], props.classes?.slider),
    nextArrow: <NextArrow className={styles['slick-pre']} />,
    prevArrow: <PrevArrow className={styles['slick-next']} />,
    beforeChange: (current: number, next: number) => setActiveImageIdx(next),
  }
  return (
    <div className={styles['gallery']}>
      <Slick {...settings}>
        {photosGallery.map((item: OfferImageType) => {
          return (
            <img
              alt={`pic-${item.id}`}
              key={item.id}
              src={item.src}
              className={classNames(props.classes?.slideImage, props.classes?.images)}
              onClick={() => {
                setLightboxOpened(true)
              }}
            />
          )
        })}
      </Slick>
      <div className={styles['gallery__preview-gallery']}>
        {photosIcon.map((item: OfferImageType, idx: number) => {
          return (
            <img
              alt={`pic-${item.id}`}
              key={item.id}
              src={item.src}
              className={classNames(styles['preview-gallery__item'], props.classes?.images)}
              onClick={() => {
                sliderRef.current?.slickGoTo(idx)
              }}
            />
          )
        })}
      </div>
      {lightboxOpened && (
        <Dialog
          fullScreen
          open
          onClose={() => {
            setLightboxOpened(false)
          }}
          //TransitionComponent={Transition}
        >
          <Img
            src="/icons/close.svg"
            classes={styles['fullscreen__close']}
            onClick={() => {
              setLightboxOpened(false)
            }}
          />
          <img
            src={photosLarge[activeImageIdx].src}
            className={classNames(styles['gallery--fullscreen'], props.classes?.images)}
            alt="fullscreen gallery"
          />
        </Dialog>
      )}
    </div>
  )
}

export default Gallery
