import classNames from 'classnames'
import React from 'react'
import styles from './FaqPage.module.scss'
import FaqPageEN from './translations/FaqPageEN'
import Img from '../../components/Img'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
// import useCms from '../../hooks/cms'
// import { MenuItem } from '@material-ui/core'
// import FaqPageDE from './translations/FaqPageDE'
// import SelectField from '../../components/UI/SelectField'

const FaqPage = () => {
  // const { language, switchLanguage, languages } = useCms()

  // const changeLanguage = (event: React.ChangeEvent<{ name?: string | undefined; value: any }>) => {
  //   switchLanguage(languages[event.target.value])
  // }

  return (
    <PageLayout classes={classNames(pageStyles['content--sides-padding'], styles['faq'])}>
      <div className={styles['promo']} />
      {/* <SelectField
        onChange={changeLanguage}
        value={language.isoCode}
        classes={{
          root: styles['language'],
        }}
      >
        <MenuItem value={languages.en.isoCode}>{languages.en.name}</MenuItem>
        <MenuItem value={languages.de.isoCode}>{languages.de.name}</MenuItem>
      </SelectField>
      {language.isoCode === languages.en.isoCode ? <FaqPageEN /> : <FaqPageDE />} */}
      <FaqPageEN />
    </PageLayout>
  )
}

export default FaqPage
