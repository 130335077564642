import { Badge, Divider, List, ListItem, ListItemIcon, ListItemText, Popover } from '@material-ui/core'
import classNames from 'classnames'
import React, { useContext } from 'react'
import UserContext from '../../contexts/UserContext'
import Children from '../../interfaces/Children.type'
import UserType from '../../interfaces/User.type'
import Dialog from '../Dialog'
import Img from '../Img'
import Link, { routes } from '../Link'
import styles from './Menu.module.scss'

type Props = {
  classes?: string
  onMouseLeave: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseEnter: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onBackdropClick: (event: React.SyntheticEvent<{}, Event>) => void
  onDialogClose: () => void
  isOpen: boolean
  isAuth: boolean
  isMobile: boolean
  setIsOpen: (value: React.SetStateAction<boolean>) => void
  setAnchorEl: (value: React.SetStateAction<HTMLButtonElement | null>) => void
  anchorEl: HTMLButtonElement | null
  toggleMenu: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  userProfile: UserType
  logOut: () => void
}

const MenuView = (props: Props) => {
  const {
    classes,
    logOut,
    userProfile,
    isOpen,
    onDialogClose,
    onMouseEnter,
    onMouseLeave,
    onBackdropClick,
    isAuth,
    isMobile,
    anchorEl,
    toggleMenu,
  } = props

  const user = useContext(UserContext)

  const className = classNames(classes, styles['menu'])
  const itemIconClasses = {
    root: styles['head__item-icon'],
  }

  const renderGuestMenu = (): Children => (
    <List>
      <Link to={routes.signIn}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/home.svg" />
          </ListItemIcon>
          <ListItemText primary="Sign in" />
        </ListItem>
      </Link>
      <Divider />
      <Link to={routes.faq}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/faq.svg" />
          </ListItemIcon>
          <ListItemText primary="FAQ" />
        </ListItem>
      </Link>
      <Link to="https://distilia.shop/" target="_blank">
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/shop.svg" />
          </ListItemIcon>
          <ListItemText primary="Shop" />
        </ListItem>
      </Link>
      <Link to={routes.contact}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/contact.svg" />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
      </Link>
    </List>
  )

  const renderUserMenu = (): Children => (
    <List>
      <ListItem>
        <ListItemIcon classes={itemIconClasses}>
          <img src={userProfile.data?.avatar} className={styles['menu__avatar']} alt="Avatar" />
        </ListItemIcon>
        <ListItemText primary={userProfile.data?.email} />
      </ListItem>
      <Divider />
      {user.data?.isAdmin || user.data?.isModerator || user.data?.isProUser ? (
        <Link to={routes.offerAdd}>
          <ListItem button>
            <ListItemIcon classes={itemIconClasses}>
              <Img src="/icons/add_grey.svg" />
            </ListItemIcon>
            <ListItemText primary="Add an Offer" />
          </ListItem>
        </Link>
      ) : null}
      <Link to={routes.messages}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/messages.svg" />
          </ListItemIcon>
          <Badge
            invisible={!userProfile.data?.unreadMessages}
            badgeContent={userProfile.data?.unreadMessages!}
            classes={{ badge: styles['menu__badge'] }}
            color="primary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ListItemText primary="Messages" />
          </Badge>
        </ListItem>
      </Link>
      <Link to={routes.myOffers}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/cart.svg" />
          </ListItemIcon>
          <ListItemText primary="My Offers" />
        </ListItem>
      </Link>
      <Link to={routes.userFavoriteOffers}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/heart2_menu.svg" />
          </ListItemIcon>
          <ListItemText primary="My Favorites" />
        </ListItem>
      </Link>
      {user.data?.isAdmin || user.data?.isModerator ? (
        <Link to={routes.newsletterSend}>
          <ListItem button>
            <ListItemIcon classes={itemIconClasses}>
              <Img src="/icons/menu/newsletter.svg" />
            </ListItemIcon>
            <ListItemText primary="Send Newsletter" />
          </ListItem>
        </Link>
      ) : null}
      <Link to={routes.userSettings}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/settings.svg" />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </Link>
      <Link to={routes.faq}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/faq.svg" />
          </ListItemIcon>
          <ListItemText primary="FAQ" />
        </ListItem>
      </Link>
      <Link to="https://distilia.shop/" target="_blank">
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/shop.svg" />
          </ListItemIcon>
          <ListItemText primary="Shop" />
        </ListItem>
      </Link>
      <Link to={routes.contact}>
        <ListItem button>
          <ListItemIcon classes={itemIconClasses}>
            <Img src="/icons/menu/contact.svg" />
          </ListItemIcon>
          <ListItemText primary="Contact" />
        </ListItem>
      </Link>
      <ListItem button onClick={logOut}>
        <ListItemIcon classes={itemIconClasses}>
          <Img src="/icons/menu/logout.svg" />
        </ListItemIcon>
        <ListItemText primary="Sign out" />
      </ListItem>
    </List>
  )

  const render = (children: Children) => {
    return isMobile ? (
      <Dialog open={isOpen} withLogo={false} onClose={onDialogClose}>
        {children}
      </Dialog>
    ) : (
      <Popover
        PaperProps={{
          onMouseLeave,
        }}
        transitionDuration={1}
        onBackdropClick={onBackdropClick}
        elevation={4}
        id="menu-content-pop"
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: styles['popover'],
        }}
      >
        {children}
      </Popover>
    )
  }

  return (
    <>
      <div className={className} onClick={toggleMenu}>
        {userProfile.data?.unreadMessages! > 0 ? (
          <Badge
            badgeContent={userProfile.data?.unreadMessages}
            color="primary"
            variant="dot"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Img src="/icons/menu__hamburger.svg" alt="Menu" />
          </Badge>
        ) : (
          <Img src="/icons/menu__hamburger.svg" alt="Menu" />
        )}
      </div>
      {render(isAuth ? renderUserMenu() : renderGuestMenu())}
    </>
  )
}

export default MenuView
