import { InputProps } from '@material-ui/core/Input'
import { FormikProps, FormikValues } from 'formik'
import React from 'react'
import { FiltersType } from '../../../../interfaces/Filters.type'
import FormFieldSAC, { FieldNameType as SACNameType } from '../../../Form/FormFieldSAC'

type Classes = {
  root?: string
}

type Props = {
  form: FormikProps<FormikValues | any>
  name: SACNameType
  label?: string
  placeholder?: string
  classes?: Classes
  inputProps?: InputProps
  onLoadSuggestions: (value: string) => Promise<any>
  submitOnEnter?: boolean
}

const AutocompleteField = (props: Props) => {
  if (!props.name || props.form.values[props.name] === undefined) {
    return null
  }

  return (
    <FormFieldSAC<FiltersType>
      classes={{ root: props.classes?.root }}
      form={props.form}
      label={props.label!}
      field={{
        name: props.name,
        value: props.form.getFieldProps(props.name).value,
        onChange: props.form.handleChange,
        onBlur: props.form.handleBlur,
      }}
      fullWidth
      InputProps={{
        ...props.inputProps,
        placeholder: props?.placeholder || '',
      }}
      getSuggestions={props.onLoadSuggestions}
      withMargins={false}
      submitOnEnter={props.submitOnEnter}
    />
  )
}

export default AutocompleteField
