import styles from '../shared.module.scss'

export default {
  root: {
    fontWeight: 400,
    fontSize: '1rem',
    padding: '8px 16px',
  },
  label: {},
  text: {
    '&:hover': {
      backgroundColor: 'unset',
      textDecoration: 'underline',
    },
  },
  contained: {
    boxShadow: 'unset',
    backgroundColor: styles['color__default'],
    '&:hover': {
      boxShadow: 'unset',
      backgroundColor: styles['color__default--dark'],
    },
    '&:active': {
      boxShadow: 'unset',
      backgroundColor: styles['color__default--active'],
    },
  },
  containedPrimary: {
    '&:active': {
      backgroundColor: styles['color__primary--active'],
    },
  },
  containedSecondary: {
    '&:active': {
      backgroundColor: styles['color__secondary--active'],
    },
  },
}
