import { ActionTypes, types } from './app.types'

export function ageAgreementAccept(): ActionTypes {
  return {
    type: types.AGE_AGREEMENT_ACCEPT,
  }
}

export function cookiesAccept(): ActionTypes {
  return {
    type: types.COOKIES_ACCEPT,
  }
}

export function cookiesRefuse(): ActionTypes {
  return {
    type: types.COOKIES_REFUSE,
  }
}
