import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup
  } from '@material-ui/core'
import { FormControlProps } from '@material-ui/core/FormControl'
import { InputProps } from '@material-ui/core/Input'
import { InputLabelProps } from '@material-ui/core/InputLabel'
import { RadioGroupProps } from '@material-ui/core/RadioGroup'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import styles from './RadioField.module.scss'

const defaultProps = Object.freeze({
  variant: 'outlined' as any,
  InputProps: { notched: false } as InputProps,
  InputLabelProps: { shrink: true } as InputLabelProps,
  withMargins: true as boolean,
  helperText: '' as string,
  emptyHelperText: true as boolean,
  row: true as boolean,
})

type Props = RadioGroupProps &
  typeof defaultProps & {
    formControlProps?: FormControlProps
    error?: boolean
    label?: React.ReactNode | string | number
    fullWidth?: boolean
    emptyHelperText?: boolean
  }

const RadioField = (props: Props) => {
  const classes = {
    root: classNames(props.classes?.root),
  }

  const errorHelper =
    props.error || props.emptyHelperText ? (
      <FormHelperText
        error
        classes={{
          root: styles['helper-text'],
        }}
      >
        {props.error ? props.helperText : ''}
      </FormHelperText>
    ) : null

  return (
    <FormControl {...props.formControlProps} fullWidth={props.fullWidth} classes={classes}>
      {props.label ? (
        <FormLabel className={styles['label']} component="label">
          {props.label}
        </FormLabel>
      ) : null}
      <RadioGroup aria-label={props.name} row={props.row} color="primary" name={props.name} value={props.value} onChange={props.onChange}>
        {props.children}
      </RadioGroup>
      {errorHelper}
    </FormControl>
  )
}

RadioField.defaultProps = defaultProps
export default RadioField
