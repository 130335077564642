import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core'
import { FormControlProps } from '@material-ui/core/FormControl'
import { InputLabelProps } from '@material-ui/core/InputLabel'
import { SelectProps } from '@material-ui/core/Select'
import { ClassNameMap } from '@material-ui/styles/withStyles'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useState } from 'react'
import styles from './SelectField.module.scss'
import ChildrenType from '../../../interfaces/Children.type'

const defaultItem = Object.freeze({
  //??
  value: '' as string,
  label: '' as string,
  InputProps: { notched: false } as SelectProps,
  InputLabelProps: { shrink: true } as InputLabelProps,
})
type ItemType = typeof defaultItem //??

type VariantType = 'standard' | 'outlined' | 'filled'

const defaultProps = Object.freeze({
  classes: {} as object,
  selectClasses: {} as Partial<ClassNameMap<string>>,
  label: '' as string,
  items: [] as ItemType[],
  variant: 'outlined' as VariantType,
  children: false as ChildrenType | false,
  helperText: '' as React.ReactNode,
  withMargins: true as boolean,
})

type Props = typeof defaultProps &
  SelectProps & {
    onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    label?: JSX.Element | string
    fullWidth?: boolean
    formControlProps?: FormControlProps
    value?: string
  }

const SelectField = (props: Props) => {
  const [selectValue, setSelectValue] = useState<string>(props.value || '')

  React.useEffect(() => {
    setSelectValue(props.value || '')
  }, [props.value])

  const onChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
    const value = (event.target as HTMLInputElement).value || ''
    setSelectValue(value)
    props.onChange ? props.onChange(event) : _.noop
  }

  const classes = {
    root: classNames(props.selectClasses?.root),
  }

  const formControlClasses = {
    root: classNames(styles['form-control'], { [styles['select-field--with-margins']]: props.withMargins }, props.classes?.root),
  }

  return (
    <FormControl
      {...props.formControlProps}
      fullWidth={props.fullWidth}
      classes={formControlClasses}
      variant={props.variant}
      disabled={props.disabled}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        {..._.omit(props, ['helperText', 'children', 'withMargins', 'selectClasses'])}
        classes={classes}
        displayEmpty
        value={selectValue}
        onChange={onChange}
      >
        {props.children}
      </Select>
      {props.helperText ? <FormHelperText error={props.error}>{props.helperText}</FormHelperText> : null}
    </FormControl>
  )
}

SelectField.defaultProps = defaultProps

export default SelectField
