import { FiltersType } from '../../interfaces/Filters.type'
import OfferType, { OfferFieldsValuesType } from '../../interfaces/Offer.type'

export enum types {
  CLEAR_CATALOG = 'CLEAR_CATALOG',
  CATALOG_SET_ITEMS = 'CATALOG_SET_ITEMS',
  CATALOG_SET_LOAD_MORE_URL = 'CATALOG_SET_LOAD_MORE_URL',
  CATALOG_SET_ITEMS_COUNTER = 'CATALOG_SET_ITEMS_COUNTER',
  CATALOG_SET_FILTERS = 'CATALOG_SET_FILTERS',
  CATALOG_SET_FIELDS_VALUES = 'CATALOG_SET_FIELDS_VALUES',
}

export interface ClearCatalogInterface {
  type: types.CLEAR_CATALOG
}

export interface SetItemsInterface {
  type: types.CATALOG_SET_ITEMS
  items: OfferType[]
}

export interface SetLoadMoreUrlInterface {
  type: types.CATALOG_SET_LOAD_MORE_URL
  loadMoreUrl: string | null
}

export interface SetItemsCounterInterface {
  type: types.CATALOG_SET_ITEMS_COUNTER
  itemsCounter: number | null
}

export interface SetFiltersInterface {
  type: types.CATALOG_SET_FILTERS
  filters: FiltersType | null
}

export interface SetFieldValuesInterface {
  type: types.CATALOG_SET_FIELDS_VALUES
  fieldsValues: OfferFieldsValuesType | null
}

export type ActionTypes = ClearCatalogInterface | SetItemsInterface | SetLoadMoreUrlInterface | SetItemsCounterInterface | SetFiltersInterface | SetFieldValuesInterface

export interface State {
  items: OfferType[]
  loadMoreUrl: string | null
  itemsCounter: number | null
  filters: FiltersType | null
  fieldsValues: OfferFieldsValuesType | null
}
