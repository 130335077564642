import { Typography } from '@material-ui/core'
import React from 'react'
import styles from '../AboutUsPage.module.scss'

const AboutUsPageDE = () => {
  const bodyClass = { root: styles['list__text'] }
  return (
    <>
      <Typography variant="h3" classes={{ root: styles['heading'] }}>
        Impressum
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Gesetzliche Anbieterkennung:
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        JP Whiskymarket.com GmbH
        <br />
        Oehlertring 1<br />
        12169 Berlin
        <br />
        Germany
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Vertreten durch die Geschäftsführer: Jakub Baginski und Piotr Kossowski
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Telefon: +49 (0) 152 5305 77 55
        <br />
        E-Mail: info@whiskymarket.com
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        eingetragen im Handelsregister des Amtsgericht Berlin (Charlottenburg)
        <br />
        Handelsregisternummer HRB198729 B
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Alternative Streitbeilegung:
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar
        unter{' '}
        <a href="https://ec.europa.eu/odr" rel="noreferrer" target="_blank" className="primary">
          https://ec.europa.eu/odr
        </a>
        .
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Wir sind nicht bereit, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.
      </Typography>
    </>
  )
}

AboutUsPageDE.getInitialProps = function() {
  return {}
}

export default AboutUsPageDE
