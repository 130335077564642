import { InputAdornment, MenuItem } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import { FormikProps } from 'formik'
import _ from 'lodash'
import React, { useState } from 'react'
import { SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest'
import styles from './FormFieldCountry.module.scss'
import countriesi18n from '../../../utils/countries'
import FormAutocompleteField from '../FormAutocompleteField'

const defaultProps = Object.freeze({
  fieldName: 'country' as string,
  language: 'en' as string,
  withMargins: true as boolean,
})

type Props<FormValuesType> = typeof defaultProps & {
  form: FormikProps<FormValuesType>
  helperText?: string
  placeholder?: string
  label: React.ReactNode | string
  formControlProps?: TextFieldProps
  field: {
    name: string
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onBlur: (event: any) => void
  }
  classes?: {
    root?: string
  }
}

function renderTextSuggestion(item: string, { query, isHighlighted }: { query: string; isHighlighted: boolean }) {
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div className={styles['autocompelte__input']}>{item}</div>
    </MenuItem>
  )
}

const FormFieldCountry = <FormValuesType extends {}>(props: Props<FormValuesType>) => {
  const { form, fieldName, language, helperText, placeholder, formControlProps, label, classes } = props
  const [countries, setCountries] = useState<string[]>([])

  const onSelected = (value: string) => {
    form.setFieldTouched(fieldName)
    form.setFieldValue(fieldName, value)
  }

  const getSuggestions = (value: string) => {
    const inputValue = _.deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    if (!inputLength) return

    const _countries = Object.values(countriesi18n.getNames(language))
    const suggestions = _countries.filter((suggestion: string) => {
      const keep = count < 20 && suggestion.slice(0, inputLength).toLowerCase() === inputValue

      if (keep) {
        count += 1
      }

      return keep
    })
    setCountries(suggestions)
  }

  const field = {
    name: fieldName,
    value: (form.values[fieldName as keyof FormValuesType] as any) as string,
    onChange: form.handleChange,
    onBlur: form.handleBlur,
  }

  return (
    <FormAutocompleteField<string, FormValuesType>
      form={form}
      field={field}
      label={label}
      helperText={helperText}
      formControlProps={{
        ...formControlProps,
        fullWidth: true,
      }}
      inputProps={field}
      InputProps={{
        placeholder,
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="primary" />
          </InputAdornment>
        ),
      }}
      autocompleteClasses={{ container: classes?.root! }}
      suggestions={countries}
      getSuggestionValue={(item: string) => item}
      onSuggestionsClearRequested={() => {}}
      onSuggestionsFetchRequested={(request: SuggestionsFetchRequestedParams) => {
        getSuggestions(request.value)
      }}
      onSuggestionSelected={(event: React.FormEvent<any>, data: SuggestionSelectedEventData<string>) => {
        onSelected(data.suggestionValue)
      }}
      renderSuggestion={renderTextSuggestion}
      withMargins={props.withMargins}
    />
  )
}
FormFieldCountry.defaultProps = defaultProps

export default FormFieldCountry
