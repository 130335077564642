import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './SellPage.module.scss'
import LoadingSpinner from '../../components/LoadingSpinner'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import SellForm from '../../components/SellForm'
import Trans from '../../components/Trans'
import { SellTopic } from '../../interfaces/Contact.type'
import * as api from '../../services/contact/contact.api'

const SellPage = () => {
  const [topics, setTopics] = React.useState<SellTopic[]>()

  React.useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await api.getSellTopics()
        setTopics(response.data.topics)
      } catch (err) {
        console.error(err)
      }
    }

    fetchTopics()
  }, [])

  return (
    <PageLayout classes={classNames(styles['container'], pageStyles['content--sides-padding'])}>
      <div className={styles['content']}>
        {topics ? (
          <>
            <div className={styles['info__container']}>
              <div className={styles['promo']}>
                <div className={styles['promo__text']}>
                  <div>
                    <b>SELL SPIRITS</b> <span>ON-LINE</span>
                  </div>
                </div>
              </div>
              <div className={styles['info']}>
                <div className={styles['info__line--1']}>
                  Looking to sell a bottle? Or a whole collection? That’s great! Let our Team do the job for you.
                </div>
              </div>
              <div className={styles['fill-in']}>
                <div className={styles['fill-in__arrows']}>
                  <div className={styles['fill-in__arrow']} />
                  <div className={styles['fill-in__arrow']} />
                </div>
                <div className={styles['fill-in__lines']}>
                  <span className={classNames(styles['fill-in__line'], styles['fill-in__line--1'])}>FILL IN THE FORM</span>
                  <span className={classNames(styles['fill-in__line'], styles['fill-in__line--2'])}>SAVE YOUR TIME</span>
                </div>
              </div>
            </div>
            <div className={styles['form__container']}>
              <Typography variant="h3">
                <Trans ns="SellForm" id="Heading" msg="Sell" />
              </Typography>
              <div className={styles['sell']} />
              <SellForm topics={topics} />
            </div>
          </>
        ) : (
          <LoadingSpinner center />
        )}
      </div>
    </PageLayout>
  )
}

export default SellPage
