type FacebookCallbackType = (response: fb.StatusResponse) => void

export const FacebookLogin = (successCallback: FacebookCallbackType | null = null, errorCallback: FacebookCallbackType | null = null) => {
  FB.getLoginStatus(function (response: fb.StatusResponse) {
    console.log('fb stat', response)
    if (response.status === 'connected') {
      FB.logout(function (logoutResponse: fb.StatusResponse) {
        console.log('Logged Out!')
      })
    } else {
      FB.login(
        function (loginResponse: fb.StatusResponse) {
          if (loginResponse.authResponse) {
            successCallback ? successCallback(loginResponse) : null
          } else {
            errorCallback ? errorCallback(loginResponse) : null
            console.error('FB auth error', loginResponse)
          }
        },
        {
          scope: 'email',
          auth_type: 'reauthenticate',
        },
      )
    }
  })
}
