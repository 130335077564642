import { ActionsType, ActionTypes } from './auth.types'
import UserType from '../../interfaces/User.type'


export function signInSuccess(data: UserType): ActionsType {
  return {
    type: ActionTypes.SIGN_IN_SUCCESS,
    payload: data,
  }
}

export function signUpSuccess(data: UserType): ActionsType {
  return {
    type: ActionTypes.SIGN_UP_SUCCESS,
    payload: data,
  }
}

export function logOutSuccess(): ActionsType {
  return {
    type: ActionTypes.LOG_OUT_SUCCESS,
  }
}
