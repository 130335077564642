import { InputAdornment, MenuItem } from '@material-ui/core'
import countries from 'country-codes-list'
import { FormikProps } from 'formik'
import React from 'react'
import styles from './FormBusiness.module.scss'
import FormDateField from '../../../Form/FormDateField'
import FormFieldCountry from '../../../Form/FormFieldCountry'
import FormFieldPhoneCode from '../../../Form/FormFieldPhoneCode'
import FormSelectField from '../../../Form/FormSelectField'
import FormSwitchField from '../../../Form/FormSwitchField'
import FormTextField from '../../../Form/FormTextField'
import { SettingsFormType } from '../UserSettings.types'

type Props = {
  formik: FormikProps<SettingsFormType>
  formRef: React.RefObject<HTMLFormElement>
}

const FormPrivate = (props: Props) => {
  const { formik, formRef } = props
  const phoneCodes = countries.customList('countryCode', '{countryCallingCode}')
  const phoneItems: JSX.Element[] = []

  for (const key in phoneCodes) {
    const value = phoneCodes[key]
    if (!value) continue
    phoneItems.push(
      <MenuItem value={value} key={`pc-${key}`}>
        <span className={`flag-icon flag-icon-${key.toLowerCase()}`} />
        <span>{value}</span>
      </MenuItem>,
    )
  }

  return (
    <form onSubmit={formik.handleSubmit} ref={formRef}>
      <FormTextField
        form={formik}
        field={{
          name: 'companyName',
          value: formik.values.companyName,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Company name*"
        fullWidth
        helperText="Required for verification"
      />

      <FormTextField
        form={formik}
        field={{
          name: 'address1',
          value: formik.values.address1,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Address 1"
        fullWidth
      />
      <FormTextField
        form={formik}
        field={{
          name: 'address2',
          value: formik.values.address2,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Address 2"
        fullWidth
      />
      <div className={styles['form__location']}>
        <FormTextField
          form={formik}
          field={{
            name: 'postCode',
            value: formik.values.postCode,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="ZIP Code"
          classes={{ root: styles['location__postCode'] }}
        />
        <FormTextField
          form={formik}
          field={{
            name: 'city',
            value: formik.values.city,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="City"
          classes={{ root: styles['location__city'] }}
        />
      </div>
      <FormFieldCountry<SettingsFormType>
        form={formik}
        fieldName="country"
        field={{
          name: 'country',
          value: formik.values.country,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Country"
        helperText="Requried for verification"
      />

      <div className={styles['form__gst']}>
        <FormSwitchField
          form={formik}
          field={{
            name: 'gst',
            value: formik.values.gst,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="VAT/GST"
          classes={{ root: styles['gst__switch'] }}
        />
        <FormTextField
          InputProps={{ disabled: !formik.values.gst, startAdornment: <InputAdornment position="start">%</InputAdornment> }}
          inputProps={{
            min: '0',
            type: 'number',
          }}
          form={formik}
          field={{
            name: 'gstRate',
            value: formik.values.gstRate,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          classes={{ root: styles['gst__rate'] }}
        />
      </div>

      <div className={styles['form__phone']}>
        <FormFieldPhoneCode
          form={formik}
          field={{
            name: 'phoneCode',
            value: formik.values.phoneCode,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="Phone Code*"
          classes={{ root: styles['phone__code'] }}
        />
        <FormTextField
          form={formik}
          field={{
            name: 'phone',
            value: formik.values.phone,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label="Phone number*"
          classes={{ root: styles['phone__number'] }}
        />
      </div>

      <FormTextField
        form={formik}
        field={{
          name: 'taxId',
          value: formik.values.taxId,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Company ID/Tax number*"
      />
      <FormTextField
        form={formik}
        field={{
          name: 'website',
          value: formik.values.website,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="Website"
      />

      <FormTextField
        form={formik}
        field={{
          name: 'aboutMe',
          value: formik.values.aboutMe,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        label="About Company"
        InputProps={{
          multiline: true,
          rows: 5,
        }}
      />
    </form>
  )
}

export default FormPrivate
