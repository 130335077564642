import { Button, Divider, Typography } from '@material-ui/core'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import Dinero from 'dinero.js'
import React from 'react'
import styles from './OfferTileItem.module.scss'
import OfferType from '../../interfaces/Offer.type'
import countries from '../../utils/countries'
import { priceToInt } from '../../utils/tax'
import Img from '../Img'
import Link, { routes } from '../Link'
import FavoriteButton from '../UI/FavoriteButton'
import ShareButton from '../UI/ShareButton'

type ClassesType = {
  item: string
}

const defaultProps = Object.freeze({
  language: 'en' as string,
})

type Props = typeof defaultProps & {
  offer: OfferType
  classes?: ClassesType
  isFavorite: boolean
  onShareClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => void
  onAddToFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => void
  onRemoveFromFavorite: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, offer: OfferType) => void
}

const OfferTileItem = (props: Props) => {
  const { classes, offer } = props

  //@ts-ignore
  const priceGross = new BigNumber(offer.priceGross).multipliedBy(100).toString()
  const priceGrossFormatted = Dinero({ amount: priceToInt(priceGross) }).toFormat('0,0.00')
  //@ts-ignore
  const priceNet = new BigNumber(offer.priceNet).multipliedBy(100).toString()
  const priceNetFormatted = Dinero({ amount: priceToInt(priceNet) }).toFormat('0,0.00')

  const netIsGross = new BigNumber(offer.priceGross).isEqualTo(new BigNumber(offer.priceNet))
  //@ts-ignore
  const pricePerLiter = new BigNumber(offer.pricePerLiterGross).multipliedBy(100).toString()
  const pricePerLiterFormatted = Dinero({ amount: priceToInt(pricePerLiter) }).toFormat('0,0.00')

  return (
    <div className={classNames(styles['item'], classes?.item)}>
      {/*
      <div className={styles['item__favorite']}>
        <div className={styles['favorite__icon']}></div>
      </div>
      <div className={styles['item__list']}>
        <div className={styles['list__icon']}></div>
      </div>
      */}
      <Link to={routes.offer} params={{ sku: offer.sku, slug: offer.slug }} silent>
        <div className={styles['item__photo']}>
          <Img
            classes={classNames(styles['item__image'], !offer.isActive && styles['item__image--inactive'])}
            src={offer.photos.catalog[0].src}
          />
          <div className={styles['item__country']}>
            <span className={`flag-icon flag-icon-${offer.bottleLocation.toLowerCase()}`} />
            <span className={styles['country__name']}> {countries.alpha2ToAlpha3(offer.bottleLocation)}</span>
          </div>
          {offer.isLastCall && (
            <div className={styles['item__badge__container']}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                classes={{
                  root: styles['item__badge'],
                  label: classNames(styles['item__badge__label'], styles['item__badge__label--last-call']),
                }}
              >
                Last call
              </Button>
            </div>
          )}
          {(!offer.isActive || offer.isArchived) && (
            <div className={styles['item__badge__container']}>
              <Button
                size="small"
                color="default"
                variant="contained"
                classes={{
                  root: styles['item__badge'],
                  label: classNames(styles['item__badge__label'], styles['item__badge__label--archived']),
                }}
              >
                Archived
              </Button>
            </div>
          )}
        </div>
        <div className={styles['item__details']}>
          <Typography variant="h6" classes={{ root: styles['details__title'] }}>
            {offer.title}
          </Typography>
          <Divider classes={{ root: styles['item__divider'] }} />

          <div className={styles['details__box']}>
            <div className={styles['details__price']}>
              <Typography variant="h5" classes={{ root: styles['price__gross'] }}>
                {priceGrossFormatted} EUR
              </Typography>
              <Typography variant="caption"> {netIsGross ? <p>&nbsp;</p> : `${priceNetFormatted} EUR Net`}</Typography>
              <Typography variant="caption">{pricePerLiterFormatted} EUR / Liter</Typography>
            </div>

            <div className={styles['details__offer']}>
              <div className={styles['details__row']}>
                {offer.isActive && !offer.isArchived ? (
                  <>
                    {offer.availability.slug ? (
                      <Img src={`/icons/availability_${offer.availability.slug}.svg`} classes={styles['details__icon']} />
                    ) : null}
                    <span>Available{/*offer.availability.name*/}&nbsp;</span>
                  </>
                ) : (
                  <>
                    <Img src="/icons/availability_sold.svg" classes={styles['details__icon']} />
                    <span className={styles['availability__name']}>Archived</span>
                  </>
                )}
              </div>
              <div className={styles['details__row']}>
                <FavoriteButton
                  onAdd={e => props.onAddToFavorite(e, props.offer)}
                  onRemove={e => props.onRemoveFromFavorite(e, props.offer)}
                  isFavorite={props.isFavorite}
                />
                <ShareButton onClick={e => props.onShareClick(e, props.offer)} />
                {/* <div className={styles['user__row']}>
                  <Img src="/icons/user.svg" classes={styles['details__icon']} />
                  <span className={styles['user__username']}>{offer.user.username}</span>
                </div>
                <Typography variant="caption">{offer.user.isBusiness ? 'Professional' : 'Basic'}</Typography> */}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

OfferTileItem.defaultProps = defaultProps

export default OfferTileItem
