import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { Redirect } from 'react-router-dom'
import styles from './ForgotPasswordPage.module.scss'
import ForgotPasswordForm from '../../components/ForgotPasswordForm'
import { routes } from '../../components/Link'
import PageLayout from '../../components/PageLayout/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import Trans from '../../components/Trans/Trans'
import useAuth from '../../hooks/auth/auth'

type Props = {}

const ForgotPasswordPage = (props: Props) => {
  const { isAuth } = useAuth()

  return isAuth ? (
    <Redirect to={routes.home} />
  ) : (
    <PageLayout classes={classNames(styles['forgot-password-page'], pageStyles['content--sides-padding'])}>
      <Typography variant="h3">Forgot your password?</Typography>
      <div className={styles['new-user']}>
        <Trans
          id="ForgotPasswordPage.SendRecoveryLink"
          msg="Don’t worry, we'll send recovery link to your mailbox."
          ns="ForgotPasswordPage"
        />
        <br />
        <Trans
          id="ForgotPasswordPage.EnterAnEmail"
          msg="Please enter an email associated with your Whiskymarket account."
          ns="ForgotPasswordPage"
        />
      </div>
      <ForgotPasswordForm />
    </PageLayout>
  )
}

ForgotPasswordPage.getInitialProps = async function () {
  return {}
}

export default ForgotPasswordPage
