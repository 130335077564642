import classNames from 'classnames'
import React from 'react'
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner'
import PageLayout from '../../../components/PageLayout'
import pageStyles from '../../../components/PageLayout/PageLayout.module.scss'
import UserFavoriteOffers from '../../../components/User/UserFavoriteOffers'
import UserContext from '../../../contexts/UserContext'
import useOffer from '../../../hooks/offer'
import OfferType from '../../../interfaces/Offer.type'
import UserType, { UserDataType } from '../../../interfaces/User.type'

const defaultProps = Object.freeze({})
type Props = typeof defaultProps

const OffersFavoritePage = (props: Props) => {
  const userContext: UserType = React.useContext(UserContext)
  const [items, setItems] = React.useState<OfferType[] | null>(null)
  const [user] = React.useState<UserDataType>(userContext.data!)
  const { getFavorites } = useOffer()

  React.useEffect(() => {
    const init = async () => {
      setItems(await getFavorites())
    }
    
    init()
  }, [])

  return (
    <PageLayout classes={classNames(pageStyles['content--sides-padding'])}>
      {items == null ? <LoadingSpinner center /> : <UserFavoriteOffers user={user} items={items} />}
    </PageLayout>
  )
}

OffersFavoritePage.defaultProps = defaultProps

export default OffersFavoritePage
