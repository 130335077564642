import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { Redirect } from 'react-router-dom'
import styles from './SignUpPage.module.scss'
import Img from '../../components/Img/Img'
import Link, { routes } from '../../components/Link'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import SignUpForm from '../../components/SignUpForm'
import Trans from '../../components/Trans'
import useAuth from '../../hooks/auth'

type Props = {}

const SignUpPage = (props: Props) => {
  const { isAuth } = useAuth()
  if (isAuth) {
    return <Redirect to={routes.home} />
  }
  return (
    <PageLayout classes={classNames(styles['sign-up-page'], pageStyles['content--sides-padding'])}>
      <div className={styles['content']}>
        <div className={styles['info__container']}>
          <div className={styles['promo']}>
            <div className={styles['promo__text']}>
              <div>
                <b>Have</b> <span>Access to the worlds’s finest and rarest spirits online.</span>
              </div>
              <div>
                <b>Buy</b> <span>And sell as many bottles as you want.</span>
              </div>
              <div>
                <b>Use</b> <span>Our concierge service.</span>
              </div>
              <div>
                <b>Save</b> <span>your time.</span>
              </div>
            </div>
          </div>
          <Img classes={styles['info__icon']} src="/img/sign_up__free_of_charge.svg" />
          <div className={styles['become']}>
            <div className={styles['become__arrows']}>
              <div className={styles['become__arrow']} />
              <div className={styles['become__arrow']} />
            </div>
            <div className={styles['become__lines']}>
              <span className={classNames(styles['become__line'], styles['become__line--1'])}>SIGN UP AND BECOME</span>
              <span className={classNames(styles['become__line'], styles['become__line--2'])}>ONE OF US</span>
            </div>
          </div>
          <div className={styles['info']}>
            <div className={styles['info__line--1']}>
              Regardless who you are - a whisky trader a private collector, or a shop owner this is the place to be!
            </div>
            <div className={styles['info__line--2']}>
              Next time you’ll be searching for your beloved bottle it will be easier than ever before! Our concierge service is tailor made
              to meet your highest expectations.
            </div>
          </div>
        </div>
        <div className={styles['form__container']}>
          <Typography variant="h3">Sign Up</Typography>
          <div className={styles['already-exist']}>
            <Trans id="SignUpPage.AlreadyExist" msg="Already have an account?" ns="SignUpPage" />
            &nbsp;
            <Link to={routes.signIn} classes="primary">
              <Trans id="SignUpPage.SignIn" msg="Sign In" ns="SignUpPage" />
            </Link>
          </div>
          <SignUpForm />
        </div>
      </div>
    </PageLayout>
  )
}

export default SignUpPage
