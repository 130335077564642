import styles from '../shared.module.scss'

export default {
  root: {
    // color: styles['color__gray1']
  },
  icon: {
    color: styles['color__primary'],
  },
}
