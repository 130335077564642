import styles from '../shared.module.scss'

export default {
  root: {
    width: '100%',
    marginBottom: '0px',
    background: '#fff',
    borderColor: styles['color__gray1'],
    '&$focused $notchedOutline': {
      borderWidth: '2px',
      borderColor: styles['color__gray1'],
    },

    '&$disabled': {
      backgroundColor: styles['color__disabled'],
    },
  },
  notchedOutline: {
    borderColor: styles['color__gray1'],
  },
  input: {
    padding: '10px',
  },
}
