import { ActionTypes, types } from './offer.types'
import OfferType from '../../interfaces/Offer.type'

export function clearFavoriteOffers(): ActionTypes {
  return {
    type: types.CLEAR_FAVORITE_OFFERS,
  }
}

export function setFavoriteOffers(offerIds: string[]): ActionTypes {
  return {
    type: types.SET_FAVORITE_OFFERS,
    offerIds,
  }
}

export function addOfferToFavorite(offer: OfferType): ActionTypes {
  return {
    type: types.ADD_OFFER_TO_FAVORITE,
    offer,
  }
}

export function removeOfferFromFavorite(offer: OfferType): ActionTypes {
  return {
    type: types.REMOVE_OFFER_FROM_FAVORITE,
    offer,
  }
}

export function temporarilyRemoveOfferFromFavorite(offer: OfferType): ActionTypes {
  return {
    type: types.TEMPORARILY_REMOVE_OFFER_FROM_FAVORITE,
    offer,
  }
}
