import { FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import React from 'react'
import ChildrenType from '../../../interfaces/Children.type'
import RadioField from '../../UI/RadioField'

type Props = {
  field: {
    name: string
    value: any
    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
    onBlur: (event: React.ChangeEvent<HTMLDivElement>) => void
  }
  form: FormikProps<FormikValues>
  label: React.ReactNode | string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  emptyHelperText?: boolean
  children: ChildrenType
  classes?: {
    root?: string
  }
}

const FormRadioField: React.FC<Props> = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
  return (
    <RadioField
      {..._.pick(props, ['type', 'label', 'fullWidth', 'classes', 'emptyHelperText'])}
      {...fields}
      error={Boolean(_.has(touched, fields.name) && _.has(errors, fields.name))}
      helperText={_.has(touched, fields.name) && _.has(errors, fields.name) ? (_.get(errors, fields.name) as string) : ''}
    >
      {props.children}
    </RadioField>
  )
}

export default FormRadioField
