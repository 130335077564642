import { MenuItem } from '@material-ui/core'
import { Field, FieldProps, FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import { SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest'
import styles from './OfferForm.module.scss'
import { AutocompleteFieldType } from './OfferForm.types'
import { Props } from './OfferFormView'
import { OfferSelectFieldValueType } from '../../interfaces/Offer.type'
import FormAutocompleteField from '../Form/FormAutocompleteField'
import FormCurrencyField from '../Form/FormCurrencyField'
import FormDateField from '../Form/FormDateField'
import FormSelectField from '../Form/FormSelectField'
import FormTextField from '../Form/FormTextField'

const renderFormField = (
  component: React.ReactNode,
  name: string,
  type: string,
  label: React.ReactNode,
  props: object = {},
  classes: object = { root: styles['form__grid--half'] },
) => {
  return <Field name={name} label={label} component={component} fullWidth type={type} classes={classes} {...props} />
}

export function renderSelectField(name: string, label: React.ReactNode, classes: object, values: any, props: object = {}) {
  return (
    <Field name={name} label={label} component={FormSelectField} fullWidth classes={classes} {...props}>
      <MenuItem value="">Choose</MenuItem>
      {_.isArray(values) &&
        values.map((item: OfferSelectFieldValueType) => {
          return (
            <MenuItem value={item.id} key={name + '-' + item.id}>
              {item.name}
            </MenuItem>
          )
        })}
    </Field>
  )
}

export function renderTextField(
  name: string,
  type: string,
  label: React.ReactNode,
  props: object = {},
  classes: object = { root: styles['form__grid--half'] },
) {
  return renderFormField(FormTextField, name, type, label, props, classes)
}

export function renderDateField(
  name: string,
  label: React.ReactNode,
  props: object = {},
  classes: object = { root: styles['form__grid--half'] },
) {
  return renderFormField(FormDateField, name, 'number', label, props, classes)
}

export function renderCurrencyField(
  props: Props,
  name: string,
  label: React.ReactNode,
  fieldProps: object = {},
  classes: object = { root: styles['form__grid--half'] },
) {
  const allProps = {
    ..._.cloneDeep(fieldProps),
    ...{
      currencySign: props.currencySign,
      maxValue: props.maxPrice,
    },
  }

  return renderFormField(FormCurrencyField, name, 'string', label, allProps, classes)
}

export function renderAutocompleteField<SuggestionT, FormT>(
  label: React.ReactNode,
  autoField: AutocompleteFieldType<SuggestionT, FormT>,
  fieldProps: FieldProps<string, FormT>,
  formikBag: FormikProps<FormT>,
  extraProps?: any,
) {
  return (
    <FormAutocompleteField<SuggestionT, FormT>
      {...fieldProps}
      inputProps={{
        ...fieldProps.field,
        /*
        onChange: (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          extraProps?.onChange()!
          autoField.onSelected(event.currentTarget?.value, formikBag)
        },
        */
      }}
      getSuggestionValue={autoField.getSuggestionValue}
      suggestions={autoField.suggestions}
      renderSuggestion={autoField.renderSuggestion}
      onSuggestionsClearRequested={autoField.onSuggestionsClearRequested}
      onSuggestionsFetchRequested={(request: SuggestionsFetchRequestedParams) => {
        autoField.onSuggestionsFetchRequested(request, formikBag)
      }}
      onSuggestionSelected={(event: React.FormEvent<any>, data: SuggestionSelectedEventData<SuggestionT>) => {
        autoField.onSuggestionSelected(event, data, formikBag)
      }}
      label={label}
      formControlProps={{
        fullWidth: true,
      }}
      field={{
        ...fieldProps.field,
        onChange: (event: React.BaseSyntheticEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          const value = event.currentTarget?.value || event.target?.innerText || ''
          autoField.onSelected(value, formikBag)
          extraProps?.onChange ? extraProps.onChange(value) : null
        },
      }}
      {...extraProps}
    />
  )
}

export function renderAutocompleteSelectField<SuggestionT, FormT>(
  label: React.ReactNode,
  autoField: AutocompleteFieldType<SuggestionT, FormT>,
  fieldProps: FieldProps<string, FormT>,
  formikBag: FormikProps<FormT>,
  extraProps?: any,
) {
  return (
    <FormAutocompleteField<SuggestionT, FormT>
      {...fieldProps}
      inputProps={{
        ...fieldProps.field,
      }}
      getSuggestionValue={autoField.getSuggestionValue}
      suggestions={autoField.suggestions}
      renderSuggestion={autoField.renderSuggestion}
      onSuggestionsClearRequested={autoField.onSuggestionsClearRequested}
      onSuggestionsFetchRequested={(request: SuggestionsFetchRequestedParams) => {
        autoField.onSuggestionsFetchRequested(request, formikBag)
      }}
      onSuggestionSelected={(event: React.FormEvent<any>, data: SuggestionSelectedEventData<SuggestionT>) => {
        autoField.onSuggestionSelected(event, data, formikBag)
      }}
      label={label}
      formControlProps={{
        fullWidth: true,
      }}
      field={{
        ...fieldProps.field,
        onChange: (event: React.BaseSyntheticEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          const value = event.currentTarget?.value || event.target?.innerText || ''
          autoField.onSelected(value.trim(), formikBag)
          extraProps?.onChange ? extraProps.onChange(value) : null
        },
        onBlur: event => {
          if (!autoField.correct) {
            autoField.onSelected('', formikBag)
          }
        },
      }}
      {...extraProps}
    />
  )
}
