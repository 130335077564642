import LanguageType from '../../interfaces/Language.type'

export enum types {
  AGE_AGREEMENT_ACCEPT = 'AGE_AGREEMENT_ACCEPT',
  COOKIES_ACCEPT = 'COOKIES_ACCEPT',
  COOKIES_REFUSE = 'COOKIES_REFUSE',
}

export interface AgeAgreementAcceptInterface {
  type: types.AGE_AGREEMENT_ACCEPT
}

export interface CookiesAcceptInterface {
  type: types.COOKIES_ACCEPT
}

export interface CookiesRefuseInterface {
  type: types.COOKIES_REFUSE
}

export type ActionTypes = AgeAgreementAcceptInterface | CookiesAcceptInterface | CookiesRefuseInterface

export interface State {
  language: LanguageType
  acceptedAgeAgreement: boolean
  acceptedCookies: boolean
}
