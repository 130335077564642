import classNames from 'classnames'
import React from 'react'
import styles from './OfferAdItem.module.scss'
import { OfferAdType } from '../../interfaces/Offer.type'
import Img from '../Img'
import Link from '../Link'

type ClassesType = {
  item: string
}

type Props = {
  ad: OfferAdType
  classes?: ClassesType
}

const OfferAdItem = (props: Props) => {
  return (
    <div className={classNames(styles['item'], props.classes?.item)}>
      <Link to={props.ad.url} silent>
        <Img
          classes={styles['item__image']}
          src={props.ad.image}
        />
      </Link>
    </div>
  )
}

export default OfferAdItem
