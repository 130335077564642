import { Button, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router'
import styles from './BuyPage.module.scss'
import Img from '../../components/Img'
import { routes } from '../../components/Link'
import PageLayout from '../../components/PageLayout/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import Trans from '../../components/Trans'

const icons = [
  'all-in-one.svg',
  'buy-and-sell.svg',
  'quick-and-easy.svg',
  'safe-and-secure.svg',
  'for-everyone.svg',
  'worldwide.svg',
  'advisory.svg',
  'support.svg',
]

const BuyPage = () => {
  const history = useHistory()

  const handleButtonClick = () => {
    history.push(routes.catalog)
  }

  return (
    <PageLayout classes={classNames(pageStyles['content--sides-padding'], styles['page'])}>
      <Typography className={styles['title']} variant="h3">
        <Trans ns="BuyPage" id="Heading" msg="Buy" />
      </Typography>
      <div className={styles['container']}>
        <div className={styles['features-circle']}>
          <Img src="/img/logo_landing.svg" classes={styles['logo']} />
          <div className={styles['features-circle__info']}>
            <Trans
              ns="BuyPage"
              id="CircleInfo"
              msg="is the world’s marketplace for allwhisky enthusiasts searching for selling or buying spirits."
            />
          </div>
          <Button className={styles['features-circle__button']} onClick={handleButtonClick} variant="contained" color="primary" fullWidth>
            <Trans ns="BuyPage" id="CallToAction" msg="Let’s start" />
          </Button>
        </div>
        <ul className={styles['features-list']}>
          {icons.map(icon => (
            <li key={icon} className={classNames(styles['features-list__item'])}>
              <Img src={`/img/buy/${icon}`} />
            </li>
          ))}
        </ul>
        <div className={classNames(styles['feature'], styles['feature--all-in-one'])}>
          <Typography className={styles['feature__title']} variant="h3" color="primary">
            <Trans ns="BuyPage" id="AllInOnelHeading" msg="All-in-One" />
          </Typography>
          <Typography className={styles['feature__desc']} variant="body1">
            Facebook, Instagram, whisky forums, and on-line shops in <b>one e-place</b>. Searching for your favorite bottles has become{' '}
            <b>easier than ever before</b>.
          </Typography>
        </div>
        <div className={classNames(styles['feature'], styles['feature--buy-and-sell'])}>
          <Typography className={styles['feature__title']} variant="h3" color="primary">
            <Trans ns="BuyPage" id="BuyAndSellHeading" msg="Buy & Sell Spirits Online" />
          </Typography>
          <Typography className={styles['feature__desc']} variant="body1">
            <b>Regardless who you are</b> - a whisky trader, a private collector or a shop owner - whiskymarket.com{' '}
            <b>is the place to be!</b>
          </Typography>
        </div>
        <div className={classNames(styles['feature'], styles['feature--quick-and-easy'])}>
          <Typography className={styles['feature__title']} variant="h3" color="primary">
            <Trans ns="BuyPage" id="QuickAndEasyHeading" msg="Quick & Easy" />
          </Typography>
          <Typography className={styles['feature__desc']} variant="body1">
            <b>It takes just 15 seconds</b> to become a member of the world’s whisky marketplace and search for your favorite bottles. And
            it’s <b>FREE of charge!</b>
          </Typography>
        </div>
        <div className={classNames(styles['feature'], styles['feature--secure'])}>
          <Typography className={styles['feature__title']} variant="h3" color="primary">
            <Trans ns="BuyPage" id="SafeAndSecureHeading" msg="Safe & Secure" />
          </Typography>
          <Typography className={styles['feature__desc']} variant="body1">
            New to the market? Afraid of shipping worldwide? No problem! Our <b>concierge</b> will walk you through a single transaction and
            handle the logistics for you. <b>Just sign up!</b>
          </Typography>
        </div>
        <Button className={styles['button']} onClick={handleButtonClick} variant="contained" color="primary" fullWidth>
          <Trans ns="BuyPage" id="CallToAction" msg="Let’s start" />
        </Button>
      </div>
    </PageLayout>
  )
}

export default BuyPage
