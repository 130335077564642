import React from 'react'
import styles from './ShareButton.module.scss'
import Img from '../../Img'

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ShareButton = (props: Props) => {
  return (
    <button className={styles['button']} type="button" onClick={e => props.onClick(e)}>
      <Img classes={styles['icon']} src="/icons/share.svg" />
    </button>
  )
}

export default ShareButton
