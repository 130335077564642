import { MenuItem } from '@material-ui/core'
import { FormikHelpers, useFormik } from 'formik'
import React from 'react'
import { SortByType } from './SortBy.types'
import * as SortByTypes from '../../../interfaces/Filters.type'
import FormSelectField from '../../Form/FormSelectField'

type Classes = {
  root?: string
}

type Props = {
  classes?: Classes
  order: string
  direction: string
  onChange: (order: SortByTypes.SortByOrderType, direction: SortByTypes.SortByDirectionType) => void
}

type SelectValue = {
  name: string
  value: string
  order: SortByTypes.SortByOrderType
  direction: SortByTypes.SortByDirectionType
}

const sortByValues: SelectValue[] = ([
  { name: 'Price: Low to High', order: SortByTypes.ORDER_PRICE_GROSS, direction: SortByTypes.DIRECTION_ASC },
  { name: 'Price: High to Low', order: SortByTypes.ORDER_PRICE_GROSS, direction: SortByTypes.DIRECTION_DESC },
  { name: 'Added: Newest to Oldest', order: SortByTypes.ORDER_CREATED_AT, direction: SortByTypes.DIRECTION_DESC },
  { name: 'Added: Oldest to Newest', order: SortByTypes.ORDER_CREATED_AT, direction: SortByTypes.DIRECTION_ASC },
  { name: 'Alphabetical: A-Z', order: SortByTypes.ORDER_TITLE, direction: SortByTypes.DIRECTION_ASC },
  { name: 'Alphabetical: Z-A', order: SortByTypes.ORDER_TITLE, direction: SortByTypes.DIRECTION_DESC },
  { name: 'Expiration date: Short to Long', order: SortByTypes.ORDER_EXPIRES_AT, direction: SortByTypes.DIRECTION_ASC },
  { name: 'Expiration date: Long to Short', order: SortByTypes.ORDER_EXPIRES_AT, direction: SortByTypes.DIRECTION_DESC },
  { name: 'Updated: Recent to Oldest', order: SortByTypes.ORDER_UPDATED_AT, direction: SortByTypes.DIRECTION_DESC },
  { name: 'Updated: Oldest to Recent', order: SortByTypes.ORDER_UPDATED_AT, direction: SortByTypes.DIRECTION_ASC },
] as any[]).map((item: SelectValue) => {
  item.value = `${item.order}-${item.direction}`
  return item
})

const SortBy = (props: Props) => {
  const defaultValue = sortByValues.find(item => item.order === props.order && item.direction === props.direction) || sortByValues[0]

  const formik = useFormik({
    initialValues: {
      sortBy: defaultValue.value,
    },
    onSubmit: (values: SortByType, formikBag: FormikHelpers<SortByType>) => {
      const sortByValue = sortByValues.find(item => item.value === values.sortBy)
      if (sortByValue) {
        props.onChange(sortByValue.order, sortByValue.direction)
      }
    },
  })

  return (
    <div className={props.classes?.root}>
      <FormSelectField
        classes={{ root: props.classes?.root }}
        form={formik}
        label="Sort By"
        field={{
          name: 'sortBy',
          value: formik.values['sortBy'],
          onChange: e => {
            formik.handleChange(e)
            formik.submitForm()
          },
          onBlur: formik.handleBlur,
        }}
        fullWidth
      >
        {sortByValues.map(item => (
          <MenuItem value={item.value} key={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </FormSelectField>
    </div>
  )
}

export default SortBy
