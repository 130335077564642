import classNames from 'classnames'
import React from 'react'
import styles from './Logo.module.scss'
import Img from '../Img'

const defaultProps = Object.freeze({
  classes: '' as string,
  mono: false as boolean,
  dialog: false as boolean,
})

type Props = typeof defaultProps & { dark?: boolean }

const Logo = (props: Props) => {
  let file = '/img/logo.png'

  if (props.dark) file = 'img/logo_dark.svg'
  const { classes } = props
  const className = classNames(classes, styles['header__logo'], { [styles['logo__dialog']]: props.dialog })
  const fileName = props.mono ? '/img/logo_mono.svg' : '/img/logo.svg'

  return <Img src={fileName} classes={className} alt="Logo" />

}

Logo.defaultProps = defaultProps

export default Logo
