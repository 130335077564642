import { FormControlLabel, Radio, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './MyAccount.module.scss'
import { UserType } from '../../../../interfaces/User.type'
import Img from '../../../Img'
import Trans from '../../../Trans'
import RadioField from '../../../UI/RadioField'
import { MODE_TYPE } from '../UserSettings'

type Props = {
  classes?: string
  user: UserType
  userType: MODE_TYPE
  onVerifyNow: () => void
  setUserType: React.Dispatch<React.SetStateAction<MODE_TYPE>>
}

const MyAccount = (props: Props) => {
  const { classes, user, onVerifyNow, setUserType, userType } = props
  const userTypeLabel = userType === MODE_TYPE.PRIVATE ? 'Basic' : 'Professional'

  return (
    <div className={classNames(classes)}>
      <Typography variant="h5">
        <Trans ns="UserSettingsMyAccount" id="MyAccountHeadline" msg="My Account" />
      </Typography>
      <div className={styles['my-account']}>
        <img className={styles['basic__avatar']} src={user.data?.avatar} alt="avatar" />
        <div className={styles['basic__user']}>
          <span className={styles['user__name']}>{user.data?.username}</span>
          <span className={styles['user__type']}>{userTypeLabel}</span>
        </div>

        <div className={styles['basic__status']}>
          {user.data?.verifiedAt ? null : (
            <>
              <span className={styles['user__name']}>Not verified</span>
              <span className={styles['user__type']}>
                <a className="primary" onClick={onVerifyNow}>
                  Verify now
                </a>
              </span>
            </>
          )}
        </div>
      </div>
      {userType === MODE_TYPE.PRIVATE ? (
        <div className={styles['basic__description']}>
          <Img src="/icons/info.svg" />
          <span className={styles['description__text']}>
            BASIC account allows you to fully benefit from the Whiskymarket.com. Once you have confirmed your email you can easily add
            offers and search for your beloved bottles. We highly recommend you upgrade your account by adding some more details (optional).
          </span>
        </div>
      ) : null}

      <Typography variant="h5">
        <Trans ns="UserSettingsMyAccount" id="AccountTypeHeadline" msg="Account type" />
      </Typography>
      <div className={styles['account-type']}>
        <RadioField name="account-type" value={userType}>
          <FormControlLabel
            value="private"
            control={<Radio color="primary" />}
            label="Private"
            onClick={() => setUserType(MODE_TYPE.PRIVATE)}
          />
          <FormControlLabel
            value="business"
            control={<Radio color="primary" />}
            label="Business"
            onClick={() => setUserType(MODE_TYPE.BUSINESS)}
          />
        </RadioField>
      </div>

      <div className={styles['basic__description']}>
        <Img src="/icons/info.svg" />
        <span className={styles['description__text']}>
          {userType === MODE_TYPE.PRIVATE ? (
            <>This account is dedicated to private users such as whisky collectors and/or whisky enthusiasts.</>
          ) : (
            <>
              This type of account is dedicated to commercial users only, such as whisky shops, traders and other companies. Please provide
              us with your company’s details to successfully set up a business account. Please mind that all business accounts will be
              verified by our support Team.
            </>
          )}
        </span>
      </div>
    </div>
  )
}

export default MyAccount
