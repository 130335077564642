import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from './UserFavoriteOffers.module.scss'
import OfferType from '../../../interfaces/Offer.type'
import { UserDataType } from '../../../interfaces/User.type'
import OfferListItem, { ACTION_STEP } from '../../OfferListItem/OfferListItem'
import Trans from '../../Trans/Trans'
import { USER_OFFERS_TYPE } from '../UserOffers'

type Props = {
  user: UserDataType
  items: OfferType[]
}

const UserOffersView = (props: Props) => {
  if (props.items.length === 0) {
    return <Typography variant="h2">No offers found at this moment.</Typography>
  }

  return (
    <>
      <div className={styles['offers']}>
        <Typography variant="h3" classes={{ root: classNames(styles['offers__headline']) }}>
          <Trans ns="UserOffers" id="MyFavorites" msg="My Favorites" />
        </Typography>
        <ul className={styles['offers__list']}>
          {props.items.map(item => (
            <li key={`li-${item.id}`}>
              <OfferListItem
                key={`fi-${item.id}`}
                onOfferRemove={() => {}}
                onOfferUpdate={() => {}}
                offer={item}
                selected={false}
                ownerType={USER_OFFERS_TYPE.OTHER}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default UserOffersView
