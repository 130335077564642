import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import UserContext from '../../contexts/UserContext'
import useAuth from '../../hooks/auth'
import { UserDataType } from '../../interfaces/User.type'
import * as userActions from '../../services/user/user.actions'
import * as userApi from '../../services/user/user.api'
import { AxiosError } from '../../utils/axios'
import { routes } from '../Link'
import styles from './Menu.module.scss'
import MenuView from './MenuView'

type Props = {
  classes?: string
}

const Menu = (props: Props) => {
  const classes = classNames(props.classes, styles['menu'])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const { isAuth, logOut } = useAuth()
  const userProfile = useContext(UserContext)
  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    const fetch = async () => {
      try {
        const userResponse = await userApi.readUser()
        const userData = userResponse.data as UserDataType

        if (userData.deletedAt != null) {
          logOut()
        } else {
          dispatch(userActions.readUserDataSuccess(userResponse.data))
        }
      } catch (error) {
        if (error) {
          const axiosError = error as AxiosError
          if (isAuth && axiosError?.response?.status === 401) {
            logOut()
          }
        }

        console.error(error)
      }
    }

    fetch()
  }, [])

  const onDialogClose = () => {
    setIsOpen(false)
  }

  const onBackdrop = (event: React.SyntheticEvent<{}, Event>) => {
    onDialogClose()
  }

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isMobile) return
    onDialogClose()
  }

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isMobile) return

    setIsOpen(true)
    setAnchorEl((event.currentTarget as unknown) as HTMLButtonElement)
  }

  const toggleMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsOpen(!isOpen)
    setAnchorEl((event.currentTarget as unknown) as HTMLButtonElement)
  }

  const onLogout = () => {
    logOut()
    history.push(routes.home)
  }

  return (
    <MenuView
      logOut={onLogout}
      userProfile={userProfile}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onDialogClose={onDialogClose}
      onBackdropClick={onBackdrop}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      isAuth={isAuth}
      isMobile={isMobile}
      classes={classes}
      toggleMenu={toggleMenu}
    />
  )
}

export default Menu
