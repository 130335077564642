import _ from 'lodash'

const buildUrl = (path: string): string => {
  const lowercasePath = path.toLowerCase()
  const isExternal = lowercasePath.startsWith('http:') || lowercasePath.startsWith('https:')
  const assetPrefix = _.trimEnd(process.env.REACT_APP_ASSET_PREFIX || '', '/')
  return isExternal ? path : `${assetPrefix}/${_.trimStart(path, '/')}`
}

export default buildUrl
