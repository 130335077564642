import { InputAdornment, MenuItem } from '@material-ui/core'
import { InputProps } from '@material-ui/core/Input'
import { TextFieldProps } from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import countries from 'country-codes-list'
import { FormikProps } from 'formik'
import _ from 'lodash'
import React, { useState } from 'react'
import { SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest'
import styles from './FormFieldPhoneCode.module.scss'
import FormAutocompleteField from '../FormAutocompleteField'

const defaultProps = Object.freeze({
  fieldName: 'phoneCode' as string,
})

type Props<FormValuesType> = typeof defaultProps & {
  form: FormikProps<FormValuesType>
  helperText?: string
  placeholder?: string
  label: React.ReactNode | string
  formControlProps?: TextFieldProps
  field: {
    name: string
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onBlur: (event: any) => void
  }
  classes?: {
    root?: string
  }
  InputProps?: InputProps
}

type PhoneCodeType = {
  key: string
  value: string
}

function renderTextSuggestion(item: PhoneCodeType, { query, isHighlighted }: { query: string; isHighlighted: boolean }) {
  return (
    //ignore for component=div - its correct with material-ui spec
    //@ts-ignore
    <MenuItem component="div" value={item.value} key={`pc-${item.key}`} selected={isHighlighted}>
      <span className={`flag-icon flag-icon-${item.key.toLowerCase()}`} />
      <span className={styles['suggestion__value']}>+{item.value}</span>
    </MenuItem>
  )
}
const FormFieldPhoneCode = <FormValuesType extends {}>(props: Props<FormValuesType>) => {
  const phoneCodesSource = countries.customList('countryCode', '{countryCallingCode}')
  const { form, fieldName, helperText, placeholder, formControlProps, label, classes } = props
  const [phoneCodes, setPhoneCodes] = useState<PhoneCodeType[]>([])

  const onSelected = (value: string) => {
    form.setFieldTouched(fieldName)
    form.setFieldValue(fieldName, value)
  }

  const getSuggestions = (value: string) => {
    const inputValue = _.deburr(value.replace('+', '').trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0
    if (!inputLength) return

    const codes = Object.keys(phoneCodesSource).map((key: string) => {
      return { key, value: phoneCodesSource[key] }
    })

    const suggestions = codes.filter((suggestion: PhoneCodeType) => {
      const keep = count < 20 && suggestion.value.slice(0, inputLength).toLowerCase() === inputValue

      if (keep) {
        count += 1
      }

      return keep
    })
    setPhoneCodes(suggestions)
  }

  const field = {
    name: fieldName,
    value: (form.values[fieldName as keyof FormValuesType] as any) as string,
    onChange: form.handleChange,
    onBlur: form.handleBlur,
  }

  return (
    <FormAutocompleteField<PhoneCodeType, FormValuesType>
      form={form}
      field={field}
      label={label}
      helperText={helperText}
      formControlProps={{
        ...formControlProps,
        fullWidth: true,
      }}
      inputProps={field}
      InputProps={{
        placeholder,
        ...props.InputProps,
      }}
      autocompleteClasses={{ container: classes?.root, suggestionsContainerOpen: styles['suggestions__container'] }}
      suggestions={phoneCodes}
      getSuggestionValue={(item: PhoneCodeType) => `+ ${item.value}`}
      onSuggestionsClearRequested={() => {}}
      onSuggestionsFetchRequested={(request: SuggestionsFetchRequestedParams) => {
        getSuggestions(request.value)
      }}
      onSuggestionSelected={(event: React.FormEvent<any>, data: SuggestionSelectedEventData<PhoneCodeType>) => {
        onSelected(data.suggestionValue)
      }}
      renderSuggestion={renderTextSuggestion}
    />
  )
}
FormFieldPhoneCode.defaultProps = defaultProps

export default FormFieldPhoneCode
