import {
  Button,
  CircularProgress,
  FormControl,
  Paper
  } from '@material-ui/core'
import classNames from 'classnames'
import {
  Field,
  Form,
  Formik,
  FormikHelpers
  } from 'formik'
import React from 'react'
import { useHistory } from 'react-router'
import styles from './ForgotPasswordForm.module.scss'
import schema from './ForgotPasswordForm.schema'
import useAuth from '../../hooks/auth/auth'
import { ResetPasswordPayloadType } from '../../services/auth/auth.types'
import { AxiosError, AxiosResponse } from '../../utils/axios'
import handleError from '../../utils/handleError'
import FormTextField from '../Form/FormTextField/FormTextField'
import { routes } from '../Link'
import notify from '../Notification/notify'
import Trans from '../Trans/Trans'

type Props = {}

const ForgotPasswordForm = (props: Props) => {
  const { resetPassword } = useAuth()
  const history = useHistory()
  const [isSent, setIsSent] = React.useState(false)

  const onSubmit = (values: ResetPasswordPayloadType, formikBag: FormikHelpers<ResetPasswordPayloadType>) => {
    resetPassword(
      values,
      (response: AxiosResponse) => {
        formikBag.setSubmitting(false)
        notify.success('We’ve sent you en email. Please check your mailbox.')
        setIsSent(true)
      },
      (error: AxiosError) => {
        formikBag.setSubmitting(false)
        handleError(error, formikBag)
      },
    )
  }

  const handleBackClick = () => {
    history.push(routes.signIn)
  }

  return (
    <Formik
      initialValues={
        {
          email: '',
        } as ResetPasswordPayloadType
      }
      validationSchema={schema}
      onSubmit={(values, formikBag) => {
        onSubmit(values, formikBag)
      }}
      render={formikBag => (
        <Paper elevation={0} classes={{ root: styles['paper'] }}>
          <Form className={styles['forgot-password']}>
            <Field
              name="email"
              label={<Trans ns="ForgotPasswordForm" id="ForgotPasswordForm.Email" msg="E-mail" />}
              component={FormTextField}
              fullWidth
              classes={{
                root: styles['forgot-password__email'],
              }}
            />
            <FormControl fullWidth className={classNames(styles['forgot-password__button--submit'])}>
              <Button variant="contained" color="primary" onClick={formikBag.submitForm} disabled={formikBag.isSubmitting}>
                {formikBag.isSubmitting ? (
                  <CircularProgress size="1.6rem" />
                ) : (
                  <Trans ns="ForgotPasswordForm" id="ForgotPasswordForm.ButtonSubmit" msg="Send" />
                )}
              </Button>
            </FormControl>
            {!isSent && !formikBag.isSubmitting && (
              <FormControl fullWidth className={classNames(styles['forgot-password__button--back'])}>
                <Button variant="contained" color="secondary" onClick={handleBackClick} disabled={formikBag.isSubmitting}>
                  {formikBag.isSubmitting ? (
                    <CircularProgress size="1.6rem" />
                  ) : (
                    <Trans ns="ForgotPasswordForm" id="ForgotPasswordForm.ButtonBack" msg="Back" />
                  )}
                </Button>
              </FormControl>
            )}
          </Form>
        </Paper>
      )}
    />
  )
}

export default ForgotPasswordForm
