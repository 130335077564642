import React from 'react'
import { isMobile } from 'react-device-detect'
import { Redirect } from 'react-router-dom'
import { routes } from '../../components/Link'
import LoadingSpinner from '../../components/LoadingSpinner'
import OfferAddForm from '../../components/OfferForm/OfferAddForm'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import useAuth from '../../hooks/auth'
import * as api from '../../services/offer/offer.api'
import {
  OfferFieldsValuesType,
  OfferSelectFieldType,
  SELECT_FIELD_NAME_AVAILABILITY,
  SELECT_FIELD_NAME_CASK_TYPE,
  SELECT_FIELD_NAME_BOTTLE_TYPE,
  SELECT_FIELD_NAME_CATEGORY,
  SELECT_FIELD_NAME_CONDITION,
  SELECT_FIELD_NAME_FILL_LEVEL,
  SELECT_FIELD_NAME_PACKAGE,
} from '../../interfaces/Offer.type'

const OfferAddPage = () => {
  const [fieldsValues, setFieldsValues] = React.useState<OfferFieldsValuesType>()
  const { isAuth } = useAuth()

  React.useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await api.searchFieldsHints()
        setFieldsValues({
          bottleType: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_BOTTLE_TYPE)[0],
          category: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CATEGORY)[0],
          caskType: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CASK_TYPE)[0],
          condition: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CONDITION)[0],
          fillLevel: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_FILL_LEVEL)[0],
          availability: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_AVAILABILITY)[0],
          package: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_PACKAGE)[0],
        })
      } catch (error) {
        console.error(error)
      }
    }
    fetchFields()
  }, [])

  if (!isAuth) {
    return <Redirect to={routes.signIn} />
  }

  return (
    <PageLayout classes={pageStyles['content--sides-padding']}>
      {fieldsValues ? <OfferAddForm isMobile={isMobile} fieldsValues={fieldsValues} /> : <LoadingSpinner center />}
    </PageLayout>
  )
}

export default OfferAddPage
