import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './UserMiniProfile.module.scss'
import { UserDataType } from '../../interfaces/User.type'
import Img from '../Img'

type Props = {
  user: UserDataType
  classes?: {
    root?: string
  }
}

const UserMiniProfile = (props: Props) => {
  const { user, classes } = props
  if (!user) {
    return null
  }

  return (
    <div className={classes?.root!}>
      {user.isBusiness ? (
        <Img src="/icons/user_shop.svg" />
      ) : user.verified ? (
        <Img src="/icons/user_verified.svg" />
      ) : (
        <Img src="/icons/user.svg" />
      )}
      <span className={styles['username']}>
        {user.username}
      </span>
      <Typography variant="body1" classes={{ root: styles['account__type'] }}>
        {user.isBusiness ? 'Professional' : user.verified ? 'Verified' : 'Basic'}
      </Typography>
    </div>
  )
}

export default UserMiniProfile
