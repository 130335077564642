import {
  Button,
  CircularProgress,
  Divider,
  Typography
  } from '@material-ui/core'
import classNames from 'classnames'
import { FormikHelpers, useFormik } from 'formik'
import React, { useContext } from 'react'
import styles from './Conversation.module.scss'
import { MessageFormType } from './Conversation.types'
import MessageForm from './MessageForm'
import schema from './MessageForm.schema'
import UserContext from '../../../contexts/UserContext'
import { ConversationType, MessageType } from '../../../interfaces/Chat.type'
import * as api from '../../../services/chat/chat.api'
import Date from '../../../utils/date'
import handleError, { handleFormikValidation } from '../../../utils/handleError'
import Link, { routes } from '../../Link'
import { CONVERSATION_TYPE } from '../Chat.types'
import ConversationItem from '../ConversationItem'

type Props = {
  conversation: ConversationType
  isLoading: boolean
  onArchive: () => void
  setConversation: (data: ConversationType | null) => void
  type: CONVERSATION_TYPE
}

const Conversation = (props: Props) => {
  const { isLoading, conversation, onArchive, setConversation, type } = props
  const user = useContext(UserContext)

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      content: '',
    },
    onSubmit: async (values: MessageFormType, formikBag: FormikHelpers<MessageFormType>) => {
      try {
        const result = await api.createMessage({
          conversationId: conversation.id!,
          content: values.content,
        })
        formikBag.setSubmitting(false)
        setConversation(result.data)
        formikBag.resetForm()
      } catch (error) {
        console.error(error)
        handleError(error, formikBag)
        formikBag.setSubmitting(false)
      }
    },
  })

  const onSubmit = async () => {
    const result = await formik.validateForm()
    handleFormikValidation(result, formik)
    formik.submitForm()
  }
  //&& conversation.archivedBySenderAt! > 0
  const mineMessage = conversation.sender.id === user.data?.id
  let author = mineMessage ? conversation.receiver : conversation.sender

  if (type === CONVERSATION_TYPE.ARCHIVE) {
    author = type === CONVERSATION_TYPE.ARCHIVE && mineMessage ? conversation.receiver : conversation.sender
  }

  return (
    <div className={styles['conversation']}>
      <Typography variant="h3" classes={{ root: styles['conversation__headline'] }}>
        Messages
      </Typography>
      {!isLoading ? (
        <>
          <ConversationItem item={conversation} inConversation type={type} />
          <div className={styles['conversation__nav']}>
            <Link
              classes={classNames('primary', styles['nav__item'])}
              to={routes.offer}
              params={{ sku: conversation.offer.sku, slug: conversation.offer.slug }}
            >
              Go to offer
            </Link>
            {conversation.archivedAt === null ? (
              <a className="primary" onClick={onArchive}>
                Archive conversation
              </a>
            ) : null}
          </div>
          <div className={styles['conversation__messages']}>
            {conversation.messages && conversation.messages.length > 0
              ? conversation.messages.map((item: MessageType) => {
                  const mine = item.author.id === user.data?.id
                  return (
                    <div className={classNames(styles['message'], { [styles['message--primary']]: !mine })} key={`itewm-${item.id}`}>
                      <div className={styles['message__author']}>
                        <span className={styles['author__name']}>{mine ? 'You' : author.username}</span>
                        <span className={styles['author__date']}>{Date.timestampToFormat(item.createdAt!, 'DD MMM, HH:mm', true)}</span>
                      </div>
                      <div className={classNames(styles['message__content'], { [styles['message__content--primary']]: !mine })}>
                        {item.content}
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
          <Divider classes={{ root: styles['conversation__divider'] }} />
          <MessageForm formik={formik} />
          <div className={styles['conversation__actions']}>
            <Link classes={classNames('primary', styles['nav__item'])} to={routes.messages}>
              <Button variant="contained" classes={{ root: styles['actions__back'] }} disabled={formik.isSubmitting}>
                Back
              </Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              classes={{ root: styles['actions__send'] }}
              onClick={onSubmit}
              disabled={formik.isSubmitting}
            >
              Send
            </Button>
          </div>
        </>
      ) : (
        <div className={styles['chat__conversations-box--empty']}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default Conversation
