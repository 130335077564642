import countries, { LocaleData, LocalizedCountryNames } from 'i18n-iso-countries'
import lngEN from 'i18n-iso-countries/langs/en.json'
import additionalCountries from './en.json'

type Country = {
  alpha2: string
  alpha3: string
  numeric: string
  name: string
  iso: string
}

const additionlAlpha2: Record<string, string> = additionalCountries.reduce((prev: Record<string, string>, country: Country) => ({
  ...prev,
  [country.alpha2]: country.alpha3,
}), {})

const additionlAlpha3: Record<string, string> = additionalCountries.reduce((prev: Record<string, string>, country: Country) => ({
  ...prev,
  [country.alpha3]: country.alpha2,
}), {})

const additionlNumeric: Record<string, string> = additionalCountries.reduce((prev: Record<string, string>, country: Country) => ({
  ...prev,
  [country.numeric]: country.alpha2,
}), {})

const additionalNames: Record<string, Country> = additionalCountries.reduce((prev: Record<string, Country>, country: Country) => ({
  ...prev,
  [country.name]: country,
}), {})

const fixedCountries = {
  ...countries,
  alpha2ToAlpha3: (alpha2: string): string => {
    const code = `${alpha2}`.toUpperCase()
    const country = additionalCountries.find(item => item.alpha2 === code)
    return country?.alpha3 || countries.alpha2ToAlpha3(alpha2)
  },
  alpha2ToNumeric: (alpha2: string): number => {
    const code = `${alpha2}`.toUpperCase()
    const country = additionalCountries.find(item => item.alpha2 === code)
    return country ? parseInt(country.numeric, 10) : countries.alpha2ToNumeric(alpha2)
  },
  alpha3ToAlpha2: (alpha3: string): string => {
    const code = `${alpha3}`.toUpperCase()
    const country = additionalCountries.find(item => item.alpha3 === code)
    return country?.alpha2 || countries.alpha3ToAlpha2(alpha3)
  },
  alpha3ToNumeric: (alpha3: string): number => {
    const code = `${alpha3}`.toUpperCase()
    const country = additionalCountries.find(item => item.alpha3 === code)
    return country ? parseInt(country.numeric, 10) : countries.alpha3ToNumeric(alpha3)
  },
  numericToAlpha2: (numeric: number | string): string => {
    const country = additionalCountries.find(item => item.numeric === numeric)
    return country?.alpha2 || countries.numericToAlpha2(numeric)
  },
  numericToAlpha3: (numeric: number | string): string => {
    const country = additionalCountries.find(item => item.numeric === numeric)
    return country?.alpha3 || countries.numericToAlpha3(numeric)
  },
  getAlpha2Codes: (): { [alpha2Key: string]: string } => {
    return {
      ...countries.getAlpha2Codes(),
      ...additionlAlpha2,
    }
  },
  getAlpha3Codes: (): { [alpha3Key: string]: string } => {
    return {
      ...countries.getAlpha3Codes(),
      ...additionlAlpha3,
    }
  },
  getNumericCodes: (): { [numericKey: number]: string } => {
    return {
      ...countries.getNumericCodes(),
      ...additionalCountries.reduce((prev: LocalizedCountryNames, country: Country) => ({
        ...prev,
        [country.numeric]: country.alpha2,
      }), {})
    }
  },
  getName: (alpha2orAlpha3orNumeric: string | number, lang: string): string => {
    const code = `${alpha2orAlpha3orNumeric}`.toUpperCase()
    const country = additionalCountries.find(item => [item.alpha2, item.alpha3, item.numeric].includes(code))
    return country?.name || countries.getName(alpha2orAlpha3orNumeric, lang)
  },
  getNames: (lang: string): LocalizedCountryNames => {
    return {
      ...countries.getNames(lang),
      ...additionalCountries.reduce((prev: LocalizedCountryNames, country: Country) => ({
        ...prev,
        [country.alpha2]: country.name,
      }), {})
    }
  },
  toAlpha3: (alpha2orNumeric: number | string): string => {
    const code = `${alpha2orNumeric}`.toUpperCase()

    if (code in additionlAlpha2) {
      return additionlAlpha2[code]
    }

    if (code in additionlNumeric) {
      const alpha2 = additionlNumeric[code]

      if (alpha2 in additionlAlpha2) {
        return additionlAlpha2[alpha2]
      }
    }

    return countries.toAlpha3(alpha2orNumeric)
  },
  toAlpha2: (alpha3orNumeric: number | string): string => {
    const code = `${alpha3orNumeric}`.toUpperCase()

    if (code in additionlAlpha3) {
      return additionlAlpha3[code]
    }

    if (code in additionlNumeric) {
      return additionlNumeric[code]
    }

    return countries.toAlpha2(alpha3orNumeric)
  },
  getAlpha2Code: (name: string, lang: string): string => {
    return name in additionalNames ? additionalNames[name].alpha2 : countries.getAlpha2Code(name, lang)
  },
  getSimpleAlpha2Code: (name: string, lang: string): string => {
    return name in additionalNames ? additionalNames[name].alpha2 : countries.getSimpleAlpha2Code(name, lang)
  },
  getAlpha3Code: (name: string, lang: string): string => {
    return name in additionalNames ? additionalNames[name].alpha3 : countries.getAlpha3Code(name, lang)
  },
  getSimpleAlpha3Code: (name: string, lang: string): string => {
    return name in additionalNames ? additionalNames[name].alpha3 : countries.getSimpleAlpha3Code(name, lang)
  },
  isValid: (alpha2orAlpha3orNumeric: string | number): boolean => {
    const code = `${alpha2orAlpha3orNumeric}`.toUpperCase()
    return code in additionlAlpha2 || code in additionlAlpha3 || code in additionlNumeric || countries.isValid(alpha2orAlpha3orNumeric)
  },
}

fixedCountries.registerLocale({
  locale: lngEN.locale,
  countries: {
    ...lngEN.countries,
    ...additionalCountries.reduce((prev: LocalizedCountryNames, country: Country) => ({
      ...prev,
      [country.alpha2]: country.name,
    }), {})
  },
})

export default fixedCountries