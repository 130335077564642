import { TextField, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React, { useRef } from 'react'
import styles from './Verification.module.scss'
import Img from '../../../Img'
import notify from '../../../Notification/notify'
import Trans from '../../../Trans'
import { MODE_TYPE } from '../UserSettings'

type Props = {
  confirmed: boolean
  verified: boolean
  userType: MODE_TYPE
  onResendEmail: () => void
  onVerifyNow: () => void
}

const Verification = (props: Props) => {
  const { confirmed, verified, userType, onResendEmail, onVerifyNow } = props
  const accountNumberRef = useRef<HTMLInputElement>(null)

  const onCopyNumber = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const el = accountNumberRef.current
    if (el) {
      el.select()
      document.execCommand('copy')
      el.selectionEnd = el.selectionStart
      notify.info(<Trans ns="UserSettingsVerification" id="CopiedNumber" msg="Account number was copied" />)
    }
  }

  return (
    <div className={styles['verification']}>
      <Typography variant="h5" classes={{ root: styles['verification__headline'] }}>
        <Trans ns="UserSettingsVerification" id="VerificationHeadline" msg="Verification" />
      </Typography>
      <Trans
        ns="UserSettingsVerification"
        id="AccountStatus"
        msg="Account status"
        classes={styles['verification__account-status']}
        tag="div"
      />
      <div className={classNames(styles['account-status__row'])}>
        {confirmed ? (
          <>
            <Img src="/icons/accept.svg" classes={styles['status-row__icon']} />
            <Trans ns="UserSettingsVerification" id="EmailConfirmed" msg="Email confirmed" classes={styles['status-row__text']} />
          </>
        ) : (
          <>
            <Img src="/icons/error.svg" classes={styles['status-row__icon']} />
            <Trans ns="UserSettingsVerification" id="EmailNotConfirmed" msg="Email not confirmed" classes={styles['status-row__text']} />
            <a className="primary" onClick={onResendEmail}>
              <Trans ns="UserSettingsVerification" id="ResendEmail" msg="Resend email" />
            </a>
          </>
        )}
      </div>

      <div className={classNames(styles['account-status__row'], styles['account-status__row--second'])}>
        {verified ? (
          <>
            <Img src="/icons/accept.svg" classes={styles['status-row__icon']} />
            <Trans ns="UserSettingsVerification" id="AccountVerified" msg="Account verified" classes={styles['status-row__text']} />
          </>
        ) : (
          <>
            <Img src="/icons/error.svg" classes={styles['status-row__icon']} />
            <Trans ns="UserSettingsVerification" id="AccountNotVerified" msg="Account not verified" classes={styles['status-row__text']} />
            <Trans ns="UserSettingsVerification" id="VerifiedNotRequired" msg="Not required" classes={styles['status-row__not-required']} />
            <a className={classNames('primary', styles['verify-link'])} onClick={onVerifyNow}>
              Verify now
            </a>
          </>
        )}
      </div>

      {userType === MODE_TYPE.BUSINESS ? (
        <div className={styles['verification__hint']}>
          To successfully set up your BUSINESS account please provide a micro payment, so we can verify your company details. Please make
          sure the details provided match the details of the card owner/bank account holder you provided.
        </div>
      ) : (
        <div className={styles['verification__hint']}>
          To successfully gain your VERIFIED status please provide us with a micro payment, so we can verify your personal details. Make
          sure the details provided match the details of the credit card/bank account you use.
        </div>
      )}

      <Trans
        ns="UserSettingsVerification"
        id="TransferHeadline"
        msg="Transfer via bank account"
        classes={styles['transfer__headline']}
        tag="div"
      />

      <TextField
        value="DE97 5012 7000 0200 0051 81"
        inputProps={{ readOnly: true }}
        InputProps={{ inputRef: accountNumberRef, disableUnderline: true }}
        classes={{ root: styles['transfer__input'] }}
      />
      <a className="primary" onClick={onCopyNumber}>
        Copy number
      </a>

      <Trans
        ns="UserSettingsVerification"
        id="BankDataHeadline"
        msg="PKO Bank Polski S.A. Niederlassung Deutschland"
        classes={styles['bank__headline']}
        tag="div"
      />
      <div className={styles['bank__info']}>
        Neue Mainzer Straße 52-58
        <br /> 60311 Frankfurt am Main <br /> BIC: BPKODEFF
      </div>

      <div className={styles['company__headline']}>Whiskymarket.com GmbH</div>
      <div className={styles['company__data']}>
        Oehlertring 1<br />
        12169 Berlin
        <br />
        Germany
        <br />
        HRB198729 B
      </div>
    </div>
  )
}

export default Verification
