import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import styles from '../TosPage.module.scss'
/* eslint-disable react/no-unescaped-entities */

const TosPageEN = () => {
  const bodyClass = { root: styles['list__text'] }
  return (
    <>
      <Typography variant="h2" classes={{ root: classNames(styles['heading'], styles['text--center']) }}>
        Allgemeine Geschäfts- und Nutzungsbedingungen
        <br />
        und Kundeninformationen
        <br />
        <a href="/" target="_blank" rel="noreferrer" className="primary">
          www.whiskymarket.com
        </a>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Willkommen bei whiskymarket.com,
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        die JP Whiskymarket.com GmbH beitreibt unter whiskymarket.com eine Webseite zum Handel mit hochwertigen Spirituosen.
        <br />
        Die JP Whiskymarket.com GmbH verkauft selbst Waren über die Webseite. Diesbezüglich gelten die Allgemeinen Geschäftsbedingungen und
        Kundeninformationen, die Sie unter B. finden.
        <br />
        Auf der Webseite haben auch Dritte die Möglichkeit ihre Waren zu präsentieren und die Webseite als Anzeigenplattform zu nutzen.
        Diesbezüglich geltend die nachstehend unter A. aufgeführten Nutzungsbedingungen. Verkäufe durch Dritte finden über die Webseite
        nicht statt.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="h3" classes={{ root: styles['subheading'] }}>
        A. Allgemeine Nutzungsbedingungen für{' '}
        <a href="/" target="_blank" rel="noreferrer" className="primary">
          www.whiskymarket.com
        </a>{' '}
        als Anzeigenplattform
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <div className={styles['tos']}>
          <ol>
            <li>
              Grundlegende Bestimmungen
              <ol>
                <li>
                  Die nachstehenden Nutzungsbedingungen gelten für die Nutzung der unter der Domain www.whiskymarket.com (nachfolgend als
                  „whiskymarket.com“ bezeichnet) durch JP Whiskymarket.com GmbH (Oehlertring 1, 12169 Berlin; nachfolgend als „Betreiber“
                  bezeichnet) betriebenen Anzeigenplattform durch registrierte Anbieter (nachfolgend als „Anbieter“ bezeichnet) und Kunden
                  (nachfolgend als „Kunden“ bezeichnet). Anbieter und Kunden werden nachfolgend gemeinschaftlich als „Nutzer“ bezeichnet.{' '}
                </li>
                <li>
                  Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken
                  abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden
                  kann. Unternehmer gem. § 14 Abs. 1 BGB ist jede natürliche oder juristische Person oder eine rechtsfähige
                  Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder
                  gewerblichen Tätigkeit handelt.{' '}
                </li>
                <li>
                  Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls vom Nutzer verwendeter eigener Bedingungen
                  widersprochen.
                </li>
              </ol>
            </li>
            <li>
              Gegentand des Vertrages
              <ol>
                <li>
                  Whiskymarket.com bietet eine digitale Anzeigenplattform, auf der Anbieter gegen Entgelt Anzeigen für den Verkauf von
                  Waren, insbesondere hochwertige Spirituosen, schalten können. Die Plattform dient dabei ausschließlich der Präsentation
                  von Angeboten zum Warenverkauf. Verträge zwischen Anbietern und Kunden werden über die Plattform nicht geschlossen.
                </li>
                <li>
                  Die Informationen zu den Angeboten der Anbieter stellen keine rechtsverbindlichen Angebote von whiskymarket.com dar.
                  whiskymarket.com übernimmt daher keine Gewähr für die angegebenen Preise, Verfügbarkeit oder Qualität der angebotenen
                  Waren.
                </li>
                <li>
                  whiskymarket.com stellt nur die technische Möglichkeit zur Nutzung der Plattform durch die Anbieter und die Kunden zur
                  Verfügung. Die Plattform dient ausschließlich der Präsentation von Waren.
                </li>
                <li>
                  Die Einzelheiten, insbesondere die wesentlichen Merkmale der Produkte und Dienstleistungen des Betreibers für Anbieter und
                  Kunden finden sich in den Produktbeschreibungen, sowie den ergänzenden Angaben auf whiskymarket.com.
                </li>
              </ol>
            </li>
            <li>
              Zustandekommen des Vertrages
              <ol>
                <li>Die Leistungen von whiskymarket.com sind für Kunden kostenfrei.</li>
                <li>
                  Voraussetzung für die Nutzung von whiskymarket.com durch Anbieter und Kunden ist die Registrierung auf der Plattform.
                </li>
                <li>
                  <u>Registrierung als Kunde</u>
                  <p>
                    Das Angebot des Betreibers auf whiskymarket.com zur Begründung eines Nutzungsvertrages mit Kunden richtet sich an
                    Verbraucher und Unternehmer und ist verbindlich.
                  </p>
                  <p>
                    Über die Schaltfläche „Sign in“ kann ein Kunde sich auf whiskymarket.com registrieren und einen Nutzer-Account anlegen.
                    Hierzu muss der Kunde nach Aufruf der Registrierungsseite seine Kontaktdaten eingeben sowie E-Mail-Adresse, Passwort und
                    Geburtsdatum festlegen. Vor Abgabe seiner Vertragserklärung hat der Kunde jederzeit die Möglichkeit, seine Eingaben zu
                    korrigieren, zu löschen oder die Registrierung durch Schließen des Browserfensters oder Auswahl der Funktion „zurück“
                    des Browsers abzubrechen. Mit dem Absenden seiner Daten durch Bestätigung der Schaltfläche „Sign up“ nimmt der Kunde das
                    Vertragsangebot des Betreibers zum Abschluss des Nutzungsvertrages an.
                  </p>
                </li>
                <li>
                  <u>Registrierung als Anbieter</u>
                  <p>
                    Das Angebot des Betreibers auf whiskymarket.com zur Begründung eines Nutzungsvertrages mit Anbietern richtet sich an
                    Verbraucher und Unternehmer und ist verbindlich.
                  </p>
                  <p>
                    Über die Schaltfläche „Sign in“ kann ein Anbieter sich auf whiskymarket.com registrieren und einen Nutzer-Account
                    anlegen. Hierzu muss der Anbieter nach Aufruf der Registrierungsseite seine E-Mail-Adresse und sein Geburtsdatum
                    eingeben sowie ein Passwort festlegen. Vor Abgabe seiner Vertragserklärung hat der Anbieter jederzeit die Möglichkeit,
                    seine Eingaben zu korrigieren, zu löschen oder die Registrierung durch Schließen des Browserfensters oder Auswahl der
                    Funktion „zurück“ des Browsers abzubrechen. Mit dem Absenden seiner Daten durch Bestätigung der Schaltfläche „Sign up“
                    nimmt der Anbieter das Vertragsangebot des Betreibers zum Abschluss des Nutzungsvertrages an.
                  </p>
                </li>
                <li>
                  Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen Informationen
                  erfolgt per E-Mail und dabei zum Teil automatisiert. Der Nutzer hat daher sicherzustellen, dass die von ihm angegebene
                  E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch SPAM-Filter
                  verhindert wird.
                </li>
              </ol>
            </li>
            <li>
              Preise und Zahlungsbedingungen
              <ol>
                <li>
                  Im Falle des Schaltens einer Anzeige durch Anbieter erhebt der Betreiber von den Anbietern eine Gebühr. Einzelheiten
                  hierzu finden sich auf whiskymarket.com. Es gelten die zum Zeitpunkt des Vertragsschlusses vom Betreiber auf
                  whiskymarket.com veröffentlichten Preise.
                </li>
                <li>Der Betreiber rechnet die Gebühren gegenüber dem Anbieter monatlich ab.</li>
                <li>Soweit nicht anders angegeben, ist die Gebühr sofort nach Entstehung zur Zahlung fällig.</li>
              </ol>
            </li>
            <li>
              Verfügbarkeit
              <ol>
                <li>Betreiber stellt whiskymarket.com mit einer Verfügbarkeit von 98% im Monatsmittel zur Verfügung.</li>
                <li>
                  Davon ausgenommen sind Wartungszeiten und Zeiten, in denen der Server nicht verfügbar ist aufgrund von technischen oder
                  sonstigen Problemen, die nicht im Einflussbereich des Betreibers liegen (höhere Gewalt, Netzwerkstörung außerhalb unseres
                  Netzes, Verschulden Dritter etc.).
                </li>
                <li>Die Regelungen der Ziffer 9. dieser Geschäfts- und Nutzungsbedingungen werden hierdurch nicht beeinträchtigt.</li>
              </ol>
            </li>
            <li>
              Nutzung der Plattform, Einschränkung beim Anbieten von Leistungen, Ranking
              <ol>
                <li>
                  Die Anzeigentexte sowie die dabei verwendeten Bilder und Videos dürfen sich ausschließlich auf die angebotenen Produkte
                  und Leistungen beziehen.
                </li>
                <li>
                  Anbieter, die in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit Produkte an Verbraucher anbieten,
                  sind verpflichtet, diesen die gesetzlich vorgeschriebenen Verbraucherschutzinformationen zu erteilen und die bestehenden
                  Kennzeichnungspflichten zu erfüllen.
                </li>
                <li>
                  Insbesondere das Anbieten von Produkten mit Bezügen zu pornografischen oder jugendgefährdenden Inhalten, z.B. durch
                  entsprechende Symbole oder Darstellungen, die religiöse Gefühle, strafrechtliche Normen, Persönlichkeitsrechte oder
                  sonstige Rechte Dritter verletzen oder dazu geeignet sind, ist untersagt.
                </li>
                <li>
                  Verstößt der Nutzer gegen die vorgenannten Regelungen, stehen dem Betreiber insbesondere die Rechte nach Ziffer 8. dieser
                  Nutzungsbedingungen zu und kann zur zeitweisen Sperrung oder Löschung des Anbieters oder einzelner Inhalte führen.
                </li>
                <li>Das Ranking der Anzeigen auf whiskymarket.com erfolgt chronologisch. Die neuesten Anzeigen werden oben angezeigt.</li>
              </ol>
            </li>
            <li>
              Nutzungsrechte an Inhalten
              <ol>
                <li>
                  Die auf der Plattform verfügbaren Inhalte sind überwiegend durch das Urheberrecht oder sonstige Schutzrechte geschützt und
                  stehen jeweils im Eigentum des Betreibers, der anderen Nutzer oder sonstiger Dritter, welche die jeweiligen Inhalte zur
                  Verfügung gestellt haben. Die Zusammenstellung der Inhalte als solche ist gegebenenfalls als Datenbank oder Datenbankwerk
                  im Sinne der §§ 4 Abs. 2, 87a Abs. 1 Urhebergesetz geschützt. Nutzer dürfen diese Inhalte lediglich gemäß diesen
                  Nutzungsbedingungen sowie den auf der Plattform vorgegebenen Bedingungen nutzen.
                </li>
                <li>
                  Mit dem Einstellen von Inhalten räumt der Nutzer dem Betreiber jeweils ein unentgeltliches und übertragbares Nutzungsrecht
                  an den jeweiligen Inhalten ein, insbesondere
                  <p>
                    - zur Speicherung der Inhalte auf dem Server des Betreibers sowie deren Veröffentlichung, insbesondere deren
                    öffentlicher Zugänglichmachung (zum Beispiel durch Anzeige der Inhalte auf der Plattform), wobei dies auch die
                    Veröffentlichung in Newslettern, Blogs, und vom Betreiber verwendete Social-Media-Kanäle umfasst, sowie
                  </p>
                  <p>
                    - zur Bearbeitung und Vervielfältigung, soweit dies für die Vorhaltung bzw. Veröffentlichung der jeweiligen Inhalte
                    erforderlich ist, wobei dies auch die Veröffentlichung in Newslettern, Blogs, und vom Betreiber verwendete
                    Social-Media-Kanäle umfasst.
                  </p>
                </li>
                <li>
                  Soweit der Nutzer die von ihm eingestellten Inhalte wieder von der Plattform herunternimmt, erlischt das dem Betreiber
                  vorstehend eingeräumte Nutzungsrecht. Der Betreiber bleibt jedoch berechtigt, zu Sicherungs- und/oder Nachweiszwecken
                  erstellte Kopien aufzubewahren. Die dem Betreiber, anderen Nutzern der Plattform oder Dritten eingeräumten Nutzungsrechte
                  bleiben insofern unberührt, als das der Betreiber weiterhin berechtigt ist, bereits veröffentlichte Newsletter,
                  Blogbeiträge oder Eintragungen auf Social Media Kanälen weiterhin zu verwenden.
                </li>
              </ol>
            </li>
            <li>
              Verantwortlichkeit für Inhalte, Nutzeraccounts und Haftungsfreistellung
              <ol>
                <li>
                  Die von den Anbietern auf der whiskymarket.com veröffentlichten Angebote werden vom Betreiber nicht auf Rechtsverletzungen
                  geprüft. Für deren Korrektheit, Vollständigkeit sowie Rechtmäßigkeit ist allein der jeweilige Anbieter verantwortlich. Der
                  Betreiber hat insbesondere keinen Einfluss auf die Richtigkeit, Vollständigkeit und Qualität der Angebote.
                </li>
                <li>
                  Die Nutzer können auf der Plattform eigene Inhalte veröffentlichen. Sie verpflichten sich dabei, keine Daten zu
                  übermitteln, deren Inhalt Rechte Dritter (insbesondere Urheberrechte, Namensrechte, Markenrechte) verletzen oder gegen
                  bestehende Gesetze verstoßen.
                </li>
                <li>
                  Der Betreiber kann einzelne Nutzer oder Inhalte eines Nutzers zeitweise sperren oder löschen, wenn der Nutzer
                  rechtswidrige oder gegen die Nutzungsbedingungen verstoßende Inhalte veröffentlicht oder für eine wiederholte
                  rechtswidrige Veröffentlichung konkrete Anhaltspunkte bestehen oder wenn der Nutzer gegen die Nutzungsbedingungen,
                  insbesondere Ziffer 6, verstößt.
                </li>
                <li>
                  Der Betreiber wird die berechtigten Interessen des Nutzers, insbesondere das Verschulden, bei Auswahl der Maßnahme
                  berücksichtigen und die Sperrung oder Löschung des Nutzers oder des Inhaltes gegenüber dem Nutzer in Textform begründen.
                </li>
                <li>
                  Im Falle einer vorübergehenden Sperrung eines Nutzers oder eines Inhaltes wird der Betreiber den Nutzer oder den Inhalt
                  unverzüglich wieder freigeben, sobald der Verdacht auf Verstoß gegen eine gesetzliche Bestimmung oder eine Vorschrift
                  dieser Geschäftsbedingungen ausgeschlossen ist.
                </li>
                <li>
                  Die Nutzer stellen den Betreiber von sämtlichen Ansprüchen Dritter frei, die diese aufgrund der Verletzung von deren
                  Rechten durch die auftragsgemäße Veröffentlichung von Materialien der Nutzer auf whiskymarket.com , im Newsletter oder
                  sonstigen Werbematerialien geltend machen. Die Freistellung umfasst neben Schadensersatzforderungen auch den Ersatz der
                  angemessenen Kosten für die Rechtsverteidigung, die aufgrund der rechtswidrigen oder nicht ordnungsgemäßen Nutzung der
                  Leistungen von whiskymarket.com entstehen. Die Freistellung setzt voraus, dass ein Vergleich oder ein Anerkenntnis über
                  Ansprüche Dritter nur mit vorheriger schriftlicher Zustimmung des Nutzers erfolgt.
                </li>
              </ol>
            </li>
            <li>
              Leistungsstörung und Haftung
              <ol>
                <li>
                  Die verschuldensunabhängige Haftung des Betreibers für anfängliche Mängel gem. § 536a BGB ist ausgeschlossen. Der
                  Betreiber haftet im Übrigen nach den im Folgenden dargelegten Grundsätzen.
                </li>
                <li>
                  Der Betreiber haftet jeweils uneingeschränkt für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit.
                  Weiter haftet der Betreiber ohne Einschränkung in allen Fällen des Vorsatzes und grober Fahrlässigkeit, bei arglistigem
                  Verschweigen eines Mangels, bei Übernahme einer Garantie und in allen anderen gesetzlich geregelten Fällen.{' '}
                </li>
                <li>
                  Sofern wesentliche Vertragspflichten des Betreibers betroffen sind, ist die Haftung des Betreibers bei leichter
                  Fahrlässigkeit auf den vertragstypischen, vorhersehbaren Schaden beschränkt. Wesentliche Vertragspflichten sind Pflichten,
                  die sich aus der Natur des Vertrages ergeben und deren Verletzung die Erreichung des Vertragszweckes gefährden würde sowie
                  Pflichten, die der Vertrag dem Betreiber nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung
                  die ordnungsgemäße Durchführung des Vertrags überhaupt erst möglich machen und auf deren Einhaltung der Nutzer regelmäßig
                  vertrauen darf.
                </li>
                <li>
                  Bei der Verletzung unwesentlicher Vertragspflichten ist die Haftung des Betreibers bei leicht fahrlässigen
                  Pflichtverletzungen ausgeschlossen.
                </li>
              </ol>
            </li>
            <li>
              Abtretung und Zurückbehaltungsrecht
              <ol>
                <li>
                  Die Abtretung von Ansprüchen gegen den Betreiber an Dritte ist nur mit schriftlicher Zustimmung möglich. Dies gilt
                  insbesondere für eine Übertragung des Benutzerkontos an einen Dritten.
                </li>
                <li>
                  Der Nutzer kann ein Zurückbehaltungsrecht nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis
                  handelt.
                </li>
              </ol>
            </li>
            <li>
              Kündigung und Löschung
              <ol>
                <li>Der Vertrag zur Nutzung der Plattform wird auf unbestimmte Zeit geschlossen.</li>
                <li>
                  Der Nutzer hat jederzeit die Möglichkeit, seinen Nutzer-Account durch Mitteilung in Textform (z.B. E-Mail) an den
                  Betreiber löschen zu lassen und damit den Vertrag zu kündigen. Der Betreiber kann den Nutzungsvertrag gegenüber dem Nutzer
                  ohne Angabe von Gründen mit einer Frist von 14 Tagen kündigen.
                </li>
                <li>
                  Das Recht zur außerordentlichen Kündigung für alle Parteien bleibt unberührt. Dabei steht dem Betreiber insbesondere bei
                  Verstößen gegen diese Nutzungsbedingungen ein außerordentliches Kündigungsrecht zu.
                </li>
                <li>Die Kündigung bedarf zu ihrer Wirksamkeit der Textform (bspw. E-Mail).</li>
              </ol>
            </li>
            <li>
              Vertragssprache, Vertragstextspeicherung
              <ol>
                <li>Vertragssprache ist deutsch.</li>
                <li>
                  Der vollständige Vertragstext wird von dem Betreiber nicht gespeichert. Vor Absenden der Registrierung des Nutzers können
                  die Vertragsdaten über die Druckfunktion des Browsers/Email-Programmes ausgedruckt oder elektronisch gesichert werden.
                  Nach Zugang der Registrierungsanfrage beim Betreiber werden die Bestelldaten, die gesetzlich vorgeschriebenen
                  Informationen bei Fernabsatzverträgen und die Allgemeinen Geschäftsbedingungen nochmals per E-Mail an den Nutzer
                  übersandt.
                </li>
              </ol>
            </li>
            <li>
              Rechtswahl, Erfüllungsort, Gerichtsstand, Alternative Streitbeilegung
              <ol>
                <li>
                  Es gilt deutsches Recht. Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung. Bei Verbrauchern gilt
                  diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen des Rechts des Staates des gewöhnlichen
                  Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird (Günstigkeitsprinzip).
                </li>
                <li>
                  Erfüllungsort für alle Leistungen aus den mit dem Betreiber bestehenden Geschäftsbeziehungen sowie Gerichtsstand ist der
                  Sitz des Betreibers, soweit der Nutzer nicht Verbraucher, sondern Kaufmann, juristische Person des öffentlichen Rechts
                  oder öffentlich-rechtliches Sondervermögen ist. Dasselbe gilt, wenn der Nutzer keinen allgemeinen Gerichtsstand in
                  Deutschland oder der EU hat oder der Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt
                  ist. Die Befugnis, auch das Gericht an einem anderen gesetzlichen Gerichtsstand anzurufen, bleibt hiervon unberührt.
                </li>
                <li>
                  Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit,
                  aufrufbar unter{' '}
                  <a href="https://ec.europa.eu/odr" target="_blank" rel="noreferrer" className="primary">
                    https://ec.europa.eu/odr
                  </a>
                  .
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="h3" classes={{ root: styles['subheading'] }}>
        B. Allgemeine Geschäftsbedingungen und Kundeninformationen für Verkäufe durch die JP Whiskymarket.com GmbH über{' '}
        <a href="/" target="_blank" rel="noreferrer" className="primary">
          www.whiskymarket.com
        </a>
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        I. Allgemeine Geschäftsbedingungen
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <div className={styles['tos']}>
          <ol>
            <li className={styles['normal']}>
              Grundlegende Bestimmungen
              <p>
                (1) Die nachstehenden Geschäftsbedingungen gelten für Verträge, die Sie mit uns als Anbieter (JP Whiskymarket.com GmbH) über
                die Internetseite www.whiskymarket.com schließen. Soweit nicht anders vereinbart, wird der Einbeziehung gegebenenfalls von
                Ihnen verwendeter eigener Bedingungen widersprochen.
              </p>
              <p>
                (2) Verbraucher im Sinne der nachstehenden Regelungen ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken
                abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann.
                Unternehmer ist jede natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines
                Rechtsgeschäfts in Ausübung ihrer selbständigen beruflichen oder gewerblichen Tätigkeit handelt.
              </p>
            </li>
            <li className={styles['normal']}>
              Zustandekommen des Vertrages
              <p>
                (1) Gegenstand des Vertrages ist der Verkauf von Waren. Unsere Angebote im Internet sind unverbindlich und kein
                verbindliches Angebot zum Abschluss eines Vertrages.
              </p>
              <p>
                (2) Ihre Anfragen zur Erstellung eines Angebotes sind für Sie unverbindlich. Wir unterbreiten Ihnen hierzu ein verbindliches
                Angebot in Textform (z.B. per E-Mail), welches Sie innerhalb von 5 Tagen annehmen können.
              </p>
              <p>
                (3) Die Abwicklung der Bestellung und Übermittlung aller im Zusammenhang mit dem Vertragsschluss erforderlichen
                Informationen erfolgt per E-Mail zum Teil automatisiert. Sie haben deshalb sicherzustellen, dass die von Ihnen bei uns
                hinterlegte E-Mail-Adresse zutreffend ist, der Empfang der E-Mails technisch sichergestellt und insbesondere nicht durch
                SPAM-Filter verhindert wird.
              </p>
            </li>
            <li className={styles['normal']}>
              Zurückbehaltungsrecht, Eigentumsvorbehalt
              <p>
                (1) Ein Zurückbehaltungsrecht können Sie nur ausüben, soweit es sich um Forderungen aus demselben Vertragsverhältnis
                handelt.
              </p>
              <p>(2) Die Ware bleibt bis zur vollständigen Zahlung des Kaufpreises unser Eigentum.</p>
              <p>(3) Sind Sie Unternehmer, gilt ergänzend Folgendes:</p>
              <p>
                a) Wir behalten uns das Eigentum an der Ware bis zum vollständigen Ausgleich aller Forderungen aus der laufenden
                Geschäftsbeziehung vor. Vor Übergang des Eigentums an der Vorbehaltsware ist eine Verpfändung oder Sicherheitsübereignung
                nicht zulässig.
              </p>
              <p>
                b) Sie können die Ware im ordentlichen Geschäftsgang weiterverkaufen. Für diesen Fall treten Sie bereits jetzt alle
                Forderungen in Höhe des Rechnungsbetrages, die Ihnen aus dem Weiterverkauf erwachsen, an uns ab, wir nehmen die Abtretung
                an. Sie sind weiter zur Einziehung der Forderung ermächtigt. Soweit Sie Ihren Zahlungsverpflichtungen nicht ordnungsgemäß
                nachkommen, behalten wir uns allerdings vor, die Forderung selbst einzuziehen.
              </p>
              <p>
                c) Bei Verbindung und Vermischung der Vorbehaltsware erwerben wir Miteigentum an der neuen Sache im Verhältnis des
                Rechnungswertes der Vorbehaltsware zu den anderen verarbeiteten Gegenständen zum Zeitpunkt der Verarbeitung.
              </p>
              <p>
                d) Wir verpflichten uns, die uns zustehenden Sicherheiten auf Ihr Verlangen insoweit freizugeben, als der realisierbare Wert
                unserer Sicherheiten die zu sichernde Forderung um mehr als 10% übersteigt. Die Auswahl der freizugebenden Sicherheiten
                obliegt uns.
              </p>
            </li>
            <li className={styles['normal']}>
              Gewährleistung
              <p>(1) Es bestehen die gesetzlichen Mängelhaftungsrechte.</p>
              <p>
                (2) Als Verbraucher werden Sie gebeten, die Sache bei Lieferung umgehend auf Vollständigkeit, offensichtliche Mängel und
                Transportschäden zu überprüfen und uns sowie dem Spediteur Beanstandungen schnellstmöglich mitzuteilen. Kommen Sie dem nicht
                nach, hat dies keine Auswirkung auf Ihre gesetzlichen Gewährleistungsansprüche.
              </p>
              <p>(3) Soweit Sie Unternehmer sind, gilt abweichend von den vorstehenden Gewährleistungsregelungen:</p>
              <p>
                a) Als Beschaffenheit der Sache gelten nur unsere eigenen Angaben und die Produktbeschreibung des Herstellers als
                vereinbart, nicht jedoch sonstige Werbung, öffentliche Anpreisungen und Äußerungen des Herstellers.
              </p>
              <p>
                b) Bei Mängeln leisten wir nach unserer Wahl Gewähr durch Nachbesserung oder Nachlieferung. Schlägt die Mangelbeseitigung
                fehl, können Sie nach Ihrer Wahl Minderung verlangen oder vom Vertrag zurücktreten. Die Mängelbeseitigung gilt nach
                erfolglosem zweiten Versuch als fehlgeschlagen, wenn sich nicht insbesondere aus der Art der Sache oder des Mangels oder den
                sonstigen Umständen etwas anderes ergibt. Im Falle der Nachbesserung müssen wir nicht die erhöhten Kosten tragen, die durch
                die Verbringung der Ware an einen anderen Ort als den Erfüllungsort entstehen, sofern die Verbringung nicht dem
                bestimmungsgemäßen Gebrauch der Ware entspricht.
              </p>
              <p>c) Die Gewährleistungsfrist beträgt ein Jahr ab Ablieferung der Ware. Die Fristverkürzung gilt nicht:</p>
              <p>
                {' '}
                - für uns zurechenbare schuldhaft verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und bei
                vorsätzlich oder grob fahrlässig verursachten sonstigen Schäden;
              </p>
              <p> - soweit wir den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache übernommen haben;</p>
              <p>
                {' '}
                - bei Sachen, die entsprechend ihrer üblichen Verwendungsweise für ein Bauwerk verwendet worden sind und dessen
                Mangelhaftigkeit verursacht haben;
              </p>
              <p> - bei gesetzlichen Rückgriffsansprüchen, die Sie im Zusammenhang mit Mängelrechten gegen uns haben.</p>
            </li>
            <li className={styles['normal']}>
              Rechtswahl, Erfüllungsort, Gerichtsstand
              <p>
                (1) Es gilt deutsches Recht. Bei Verbrauchern gilt diese Rechtswahl nur, soweit hierdurch der durch zwingende Bestimmungen
                des Rechts des Staates des gewöhnlichen Aufenthaltes des Verbrauchers gewährte Schutz nicht entzogen wird
                (Günstigkeitsprinzip).
              </p>
              <p>
                (2) Erfüllungsort für alle Leistungen aus den mit uns bestehenden Geschäftsbeziehungen sowie Gerichtsstand ist unser Sitz,
                soweit Sie nicht Verbraucher, sondern Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
                Sondervermögen sind. Dasselbe gilt, wenn Sie keinen allgemeinen Gerichtsstand in Deutschland oder der EU haben oder der
                Wohnsitz oder gewöhnliche Aufenthalt im Zeitpunkt der Klageerhebung nicht bekannt ist. Die Befugnis, auch das Gericht an
                einem anderen gesetzlichen Gerichtsstand anzurufen, bleibt hiervon unberührt.
              </p>
              <p>(3) Die Bestimmungen des UN-Kaufrechts finden ausdrücklich keine Anwendung.</p>
            </li>
            <li className={styles['normal']}>
              Jugendschutz
              <p>
                (1) Beim Verkauf von Ware, die den Regelungen des Jugendschutzgesetzes unterfällt, gehen wir nur Vertragsbeziehungen mit
                Kunden ein, die das gesetzlich vorgeschriebene Mindestalter erreicht haben. Auf bestehende Altersbeschränkungen wird in der
                jeweiligen Artikelbeschreibung hingewiesen.
              </p>
              <p>
                (2) Sie versichern mit Absenden Ihrer Bestellung, das gesetzlich vorgeschriebene Mindestalter erreicht zu haben und dass
                Ihre Angaben bezüglich Ihres Namens und Ihrer Adresse richtig sind. Sie sind verpflichtet, dafür Sorge zu tragen, dass nur
                Sie selbst oder solche von Ihnen zur Entgegennahme der Lieferung ermächtigte Personen, die das gesetzlich vorgeschriebene
                Mindestalter erreicht haben, die Ware in Empfang nehmen.
              </p>
              <p>
                (3) Soweit wir aufgrund der gesetzlichen Bestimmungen verpflichtet sind, eine Alterskontrolle vorzunehmen, weisen wir den
                mit der Lieferung beauftragten Logistikdienstleister an, die Lieferung nur an Personen zu übergeben, die das gesetzlich
                vorgeschriebene Mindestalter erreicht haben, und sich im Zweifelsfall den Personalausweis der die Ware in Empfang nehmenden
                Person zur Alterskontrolle vorzeigen zu lassen.
              </p>
              <p>
                (4) Soweit wir über das gesetzlich vorgeschriebene Mindestalter hinausgehend in der jeweiligen Artikelbeschreibung
                ausweisen, dass Sie für den Kauf der Ware das 18. Lebensjahr vollendet haben müssen, gelten vorstehende Absätze 1-3 mit der
                Maßgabe, dass anstelle des gesetzlich vorgeschriebenen Mindestalters Volljährigkeit vorliegen muss.
              </p>
            </li>
          </ol>
        </div>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        II. Kundeninformationen
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        <div className={styles['tos']}>
          <ol>
            <li>
              Identität des Verkäufers
              <Typography variant="body1" classes={bodyClass}>
                JP Whiskymarket.com GmbH
                <br />
                Oehlertring 1<br />
                12169 Berlin
                <br />
                Deutschland
                <br />
                <br />
                Telefon: +49 (0) 15 25 30 5 77 55
                <br />
                E-Mail: info@whiskymarket.com
              </Typography>
              <Typography variant="body1" classes={bodyClass}>
                <b>Alternative Streitbeilegung:</b>
                <br />
                Die Europäische Kommission stellt eine Plattform für die außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit,
                aufrufbar unter{' '}
                <a href="https://ec.europa.eu/odr" target="_blank" rel="noreferrer" className="primary">
                  https://ec.europa.eu/odr
                </a>
                .
              </Typography>
              <Typography variant="body1" classes={bodyClass}>
                Wir sind nicht bereit, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.
              </Typography>
            </li>
            <li>
              Informationen zum Zustandekommen des Vertrages
              <Typography variant="body1" classes={bodyClass}>
                Die technischen Schritte zum Vertragsschluss, der Vertragsschluss selbst und die Korrekturmöglichkeiten erfolgen nach
                Maßgabe der Regelungen "Zustandekommen des Vertrages" unserer Allgemeinen Geschäftsbedingungen (Teil I.).
              </Typography>
            </li>
            <li>
              Vertragssprache, Vertragstextspeicherung
              <ol>
                <li>Vertragssprache ist deutsch.</li>
                <li>
                  Der vollständige Vertragstext wird von uns nicht gespeichert. Vor Absenden der Bestellung können die Vertragsdaten über
                  die Druckfunktion des Browsers ausgedruckt oder elektronisch gesichert werden. Nach Zugang der Bestellung bei uns werden
                  die Bestelldaten, die gesetzlich vorgeschriebenen Informationen bei Fernabsatzverträgen und die Allgemeinen
                  Geschäftsbedingungen nochmals per E-Mail an Sie übersandt.
                </li>
                <li>
                  Bei Angebotsanfragen außerhalb des Online-Warenkorbsystems erhalten Sie alle Vertragsdaten im Rahmen eines verbindlichen
                  Angebotes in Textform übersandt, z.B. per E-Mail, welche Sie ausdrucken oder elektronisch sichern können.
                </li>
              </ol>
            </li>
            <li>
              Wesentliche Merkmale der Ware oder Dienstleistung
              <Typography variant="body1" classes={bodyClass}>
                Die wesentlichen Merkmale der Ware und/oder Dienstleistung finden sich im jeweiligen Angebot.
              </Typography>
            </li>
            <li>
              Preise und Zahlungsmodalitäten
              <ol>
                <li>
                  Die in den jeweiligen Angeboten angeführten Preise sowie die Versandkosten stellen Gesamtpreise dar. Sie beinhalten alle
                  Preisbestandteile einschließlich aller anfallenden Steuern.
                </li>
                <li>
                  Die anfallenden Versandkosten sind nicht im Kaufpreis enthalten. Sie sind über eine entsprechend bezeichnete Schaltfläche
                  auf unserer Internetpräsenz oder im jeweiligen Angebot aufrufbar, werden im Laufe des Bestellvorganges gesondert
                  ausgewiesen und sind von Ihnen zusätzlich zu tragen, soweit nicht die versandkostenfreie Lieferung zugesagt ist.
                </li>
                <li>
                  Erfolgt die Lieferung in Länder außerhalb der Europäischen Union können von uns nicht zu vertretende weitere Kosten
                  anfallen, wie z.B. Zölle, Steuern oder Geldübermittlungsgebühren (Überweisungs- oder Wechselkursgebühren der
                  Kreditinstitute), die von Ihnen zu tragen sind.
                </li>
                <li>
                  Entstandene Kosten der Geldübermittlung (Überweisungs- oder Wechselkursgebühren der Kreditinstitute) sind von Ihnen in den
                  Fällen zu tragen, in denen die Lieferung in einen EU-Mitgliedsstaat erfolgt, die Zahlung aber außerhalb der Europäischen
                  Union veranlasst wurde.
                </li>
                <li>
                  Die Ihnen zur Verfügung stehenden Zahlungsarten sind unter einer entsprechend bezeichneten Schaltfläche auf unserer
                  Internetpräsenz oder im jeweiligen Angebot ausgewiesen.
                </li>
                <li>
                  Soweit bei den einzelnen Zahlungsarten nicht anders angegeben, sind die Zahlungsansprüche aus dem geschlossenen Vertrag
                  sofort zur Zahlung fällig.
                </li>
              </ol>
            </li>
            <li>
              Lieferbedingungen
              <ol>
                <li>
                  Die Lieferbedingungen, der Liefertermin sowie gegebenenfalls bestehende Lieferbeschränkungen finden sich unter einer
                  entsprechend bezeichneten Schaltfläche auf unserer Internetpräsenz oder im jeweiligen Angebot.
                </li>
                <li>
                  Soweit Sie Verbraucher sind ist gesetzlich geregelt, dass die Gefahr des zufälligen Untergangs und der zufälligen
                  Verschlechterung der verkauften Sache während der Versendung erst mit der Übergabe der Ware an Sie übergeht, unabhängig
                  davon, ob die Versendung versichert oder unversichert erfolgt. Dies gilt nicht, wenn Sie eigenständig ein nicht vom
                  Unternehmer benanntes Transportunternehmen oder eine sonst zur Ausführung der Versendung bestimmte Person beauftragt
                  haben.
                </li>
                <li>Sind Sie Unternehmer, erfolgt die Lieferung und Versendung auf Ihre Gefahr.</li>
              </ol>
            </li>
            <li>
              Gesetzliches Mängelhaftungsrecht
              <Typography variant="body1" classes={bodyClass}>
                Die Mängelhaftung richtet sich nach der Regelung "Gewährleistung" in unseren Allgemeinen Geschäftsbedingungen (Teil I).
              </Typography>
            </li>
          </ol>
        </div>
      </Typography>
    </>
  )
}

TosPageEN.getInitialProps = function () {
  return {}
}

export default TosPageEN
