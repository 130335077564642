import classNames from 'classnames'
import React from 'react'
import styles from './ImageUpload.module.scss'
import Img from '../Img'

type Props = {
  classes?: string
  accept: string
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDeleteClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  imagePreview: string | null
  primary: boolean
  disabled?: boolean
}

const ImageUploadImage = (props: Props) => {
  const { accept, onFileChange, imagePreview } = props

  const classes = classNames(styles['upload-box'], {
    [styles['upload-box--primary']]: props.primary,
  })

  return (
    <div className={classes}>
      <input accept={accept} type="file" className={styles['upload-box__input']} onChange={onFileChange} disabled={props.disabled} />
      {imagePreview ? (
        <>
          <Img base64 src={imagePreview} classes={styles['upload-box__preview']} />
          {props.onDeleteClick ? (
            <Img
              classes={styles['upload-box__icon-delete']}
              src="/icons/delete.svg"
              onClick={event => {
                event.stopPropagation()
                props.onDeleteClick?.(event)
              }}
            />
          ) : null}
        </>
      ) : (
        <Img src="/icons/add.svg" classes={styles['upload-box__icon-add']} alt="Add" />
      )}
    </div>
  )
}

export default ImageUploadImage
