import Yup from '../../../../utils/yup'

const schema = Yup.object().shape({
  companyName: Yup.string()
    .trim()
    .required('Required for verification')
    .max(35, 'Can contain 35 signs'),
  website: Yup.string()
    .trim()
    .max(200, 'Can contain 200 signs'),
  address1: Yup.string()
    .trim()
    .max(30, 'Can contain 30 signs'),
  address2: Yup.string()
    .trim()
    .max(30, 'Can contain 30 signs'),
  postCode: Yup.string()
    .trim()
    .max(50, 'Can contain 50 signs'),
  city: Yup.string(),
  taxId: Yup.string()
    .required('Required for verification')
    .max(35, 'Can contain 35 signs'),
  country: Yup.string()
    .required('Required for verification')
    //@ts-ignore
    .isValidCountryIsoCode('Must be valid country name'),
  phoneCode: Yup.string().required('Required'),
  phone: Yup.string()
    .trim()
    .required('Required for verification'),
  aboutMe: Yup.string()
    .trim()
    .max(200, 'Can contain 200 signs'),
})

export default schema
