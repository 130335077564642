import _ from 'lodash'
import React, { useState } from 'react'
import { v1 as uuid } from 'uuid'
import ImageUploadAvatar from './ImageUploadAvatar'
import ImageUploadFile from './ImageUploadFile'
import ImageUploadImage from './ImageUploadImage'

const ERROR_REASON_ABORT = 'abort'
const ERROR_REASON_ERROR = 'error'

export enum MODE_TYPE {
  AVATAR = 'avatar',
  FILE = 'file',
  IMAGE = 'image',
}

const IMAGE =
  'data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYgMTYiIHdpZHRoPSIxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgY3g9IjgiIGN5PSI4IiBmaWxsPSIjYThjZjc3IiByPSI4Ii8+PHBhdGggZD0ibTMuNzk3OTE4NDcgNi4wNDA1NTkxNmguMDAyMDgxNTN2MmgtMy44di0yaDEuNzk3OTE4NDd2LTYuMDQwNTU5MTZoMnoiIGZpbGw9IiNmZmYiIHRyYW5zZm9ybT0ibWF0cml4KC43MDcxMDY3OCAuNzA3MTA2NzggLS43MDcxMDY3OCAuNzA3MTA2NzggOS40MjA4MTUxMTUzNiAzLjE1Njg1NDExNTM2KSIvPjwvZz48L3N2Zz4='

const defaultProps = Object.freeze({
  onSizeError: _.noop,
  onTypeError: _.noop,
  accept: 'image/*,capture=camera' as string,
  acceptedTypes: ['image/png', 'image/jpg', 'image/jpeg'] as string[],
  mode: MODE_TYPE.IMAGE as MODE_TYPE,
})

type Props = typeof defaultProps & {
  classes?: string
  primary?: boolean
  onError?: (file: File | null, reason: string) => void
  /** in kB */
  minSize?: number
  /** in kB */
  maxSize?: number
  imageKey?: string
  onChange?: (file: File, base64: string, imageKey: string, oldId: string | null) => void
  onDeleteClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, imageKey: string, oldId: string | null) => void
  onSizeError?: (toBig: boolean, toSmall: boolean) => void
  onTypeError?: (type: string) => void
  changeLabel?: string
  imageUploading?: boolean
  image?: string | null
  imageId?: string | null
  disabled?: boolean
}

const ImageUpload = (props: Props) => {
  const { onSizeError, accept, acceptedTypes, onTypeError, onError, mode, primary, changeLabel, imageUploading, image } = props
  const [imagePreview, setImagePreview] = useState<string | null>(image || null)

  React.useEffect(() => {
    setImagePreview(props.image || null)
  }, [props.image])

  const minSize = (props.minSize || 1) * 1024
  const maxSize = (props.maxSize || 1024) * 1024

  const onChange = (file: File | null, base64: string = '') => {
    const imgData = file ? URL.createObjectURL(file) : null
    setImagePreview(imgData)
    props.onChange && file && imgData ? props.onChange(file, base64, props.imageKey || uuid(), props.imageId || null) : _.noop()
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input: EventTarget & HTMLInputElement = event.target
    const file: File | null = input?.files ? input.files[0] : null
    const reader = new FileReader()

    reader.onabort = () => onError && onError(file, ERROR_REASON_ABORT)
    reader.onerror = () => onError && onError(file, ERROR_REASON_ERROR)
    reader.onload = () => {
      if (file?.type && !acceptedTypes.includes(file.type)) {
        onTypeError && onTypeError(file.type)
        return
      }

      if (file && file.size && (file.size > maxSize || file.size < minSize)) {
        onSizeError && onSizeError(file.size > maxSize, file.size < minSize)
        return
      }

      onChange(file, reader.result as string)

      if (!file?.type.startsWith('image/')) {
        setImagePreview(IMAGE)
      }
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  let result: JSX.Element

  if (mode === MODE_TYPE.AVATAR) {
    result = (
      <ImageUploadAvatar
        accept={accept}
        onFileChange={onFileChange}
        imagePreview={imagePreview}
        changeLabel={changeLabel || 'Change'}
        imageUploading={imageUploading}
        disabled={props.disabled}
      />
    )
  } else if (mode === MODE_TYPE.FILE) {
    result = (
      <ImageUploadFile
        accept={accept}
        onFileChange={onFileChange}
        onDeleteClick={props.onDeleteClick ? e => {
          setImagePreview(null)
          props.onDeleteClick?.(e, props.imageKey || uuid(), props.imageId || null)
        } : undefined}
        imagePreview={imagePreview}
        primary={primary || false}
        disabled={props.disabled}
      />
    )
  } else if (mode === MODE_TYPE.IMAGE) {
    result = (
      <ImageUploadImage
        accept={accept}
        onFileChange={onFileChange}
        onDeleteClick={e => {
          if (props.onDeleteClick) {
            setImagePreview(null)
            props.onDeleteClick(e, props.imageKey || uuid(), props.imageId || null)
          }
        }}
        imagePreview={imagePreview}
        primary={primary || false}
        disabled={props.disabled}
      />
    )
  } else {
    throw new Error('Invalid upload mode')
  }

  return result
}

ImageUpload.defaultProps = defaultProps

export default ImageUpload
