import { Button } from '@material-ui/core'
import classNames from 'classnames'
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import _, { values } from 'lodash'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styles from './ContactForm.module.scss'
import schema from './ContactForm.schema'
import { FormType } from './ContactForm.types'
import { renderTextarea, renderTextField } from './ContactFormFields'
import { FileUploadListType } from '../../../interfaces/Contact.type'
import { OfferType } from '../../../interfaces/Offer.type'
import { UserType } from '../../../interfaces/User.type'
import FormCheckboxField from '../../Form/FormCheckboxField'
import notify from '../../Notification/notify'
import Trans from '../../Trans'

export type Props = {
  offer: OfferType
  user: UserType
  onFormSubmit: (values: FormType, formiKBag: FormikProps<FormType>) => void
}

const ContactFormView = (props: Props) => {
  const [isVerified, setIsVerified] = React.useState(false)
  const phone = props.user.data?.phoneCode && props.user.data?.phone ? `${props.user.data?.phoneCode} ${props.user.data?.phone}` : ''

  const handleRecaptchaSolved = () => {
    setIsVerified(true)
  }

  return (
    <div className={styles['contact__box']}>
      <Formik
        initialValues={
          {
            name: '',
            email: props.user.data?.email || '',
            phone,
            topic: props.offer.title,
            description: '',
            tos: false,
          } as FormType
        }
        validationSchema={schema}
        onSubmit={(inValues: FormType, formikBag: FormikHelpers<FormType>) => {
          if (isVerified) {
            props.onFormSubmit(inValues, formikBag as FormikProps<FormType>)
          } else {
            formikBag.setSubmitting(false)
            notify.error(<Trans ns="ContactForm" id="RecaptchaError" msg="You'll need to solve a ReCAPTCHA" />)
          }
        }}
      >
        {(formikBag: FormikProps<FormType>) => {
          return (
            <Form className={classNames(styles['form__grid--full'], styles['contact__form'])}>
              {renderTextField(
                'name',
                'text',
                <Trans ns="ContactForm" id="Name" msg="Name*" />,
                {
                  InputProps: {
                    placeholder: 'e.g. John Smith',
                  },
                },
                { root: classNames(styles['form__grid--half'], styles['form__grid--name']) },
              )}
              {renderTextField(
                'email',
                'string',
                <Trans ns="ContactForm" id="Email" msg="Email*" />,
                {
                  InputProps: {
                    placeholder: 'e.g. smith@outlook.com',
                  },
                },
                { root: classNames(styles['form__grid--half'], styles['form__grid--email']) },
              )}
              {renderTextField(
                'phone',
                'string',
                <Trans ns="ContactForm" id="PhoneNumber" msg="Phone number" />,
                {
                  InputProps: {
                    placeholder: 'e.g. 123 456 789',
                  },
                },
                { root: classNames(styles['form__grid--half'], styles['form__grid--phone']) },
              )}
              {renderTextField(
                'topic',
                'string',
                <Trans ns="ContactForm" id="Topic" msg="Topic*" />,
                {
                  InputProps: {
                    placeholder: 'e.g. 123 456 789',
                    readOnly: true,
                  },
                },
                { root: classNames(styles['form__grid--half'], styles['form__grid--topic']) },
              )}
              {renderTextarea(
                'description',
                <Trans ns="ContactForm" id="Description" msg="Type your question here" />,
                {
                  InputProps: { placeholder: 'Start typing...' },
                  helperText: (
                    <>
                      <Trans ns="ContactForm" id="DescriptionLimit" msg="Max 5000 signs" />
                    </>
                  ),
                },
                { root: classNames(styles['form__grid--full'], styles['form__grid--description']) },
              )}
              <div className={classNames(styles['captcha-box'])}>
                <ReCAPTCHA onChange={handleRecaptchaSolved} sitekey="6Ler8yAaAAAAANbJJuW52hus01Ni_JasUlWD5nx-" />
              </div>
              <div className={styles['contact__tos']}>
                <Field type="checkbox" name="tos" label="I accept the Terms Of Service" component={FormCheckboxField} fullWidth />
              </div>
              <div className={styles['button-container']}>
                <Button
                  onClick={async () => {
                    const result = await formikBag.validateForm()

                    if (Object.keys(result).length) {
                      notify.error(<Trans ns="ContactForm" id="GlobalError" msg="Some fields needs correction" />)
                    }

                    formikBag.submitForm()
                  }}
                  variant="contained"
                  color="primary"
                  className={styles['button-container__button--add']}
                  disabled={formikBag.isSubmitting}
                >
                  <Trans ns="ContactForm" id="ButtonSend" msg="Send" />
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ContactFormView
