type ShareDetails = ShareData & {
  clipboardText?: string
}

type ShareType = 'share' | 'clipboard'

const useShare = () => {
  const share = async (data: ShareDetails): Promise<ShareType> => {
    if (navigator.share) {
      try {
        await navigator.share(data)
      } catch (e) {
        console.error(e)
      }
      
      return Promise.resolve('share')
    }
    
    if (navigator.clipboard && data.clipboardText) {
      navigator.clipboard.writeText(data.clipboardText)
      return Promise.resolve('clipboard')
    }

    return Promise.reject('Web share is currently not supported on this browser')
  }

  return {
    share,
  }
}

export default useShare
