import React from 'react'
import { useSelector } from 'react-redux'
import useAuth from '../../hooks/auth'
import { GoogleSignInPayloadType } from '../../services/auth/auth.types'
import { StateType } from '../../services/reducers'
import { AxiosError } from '../../utils/axios'
import { FacebookLogin } from '../../utils/fb'
import handleError from '../../utils/handleError'
import notify from '../Notification/notify'
import Trans from '../Trans'
import FacebookButton from '../UI/FacebookButton'
import GoogleButton from '../UI/GoogleButton'

type Props = {
  classes?: {
    facebook?: string
    google?: string
    weChat?: string
  }
}

const SignSocial = (props: Props) => {
  const { classes } = props
  const { facebookAuth, googleAuth } = useAuth()
  const acceptedCookies = useSelector((state: StateType) => state.app.acceptedCookies)

  const onGoogle = (payload: GoogleSignInPayloadType) => {
    if (payload && payload.accessToken && payload.idToken) {
      googleAuth(
        payload,
        () => {
          notify.success('You have been successfully logged in')
        },
        (response: AxiosError) => {
          notify.error('Something went wrong, please try again and try clear your cookies.')
        },
      )
    } else {
      notify.error('Something went wrong, please try again and try clear your cookies.')
    }
  }

  const onGoogleError = (error: string) => {
    console.log(error)
    notify.error('Please authorize our application')
  }

  const onFacebook = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    FacebookLogin(
      (response: fb.StatusResponse) => {
        console.log({ type: 'facebook', response })
        facebookAuth(
          response.authResponse?.accessToken!,
          () => {
            notify.success('You have been successfully logged in')
          },
          (error: AxiosError) => {
            handleError(error)
          },
        )
      },
      (response: fb.StatusResponse) => {
        notify.error('Please authorize our application')
      },
    )
  }

  return acceptedCookies ? (
    <>
      <FacebookButton
        onClick={onFacebook}
        classes={{ root: classes?.facebook }}
        label={<Trans ns="SignSocial" id="SignSocial.FacebookButton" msg="Continue with Facebook" />}
      />
      <GoogleButton
        onSuccess={onGoogle}
        onError={onGoogleError}
        classes={{ root: classes?.google }}
        label={<Trans ns="SignSocial" id="SignSocial.GoogleButton" msg="Continue with Google" />}
      />
      {/*
      <WeChatButton
        classes={{ root: classes?.weChat }}
        label={<Trans ns="SignSocial" id="SignInForm.WeChatButton" msg="Continue with WeChat" />}
      />
      */}
    </>
  ) : null
}

export default SignSocial
