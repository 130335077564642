import { Button, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import _ from 'lodash'
import React from 'react'
import styles from './ChangePassword.module.scss'
import schema from './ChangePassword.schema'
import { ChangePasswordFormType } from './ChangePassword.types'
import * as userApi from '../../../../services/user/user.api'
import { AxiosResponse } from '../../../../utils/axios'
import handleError from '../../../../utils/handleError'
import Dialog, { DISPLAY_MODE } from '../../../Dialog'
import FormTextField from '../../../Form/FormTextField'
import notify from '../../../Notification/notify'
import Trans from '../../../Trans'
import { PASSWORD_FORM_MODE } from '../UserSettings.types'

type Props = {
  open?: boolean
  onClose?: () => void
  setMode: React.Dispatch<React.SetStateAction<PASSWORD_FORM_MODE>>
}

const ChangePassword = (props: Props) => {
  const { setMode, open } = props
  const onClose = (
    event: React.MouseEvent<HTMLDivElement | SVGSVGElement | HTMLDivElement | HTMLButtonElement, MouseEvent>,
    reason?: string,
  ) => {
    setMode(PASSWORD_FORM_MODE.HIDE)
  }

  const onSubmit = async (values: ChangePasswordFormType, formikBag: FormikHelpers<ChangePasswordFormType>) => {
    let response: AxiosResponse | null = null
    try {
      response = await userApi.updatePassword(values)
      formikBag.setSubmitting(false)
      formikBag.resetForm()
      setMode(PASSWORD_FORM_MODE.HIDE)
      notify.success('Password was changed successfully')
    } catch (error) {
      console.error(error)
      formikBag.setSubmitting(false)
      handleError(error, formikBag)
    }
  }
  return (
    <>
      <a className={classNames('primary', styles['link'])} onClick={() => setMode(PASSWORD_FORM_MODE.SHOW)}>
        Change your password
      </a>

      <Dialog
        open={open || false}
        onClose={onClose}
        displayMode={DISPLAY_MODE.fullScreenMobileOnly}
        classes={{ root: styles['dialog'] }}
        hideCloseIcon
      >
        <Typography variant="h3" classes={{ root: styles['headline'] }}>
          <Trans ns="ChangePassword" id="ChangePasswordHeadline" msg="Change your password" />
        </Typography>

        <Formik
          validationSchema={schema}
          initialValues={
            {
              oldPassword: '',
              newPassword: '',
              repeatPassword: '',
            } as ChangePasswordFormType
          }
          onSubmit={(values: ChangePasswordFormType, formikBag: FormikHelpers<ChangePasswordFormType>) => {
            onSubmit(values, formikBag)
          }}
        >
          {(formikBag: FormikProps<ChangePasswordFormType>) => {
            return (
              <Form>
                <Field name="oldPassword" fullWidth>
                  {(_props: FieldProps<string, ChangePasswordFormType>) => {
                    return <FormTextField {..._props} label="Old Password" fullWidth InputProps={{ type: 'password' }} />
                  }}
                </Field>
                <Field name="newPassword" fullWidth>
                  {(_props: FieldProps<string, ChangePasswordFormType>) => {
                    return <FormTextField {..._props} label="New password" fullWidth InputProps={{ type: 'password' }} />
                  }}
                </Field>
                <Field name="repeatPassword" fullWidth>
                  {(_props: FieldProps<string, ChangePasswordFormType>) => {
                    return <FormTextField {..._props} label="Repeat password" fullWidth InputProps={{ type: 'password' }} />
                  }}
                </Field>
                <div className={styles['navigation']}>
                  <Button variant="contained" color="default" onClick={onClose} disabled={formikBag.isSubmitting}>
                    <Trans ns="ChangePassword" id="CancelButton" msg="Cancel" />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={formikBag.submitForm}
                    disabled={formikBag.isSubmitting}
                    classes={{ root: styles['navigation__button-save'] }}
                  >
                    <Trans ns="ChangePassword" id="CancelButton" msg="Save" />
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </>
  )
}

export default ChangePassword
