import { TextField as MaterialTextField } from '@material-ui/core'
import { InputProps } from '@material-ui/core/Input'
import { InputLabelProps } from '@material-ui/core/InputLabel'
import { TextFieldProps } from '@material-ui/core/TextField'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import styles from './TextField.module.scss'

const defaultProps = Object.freeze({
  variant: 'outlined' as any,
  InputProps: { notched: false } as InputProps,
  InputLabelProps: { shrink: true } as InputLabelProps,
  withMargins: true as boolean,
})

type Props = TextFieldProps & typeof defaultProps

const TextField = (props: Props) => {
  const classes = {
    root: classNames(
      { [styles['text-field--with-margins']]: props.withMargins, [styles['text-field--hasHelper']]: !!props.helperText },
      props.classes?.root,
    ),
  }
  const inputProps = _.merge({}, defaultProps.InputProps, props.InputProps)
  return <MaterialTextField {..._.omit(props, ['withMargins'])} InputProps={inputProps} classes={classes} />
}

TextField.defaultProps = defaultProps

export default TextField
