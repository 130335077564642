import { ChangePasswordPayloadType, GoogleSignInPayloadType, ResetPasswordPayloadType } from './auth.types'
import axios from '../../utils/axios'

type SignInPayloadType = {
  email: string
  password: string
}

type SignUpPayloadType = {
  email: string
  password: string
  repeatedPassword: string
  tos: boolean
  newsletter: boolean
}

export function signUp(payload: SignUpPayloadType) {
  return axios({
    method: 'POST',
    url: '/auth/sign-up',
    data: payload,
  })
}

export function confirm(userId: string, token: string) {
  return axios({
    method: 'PUT',
    url: `/user/${userId}/confirm/${token}`,
  })
}

export function signIn(payload: SignInPayloadType) {
  return axios({
    method: 'POST',
    url: '/auth/sign-in',
    data: payload,
  })
}

export function logOut() {
  return axios({
    method: 'GET',
    url: '/auth/sign-out',
  })
}

export function facebook(token: string) {
  return axios({
    method: 'post',
    url: '/facebook/sign-in',
    data: {
      accessToken: token,
    },
  })
}

export function google(payload: GoogleSignInPayloadType) {
  return axios({
    method: 'post',
    url: '/google/sign-in',
    data: payload,
  })
}

export function resetPassword(payload: ResetPasswordPayloadType) {
  return axios({
    method: 'post',
    url: '/user/reset-password',
    data: payload,
  })
}

export function changePassword(payload: ChangePasswordPayloadType) {
  return axios({
    method: 'post',
    url: `/user/${payload.userId}/change-password/${payload.token}`,
    data: {
      password: payload.password,
      repeatedPassword: payload.repeatedPassword,
    },
  })
}
