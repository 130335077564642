import { MenuItem } from '@material-ui/core'
import { Field } from 'formik'
import _ from 'lodash'
import React from 'react'
import styles from './SellForm.module.scss'
import FormSelectField from '../Form/FormSelectField'
import FormTextField from '../Form/FormTextField'

type Topic = {
  id: string
  name: string
}

export const renderSelectField = (name: string, label: React.ReactNode, values: Topic[], props: object = {}, classes: object) => {
  return (
    <Field name={name} label={label} component={FormSelectField} fullWidth classes={classes} {...props}>
      <MenuItem value="">Choose</MenuItem>
      {values.map((item: Topic) => (
        <MenuItem value={item.id} key={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Field>
  )
}

export const renderTextarea = (
  name: string,
  label: React.ReactNode,
  props: Record<string, any> = {},
  classes: object = { root: styles['form__grid--full'] },
) => {
  const fullWidth = props?.fullWidth == null || props.fullWidth === true
  const InputProps = props.InputProps != null ? props.InputProps : {}
  InputProps.multiline = InputProps.multiline || true
  InputProps.rows = InputProps.rows || 10

  return renderFormField(
    FormTextField,
    name,
    'text',
    label,
    {
      ...props,
      fullWidth,
      InputProps,
    },
    classes,
  )
}

export const renderTextField = (
  name: string,
  type: string,
  label: React.ReactNode,
  props: object = {},
  classes: object = { root: styles['form__grid--half'] },
) => {
  return renderFormField(FormTextField, name, type, label, props, classes)
}

const renderFormField = (
  component: React.ReactNode,
  name: string,
  type: string,
  label: React.ReactNode,
  props: object = {},
  classes: object = { root: styles['form__grid--half'] },
) => {
  return <Field name={name} label={label} component={component} fullWidth type={type} classes={classes} {...props} />
}
