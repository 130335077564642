import { Dialog as MaterialDialog } from '@material-ui/core'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { isMobile } from 'react-device-detect'
import styles from './Dialog.module.scss'
import ChildrenType from '../../interfaces/Children.type'
import Img from '../Img'
import Logo from '../Logo'

export enum DISPLAY_MODE {
  fullScreenAlways = 'fullScreen',
  fullScreenMobileOnly = 'fullScreenMobileOnly',
  fullScreenMobileOnlyWithHiddenPC = 'fullScreenMobileOnlyWithHiddenPC',
}

const defaultProps = Object.freeze({
  open: false as boolean,
  children: [] as ChildrenType,
  onClose: _.noop as (event: React.MouseEvent<HTMLDivElement | SVGSVGElement, MouseEvent>, reason?: string) => void,
  withLogo: false as boolean,
  displayMode: DISPLAY_MODE.fullScreenAlways as DISPLAY_MODE,
  hideCloseIcon: false as boolean,
})

type ClassesType = {
  root?: string
}

type Props = typeof defaultProps & {
  classes?: ClassesType
}

const Dialog = (props: Props) => {
  const { children, withLogo, onClose, displayMode, hideCloseIcon } = props
  let open = props.open
  let fullScreen = displayMode === DISPLAY_MODE.fullScreenAlways
  if (displayMode === DISPLAY_MODE.fullScreenMobileOnly && isMobile) {
    fullScreen = true
  } else if (displayMode === DISPLAY_MODE.fullScreenMobileOnlyWithHiddenPC && isMobile) {
    fullScreen = true
  } else if (displayMode === DISPLAY_MODE.fullScreenMobileOnlyWithHiddenPC && !isMobile) {
    fullScreen = false
    open = false
  }
  return (
    <MaterialDialog
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      classes={{
        paper: classNames(styles['dialog'], props.classes?.root, { isMobile }),
      }}
      disableEscapeKeyDown
    >
      <>
        <div className={styles['header']}>
          {withLogo ? (
            <div className={styles['header__logo']}>
              <Logo dialog />
            </div>
          ) : null}
          {hideCloseIcon ? null : <Img src="/icons/close.svg" classes={styles['header__close']} onClick={onClose} />}
        </div>
        {children}
      </>
    </MaterialDialog>
  )
}

Dialog.defaultProps = defaultProps

export default Dialog
