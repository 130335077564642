import { Typography } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import cn from 'classnames'
import React from 'react'
import styles from './Footer.module.scss'
import Img from '../Img'
import Link, { routes } from '../Link'
import Newsletter from '../Newsletter'
import Trans from '../Trans'

const defaultProps = Object.freeze({
  mode: 'normal' as 'normal' | 'simplified',
})

type Props = typeof defaultProps

const Footer = (props: Props) => {
  return (
    <footer className={styles['footer']}>
      <div className={styles['company']}>
        <Img src="/img/logo_white.svg" classes={styles['logo']} />
        <div className={styles['social']}>
          <a href="https://facebook.com" target="_blank" rel="noreferrer" className={styles['social__icon']}>
            <FacebookIcon htmlColor="#fff" />
          </a>
          <a href="https://instagram.com" target="_blank" rel="noreferrer" className={styles['social__icon']}>
            <InstagramIcon htmlColor="#fff" />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noreferrer" className={styles['social__icon']}>
            <TwitterIcon htmlColor="#fff" />
          </a>
        </div>
        <div className={styles['company__line--first']}>Impressum</div>
        <div>
          Gesetzliche Anbieterkennung:
          <br /> JP Whiskymarket.com GmbH
          <br /> Oehlertring 1<br /> 12169 Berlin
          <br /> Germany
        </div>
      </div>
      <div className={styles['links']}>
        <ul className={styles['links__list']}>
          <li className={styles['list__item']}>
            <Link to={routes.faq} classes="inverted">
              FAQ
            </Link>
          </li>
          <li className={styles['list__item']}>
            <Link to={routes.tos} classes="inverted">
              Kundeninformation
            </Link>
          </li>
          <li className={styles['list__item']}>
            <Link to={routes.aboutUs} classes="inverted">
              Impressum
            </Link>
          </li>
          <li className={styles['list__item']}>
            <Link to={routes.ror} classes="inverted">
              Widerrufsrecht
            </Link>
          </li>
          <li className={styles['list__item']}>
            <Link to={routes.gdpr} classes="inverted">
              Datenschutzerklärung
            </Link>
          </li>
          <li className={styles['list__item']}>
            <Link to={routes.contact} classes="inverted">
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles['newsletter']}>
        <Typography variant="h5" classes={{ root: styles['newsletter__heading'] }}>
          Don&apos;t want to miss any of your favorite bottles?
          <br /> Sign up and stay tuned.
        </Typography>

        <Newsletter
          placeholder="E-mail address"
          buttonLabel={<Trans ns="Newsletter" id="Newsletter.VeryBeginigNewsletter" msg="Let me know!" />}
          controlLabel=""
          classes={{ checkbox: styles['newsletter__checkbox'], input: styles['newsletter__label'] }}
        />
        <div className={styles['social-icons']}>
          <ul className={styles['social-icons__list']}>
            <li className={cn(styles['social-icons__list__item'], styles['social-icon'])}>
              <a className={styles['social-icon__link']} rel="noreferrer" href="https://www.facebook.com/whiskymarketcom" target="_blank">
                <Img classes={styles['social-icon__icon']} src="/icons/facebook.svg" />
              </a>
            </li>
            <li className={styles['share-icons__item']}>
              <a
                className={styles['social-icon__link']}
                rel="noreferrer"
                href="https://www.instagram.com/whiskymarket_com/"
                target="_blank"
              >
                <Img classes={styles['social-icon__icon']} src="/icons/instagram.svg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = defaultProps

export default Footer
