import OfferType from '../../../interfaces/Offer.type'

const createMetaDescription = (offer: OfferType): string => {
  const result = []

  if (offer.bottle.distillery.name !== 'Other') {
    result.push(offer.bottle.distillery.name)
  }

  if (offer.bottle.distilled) {
    result.push(`Distilled in ${offer.bottle.distilled}`)
  }

  if (offer.bottle.bottled) {
    result.push(`Bottled in ${offer.bottle.bottled}`)
  }

  if (offer.age) {
    result.push(`${offer.age} years old`)
  }

  if (offer.bottle.abv) {
    result.push(`ABV ${offer.bottle.abv}%`)
  }

  if (offer.bottle.size) {
    result.push(`Bottle size ${offer.bottle.size}ml`)
  }

  if (offer.bottle.caskNumber) {
    result.push(`Cask#${offer.bottle.caskNumber}`)
  }

  if (offer.subtitle) {
    result.push(offer.subtitle)
  }

  return result.join('. ')
}

export default createMetaDescription
