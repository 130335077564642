import { Button, Dialog } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import styles from './AgeAgreement.module.scss'
import * as actions from '../../services/app/app.actions'
import { ActionTypes } from '../../services/app/app.types'
import { StateType } from '../../services/reducers'
import FullscreenImageLayout from '../Layout/FullscreenImageLayout'
import Trans from '../Trans'

const REDIRECT_URL = 'https://disney.com/'

const AgeAgreement = () => {
  const dispatch: Dispatch<ActionTypes> = useDispatch()
  const acceptedAgeAgreement = useSelector((state: StateType) => state.app.acceptedAgeAgreement)
  const [isLeaving, setIsLeaving] = useState<boolean>(false)

  const handleAccept = () => {
    dispatch(actions.ageAgreementAccept())
  }

  const handleDecline = () => {
    setIsLeaving(true)
    setTimeout(() => {
      if (window) {
        window.location.href = REDIRECT_URL
      }
    }, 3000)
  }

  return acceptedAgeAgreement ? null : (
    <Dialog fullScreen open onClose={handleDecline}>
      <FullscreenImageLayout image="https://cdn.whiskymarket.com/lp/age-agreement.jpg" classes={{ container: styles['container'] }}>
        <div className={styles['content']}>
          {isLeaving ? (
            <h3>
              <Trans ns="AgeAgreement" id="Leaving" msg="We’re sorry, we can’t let you in..." />
            </h3>
          ) : (
            <>
              <h3>
                <Trans ns="AgeAgreement" id="Header" msg="Are you 18+?" />
              </h3>
              <div>
                <Trans ns="AgeAgreement" id="Description" msg="To visit our website you have to be of legal drinking age." />
              </div>
              <div className={styles['button-container']}>
                <Button variant="contained" type="submit" color="primary" onClick={handleAccept}>
                  <Trans ns="AgeAgreement" id="Accept" msg="I am 18+, let me in" />
                </Button>
                <Button variant="contained" type="submit" color="secondary" onClick={handleDecline}>
                  <Trans ns="AgeAgreement" id="Decline" msg="No, I want to leave" />
                </Button>
              </div>
            </>
          )}
        </div>
      </FullscreenImageLayout>
    </Dialog>
  )
}

export default AgeAgreement
