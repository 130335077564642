import { combineReducers } from 'redux'
import app from './app/app.reducer'
import { State as AppState } from './app/app.types'
import catalog from './catalog/catalog.reducer'
import { State as CatalogState } from './catalog/catalog.types'
import notification from './notification/notification.reducer'
import { State as NotificationState } from './notification/notification.types'
import offer from './offer/offer.reducer'
import { State as OfferState } from './offer/offer.types'
import user from './user/user.reducer'
import { State as UserState } from './user/user.types'

export default combineReducers({
  notification,
  app,
  catalog,
  offer,
  user,
})

export type StateType = {
  notification: NotificationState
  app: AppState
  catalog: CatalogState
  offer: OfferState
  user: UserState
}
