import { moment } from '../../../../utils/date'
import Yup from '../../../../utils/yup'

const birthdayMinDate = moment().subtract(120, 'years')
const birthdayMaxDate = moment().subtract(18, 'years')

const schema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .max(25, 'Can contain 25 signs'),
  name: Yup.string()
    .trim()
    .max(30, 'Can contain 30 signs')
    .required('Required for verificaiton'),
  surname: Yup.string()
    .ensure()
    .trim()
    .max(30, 'Can contain 30 signs')
    .required('Required for verificaiton'),
  address1: Yup.string()
    .trim()
    .max(30, 'Can contain 30 signs'),
  address2: Yup.string()
    .trim()
    .max(30, 'Can contain 30 signs'),
  postCode: Yup.string()
    .trim()
    .max(50, 'Can contain 50 signs'),
  city: Yup.string(),
  birthday: Yup.string()
    .nullable()
    // @ts-ignore
    .isValidDateRange(birthdayMinDate, birthdayMaxDate, 'Wrong fromat of date', 'Not possible you still alive', 'You are to young'),
  country: Yup.string()
    .required('Required for verificaiton')
    //@ts-ignore
    .isValidCountryIsoCode('Must be valid country name'),
  aboutMe: Yup.string()
    .trim()
    .max(200, 'Can contain 200 signs'),
})

export default schema
