import _ from 'lodash'
import {
  ActionTypes,
  AddOfferToFavorite,
  RemoveOfferFromFavorite,
  SetFavoriteOffers,
  State,
  TemporarilyRemoveOfferFromFavorite,
  types
  } from './offer.types'

const initialState: Readonly<State> = {
  favorites: [],
}

const offerReducer = (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case types.CLEAR_FAVORITE_OFFERS:
      return reduceClearFavoriteOffers(state)

    case types.SET_FAVORITE_OFFERS:
      return reduceSetFavoriteOffers(state, action)

    case types.ADD_OFFER_TO_FAVORITE:
      return reduceAddOfferToFavorite(state, action)

    case types.TEMPORARILY_REMOVE_OFFER_FROM_FAVORITE:
      return reduceTemporarilyRemoveOfferFromFavorite(state, action)

    case types.REMOVE_OFFER_FROM_FAVORITE:
      return reduceRemoveOfferFromFavorite(state, action)
  }

  return state
}

const reduceClearFavoriteOffers = (state: State): State => {
  return {
    ...state,
    favorites: [],
  }
}

const reduceSetFavoriteOffers = (state: State, action: SetFavoriteOffers): State => {
  return {
    ...state,
    favorites: action.offerIds.map(offerId => ({
      offerId,
      status: 'added',
    }))
  }
}

const reduceAddOfferToFavorite = (state: State, action: AddOfferToFavorite): State => {
  const newState = _.cloneDeep(state)
  const index = newState.favorites.findIndex(item => item.offerId === action.offer.id)

  if (index >= 0) {
    newState.favorites[index].status = 'added'
  } else {
    newState.favorites.unshift(
      {
        offerId: action.offer.id,
        status: 'added',
      },
      ...newState.favorites,
    )
  }

  return newState
}

const reduceTemporarilyRemoveOfferFromFavorite = (state: State, action: TemporarilyRemoveOfferFromFavorite): State => {
  const newState = _.cloneDeep(state)

  newState.favorites.map(item => {
    if (item.offerId === action.offer.id) {
      item.status = 'temporarily-removed'
    }

    return item
  })

  return newState
}

const reduceRemoveOfferFromFavorite = (state: State, action: RemoveOfferFromFavorite): State => {
  const newState = _.cloneDeep(state)
  newState.favorites = newState.favorites.filter(item => item.offerId !== action.offer.id)
  return newState
}

export default offerReducer
