import { Typography } from '@material-ui/core'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import styles from './OffersPage.module.scss'
import Link, { routes } from '../../components/Link'
import Trans from '../../components/Trans'
import { resolveRoute } from '../../components/Link/linkResolver'
import PageLayout from '../../components/PageLayout'
import pageStyles from '../../components/PageLayout/PageLayout.module.scss'
import SearchInput from '../../components/Search/SearchInput'
import UserOffers, { USER_OFFERS_TYPE } from '../../components/User/UserOffers'
import UserContext from '../../contexts/UserContext'
import useAuth from '../../hooks/auth'
import OfferType from '../../interfaces/Offer.type'
import UserType, { UserDataType } from '../../interfaces/User.type'
import * as userApi from '../../services/user/user.api'

const defaultProps = Object.freeze({})
type Props = typeof defaultProps

type UrlParamsType = {
  id: string
}

const OffersPage = (props: Props) => {
  const history = useHistory()
  const location = useLocation()
  const urlParams = useParams<UrlParamsType>()
  const [userId, setUserId] = useState<string | undefined>(urlParams.id)
  const [query, setQuery] = useState(new URLSearchParams(location.search).get('query') || '')
  const userContext: UserType = useContext(UserContext)
  let userInitial: UserDataType | undefined
  let ownerType = USER_OFFERS_TYPE.MY

  if (urlParams.id && urlParams.id !== userContext.data?.id) {
    ownerType = USER_OFFERS_TYPE.OTHER
  } else {
    userInitial = userContext.data!
  }

  const { isAuth } = useAuth()
  const [loadMoreUrl, setLoadMoreUrl] = useState<string | undefined>(undefined)
  const [items, setItems] = useState<OfferType[] | false>(false)
  const [user, setUser] = useState<UserDataType>(userInitial!)
  const [error, setError] = React.useState<string>('')

  useEffect(() => {
    setUserId(urlParams.id || userInitial?.id)
    setItems(false)
    setLoadMoreUrl(undefined)
  }, [urlParams.id])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const currentQuery = queryParams.get('query') || ''

    if (currentQuery !== query) {
      setQuery(currentQuery)
    }
  }, [location])

  useEffect(() => {
    onLoadMore(true)
  }, [query])

  const onLoadMore = async (clear: boolean = false, all: boolean = false) => {
    try {
      all = all && ownerType == USER_OFFERS_TYPE.MY
      clear = clear || all

      const response = userId
        ? await userApi.readOffers(userId, all || clear ? undefined : loadMoreUrl, all, query)
        : await userApi.readMyOffers(all || clear ? undefined : loadMoreUrl, all, query)

      const offersCounter = response.data?.user?.offers || 0

      if (response.data.items[0]?.user) {
        //TODO: change after backend update
        setUser({ ...response.data.items[0]?.user, offers: offersCounter }) // _.cloneDeep(result.user!)
        const newItems = [..._.cloneDeep(items || []), ...response.data.items]
        setItems(clear ? response.data.items : newItems)
        setLoadMoreUrl(response.data._links.next!)
        setError('')
      } else if (items === false) {
        setItems([])
      }

    } catch (e) {
      console.error(e)

      if (clear || items === false || items.length === 0) {
        // if (e?.response?.data?.message) {
        //   setError(e.response.data.message)
        // } else {
        setError('No offers found')
        // }
      }

      if (items === false) {
        setItems([])
      } else if (clear) {
        setItems([])
      }
    }
  }

  const onSearchClick = (value: string) => {
    const pathname = resolveRoute(routes.userOffers, { id: userId })
    history.push({ pathname, search: `query=${encodeURIComponent(value)}` })
  }

  const onOfferUpdate = (offer: OfferType) => {
    if (items) {
      const newItems = _.cloneDeep(items).map(item => (item.id === offer.id ? _.cloneDeep(offer) : item))
      setItems(newItems)
    }
  }

  const onOffersArchive = (ids: string[]) => {
    if (items) {
      const newItems = _.cloneDeep(items).map(item => {
        if (ids.includes(item.id)) {
          item.isSold = false
          item.isActive = false
          item.isArchived = true
        }

        return item
      })

      setItems(newItems)
    }
  }

  const onOffersRemove = (ids: string[]) => {
    if (items) {
      const newItems = _.cloneDeep(items).filter(item => !ids.includes(item.id))
      setItems(newItems)
    }
  }

  useEffect(() => {
    onLoadMore(true)
  }, [userId])

  useEffect(() => {
    onLoadMore(true)
  }, [])

  if (!isAuth && !userId && ownerType == USER_OFFERS_TYPE.MY) {
    return <Redirect to={routes.signIn} />
  }

  const itemsCounter = user?.offers || 0

  return (
    <PageLayout classes={classNames(pageStyles['content--sides-padding'])} headerMode="simplified">
      {error ? (
        <div className={styles['offers']}>
          {/* <Typography variant="h3" classes={{ root: classNames(styles['offers__headline'], ownerType == USER_OFFERS_TYPE.MY && styles['content--mine']) }}>
            {ownerType === USER_OFFERS_TYPE.MY ? (
              <Trans ns="UserOffers" id="MyOffersHeadline" msg="My Offers" />
            ) : (
              <>
                <Trans ns="UserOffers" id="OtherOffersHeadline" msg="Offers:" /> <a className="primary text">{user?.username}</a>
              </>
            )}
          </Typography> */}
          <div className={styles['search__container']}>
            <SearchInput
              onSearchClick={onSearchClick}
              userId={userId}
              name="query"
              value={query}
              placeholder={itemsCounter > 0 ? `Search from ${itemsCounter} ${user?.username}'s offer${itemsCounter > 1 ? 's' : ''}` : 'Search'}
            />
            {query ? (
              <Link classes={classNames('primary', styles['search__show-all'])} to={routes.catalog} search={`query=${encodeURIComponent(query)}`}>
                <Trans ns="UserOffers" id="SearchShowAll" msg="Show results for all offers" />
              </Link>
            ) : null}
          </div>
          <h1 className={styles['error']}>{error}</h1>
        </div>
      ) : (
        <UserOffers
          user={user}
          ownerType={ownerType}
          items={items!}
          onLoadMore={onLoadMore}
          onSearchClick={onSearchClick}
          onOfferUpdate={onOfferUpdate}
          onOffersArchive={onOffersArchive}
          onOffersRemove={onOffersRemove}
          loadMoreUrl={loadMoreUrl}
        />
      )}
    </PageLayout>
  )
}

OffersPage.defaultProps = defaultProps

export default OffersPage
