import moment from 'moment'
import 'moment/locale/en-au'
import 'moment/locale/pl'
import store from '../services/store'

const language = store.getState().app.language
moment.locale(language.fullIsoCode.toLocaleLowerCase())

export { moment }

class DateUtil {
  dateToUnixTimestamp(date: string): number {
    const currentFormat = this.getDateFormatPattern(moment())
    const timestamp = moment(date, currentFormat).unix()
    if ((timestamp === null || Number.isNaN(timestamp)) && parseInt(date, 10) > 0) {
      return 0
    }

    return timestamp
  }

  timestampToDateString(timestamp: number) {
    if (timestamp == null) {
      return null
    }
    const currentFormat = this.getDateFormatPattern(<moment.Moment>(<unknown>moment))
    return moment.unix(timestamp).format(currentFormat)
  }

  timestampToFormat(timestamp: number, format: string, milliseconds: boolean) {
    if (timestamp == null) {
      return null
    }

    const stamp = milliseconds ? timestamp / 1000 : timestamp

    return moment.unix(stamp).format(format)
  }

  /**
   * Return the date format from Moment.ts
   * @param longFormat - possible values below
   * LT : 'HH:mm',
   * LTS : 'HH:mm:ss',
   * L : 'DD/MM/YYYY',
   * LL : 'D MMMM YYYY',
   * LLL : 'D MMMM YYYY HH:mm',
   * LLLL : 'dddd D MMMM YYYY HH:mm'
   **/
  getDateFormatPattern(dateMoment: moment.Moment, longFormat: moment.LongDateFormatKey = 'L') {
    const momentLocale = dateMoment.localeData()
    return momentLocale.longDateFormat(longFormat)
  }
}

const date = new DateUtil()
export default date
