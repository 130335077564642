import { Typography } from '@material-ui/core'
import React from 'react'
import styles from '../RoRPage.module.scss'

const RoRPageDE = () => {
  const bodyClass = { root: styles['list__text'] }
  return (
    <>
      <Typography variant="h3" classes={{ root: styles['heading'] }}>
        Widerrufsrecht für Verbraucher
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        (Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch
        ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden können.)
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Widerrufsbelehrung
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Widerrufsrecht
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
        <br />
        Die Widerrufsfrist beträgt 14 Tage ab dem Tag,
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat,
            sofern Sie eine oder mehrere Waren im Rahmen einer einheitlichen Bestellung bestellt haben und diese einheitlich geliefert wird
            bzw. werden;
          </li>
          <li className={styles['list__item']}>
            an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw.
            hat, sofern Sie mehrere Waren im Rahmen einer einheitlichen Bestellung bestellt haben und diese getrennt geliefert werden;
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
        <b>
          (JP Whiskymarket.com GmbH, Oehlertring 1, 12169 Berlin, Telefonnr.: +49 (0) 152 15 25 30 5 77 55, e-mail:
          <a href="mailto:info@whiskymarket.com" className="primary">
            info@whiskymarket.com
          </a>
          )
        </b>
        mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen
        Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht
        vorgeschrieben ist.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
        Widerrufsfrist absenden.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Folgen des Widerrufs
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
        Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns
        angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an
        dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
        Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes
        vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass
        Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen 14 Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
        Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von
        14 Tagen absenden.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,
        Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Ausschluss- bzw. Erlöschensgründe
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Das Widerrufsrecht besteht nicht bei Verträgen
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            zur Lieferung von Waren, die nicht vorgefertigt sind und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch
            den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind;
          </li>
          <li className={styles['list__item']}>
            zur Lieferung von Waren, die schnell verderben können oder deren Verfallsdatum schnell überschritten würde;
          </li>
          <li className={styles['list__item']}>
            zur Lieferung alkoholischer Getränke, deren Preis bei Vertragsschluss vereinbart wurde, die aber frühestens 30 Tage nach
            Vertragsschluss geliefert werden können und deren aktueller Wert von Schwankungen auf dem Markt abhängt, auf die der Unternehmer
            keinen Einfluss hat;
          </li>
          <li className={styles['list__item']}>
            zur Lieferung von Zeitungen, Zeitschriften oder Illustrierten mit Ausnahme von Abonnement-Verträgen.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        Das Widerrufsrecht erlischt vorzeitig bei Verträgen
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            zur Lieferung versiegelter Waren, die aus Gründen des Gesundheitsschutzes oder der Hygiene nicht zur Rückgabe geeignet sind,
            wenn ihre Versiegelung nach der Lieferung entfernt wurde;
          </li>
          <li className={styles['list__item']}>
            zur Lieferung von Waren, wenn diese nach der Lieferung aufgrund ihrer Beschaffenheit untrennbar mit anderen Gütern vermischt
            wurden;
          </li>
          <li className={styles['list__item']}>
            zur Lieferung von Ton- oder Videoaufnahmen oder Computersoftware in einer versiegelten Packung, wenn die Versiegelung nach der
            Lieferung entfernt wurde.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <hr className={styles['delimiter']} />
      </Typography>
      <Typography variant="h4" classes={{ root: styles['subheading'] }}>
        Muster-Widerrufsformular
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück.)
      </Typography>
      <Typography variant="body1" classes={bodyClass} component="span">
        <ul className={styles['list']}>
          <li className={styles['list__item']}>
            An JP Whiskymarket.com GmbH, Oehlertring 1, 12169 Berlin, e-mail:
            <a href="mailto:info@whiskymarket.com" className="primary">
              info@whiskymarket.com
            </a>
            ,
          </li>
          <li className={styles['list__item']}>
            Hiermit widerrufe(n) ich/ wir (*) den von mir/ uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/ die
            Erbringung der folgenden Dienstleistung (*)
          </li>
          <li className={styles['list__item']}>Bestellt am (*)/ erhalten am (*)</li>
          <li className={styles['list__item']}>Name des/ der Verbraucher(s)</li>
          <li className={styles['list__item']}>Anschrift des/ der Verbraucher(s)</li>
          <li className={styles['list__item']}>Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
          <li className={styles['list__item']}>Datum</li>
        </ul>
      </Typography>
      <Typography variant="body1" classes={bodyClass}>
        (*) Unzutreffendes streichen.
      </Typography>
    </>
  )
}

RoRPageDE.getInitialProps = function() {
  return {}
}

export default RoRPageDE
