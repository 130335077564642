import React from 'react'
import { useHistory } from 'react-router'
import HomeView from '../../components/Home'
import { routes } from '../../components/Link'
import { Slide } from '../../components/Slider'
import InitContext from '../../contexts/InitContext'
import { BasicFiltersType } from '../../interfaces/Filters.type'
import OfferType, {
  OfferAdType,
  OfferFieldsValuesType,
  OfferSelectFieldType,
  SELECT_FIELD_NAME_AVAILABILITY,
  SELECT_FIELD_NAME_BOTTLE_TYPE,
  SELECT_FIELD_NAME_CASK_TYPE,
  SELECT_FIELD_NAME_CATEGORY,
  SELECT_FIELD_NAME_CONDITION,
  SELECT_FIELD_NAME_FILL_LEVEL,
  SELECT_FIELD_NAME_PACKAGE
  } from '../../interfaces/Offer.type'
import * as api from '../../services/offer/offer.api'
import axios from '../../utils/axios'
import { stringify as stringifyFilters } from '../../utils/filters'

const HomePage = () => {
  const history = useHistory()
  const initData = React.useContext(InitContext)
  const [fieldsValues, setFieldsValues] = React.useState<OfferFieldsValuesType>()
  const [offers, setOffers] = React.useState<Array<OfferType | OfferAdType>>([])

  React.useEffect(() => {
    const initFetch = async () => {
      try {
        const response = await api.searchFieldsHints()
        setFieldsValues({
          bottleType: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_BOTTLE_TYPE)[0],
          category: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CATEGORY)[0],
          caskType: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CASK_TYPE)[0],
          condition: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_CONDITION)[0],
          fillLevel: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_FILL_LEVEL)[0],
          availability: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_AVAILABILITY)[0],
          package: response.data.filter((item: OfferSelectFieldType) => item.type === SELECT_FIELD_NAME_PACKAGE)[0],
        })
      } catch (error) {
        console.error(error)
      }
    }

    const fetch = async () => {
      const response = await axios({
        method: 'GET',
        url: '/search/offer/popular',
      })

      setOffers(response.data.items)
    }

    initFetch()
    fetch()
  }, [])

  const handleSliderItemClick = (item: Slide) => {
    const openUrl = (url: string) => {
      const isExternal = url.startsWith('http:') || url.startsWith('https:')

      if (isExternal) {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      } else {
        history.push(url)
      }
    }

    if (item.url) {
      openUrl(item.url)
    }
  }

  const handleSubmit = async (inFilters: BasicFiltersType) => {
    history.push({ pathname: routes.catalog, search: stringifyFilters(inFilters) })
  }

  return <HomeView
    mainBanner={initData.homePage.mainBanner}
    slides={initData.homePage.slides}
    topSearches={initData.homePage.topSearches}
    mostWanted={initData.homePage.mostWanted}
    fieldsValues={fieldsValues}
    offers={offers}
    onSlideClick={handleSliderItemClick}
    onSubmit={handleSubmit}
  />
}

export default HomePage
