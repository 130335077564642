import classNames from 'classnames'
import React from 'react'
import styles from './Arrow.module.scss'

type Props = {
  type: 'prev' | 'next'
  onClick?: () => void
}

const Arrow = (props: Props) => (
  <div
    className={classNames(styles['arrow'], props.type === 'prev' && styles['arrow--prev'], props.type === 'next' && styles['arrow--next'])}
    onClick={props.onClick}
  />
)

export default Arrow
