import classNames from 'classnames'
import React from 'react'
import Slick from 'react-slick'
import Arrow from './Arrow'
import styles from './Slider.module.scss'
import Img from '../Img'

export type Slide = {
  src: string
  srcSet?: string
  url?: string
}

type Props = {
  slides: Slide[]
  onItemClick?: (item: Slide, index: number) => void
}

type RefType = React.MutableRefObject<any> | ((instance: any) => void) | null

const Slider = React.forwardRef((props: Props, ref: RefType) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [clickable, setClickable] = React.useState(true)

  const settings = {
    className: styles['slider'],
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <Arrow type="prev" />,
    nextArrow: <Arrow type="next" />,
    beforeChange: (prev: number, next: number) => {
      setClickable(false)
      setCurrentSlide(next)
    },
    afterChange: (index: number) => {
      setClickable(true)
    },
    appendDots: (dots: any[]) => (
      <div>
        <ul className={styles['dots']}>
          {dots.map((item, index) => {
            return (
              <li key={`i-${index}`} className={classNames(styles['dots__item'], index === currentSlide && styles['dots__item--active'])}>
                {item.props.children}
              </li>
            )
          })}
        </ul>
      </div>
    ),
    customPaging: () => <button type="button" />,
  }

  return (
    <div ref={ref}>
      <Slick {...settings}>
        {props.slides.map((item, index) => (
          <div key={item.src} className={styles['slide']}>
            <Img
              classes={classNames(styles['slide__image'], props.onItemClick && styles['slide__link'])}
              src={item.src}
              srcSet={item.srcSet}
              onClick={() => clickable && props.onItemClick!(item, index)}
            />
          </div>
        ))}
      </Slick>
    </div>
  )
})

export default Slider
