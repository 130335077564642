import {
  ActionTypes,
  types,
  NotificationInterface,
  NotificationCreateRequestInterface,
  NotificationDeleteRequestInterface,
  State,
} from './notification.types'

const initialState: Readonly<State> = {
  messages: [],
}

function reduceNotificationCreate(state: State, action: NotificationCreateRequestInterface) {
  const message: NotificationInterface = <NotificationInterface>{
    id: action.payload.id,
    type: action.payload.type,
    content: action.payload.content,
    options: action.payload.options,
  }

  const messages: NotificationInterface[] = state.messages.map((item: NotificationInterface) => ({ ...item }))
  messages.push(message)

  return {
    messages,
  }
}

function reduceNotificationDelete(state: State, action: NotificationDeleteRequestInterface) {
  const messages = state.messages
    .map((item: NotificationInterface) => ({ ...item }))
    .filter(item => item.id !== action.payload)
  return {
    messages,
  }
}

function reduceNotificationsDelete() {
  return initialState
}

function notificationReducer(state: State = initialState, action: ActionTypes): State {
  switch (action.type) {
    case types.NOTIFICATION_CREATE_REQUEST:
      return reduceNotificationCreate(state, action)

    case types.NOTIFICATION_DELETE_REQUEST:
      return reduceNotificationDelete(state, action)

    case types.NOTIFICATIONS_DELETE_REQUEST:
      return reduceNotificationsDelete()
  }

  return state
}

export default notificationReducer
