import { passwordMinChars } from '../../utils/validation'
import Yup from '../../utils/yup'

const schema = Yup.object().shape({
  password: Yup.string()
    .ensure()
    .min(passwordMinChars, 'Password must contain at least 6 characters')
    .required('Required'),
  repeatedPassword: Yup.string()
    .ensure()
    // @ts-ignore
    .equalTo(Yup.ref('password'), 'Passwords must match')
    .required('Required'),
})

export default schema
